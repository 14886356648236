/** @jsx dom */
import dom from 'magic-virtual-element';
import {SelectField} from '@viskan/deku-form';

const handleChange = props => e => {
	const sku = v12.article.resetAttributes(props.article, Object.assign({}, props.active, {
		[`${props.attribute}_id`]: parseInt(e.target.value, 10)
	}));

	props.setSku(props.article, sku, {getFirstInStock: props.attribute === 'attr1'});
};

const getItems = (attributes, id) => attributes.map(attribute => ({
	label: attribute[`attribute${id}`][`attr${id}_code`],
	value: attribute[`attr${id}_id`]
}));

const getAttribute = props => {
	const attributeArray = v12.article.attributes(props.article, props.active, {checkBalance: props.checkBalance})[props.attribute];
	const attributeId = props.attribute.match(/attr(\d)/)[1];
	const items = getItems(attributeArray, attributeId);
	const name = `${props.attribute}_id`;

	if (items.length === 0 || items.length === 1) {
		return <noscript key={`${name}-${props.article.art_id}`}/>;
	}

	return <SelectField key={`${props.attribute}-${props.article.art_id}`} class='CMS-AddonAttributes-select' name={name} value={props.active[name]} label={props.label} options={items} onChange={handleChange(props)}/>;
};

const render = ({props}) => {
	return (
		<div class={['CMS-AddonAttributes', props.class]}>
			{getAttribute(props)}
		</div>
	);
};

export default {render};
