/** @jsx dom */
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const render = ({props}) => {
	const {hideOutOfStockButton, outOfStockMessage, closePopup, popupIsOpen} = props;
	const inStock = props.article.inStock && props.active.inStock;
	const styles = {
		cursor: inStock ? 'pointer' : 'not-allowed'
	};

	if (hideOutOfStockButton && !inStock) {
		return cmsUtils.createCmsComponent((
			<div class={['CMS-Article-outOfStockMessage', props.class, props.styleClasses.Container]} data-style-attr='Container'>
				{outOfStockMessage}
			</div>
		), props);
	}

	const onClick = () => {
		if (popupIsOpen) {
			closePopup();
		}
		debounce(() => props.addToCart(props.article, props.active, props.addons, props.quantity), 1000, true)();
	};

	return cmsUtils.createCmsComponent((
		<button class={['CMS-Article-buyButton', props.class, props.styleClasses.Button]} style={styles} disabled={!inStock} onClick={onClick} data-style-attr='Button'>
			{inStock ? props.buttonText : props.outOfStockButtonText}
		</button>
	), props);
};

export default {render};
