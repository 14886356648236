import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../sources/translator';
import Shopcart from './components';
import DiscountComponent from './components/discount';
import RowComponent from './components/row';
import SummaryComponent from './components/summary';
import {edit, removeItem} from './actions';

export default connect(
	state => ({
		getTranslation: getTranslator(state),
		shopcart: state.shopcart
	})
)(Shopcart);

export const Discount = connect(
	state => ({
		getTranslation: getTranslator(state)
	})
)(DiscountComponent);

export const Row = connect(
	state => ({
		getTranslation: getTranslator(state)
	}),
	dispatch => ({
		editCart: (id, qty, dontOpen) => dispatch(edit(id, qty, dontOpen)),
		removeFromCart: (data, qty, dontOpen) => dispatch(removeItem(data, qty, dontOpen))
	})
)(RowComponent);

export const Summary = connect(
	state => ({
		getTranslation: getTranslator(state)
	})
)(SummaryComponent);
