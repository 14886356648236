/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import Popup from '@viskan/deku-popup';
import {url} from '@viskan/streamline-utilities';
import Link from '@viskan/deku-link';
import {Image} from '../../../media';

const name = 'Locale';

const renderLocales = ({countries, localeMode, flags}) => {
	return countries.map(country => {
		return (
			<div class='Locales-country' key={country.id}>
				<Link href='/' localeMode={localeMode} countryCode={country.iso} languageCode={country.language} title={country.name} class='CMS-Locale-link'>
					{flags && <Image class='CMS-Locale-flag' source={`${country.iso.toLowerCase()}.png`}/>}{country.name}
				</Link>
			</div>
		);
	});
};

const render = ({props}) => {
	if (props.localeMode === 'DOMAIN_ONLY') {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class={name}>
			{renderLocales(props)}
		</div>
	), props);
};

export default {name, render};
