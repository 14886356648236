/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import emailRegex from 'email-regex';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import store from 'store2';
import {utils as cmsUtils} from '@viskan/cms-components';
import {Form, InputField} from '@viskan/deku-form';
import {Spinner} from '@viskan/estore-components';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	validationError: {
		source: 'validationError'
	}
};

const getFeedback = ({feedbackDescription, feedbackHeader, styleClasses}) => {
	return (
		<div class='CMS-CustomerMatchForm-feedbackWrapper'>
			<h1 class={['CMS-CustomerMatchForm-feedbackHeader', styleClasses.Feedback_Header]} data-style-attr='Feedback_Header'>{feedbackHeader}</h1>
			<p class={['CMS-CustomerMatchForm-feedbackDescription', styleClasses.Feedback_Description]} data-style-attr='Feedback_Description'>{feedbackDescription}</p>
		</div>
	);
};

const onSubmit = (props, setState) => data => {
	const email = data.email;
	const {country_id} = props.site;

	setState({loading: true});
	props.saveEmail(email);

	if (emailRegex().test(store('vs-email'))) {
		v12.customer.match({
			'del_country.id': country_id,
			'inv_country.id': country_id,
			'vis_country.id': country_id,
			email_1: store('vs-email')
		})
			.then(data => {
				bus.emit('login', data);
				setState({matched: true, loading: false});
			})
			.catch(v12.util.error);
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(state, nextState) || !deepEqual(props, nextProps);

const render = ({props, state}, setState) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-CustomerMatchForm', props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{state.matched ? getFeedback(props) : (
				<Form onSubmit={onSubmit(props, setState)}>
					<div class={['CMS-CustomerMatchForm-header', props.styleClasses.Header]} data-style-attr='Header'>{props.header}</div>
					<InputField class={['CMS-CustomerMatchForm-input', props.styleClasses.Input]} data-style-attr='Input' type='email' name='email' placeholder={props.placeholder} validationMessage={props.validationError(props.getTranslation('newsletter.email'), props.getTranslation)} required/>
					<Button class={['CMS-CustomerMatchForm-button', props.styleClasses.Button]} data-style-attr='Button' type='submit'>
						{props.buttonText}
					</Button>
					{state.loading && <Spinner/>}
				</Form>
			)}
		</div>
	), props);
};

export default {propTypes, render, shouldUpdate};
