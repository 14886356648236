import {connect} from '@viskan/deku-redux';
import Component from './components';
import config from './config';

const component = connect(
	({site, cmsComponents}, {id}) => ({
		localeMode: site.localeMode,
		countries: site.countries,
		locale: site.locale,
		localeMode: site.localeMode
	})
)(Component);

export default {component, config};
