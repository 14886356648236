import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../sources/translator';
import {articlesFetch, articlesFetchFavorites, articlesSetState, nextPagination} from './actions';
import config from './config';
import Component from './components';

export const getSort = state => {
	if (state.articles.sort && state.articles.sort[0]) {
		return state.articles.sort;
	}

	return [{[state.app.category.main.sortByField]: state.app.category.main.sortDescending ? 'desc' : 'asc'}];
};

export const component = connect(
	state => ({
		activeFilters: state.articles.activeFilters,
		articles: state.articles.articles,
		back: state.articles.back,
		breakpoint: typeof state.breakpoint.breakpointOverride === 'number' ? state.breakpoint.breakpointOverride : state.breakpoint.breakpoint,
		categoryId: state.app.category.main.categoryId,
		cmsActive: state.streamlineCms.loaded && !state.cms.base.hidden,
		favorites: state.articles.favorites.articles,
		favoritesArtNos: state.articles.favorites.artNos,
		firstTotal: state.articles.firstTotal,
		from: state.articles.from,
		front: state.articles.front,
		getTranslation: getTranslator(state),
		initialLoad: state.articles.initialLoad,
		initialSize: state.articles.initialSize,
		isArticle: state.router.path.startsWith(`/${state.site.articleUrlPrefix}/`),
		isCrawler: state.app.isCrawler,
		isLoading: state.articles.isLoading,
		meta: state.articles.meta,
		notFound: false,
		searchQuery: state.router.searchQuery,
		sort: getSort(state),
		viewActive: state.articles.viewActive
	}),
	dispatch => ({
		articlesFetch: (categoryId, options) => dispatch(articlesFetch(categoryId, options)),
		articlesFetchFavorites: (favoritesArtNos, splitByAttribute1) => dispatch(articlesFetchFavorites(favoritesArtNos, splitByAttribute1)),
		articlesSetState: stateObject => dispatch(articlesSetState(stateObject)),
		nextPagination: options => dispatch(nextPagination(options))
	})
)(Component);

export default {component, config};
