/** @jsx dom */
import clickOutside from 'click-outside';
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import page from 'page';
import {Dropdown, DropdownMenu} from '@viskan/estore-components';
import {Form} from '@viskan/deku-form';
import {utils as cmsUtils} from '@viskan/cms-components';
import {getLocaleFriendlyPath} from '@viskan/streamline-utilities/lib/url';
import getIcon from '../../utils/actions';
import SearchFormRow from './row';

const propTypes = {
	class: {
		type: 'string'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	size: {
		type: 'number'
	},
	onSearch: {
		type: 'function'
	}
};

const defaultProps = {
	size: 5
};

const name = 'CMS-SearchForm';

const initialState = () => {
	return {
		data: {
			articles: {
				hits: []
			},
			meta: {}
		},
		query: ''
	};
};

const onFocus = () => e => {
	e.target.value = '';
};

const onKeyUp = (size, setState, splitByAttribute1) => e => {
	const query = e.target.value.trim();

	if (query.length === 0) {
		setState(initialState());
		return;
	}

	setState({query});

	v12.search(query, {
		size,
		query: {
			splitByAttribute1
		}
	})
	.then(data => setState({data}))
	.catch(v12.util.error);
};

const onSubmit = (props, setState) => data => {
	const val = (data.query || '').trim();

	if (val.length !== 0) {
		page(getLocaleFriendlyPath(`${props.searchPath}/${val}`, props.localeMode, props.pathPrefix));
	}

	setState({query: ''});
	document.activeElement.blur();

	if (props.onSearch) {
		props.onSearch();
	}
};

const getRows = (prefix, data, meta) => data.map(x => <SearchFormRow prefix={prefix} data={x} meta={meta}/>);
const getDropdown = (props, data, meta) => (
	<DropdownMenu class='SearchFormDropdownMenu' alignRight>
		{getRows(props.articleUrlPrefix, data, meta)}
	</DropdownMenu>
);

const afterMount = ({state}, el, setState) => {
	requestAnimationFrame(() => {
		clickOutside(el.querySelector('input'), () => setState({
			query: ''
		}));
	});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {size, splitByAttribute1, placeholder} = props;
	const {data, query} = state;
	const {articles, meta} = data;

	return cmsUtils.createCmsComponent((
		<div class='CMS-SearchForm'>
			<Dropdown class='SearchForm'>
				{props.icon && <span class='SearchForm-icon'>{getIcon(props)}</span>}
				{props.image && <span class='SearchForm-image'><img src={props.image} alt=''/></span>}
				<Form onSubmit={onSubmit(props, setState)}>
					<input class='SearchForm-input' type='search' name='query' placeholder={placeholder} value={query} autocomplete='off' onFocus={onFocus()} onKeyUp={debounce(onKeyUp(size, setState, splitByAttribute1), 500)}/>
					{(Boolean(query.length) && Boolean(articles && articles.hits.length)) && getDropdown(props, articles.hits, meta)}
				</Form>
			</Dropdown>
		</div>
	), props);
};

export default {afterMount, defaultProps, initialState, name, propTypes, render, shouldUpdate};
