export const ARTICLE_ADDON_ADD = 'ARTICLE_ADDON_ADD';
export const ARTICLE_ADDON_CHECKBOX_INPUT_TOGGLE = 'ARTICLE_ADDON_CHECKBOX_INPUT_TOGGLE';
export const ARTICLE_ADDON_REMOVE = 'ARTICLE_ADDON_REMOVE';
export const ARTICLE_ADDON_SELECT_REMOVE = 'ARTICLE_ADDON_SELECT_REMOVE';
export const ARTICLE_ADDON_SKU_SET = 'ARTICLE_ADDON_SKU_SET';
export const ARTICLE_BACK_IN_STOCK_REQUEST = 'ARTICLE_BACK_IN_STOCK_REQUEST';
export const ARTICLE_BACK_IN_STOCK_REQUEST_FULFILLED = 'ARTICLE_BACK_IN_STOCK_REQUEST_FULFILLED';
export const ARTICLE_FETCH = 'ARTICLE_FETCH';
export const ARTICLE_FETCH_FULFILLED = 'ARTICLE_FETCH_FULFILLED';
export const ARTICLE_FETCH_PENDING = 'ARTICLE_FETCH_PENDING';
export const ARTICLE_FETCH_REJECTED = 'ARTICLE_FETCH_REJECTED';
export const ARTICLE_MEDIA_CURRENT_SET = 'ARTICLE_MEDIA_CURRENT_SET';
export const ARTICLE_QUANTITY_SET = 'ARTICLE_QUANTITY_SET';
export const ARTICLE_RESET = 'ARTICLE_RESET';
export const ARTICLE_SKU_SET = 'ARTICLE_SKU_SET';

export const ARTICLES_FAVORITES_ADD = 'ARTICLES_FAVORITES_ADD';
export const ARTICLES_FAVORITES_ADD_FULFILLED = 'ARTICLES_FAVORITES_ADD_FULFILLED';
export const ARTICLES_FAVORITES_ADD_PENDING = 'ARTICLES_FAVORITES_ADD_PENDING';
export const ARTICLES_FAVORITES_ADD_REJECTED = 'ARTICLES_FAVORITES_ADD_REJECTED';
export const ARTICLES_FAVORITES_FETCH = 'ARTICLES_FAVORITES_FETCH';
export const ARTICLES_FAVORITES_FETCH_FULFILLED = 'ARTICLES_FAVORITES_FETCH_FULFILLED';
export const ARTICLES_FAVORITES_FETCH_PENDING = 'ARTICLES_FAVORITES_FETCH_PENDING';
export const ARTICLES_FAVORITES_FETCH_REJECTED = 'ARTICLES_FAVORITES_FETCH_REJECTED';
export const ARTICLES_FAVORITES_REMOVE = 'ARTICLES_FAVORITES_REMOVE';
export const ARTICLES_FAVORITES_REMOVE_FULFILLED = 'ARTICLES_FAVORITES_REMOVE_FULFILLED';
export const ARTICLES_FAVORITES_REMOVE_PENDING = 'ARTICLES_FAVORITES_REMOVE_PENDING';
export const ARTICLES_FAVORITES_REMOVE_REJECTED = 'ARTICLES_FAVORITES_REMOVE_REJECTED';
export const ARTICLES_FETCH = 'ARTICLES_FETCH';
export const ARTICLES_FETCH_FULFILLED = 'ARTICLES_FETCH_FULFILLED';
export const ARTICLES_FETCH_PENDING = 'ARTICLES_FETCH_PENDING';
export const ARTICLES_FETCH_REJECTED = 'ARTICLES_FETCH_REJECTED';
export const ARTICLES_FILTERS_RESET = 'ARTICLES_FILTERS_RESET';
export const ARTICLES_PAGINATION_NEXT = 'ARTICLES_PAGINATION_NEXT';
export const ARTICLES_SET_ACTIVE_FILTERS = 'ARTICLES_SET_ACTIVE_FILTERS';
export const ARTICLES_SET_STATE = 'ARTICLES_SET_STATE';
export const ARTICLES_SET_VIEW = 'ARTICLES_SET_VIEW';
export const ARTICLES_SORT = 'ARTICLES_SORT';

export const ARTICLES_SLIDER_FETCH = 'ARTICLES_SLIDER_FETCH';
export const ARTICLES_SLIDER_FETCH_FULFILLED = 'ARTICLES_SLIDER_FETCH_FULFILLED';
export const ARTICLES_SLIDER_FETCH_PENDING = 'ARTICLES_SLIDER_FETCH_PENDING';
export const ARTICLES_SLIDER_FETCH_REJECTED = 'ARTICLES_SLIDER_FETCH_REJECTED';

export const B2B_B2C_SET = 'B2B_B2C_SET';

export const BREAKPOINT_SET = 'BREAKPOINT_SET';
export const BREAKPOINT_OVERRIDE_SET = 'BREAKPOINT_OVERRIDE_SET';

export const CHECKOUT_FETCH = 'CHECKOUT_FETCH';
export const CHECKOUT_FETCH_FULFILLED = 'CHECKOUT_FETCH_FULFILLED';
export const CHECKOUT_FETCH_PENDING = 'CHECKOUT_FETCH_PENDING';
export const CHECKOUT_FETCH_REJECTED = 'CHECKOUT_FETCH_REJECTED';
export const CHECKOUT_GET_DELIVERY_POINTS = 'CHECKOUT_GET_DELIVERY_POINTS';
export const CHECKOUT_GET_DELIVERY_POINTS_FULFILLED = 'CHECKOUT_GET_DELIVERY_POINTS_FULFILLED';
export const CHECKOUT_GET_DELIVERY_POINTS_PENDING = 'CHECKOUT_GET_DELIVERY_POINTS_PENDING';
export const CHECKOUT_GET_DELIVERY_POINTS_REJECTED = 'CHECKOUT_GET_DELIVERY_POINTS_REJECTED';
export const CHECKOUT_SET_CHOSEN_CITY = 'CHECKOUT_SET_CHOSEN_CITY';
export const CHECKOUT_SET_CHOSEN_DELIVERY_POINT = 'CHECKOUT_SET_CHOSEN_DELIVERY_POINT';

export const CLOSE_QUICK_CART = 'CLOSE_QUICK_CART';

export const CUSTOMER_FETCH = 'CUSTOMER_FETCH';
export const CUSTOMER_FETCH_FULFILLED = 'CUSTOMER_FETCH_FULFILLED';
export const CUSTOMER_PATCH = 'CUSTOMER_PATCH';
export const CUSTOMER_PATCH_FULFILLED = 'CUSTOMER_PATCH_FULFILLED';

export const POPUP_CLOSE = 'POPUP_CLOSE';
export const POPUP_OPEN = 'POPUP_OPEN';

export const RECEIPT_FETCH = 'RECEIPT_FETCH';
export const RECEIPT_FETCH_FULFILLED = 'RECEIPT_FETCH_FULFILLED';

export const RECIEVE_SHOPCART = 'RECIEVE_SHOPCART';

export const RECIEVE_SITE = 'RECIEVE_SITE';
export const REQUEST_SITE = 'REQUEST_SITE';

export const SET_COMPONENT_PROPERTY = 'SET_COMPONENT_PROPERTY';

export const SHOPCART_ADD = 'SHOPCART_ADD';
export const SHOPCART_ADD_FULFILLED = 'SHOPCART_ADD_FULFILLED';
export const SHOPCART_ADD_PENDING = 'SHOPCART_ADD_PENDING';
export const SHOPCART_ADD_REJECTED = 'SHOPCART_ADD_REJECTED';
export const SHOPCART_ADD_SUBSCRIPTION = 'SHOPCART_ADD_SUBSCRIPTION';
export const SHOPCART_ADD_SUBSCRIPTION_FULFILLED = 'SHOPCART_ADD_SUBSCRIPTION_FULFILLED';
export const SHOPCART_ADD_SUBSCRIPTION_PENDING = 'SHOPCART_ADD_SUBSCRIPTION_PENDING';
export const SHOPCART_ADD_SUBSCRIPTION_REJECTED = 'SHOPCART_ADD_SUBSCRIPTION_REJECTED';
export const SHOPCART_EDIT = 'SHOPCART_EDIT';
export const SHOPCART_EDIT_FULFILLED = 'SHOPCART_EDIT_FULFILLED';
export const SHOPCART_EDIT_PENDING = 'SHOPCART_EDIT_PENDING';
export const SHOPCART_EDIT_REJECTED = 'SHOPCART_EDIT_REJECTED';
export const SHOPCART_FETCH = 'SHOPCART_FETCH';
export const SHOPCART_FETCH_FULFILLED = 'SHOPCART_FETCH_FULFILLED';
export const SHOPCART_FETCH_PENDING = 'SHOPCART_FETCH_PENDING';
export const SHOPCART_FETCH_REJECTED = 'SHOPCART_FETCH_REJECTED';
export const SHOPCART_REMOVE = 'SHOPCART_REMOVE';
export const SHOPCART_REMOVE_FULFILLED = 'SHOPCART_REMOVE_FULFILLED';
export const SHOPCART_REMOVE_PENDING = 'SHOPCART_REMOVE_PENDING';
export const SHOPCART_REMOVE_REJECTED = 'SHOPCART_REMOVE_REJECTED';
export const SHOPCART_UPDATE = 'SHOPCART_UPDATE';
export const SHOPCART_UPDATE_FULFILLED = 'SHOPCART_UPDATE_FULFILLED';
export const SHOPCART_UPDATE_PENDING = 'SHOPCART_UPDATE_PENDING';
export const SHOPCART_UPDATE_REJECTED = 'SHOPCART_UPDATE_REJECTED';
export const SHOPCART_SET = 'SHOPCART_SET';

export const TOGGLE_QUICK_CART = 'TOGGLE_QUICK_CART';
export const TOGGLE_MAINNAV_ITEM = 'TOGGLE_MAINNAV_ITEM';

export const TREE_FETCH = 'TREE_FETCH';
export const TREE_FETCH_FULFILLED = 'TREE_FETCH_FULFILLED';

export const CMS_ACTIVE_NODE_SET = 'CMS_ACTIVE_NODE_SET';

export const CMS_FETCH = 'CMS_FETCH';
export const CMS_FETCH_FULFILLED = 'CMS_FETCH_FULFILLED';
export const CMS_FETCH_PENDING = 'CMS_FETCH_PENDING';
export const CMS_FETCH_REJECTED = 'CMS_FETCH_REJECTED';

export const PATH_CHANGE = 'PATH_CHANGE';

export const CONTENT_RESET = 'CONTENT_RESET';

export const CONTENT_SET = 'CONTENT_SET';
export const CONTENT_SET_FULFILLED = 'CONTENT_SET_FULFILLED';
export const CONTENT_SET_PENDING = 'CONTENT_SET_PENDING';
export const CONTENT_SET_REJECTED = 'CONTENT_SET_REJECTED';

export const CATEGORY_FETCH = 'CATEGORY_FETCH';
export const CATEGORY_FETCH_FULFILLED = 'CATEGORY_FETCH_FULFILLED';
export const CATEGORY_FETCH_PENDING = 'CATEGORY_FETCH_PENDING';
export const CATEGORY_FETCH_REJECTED = 'CATEGORY_FETCH_REJECTED';

export const CMS_DRAFT_CATEGORY_FETCH_FULFILLED = 'CMS_DRAFT_CATEGORY_FETCH_FULFILLED';
export const CMS_DRAFT_CATEGORY_POST_FULFILLED = 'CMS_DRAFT_CATEGORY_POST_FULFILLED';

export const CMS_DRAG_START = 'CMS_DRAG_START';

export const CMS_MAIN_CONTENT_FETCH_FULFILLED = 'CMS_MAIN_CONTENT_FETCH_FULFILLED';

export const CMS_NODE_DELETE = 'CMS_NODE_DELETE';
export const CMS_NODE_INSERT = 'CMS_NODE_INSERT';
export const CMS_NODE_MOVE = 'CMS_NODE_MOVE';

export const CMS_CATEGORY_IMPORT = 'CMS_CATEGORY_IMPORT';
export const CMS_CATEGORY_IMPORT_FULFILLED = 'CMS_CATEGORY_IMPORT_FULFILLED';
export const CMS_CATEGORY_IMPORT_PENDING = 'CMS_CATEGORY_IMPORT_PENDING';
export const CMS_CATEGORY_IMPORT_REJECTED = 'CMS_CATEGORY_IMPORT_REJECTED';

export const INSTAGRAM_FEED_FETCH_FULFILLED = 'INSTAGRAM_FEED_FETCH_FULFILLED';
export const INSTAGRAM_FEED_FETCH = 'INSTAGRAM_FEED_FETCH';
export const INSTAGRAM_SET_ACTIVE_INDEX = 'INSTAGRAM_SET_ACTIVE_INDEX';
export const INSTAGRAM_SET_CURRENT_INDEX = 'INSTAGRAM_SET_CURRENT_INDEX';
export const INSTAGRAM_SET_NEXT_INDEX = 'INSTAGRAM_SET_NEXT_INDEX';
export const INSTAGRAM_SET_PREV_INDEX = 'INSTAGRAM_SET_PREV_INDEX';
export const INSTAGRAM_SET_MARKERS = 'INSTAGRAM_SET_MARKERS';
export const INSTAGRAM_SHOW_MARKER_TEXT = 'INSTAGRAM_SHOW_MARKER_TEXT';
export const INSTAGRAM_PAGINATION_NEXT = 'INSTAGRAM_PAGINATION_NEXT';
export const INSTAGRAM_POPUP_OPEN = 'INSTAGRAM_POPUP_OPEN';

export const FINDSTORE_STORES_FETCH_FULFILLED = 'FINDSTORE_STORES_FETCH_FULFILLED';
export const FINDSTORE_STORES_FETCH = 'FINDSTORE_STORES_FETCH';
export const FINDSTORE_SET_LOCATION = 'FINDSTORE_SET_LOCATION';
export const FINDSTORE_SET_STORES = 'FINDSTORE_SET_STORES';

export const LEAVE_WINDOW_REMARKET = 'LEAVE_WINDOW_REMARKET';

export const LOGIN_SET_ERROR = 'LOGIN_SET_ERROR';

export const STORE_BALANCE_FETCH = 'STORE_BALANCE_FETCH';
export const STORE_BALANCE_FETCH_FULFILLED = 'STORE_BALANCE_FETCH_FULFILLED';
export const STORE_BALANCE_SEARCH = 'STORE_BALANCE_SEARCH';
export const STORE_BALANCE_STORE_SET = 'STORE_BALANCE_STORE_SET';
export const STORES_FETCH = 'STORES_FETCH';
export const STORES_FETCH_FULFILLED = 'STORES_FETCH_FULFILLED';

export const SET_CLIENT_ID = 'SET_CLIENT_ID';
