/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {InputField} from '@viskan/deku-form';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	title: {
		type: 'string'
	},
	validationError: {
		source: 'validationError'
	}
};

const getFields = (keys, {getTranslation, validationError}) => Object.keys(keys).map((x, i) => {
	const {label, value, type, required, rules} = keys[x];

	return <InputField key={i} type={type || 'text'} name={x} id={x} label={label} value={value} rules={rules} validationMessage={validationError(label, getTranslation)} required={required} validate={['onBlur']}/>;
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {getTranslation, title, validationError} = props;
	const keys = {
		password_old: {
			label: getTranslation('password.old'),
			required: false,
			type: 'password'
		},
		password_new: {
			label: getTranslation('password.password'),
			required: false,
			type: 'password',
			rules: val => v12.validation(val, {minLength: 6})
		},
		password_new_confirm: {
			label: getTranslation('password.confirm'),
			required: false,
			type: 'password',
			rules: val => v12.validation(val, {matches: {value: document.getElementById('password_new').value, msg: getTranslation('password.password')}})
		}
	};

	return (
		<div>
			<h2 class='Heading u-marginTop u-marginBottom'>{title}</h2>
			<div>
				{getFields(keys, {getTranslation, validationError})}
			</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
