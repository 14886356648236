import {connect} from '@viskan/deku-redux';
import {close as closePopup, open as openPopup} from '../../../popup-redux/actions';
import {getTranslator} from '../../../sources/translator';
import {requestBackInStock} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		active: state.article.active,
		article: state.article.article,
		countryId: state.site.country_id,
		getTranslation: getTranslator(state),
		requested: state.articleBackInStock.requested
	}),
	dispatch => ({
		closePopup: () => dispatch(closePopup()),
		openPopup: (content, options) => dispatch(openPopup(content, options)),
		requestBackInStock: (data, props) => dispatch(requestBackInStock(data, props))
	})
)(Component);

export default {component, config};
