/** @jsx dom */
import dom from 'magic-virtual-element';
import cmsComponents from '@viskan/cms-components';
import streamlineCmsComponents from '../../cms-components';
import {Overlay as DomObserverOverlay} from '../../dom-observer';
import {Overlay} from '..';

const name = 'StreamlineCmsSection';
const components = {
	...cmsComponents,
	...streamlineCmsComponents
};
const isMatchingElementId = id => element => element.dataset && parseInt(element.dataset.id, 10) === id;

const onDragStart = ({activeNode, dragStart}, component) => e => {
	const activeElementInPath = activeNode && e.path.find(isMatchingElementId(activeNode.id));
	if (activeElementInPath && component.id !== activeNode.id) {
		return;
	}

	const dragElement = e.delegateTarget;
	const dragComponent = component;

	e.dataTransfer.effectAllowed = 'move';

	setTimeout(() => dragElement.classList.add('isDragging'), 1);
	dragStart(dragComponent);

	return false;
};

const renderChildren = (children, props) => {
	const {activeNode, isEditing} = props;

	return children.filter(Boolean).map(child => {
		const Component = components[child.type] && components[child.type].component;
		const classes = [
			child.props.class,
			'StreamlineCmsSection-node',
			isEditing && activeNode && activeNode.id === child.id && 'isActive'
		];
		const internalAttributes = {
			draggable: isEditing,
			onDragStart: isEditing && onDragStart(props, child)
		};

		if (!Component) {
			console.warn(`Component ${child.type} does not exist`);
			return <noscript/>;
		}

		return (
			<Component id={child.id} {...child.props} components={components} internalAttributes={internalAttributes} class={classes} styleClasses={child.props.styleClasses || {}}>
				{child.children && renderChildren(child.children, props)}
			</Component>
		);
	});
};

const renderNodes = props => (
	<div class={['StreamlineCmsSection-node', props.isEditing && !props.activeNode && 'isActive']} data-id={0} data-topnode='true'>
		{props.content.length !== 0 && renderChildren(props.content, props)}
	</div>
);

const render = ({props}) => {
	const classes = [name, `${name}--${props.sectionId}`, props.class];

	if (props.isEditing) {
		const renderedOverlay = <Overlay components={components} sectionId={props.sectionId}/>;

		return (
			<div class={classes.concat('isEditing')}>
				<DomObserverOverlay class='StreamlineCmsSection-activeOverlay' selector='.StreamlineCmsSection-node.isActive' overlayContent={renderedOverlay}>
					{renderNodes(props)}
				</DomObserverOverlay>
			</div>
		);
	}

	return (
		<div class={classes}>
			{renderNodes(props)}
		</div>
	);
};

export default {name, render};
