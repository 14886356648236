export default {
	type: 'ARTICLE_MEDIA',
	name: 'Media',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'mobileLayout',
		inputType: 'select',
		label: 'Layout on mobile',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}]
	}, {
		name: 'tabletLayout',
		inputType: 'select',
		label: 'Layout on tablet',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}]
	}, {
		name: 'desktopLayout',
		inputType: 'select',
		label: 'Layout on desktop',
		default: 'Slider',
		values: [{
			label: 'Zoom on hover',
			value: 'Zoom'
		}, {
			label: 'Zoom on scroll and click',
			value: 'TwoStepZoom'
		}, {
			label: 'Slider',
			value: 'Slider'
		}, {
			label: 'Slider with zoom on hover',
			value: 'SliderZoom'
		}, {
			label: 'Slider with zoom on scroll and click',
			value: 'TwoStepSliderZoom'
		}]
	}],
	hiddenProps: [{
		label: 'Slider layout settings',
		props: [{
			name: 'sliderLoop',
			inputType: 'checkbox',
			label: 'Loop',
			default: true
		}, {
			name: 'sliderPagination',
			inputType: 'checkbox',
			label: 'Pagination',
			default: true
		}, {
			name: 'sliderArrows',
			inputType: 'checkbox',
			label: 'Arrows',
			default: false
		}, {
			name: 'sliderAutoplay',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'sliderColor',
			inputType: 'color',
			label: 'Navigation color',
			default: '#000000'
		}]
	}, {
		label: 'Zoom layout settings',
		props: [{
			name: 'zoomDelay',
			inputType: 'number',
			label: 'Delay before zooming',
			default: '500'
		}, {
			name: 'zoomPopup',
			inputType: 'checkbox',
			label: 'Popup on click',
			default: true
		}, {
			name: 'zoomThumbnails',
			inputType: 'checkbox',
			label: 'Show thumbnails',
			default: true
		}]
	}, {
		label: 'Offer settings',
		props: [{
			name: 'showOffers',
			inputType: 'checkbox',
			label: 'Show offer icon',
			default: true
		}, {
			name: 'offerPopup',
			inputType: 'checkbox',
			label: 'Popup on click',
			default: true
		}, {
			name: 'offerHeader',
			inputType: 'text',
			label: 'Offer header',
			default: ''
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
