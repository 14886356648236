/** @jsx dom */
import bus from '@viskan/bus';
import dom from 'magic-virtual-element';
import {Spinner} from '@viskan/estore-components';

const propTypes = {
	class: {
		type: 'string'
	}
};

const initialState = () => ({active: false});

const afterMount = ({props}, el, setState) => {
	bus.on('loading:open', () => setState({active: true}));
	bus.on('loading:close', () => setState({active: false}));
};

const render = ({props, state}) => {
	const {active} = state;

	if (!active) {
		return <noscript/>;
	}

	return (
		<div class={['Spinner-container', props.class]}>
			<Spinner/>
		</div>
	);
};

export default {afterMount, initialState, propTypes, render};
