/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import LoginForm from '../../account/login-form';
import AccountNav from '../../account-nav';
import {Section} from '../../streamline-cms';

const propTypes = {
	customer: {
		source: 'customer'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	validationError: {
		source: 'validationError'
	},
	viewAccount: {
		source: 'viewAccount'
	}
};

const afterMount = ({props}) => !props.contentManagementActive && props.categoryFetch(props.query, {
	languageId: props.languageId
});

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = () => (
	<div class='Login'>
		<Section sectionId='main'/>
		<div class='Container'>
			<Grid gutter>
				<Cell size='1of6'>
					<AccountNav active={2} loggedIn={false}/>
				</Cell>
				<Cell size='5of6'>
					<Grid>
						<Cell size='full' md-size='1of2' lg-size='1of3'>
							<div class='u-marginTop u-marginBottom'>
								<LoginForm/>
							</div>
						</Cell>
					</Grid>
				</Cell>
			</Grid>
		</div>
	</div>
);

export default {afterMount, propTypes, render, shouldUpdate};
