/** @jsx dom */
import bus from '@viskan/bus';
import Button from '@viskan/deku-button';
import dom from 'magic-virtual-element';
import {Form, InputField, TextField} from '@viskan/deku-form';
import Link from '../link';

const defaultProps = {
	fluidButton: false,
	inlineLayout: false,
	placeholders: false,
	resetPath: '/password',
	registerPath: '/register',
	showResetLink: true,
	showRegisterLink: true
};

const propTypes = {
	customer: {
		source: 'customer'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	validationError: {
		source: 'validationError'
	},
	viewAccount: {
		source: 'viewAccount'
	}
};

const getLinks = props => {
	const {registerPath, resetPath, showRegisterLink, showResetLink} = props;
	const labels = {
		reset: props.resetText || props.getTranslation('password.forgot'),
		register: props.registerText || props.getTranslation('login.register')
	};

	if (!showRegisterLink && !showResetLink) {
		return <noscript/>;
	}

	return (
		<div class='AccountLogin-links'>
			{showResetLink && <Link href={resetPath}>{labels.reset}</Link>}
			{showRegisterLink && <Link href={registerPath}>{labels.register}</Link>}
		</div>
	);
};

const onSubmit = (props, onError, onSuccess) => data => {
	bus.emit('loading:open');

	v12.customer.login(data, {json: false}).then(() => {
		bus.emit('login');
		bus.emit('loading:close');
		onSuccess(props.pathPrefix);
	}).catch(error => {
		v12.util.error(error);
		onError({error: true});
		bus.emit('loading:close');
	});
};

const onChange = saveEmail => e => saveEmail(e.target.value);

const afterUpdate = ({props, state}) => {
	const {getTranslation, inlineLayout, openPopup} = props;
	const error = props.error || state.error;

	if (inlineLayout && error) {
		openPopup(<p class='AccountLogin-alert'>{getTranslation('login.error')}</p>, {class: 'AccountLogin-popup', auto: true});
	}
};

const render = ({props, state}, setState) => {
	const {getTranslation, fluidButton, inlineLayout, placeholders, saveEmail, validationError} = props;
	const onError = props.onError || setState;
	const onSuccess = props.onSuccess || (() => props.viewAccount(props.pathPrefix));
	const error = props.error || state.error;
	const labels = {
		login: props.loginButtonText || getTranslation('login.login'),
		password: props.passwordFieldLabel || getTranslation('password.password'),
		user: props.userFieldLabel || getTranslation('customer.username')
	};

	return (
		<div class={['AccountLogin', props.inlineLayout && 'AccountLogin--inline', props.class]}>
			<Form onSubmit={onSubmit(props, onError, onSuccess)} class='AccountLogin-form'>
				<TextField onChange={onChange(saveEmail)} id='j_username' name='j_username' label={!placeholders && labels.user} placeholder={placeholders && labels.user} validationMessage={validationError(getTranslation('customer.username'), getTranslation)} required/>
				<InputField id='j_password' name='j_password' label={!placeholders && labels.password} placeholder={placeholders && labels.password} type='password' validationMessage={validationError(getTranslation('password.password'), getTranslation)} required/>
				<Button class={['AccountLogin-button', fluidButton && 'AccountLogin-button--fluid']} type='submit'>{labels.login}</Button>
				{!inlineLayout && error && <p class='AccountLogin-alert'>{getTranslation('login.error')}</p>}
			</Form>
			{getLinks(props)}
		</div>
	);
};

export default {afterUpdate, defaultProps, propTypes, render};
