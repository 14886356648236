import {connect} from '@viskan/deku-redux';
import {addSubscription} from '../../../shopcart/actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		subscriptions: state.article.article.prentypes
	}),
	dispatch => ({
		addToCart: subscription => dispatch(addSubscription(subscription))
	})
)(Component);

export default {component, config};
