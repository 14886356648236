import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../sources/translator';
import {storesFetch, setLocation, setStores} from './actions';
import config from './config';
import FindStore from './components';

export const component = connect(
	state => ({
		activeCountry: state.findStore.activeCountry,
		activeStore: state.findStore.activeStore,
		breakpoint: state.breakpoint.breakpointOverride,
		closestStores: state.findStore.closestStores,
		cmsLoaded: state.streamlineCms.loaded,
		getTranslation: getTranslator(state),
		location: state.findStore.location,
		map: state.findStore.map,
		site: state.site,
		stores: state.findStore.stores,
		zoomCountry: state.findStore.zoomCountry
	}),
	dispatch => ({
		storesFetch: query => dispatch(storesFetch(query)),
		setLocation: location => dispatch(setLocation(location)),
		setStores: data => dispatch(setStores(data))
	})
)(FindStore);

export default {component, config};
