import {createAction} from 'redux-actions';
import {ARTICLE_BACK_IN_STOCK_REQUEST} from '../../../action-types';

export const requestBackInStock = createAction(ARTICLE_BACK_IN_STOCK_REQUEST, (data, props) => {
	const customerObject = Object.assign({}, data, {
		'inv_country.id': props.country_id,
		'del_country.id': props.country_id,
		'vis_country.id': props.country_id
	});

	return v12.customer.match(customerObject)
		.then(() => {
			return v12.article.interests({
				art_id: props.article.art_id,
				attr1_id: props.active.attr1_id,
				attr2_id: props.active.attr2_id,
				attr3_id: props.active.attr3_id,
				interestType: 'BACK_IN_STOCK'
			});
		});
});
