/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import {setTitle} from '../../actions';
import {trackPurchase as trackPurchaseOnGTM} from '../../analytics';
import {NostoPurchase} from '../../nosto';
import EmbeddedSnippet from '../../checkout/components/embedded-snippet';
import getType from '../../checkout/components/embedded-type';
import ReceiptCustomer from './customer';
import ReceiptRow from './row';
import ReceiptSubscriptionRow from './subscription-row';

const name = 'Receipt';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const getMediaFilename = row => {
	if (!row.meta.imageFileName) {
		return null;
	}

	return row.meta.imageFileName;
};

const getRows = (rows, {articleUrlPrefix}) => rows.map(row => <ReceiptRow key={row.rowId} mediaFilename={getMediaFilename(row)} data={row} articleUrlPrefix={articleUrlPrefix}/>);
const getSubscriptions = subscriptions => subscriptions.map(subscription => <ReceiptSubscriptionRow key={subscription.rowId} mediaFilename={getMediaFilename(subscription)} data={subscription}/>);

const getHeadOffer = (offers, getTranslation) => offers.map(offer => {
	return (
		<Arrange key={offer.offerId}>
			<ArrangeFill>
				<strong>{getTranslation('article.offer')}</strong>
			</ArrangeFill>
			<ArrangeFit>
				<div class='u-textNoWrap' innerHTML={offer.camp_name}/>
			</ArrangeFit>
		</Arrange>
	);
});

const getDiscount = (discount, discount_exvat, {getPrice, getTranslation}) => {
	return (
		<div class='Receipt-discount'>
			<Arrange>
				<ArrangeFill>
					<strong>{getTranslation('global.discount')}</strong>
				</ArrangeFill>
				<ArrangeFit>
					{getPrice(discount, discount_exvat)}
				</ArrangeFit>
			</Arrange>
		</div>
	);
};

const getV1Receipt = props => {
	const {getPrice, getTranslation, receipt} = props;
	const {customer, rows, subscriptions, offers, value} = receipt;
	const {total, totalExcludingTax, discountTotal, discountTotalExcludingTax, shippingFee, shippingFeeExcludingTax, paymentFee, paymentFeeExcludingTax} = value;

	return (
		<Grid gutter>
			<Cell size='full' lg-size='1of2'>
				<ReceiptCustomer customer={customer} getTranslation={getTranslation}/>
			</Cell>
			<Cell size='full' lg-size='1of2'>
				<div class='Receipt-rows'>
					{Boolean(rows.length) && getRows(rows, props)}
				</div>
				<div class='Receipt-rows'>
					{Boolean(subscriptions.length) && getSubscriptions(subscriptions)}
				</div>
				<div class='Receipt-summary'>
					<div class='Receipt-offer-head'>
						{Boolean(offers.length) && getHeadOffer(offers, getTranslation)}
					</div>
					{Boolean(discountTotal) && getDiscount(discountTotal, discountTotalExcludingTax, props)}
					<Arrange>
						<ArrangeFill>
							<strong>{getTranslation('shopcart.shipping_carriage')}</strong>
						</ArrangeFill>
						<ArrangeFit>
							{getPrice(shippingFee, shippingFeeExcludingTax)}
						</ArrangeFit>
					</Arrange>
					<Arrange>
						<ArrangeFill>
							<strong>{getTranslation('shopcart.payment_carriage')}</strong>
						</ArrangeFill>
						<ArrangeFit>
							{getPrice(paymentFee, paymentFeeExcludingTax)}
						</ArrangeFit>
					</Arrange>
				</div>
				<Arrange>
					<ArrangeFill>
						<strong>{getTranslation('global.total')}</strong>
					</ArrangeFill>
					<ArrangeFit>
						{getPrice(total, totalExcludingTax)}
					</ArrangeFit>
				</Arrange>
			</Cell>
		</Grid>
	);
};

const getV2Receipt = (type, params) => (
	<EmbeddedSnippet type={type} params={params}/>
);

const isPurchase = (props, prevProps) => {
	const {receipt, shopcart} = props;

	if (!shopcart.head.shopcart_id_str) {
		return;
	}

	const receiptIdChanged = receipt.shopcartId !== (prevProps.receipt && prevProps.receipt.shopcartId);
	const shopcartIdChanged = shopcart.head.shopcart_id_str !== prevProps.shopcart.head.shopcart_id_str;

	if ((receiptIdChanged || shopcartIdChanged) && receipt.shopcartId === shopcart.head.shopcart_id_str) {
		return true;
	}
};

const trackPurchase = (props, setState) => {
	props.fetchShopcart(true);
	trackPurchaseOnGTM(props.receipt);
	setState({trackedPurchase: true, willTrackNostoPurchase: true});
};

const beforeMount = ({props}) => {
	const {countryCode, getTranslation, fetch, purchaseNumber} = props;

	setTitle(getTranslation('receipt.title'));

	if (purchaseNumber.length > 15) {
		fetch('v1', {countryCode, id: purchaseNumber, mapping: 'StockKeepingUnitMetaData', type: 'vco'});
		return;
	}

	fetch('v2', {purchaseNumber, mapping: 'StockKeepingUnitMetaData'});
};

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	if (!props.receipt || state.trackedPurchase) {
		return;
	}

	if ('trackReceipt' in props.receipt) {
		if (props.receipt.trackReceipt) {
			trackPurchase(props, setState);
		}

		return;
	}

	if (isPurchase(props, prevProps)) {
		props.removeShopcart().then(() => trackPurchase(props, setState));
	}
};

const render = ({props, state}) => {
	const {getTranslation, receipt} = props;
	const {willTrackNostoPurchase} = state;

	if (!receipt) {
		return <noscript/>;
	}

	const {params, rows} = receipt;
	const type = params ? getType(params) : 'v1';
	const receiptContent = type === 'v1' ? getV1Receipt(props) : getV2Receipt(type, params);

	return (
		<div class={name}>
			<div class='Container'>
				{type === 'v1' &&
					<h2 class='Heading u-marginBottom'>{getTranslation('receipt.title')}</h2>
				}
				{receiptContent}
				{Boolean(rows.length) && willTrackNostoPurchase && <NostoPurchase data={receipt}/>}
			</div>
		</div>
	);
};

export default {afterUpdate, beforeMount, name, propTypes, render};
