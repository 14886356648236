/** @jsx dom */
import dom from 'magic-virtual-element';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const handleChange = props => e => {
	const fn = e.target.checked ? props.onAdd : props.onRemove;

	fn({
		art_id: props.data.article.art_id,
		attr1_id: props.data.article.articleAttributes[0].attr1_id || 0,
		attr2_id: props.data.article.articleAttributes[0].attr2_id || 0,
		attr3_id: props.data.article.articleAttributes[0].attr3_id || 0,
		personalization_text: '',
		qty: 1
	});
};

const render = ({props}) => {
	const name = `CMS-AddonCheckbox-${props.data.article.art_id}`;

	return (
		<div class='CMS-AddonCheckbox'>
			<input class='CMS-AddonCheckbox-checkbox' type='checkbox' id={name} name={name} onChange={handleChange(props)}/>
			<label class='CMS-AddonCheckbox-label' for={name}>
				<span class='CMS-AddonCheckbox-name'>{props.data.article.artname}</span>
			</label>
			<span class='CMS-AddonCheckbox-price'>+{props.getPrice(props.data.article.articleAttributes[0].articlePrice.price_sales)}</span>
		</div>
	);
};

export default {propTypes, render};
