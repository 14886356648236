import counterpart from 'counterpart';
import estoreLocales from '@viskan/estore-locales';
import {createSelector} from 'reselect';

const defaultTranslation = 'en';
const translations = {
	en: require('../../locale/en.json'),
	fi: require('../../locale/fi.json'),
	sv: require('../../locale/sv.json')
};

const instances = {};

const translator = site => {
	const {lang_code_web} = site;

	if (!instances[lang_code_web]) {
		const instance = new counterpart.Instance();

		instance.registerTranslations(lang_code_web, estoreLocales[lang_code_web]());
		instance.registerTranslations(lang_code_web, translations[lang_code_web]);
		instance.setLocale(lang_code_web);
		instance.onTranslationNotFound((locale, key) => console.warn(`missing translation: ${locale}.${key}`));

		if (lang_code_web !== 'en') {
			instance.registerTranslations(defaultTranslation, estoreLocales[defaultTranslation]());
			instance.registerTranslations(defaultTranslation, translations[defaultTranslation]);
			instance.setFallbackLocale(defaultTranslation);
		}

		instances[lang_code_web] = (key, options) => instance.translate(key, options);
	}

	return instances[lang_code_web];
};

export const getTranslator = createSelector(
	[state => state.site],
	site => translator(site)
);

export default translator;
