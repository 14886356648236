import {createAction} from 'redux-actions';
import {RECIEVE_SITE, SET_CLIENT_ID} from '../action-types';

export const recieve = data => dispatch => dispatch({
	type: RECIEVE_SITE,
	data
});

export const request = () => dispatch => v12.customer.get()
	.then(data => dispatch(recieve(data)));

export const setGoogleAnalyticsId = createAction(SET_CLIENT_ID);
