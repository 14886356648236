export default {
	type: 'ARTICLES_SORT',
	name: 'Articles sort',
	allowedSections: [
		'main'
	],
	disallowedChildren: [
		'*'
	],
	category: 'Articles',
	props: [],
	hiddenProps: [
		{
			label: 'Sort options',
			props: [{
				name: 'activationDateAsc',
				inputType: 'checkbox',
				label: 'Activation date (ASCENDING)',
				'default': false
			}, {
				name: 'activationDateDesc',
				inputType: 'checkbox',
				label: 'Activation date (DESCENDING)',
				'default': true
			}, {
				name: 'lowestPriceSalesAsc',
				inputType: 'checkbox',
				label: 'Price (ASCENDING)',
				'default': true
			}, {
				name: 'lowestPriceSalesDesc',
				inputType: 'checkbox',
				label: 'Price (DESCENDING)',
				'default': true
			}, {
				name: 'artnameAsc',
				inputType: 'checkbox',
				label: 'Article name (ASCENDING)',
				'default': false
			}, {
				name: 'artnameDesc',
				inputType: 'checkbox',
				label: 'Article name (DESCENDING)',
				'default': false
			}, {
				name: 'artnoAsc',
				inputType: 'checkbox',
				label: 'Article number (ASCENDING)',
				'default': false
			}, {
				name: 'artnoDesc',
				inputType: 'checkbox',
				label: 'Article number (DESCENDING)',
				'default': false
			}, {
				name: 'articleCategoryAsc',
				inputType: 'checkbox',
				label: 'Article category (ASCENDING)',
				'default': false
			}, {
				name: 'articleCategoryDesc',
				inputType: 'checkbox',
				label: 'Article category (DESCENDING)',
				'default': false
			}]
		}
	]
};
