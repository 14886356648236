/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const formatData = props => {
	const {data, getPrice, field} = props;
	const priceFields = ['free_carriage_left', 'tot_to_pay', 'tot_vat', 'total_discount', 'wdel_carriage', 'wpay_carriage'];
	const priceFieldsDontAcceptMinus = ['free_carriage_left'];

	if (priceFields.includes(field)) {
		const value = priceFieldsDontAcceptMinus.includes(field) && data[field] < 0 ? 0 : data[field];
		const valueExvat = priceFieldsDontAcceptMinus.includes(field) && data[`${field}_exvat`] < 0 ? 0 : data[`${field}_exvat`];

		return getPrice(value, Object.prototype.hasOwnProperty.call(data, `${field}_exvat`) ? valueExvat : undefined);
	}

	return data[field];
};

const render = ({props}) => {
	const {data, field, styleClasses} = props;
	const missingField = !Object.prototype.hasOwnProperty.call(data, field);
	const noRender = props.hideEmpty && Boolean(data[field]) === false;
	const fData = formatData(props);
	const label = <span class={styleClasses.Label} data-style-attr='Label'>{props.label}</span>;
	const value = <span class={styleClasses.Value} data-style-attr='Value'>{`${props.prefixText}${fData}${props.suffixText}`}</span>;
	const element = (missingField || noRender) ? <noscript/> : dom(props.elementType, {
		class: ['CMS-ShopcartSummaryField', `CMS-ShopcartSummaryField-${field}`, props.class, styleClasses.Field],
		'data-style-attr': 'Field'
	}, label ? [label, value] : [value]);

	return cmsUtils.createCmsComponent(element, props);
};

export default {propTypes, render};
