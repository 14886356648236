/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {FilterSlider} from '../../../filter';

const defaultProps = {
	ranges: ['-50', '50-100', '100-200', '200-300', '300-400', '400-500', '500-1000', '1000-2000', '2000-3000', '3000-4000', '3000-5000', '5000-']
};

const handleFilterChange = (activeFilters, onChangeFilter) => data => onChangeFilter(activeFilters, data);
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {activeFilters, hasArticlesIfNoFilter, onChangeFilter, ranges} = props;

	if (!hasArticlesIfNoFilter) {
		return <noscript/>;
	}

	const key = activeFilters.apiInput && activeFilters.apiInput.lowest_price_sales ? activeFilters.apiInput.lowest_price_sales.ranges.map(range => `${range.from}${range.to}`) : 0;

	return cmsUtils.createCmsComponent((
		<div class='CMS-FilterSlider'>
			<FilterSlider key={key} activeFilters={activeFilters} ranges={ranges} onChange={handleFilterChange(activeFilters, onChangeFilter)}/>
		</div>
	), props);
};

export default {defaultProps, render, shouldUpdate};
