/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import emailRegex from 'email-regex';
import {Form, InputField} from '@viskan/deku-form';
import store from 'store2';

const propTypes = {
	data: {
		type: 'object'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	onSubmit: {
		type: 'function'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	site: {
		source: 'site'
	}
};

const defaultProps = {
	data: {},
	type: 'email_1'
};

const handleSubmit = (type, country_id, {onSubmit, saveEmail}) => (data, el) => {
	bus.emit('loading:open');

	if (type === 'email_1') {
		saveEmail(data[type]);
	}

	v12.customer.lookup(data[type])
		.then(body => {
			if (!onSubmit) {
				bus.emit('loading:close');
				return;
			}

			if (body.addresses.length === 0) {
				onSubmit([{
					[type]: data[type],
					'inv_country.id': country_id,
					'del_country.id': country_id,
					'vis_country.id': country_id
				}], data, el);
				return;
			}

			const {addresses} = body;
			const ret = addresses.map(x => {
				return {
					person_flg: x.person_flg,
					email_1: x.email_1 ? x.email_1 : (type === 'email_1' ? data[type] : null),
					org_no: x.org_no,
					cust_desc2: x.cust_desc2,
					cust_desc1: x.cust_desc1,
					inv_address: x.address,
					inv_address_desc: x.address_desc,
					inv_zipcode: x.zipcode,
					inv_city: x.city,
					'inv_country.id': country_id,
					'del_country.id': country_id,
					'vis_country.id': country_id,
					fields: x.fields
				};
			});

			onSubmit(ret, data, el);
		})
		.catch(v12.util.error);
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getTranslation, onSubmit, saveEmail, site, type} = props;
	const {country_id} = site;
	const label = type === 'org_no' ? 'customer.ssn' : 'customer.email';
	const pattern = type === 'org_no' ? '(\\d{2,4})(?:-)?(\\d{2})(?:-)?(\\d{2})(?:-)?(\\d{4})' : emailRegex().source;
	const value = data[type] || type === 'email_1' ? store('vs-email') : null;

	return (
		<Form class={['Form--horizontal', props.class]} onSubmit={handleSubmit(type, country_id, {onSubmit, saveEmail})}>
			<div class='u-cf'>
				<div class='u-floatRight'>
					<Button class='Button--primary' type='submit'>{getTranslation('lookup.get_address')}</Button>
				</div>
				<div class='u-nbfc'>
					<InputField name={type} id={type} placeholder={getTranslation(label)} value={value} type={type === 'email_1' ? 'email' : 'text'} pattern={pattern} required/>
				</div>
			</div>
		</Form>
	);
};

export default {defaultProps, propTypes, render, shouldUpdate};
