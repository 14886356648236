import {connect} from '@viskan/deku-redux';
import {setActiveFilters, sort} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		activeFilters: state.articles.activeFilters,
		currentFilters: state.articles.currentFilters,
		filters: state.articles.filters,
		hasArticlesIfNoFilter: state.articles.firstTotal > 0,
		selectedFilters: state.articles.selectedFilters,
		size: state.articles.size,
		sort: state.articles.sort
	}),
	dispatch => ({
		onChangeFilter: (activeFilters, data) => dispatch(setActiveFilters(activeFilters, data)),
		onSortChange: sortValues => dispatch(sort(sortValues))
	})
)(Component);

export default {component, config};
