/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {Dropdown, Input, PlusMinusButtons} from './layouts';

const getLayout = props => {
	switch (props.layout) {
		case 'dropdown': {
			return <Dropdown {...props}/>;
		}
		case 'plusMinusButtons': {
			return <PlusMinusButtons {...props}/>;
		}
		case 'input':
		default: {
			return <Input {...props}/>;
		}
	}
};

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleQuantity', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<label class={['CMS-ArticleQuantity-label', props.styleClasses.Label]} data-style-attr='Label'>
				{props.label}
			</label>
			{getLayout(props)}
		</div>
	), props);
};

export default {render};
