import {ARTICLE_ADDON_CHECKBOX_INPUT_TOGGLE, ARTICLE_ADDON_SKU_SET, ARTICLE_FETCH_FULFILLED} from '../../../action-types';

export const initialState = {
	activeSkus: {},
	openCheckboxInputs: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ARTICLE_ADDON_CHECKBOX_INPUT_TOGGLE: {
			const activeSkus = Object.assign({}, state.activeSkus);
			const newOpen = state.openCheckboxInputs.slice();
			const index = newOpen.findIndex(id => id === action.payload);

			if (index === -1) {
				newOpen.push(action.payload);
			} else {
				newOpen.splice(index, 1);
				delete activeSkus[action.payload];
			}

			return {
				...state,
				activeSkus,
				openCheckboxInputs: newOpen
			};
		}
		case ARTICLE_ADDON_SKU_SET: {
			const activeSkus = Object.assign({}, state.activeSkus, {
				[action.payload.article.art_id]: action.payload.active
			});

			return {
				...state,
				activeSkus
			};
		}
		case ARTICLE_FETCH_FULFILLED: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
