/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import Button from '@viskan/deku-button';
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import Slide from '@viskan/deku-slide';
import Link from '../../../link';

const parseNumber = (num, def) => parseInt(num, 10) || def;

const onClick = (address, setLocation) => () => setLocation({location: address});

const getStores = (data, getTranslation, setLocation) => {
	return data.map(x => {
		const {address, link, openingHoursSaturday, openingHoursSunday, openingHoursWeekdays, phone, storeId, storeName} = x;
		const {addressLine1, city, zipcode} = address;

		return (
			<Cell key={storeId} size='full' md-size='1of3' lg-size='1of5'>
				<div class='FindStore-closestStore'>
					<h4 class='u-marginBottom' innerHTML={storeName}/>
					<div class='u-marginBottom'>{phone}</div>
					<div class='FindStore-addressLines'>{addressLine1}</div>
					<div class='FindStore-addressLines'>{`${zipcode} ${city}`}</div>
					<div class='FindStore-times u-marginTop u-marginBottom'>
						<div class='FindStore-time'>
							<Arrange>
								<ArrangeFill>
									<div class='u-textNoWrap'>{getTranslation('store.opening_weekdays')}</div>
								</ArrangeFill>
								<ArrangeFit>
									<div class='u-textNoWrap'>{openingHoursWeekdays}</div>
								</ArrangeFit>
							</Arrange>
						</div>
						<div class='FindStore-time'>
							<Arrange>
								<ArrangeFill>
									<div class='u-textNoWrap'>{getTranslation('store.opening_saturday')}</div>
								</ArrangeFill>
								<ArrangeFit>
									<div class='u-textNoWrap'>{openingHoursSaturday}</div>
								</ArrangeFit>
							</Arrange>
						</div>
						<div class='FindStore-time'>
							<Arrange>
								<ArrangeFill>
									<div class='u-textNoWrap'>{getTranslation('store.opening_sunday')}</div>
								</ArrangeFill>
								<ArrangeFit>
									<div class='u-textNoWrap'>{openingHoursSunday}</div>
								</ArrangeFit>
							</Arrange>
						</div>
					</div>
					<Link href={link}>
						<Button class='Button Button--primary FindStore-button' onClick={onClick(address, setLocation)}>
							{`${getTranslation('global.to')} ${storeName}`}
						</Button>
					</Link>
				</div>
			</Cell>
		);
	});
};

const getSliderStores = (data, getTranslation, setLocation) => {
	return data.map(x => {
		const {address, link, openingHoursSaturday, openingHoursSunday, openingHoursWeekdays, phone, storeId, storeName} = x;
		const {addressLine1, city, zipcode} = address;

		return (
			<div key={storeId} class='FindStore-closestStoreSlider'>
				<h4 class='u-marginBottom' innerHTML={storeName}/>
				<div class='u-marginBottom'>{phone}</div>
				<div class='FindStore-addressLines'>{addressLine1}</div>
				<div class='FindStore-addressLines'>{`${zipcode} ${city}`}</div>
				<div class='FindStore-times u-marginTop u-marginBottom'>
					<div class='FindStore-time'>
						<Arrange>
							<ArrangeFill>
								<div class='u-textNoWrap'>{getTranslation('store.opening_weekdays')}</div>
							</ArrangeFill>
							<ArrangeFit>
								<div class='u-textNoWrap'>{openingHoursWeekdays}</div>
							</ArrangeFit>
						</Arrange>
					</div>
					<div class='FindStore-time'>
						<Arrange>
							<ArrangeFill>
								<div class='u-textNoWrap'>{getTranslation('store.opening_saturday')}</div>
							</ArrangeFill>
							<ArrangeFit>
								<div class='u-textNoWrap'>{openingHoursSaturday}</div>
							</ArrangeFit>
						</Arrange>
					</div>
					<div class='FindStore-time'>
						<Arrange>
							<ArrangeFill>
								<div class='u-textNoWrap'>{getTranslation('store.opening_sunday')}</div>
							</ArrangeFill>
							<ArrangeFit>
								<div class='u-textNoWrap'>{openingHoursSunday}</div>
							</ArrangeFit>
						</Arrange>
					</div>
				</div>
				<Link href={link}>
					<Button class='Button Button--primary FindStore-button' onClick={onClick(address, setLocation)}>
						{`${getTranslation('global.to')} ${storeName}`}
					</Button>
				</Link>
			</div>
		);
	});
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {breakpoint, closestStores, cmsLoaded, getTranslation, setLocation, useSlider} = props;

	if (useSlider) {
		const columnsMobile = parseNumber(props.columnsMobile, 1);
		const columnsTablet = parseNumber(props.columnsTablet, 1);
		const columnsDesktop = parseNumber(props.columnsDesktop, 1);

		const options = {
			breakpoints: {
				640: {
					slidesPerView: columnsMobile
				},
				960: {
					slidesPerView: columnsTablet
				}
			},
			slidesPerView: columnsDesktop
		};

		if (cmsLoaded) {
			options.simulateTouch = false;
		}

		if (cmsLoaded && breakpoint === 'md') {
			options.slidesPerView = columnsTablet;
		}

		if (cmsLoaded && breakpoint === 'xsm') {
			options.slidesPerView = columnsMobile;
		}

		return (
			<div class='FindStore-closest'>
				<Slide options={options} loop={false} arrows>
					{closestStores && getSliderStores(closestStores, getTranslation, setLocation, useSlider)}
				</Slide>
			</div>
		);
	}

	return (
		<div class='FindStore-closest'>
			<div class='FindStore-more'>
				<span>{getTranslation('store.more_stores')}</span>
			</div>
			<Grid gutter equalHeight>
				{closestStores && getStores(closestStores, getTranslation, setLocation)}
			</Grid>
		</div>
	);
};

export default {render, shouldUpdate};
