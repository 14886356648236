import cookie from 'component-cookie';
import store from 'store2';

export default () => next => action => {
	const sessionCheckId = cookie('viskan-session-check');

	if (!window._streamline.holdNewSessionReload && window._streamline.sessionCheckId && window._streamline.sessionCheckId !== sessionCheckId) {
		store.remove('vs-person_flg');
		window.location.reload();
		return;
	}

	window._streamline.holdNewSessionReload = false;
	window._streamline.sessionCheckId = cookie('viskan-session-check');

	return next(action);
};
