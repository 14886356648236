/** @jsx dom */
import dom from 'magic-virtual-element';
import {generateMediaURL} from '@viskan/streamline-utilities';
import {handleImageClick, getSliderCommon} from '../common';

const sliderContent = (imageArray, props) => imageArray.map(image => {
	const src = generateMediaURL(image.filename, {scale: 'normal'}, props.mediaConfig);

	return (
		<figure key={image.path} class={['CMS-ArticleMedia-sliderImage', props.styleClasses['Slider Figure']]} onClick={props.zoomPopup && handleImageClick(props)} data-style-attr='Slider Figure'>
			<img class={props.styleClasses['Slider Image']} src={src} alt={image.media_alt_desc || props.article.artname} data-style-attr='Slider Image'/>
		</figure>
	);
});

const getSlider = props => getSliderCommon(props, sliderContent);

export default getSlider;
