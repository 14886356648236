/** @jsx dom */
import dom from 'magic-virtual-element';
import {Slider} from '../';
import {Image} from '../../../../media';

const handleClick = (option, props) => e => {
	e.preventDefault();

	const sku = v12.article.resetAttributes(props.article, Object.assign({}, props.active, {
		[props.name]: parseInt(option.value, 10)
	}), {getFirstInStock: props.checkBalance});

	props.setSku(props.article, sku, {getFirstInStock: props.attribute === 'attr1'});
	history.replaceState(null, null, `${window.location.pathname}?attr1_id=${sku.attr1_id}`);
};

const getBoxes = props => {
	return props.options.map(option => {
		const isActive = option.value === props.active[props.name];
		const id = `${props.name}_${option.value}_${props.article.art_id}_${props.showAsSlider}`;

		const classes = {
			'CMS-AttributeMedia': true,
			'CMS-AttributeMedia--active': isActive,
			'CMS-AttributeMedia--disabled': option.disabled
		};

		const medias = v12.article.medias({
			articleAttributeMedias: props.article.articleAttributeMedias
		}, {
			include: [1010],
			sku: {attr1_id: option.value},
			size: ''
		});

		return (
			<div class={['CMS-AttributeMedia-wrapper', !option.inStock && 'CMS-ArticleAttribute--outOfStock', props.styleClasses['Media Wrapper']]} onClick={!option.disabled && handleClick(option, props)} data-style-attr='Media Wrapper'>
				<label for={id} class={[classes, props.styleClasses.Media]} data-style-attr='Media'>
					{Boolean(medias.length) && <Image source={medias[0].filename} scale='thumbnail'/>}
					{props.attributeBelow && <div class={['CMS-AttributeMedia-desc', props.styleClasses['Media Label']]} data-style-attr='Media Label'>{option.label}</div>}
				</label>
				<input class='u-hidden' type='radio' name={props.name} value={option.value} id={id} checked={isActive}/>
			</div>
		);
	});
};

const render = ({props}) => (
	<div class={['CMS-AttributeMedia-container', props.class, props.styleClasses['Media Container']]} data-style-attr='Media Container'>
		{props.label}
		<div class={['CMS-AttributeMedia-attributes', props.styleClasses['Attribute Container']]} data-style-attr='Attribute Container'>
			{props.showAsSlider ?
				<Slider {...props} attributeElements={getBoxes(props)}/> :
				getBoxes(props)
			}
		</div>
	</div>
);

export default {render};
