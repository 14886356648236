/** @jsx dom */
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import emailRegex from 'email-regex';
import {InputField} from '@viskan/deku-form';
import store from 'store2';

const propTypes = {
	saveEmail: {
		source: 'saveEmail'
	}
};

const onChange = ({saveEmail, site}) => e => {
	const {country_id} = site;

	saveEmail(e.target.value);

	if (emailRegex().test(store('vs-email'))) {
		v12.customer.match({
			'del_country.id': country_id,
			'inv_country.id': country_id,
			'vis_country.id': country_id,
			email_1: store('vs-email')
		})
			.then(data => bus.emit('login', data))
			.catch(v12.util.error);
	}
};

const afterMount = (component, el, setState) => setState({email: store('vs-email') || ''});
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props, state}) => {
	const {getTranslation} = props;
	const {email} = state;

	return (
		<div class='QuickCart-remarket QuickCartDropdownMenu-item'>
			<InputField type='email' class='QuickCart-remarket-input' value={email} id='quickcart-remarket-input' label={getTranslation('remarket.save')} name='email' placeholder={getTranslation('placeholder.email')} onChange={onChange(props)}/>
		</div>
	);
};

export default {afterMount, propTypes, render, shouldUpdate};
