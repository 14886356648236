import page from 'page';
import {createAction} from 'redux-actions';
import {CATEGORY_FETCH} from '../action-types';

const findRedirects = async query => {
	try {
		const {originalReferrer, redirect} = await v12.redirect.get(query, {query: v12.options.defaults});
		const {targetUrl, status} = redirect;

		if (targetUrl.startsWith('http') && !targetUrl.includes(location.host)) {
			location.href = targetUrl;
		}

		const querySeparator = targetUrl.includes('?') ? '&' : '?';
		const targetUrlWithReferrer = originalReferrer ? `${targetUrl}${querySeparator}referrer=${originalReferrer}` : targetUrl;

		if (targetUrlWithReferrer.startsWith('http')) {
			location.href = targetUrlWithReferrer;
		}

		if (!targetUrlWithReferrer.startsWith('/')) {
			page.redirect(`/${targetUrlWithReferrer}`);
		}

		if (targetUrlWithReferrer.startsWith('/')) {
			page.redirect(targetUrlWithReferrer);
		}

		throw parseInt(status, 10);
	} catch (error) {
		throw (error);
	}
};

export const categoryFetch = createAction(CATEGORY_FETCH, async (query, section, v12Options, options = {}) => {
	try {
		const data = await v12.category.get(v12.util.pathToLinkFriendlyName(query), v12Options);

		return {
			data,
			section,
			notFound: false
		};
	} catch (error) {
		if (section === 'main' && !options.dontRedirect) {
			return findRedirects(query, error);
		}

		throw (error);
	}
}, (query, section) => ({section}));
