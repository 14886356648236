import {createAction} from 'redux-actions';
import {BREAKPOINT_SET} from '../action-types';

const replaceAll = (target, search, replacement) => target.split(search).join(replacement);
const formatString = str => replaceAll(replaceAll(str, '"', ''), `'`, '');

export const setBreakpoint = createAction(BREAKPOINT_SET, value => formatString(value));

export const getIntValue = (str, breakpoints) => {
	const value = breakpoints.find(x => x.id === str);

	if (value) {
		return value.width;
	}

	return undefined;
};
