/** @jsx dom */
import dom from 'magic-virtual-element';
import breakpoints from '../../breakpoints';

const getShowValue = (width, {max, min}) => {
	if (typeof max === 'number' && typeof min === 'number') {
		return min <= width && width <= max;
	}

	if (typeof max === 'number') {
		return width <= max;
	}

	if (typeof min === 'number') {
		return min <= width;
	}

	return true;
};

const render = ({props}) => {
	const {breakpoint, breakpointOverride, children, getIntValue, min, max} = props;
	const minValue = getIntValue(min, breakpoints);
	const maxValue = getIntValue(max, breakpoints);
	const breakpointValue = getIntValue(breakpointOverride ? breakpointOverride : breakpoint, breakpoints);
	const show = getShowValue(breakpointValue, {min: minValue, max: maxValue});

	if (!show) {
		return <noscript/>;
	}

	return (
		<div class={props.class}>
			{children.map(child => typeof child === 'function' ? child() : child)}
		</div>
	);
};

export default {render};
