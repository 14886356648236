/** @jsx dom */
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';
import Popup from '@viskan/deku-popup';

const render = ({props}) => {
	const {close, content, open, options} = props;

	if (!open) {
		return <noscript/>;
	}

	return (
		<Popup {...options} onClickOutside={close} open>
			<div class='Popup-close' onClick={close}>
				<Icon style='close'/>
			</div>
			<div class='Popup-content'>
				{content}
			</div>
		</Popup>
	);
};

export default {render};
