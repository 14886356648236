/** @jsx dom */
import dom from 'magic-virtual-element';
import pathToRegexp from 'path-to-regexp';
import store from 'store2';
import Account from '../../account';
import Article from '../../article';
import Category from '../../category';
import Checkout from '../../checkout';
import Login from '../../login';
import Logout from '../../logout';
import NewPassword from '../../new-password';
import Password from '../../password';
import Receipt from '../../receipt';
import Register from '../../register';

const getRouteMatch = (test, route, callback) => {
	const regex = pathToRegexp(route);
	const match = regex.exec(test);

	if (match) {
		return typeof callback === 'function' ? callback(match.slice(1)) : callback;
	}
};

const redirectToCountry = props => {
	const {router} = props;
	const cmsLoaded = window._streamline.siteContext.cmsTokenSet;
	const {disableCountryRedirect} = store('vs-debug') || {};

	if (!store('locale') || cmsLoaded || router.querystring || disableCountryRedirect) {
		return;
	}

	window.location = `${window.location}${store('locale')}/`;
};

const renderByPath = props => ([
	getRouteMatch(props.router.pathname, '/locale/', () => redirectToCountry(props)),
	getRouteMatch(props.router.pathname, '/password/:token', ([token]) => <NewPassword token={token}/>),
	getRouteMatch(props.router.pathname, '/receipt/:purchaseNumber', ([purchaseNumber]) => <Receipt purchaseNumber={purchaseNumber}/>),
	getRouteMatch(props.router.pathname, `/${props.site.articleUrlPrefix}/:slug`, ([slug]) => <Article query={slug} sku={props.router.query}/>),
	getRouteMatch(props.router.pathname, '/account', <Account pathPrefix={props.router.pathPrefix}/>),
	getRouteMatch(props.router.pathname, '/checkout', <Checkout/>),
	getRouteMatch(props.router.pathname, '/login', <Login/>),
	getRouteMatch(props.router.pathname, /^\/logout(.*)$/, ([redirect]) => <Logout pathPrefix={props.router.pathPrefix} redirect={redirect}/>),
	getRouteMatch(props.router.pathname, '/password', <Password/>),
	getRouteMatch(props.router.pathname, '/register', <Register pathPrefix={props.router.pathPrefix}/>),
	getRouteMatch(props.router.pathname, '/:slug(.*)', ([slug]) => <Category query={slug || '/'}/>)
]).find(Boolean);

const render = ({props}) => (
	<div class={['RouterView', props.class]}>
		{renderByPath(props)}
	</div>
);

export default {render};
