import bus from '@viskan/bus';
import page from 'page';
import store from 'store2';

const viewAccount = pathPrefix => page(`${pathPrefix}account`);
export const openPopup = (data, opts) => bus.emit('popup:open', data, opts);
export const saveEmail = str => (str && v12.isValid.email(str)) && (store('vs-email', str) || true);
export const isPerson = () => store('vs-person_flg') !== null ? store('vs-person_flg') : true;
export const setMetaDescription = (string = '') => {
	const element = document.querySelector('meta[name="description"]');

	return element && element.setAttribute('content', string);
};

export const setTitle = str => {
	if (!str) {
		return;
	}

	document.title = `${str} - ${window._streamline.siteContext.companyName}`;
};

export const validationError = (str, getTranslation) => (validity, el, rule) => {
	const errors = [];
	const rules = [
		'matches',
		'differs',
		'exactLength',
		'greaterThan',
		'greaterThanEqual',
		'lowerThan',
		'lowerThanEqual'
	];

	for (const x in el.validity) {
		if (el.validity[x] && x !== 'valid') {
			if (x === 'valueMissing') {
				errors.unshift(getTranslation(`validation.${x}`, {field: str}));
				break;
			}
			if (rule && rules.includes(rule.rule)) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str, msg: rule.msg}));
				break;
			}

			if (rule) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str}));
				break;
			}

			errors.push(getTranslation(`validation.${x}`, {field: str}));
		}
	}

	return errors[0];
};

export default app => {
	app.set('isPerson', isPerson);
	app.set('saveEmail', saveEmail);
	app.set('setTitle', setTitle);
	app.set('validationError', validationError);
	app.set('viewAccount', viewAccount);
	app.set('openPopup', openPopup);
};
