import {connect} from '@viskan/deku-redux';
import * as customer from '../customer/actions';
import * as shopcart from '../shopcart/actions';
import {recieve as recieveSite} from '../site/actions';
import {fetchTree} from '../tree/actions';
import App from './components';

export default connect(
	({app, breakpoint, customer, router, shopcart, site}) => ({
		breakpointOverride: breakpoint.breakpointOverride,
		cssNamespace: app.cssNamespace,
		customer,
		isLandingPage: router.pathname === site.localePickerPath,
		mainSection: app.category.main,
		shopcart,
		site
	}),
	dispatch => ({
		customerPatch: data => dispatch(customer.patch(data)),
		fetchCustomer: () => dispatch(customer.fetch()),
		fetchTree: tag => dispatch(fetchTree(tag)),
		fetchShopcart: () => dispatch(shopcart.fetch(true)),
		recieveSite: data => dispatch(recieveSite(data))
	})
)(App);
