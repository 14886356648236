/** @jsx dom */
import dom from 'magic-virtual-element';
import hex2rgb from 'hex-to-rgb';
import luminance from 'color-luminance';
import {Slider} from '../';

const handleClick = (option, props) => e => {
	e.preventDefault();

	const sku = v12.article.resetAttributes(props.article, Object.assign({}, props.active, {
		[props.name]: parseInt(option.value, 10)
	}), {getFirstInStock: props.checkBalance});

	props.setSku(props.article, sku, {getFirstInStock: props.attribute === 'attr1'});
	history.replaceState(null, null, `${window.location.pathname}?attr1_id=${sku.attr1_id}`);
};

const getBoxes = props => {
	return props.options.map(option => {
		const isActive = option.value === props.active[props.name];
		const id = `${props.name}_${option.value}_${props.article.art_id}_${props.showAsSlider}`;
		const classes = {
			'CMS-AttributeBox': true,
			'CMS-AttributeBox--active': isActive,
			'CMS-AttributeBox--color-light': luminance(hex2rgb(option.rgb_code || '#fff')) >= 180,
			'CMS-AttributeBox--disabled': option.disabled
		};

		return (
			<div class={['CMS-AttributeBox-wrapper', !option.inStock && 'CMS-ArticleAttribute--outOfStock', props.styleClasses['Box Wrapper']]} onClick={!option.disabled && handleClick(option, props)} data-style-attr='Box Wrapper'>
				<label for={id} style={{'background-color': option.rgb_code ? `#${option.rgb_code}` : '#fff'}} class={[classes, props.styleClasses.Box]} data-style-attr='Box'/>
				{props.attributeBelow && <div class={['CMS-AttributeBox-desc', props.styleClasses['Box Label']]} data-style-attr='Box Label'>{option.label}</div>}
				<input class='u-hidden' type='radio' name={props.name} value={option.value} id={id} checked={isActive}/>
			</div>
		);
	});
};

const render = ({props}) => (
	<div class={['CMS-AttributeBox-container', props.class, props.styleClasses['Box Container']]} data-style-attr='Box Container'>
		{props.label}
		<div class={['CMS-AttributeBox-attributes', props.styleClasses['Attribute Container']]} data-style-attr='Attribute Container'>
			{props.showAsSlider ?
				<Slider {...props} attributeElements={getBoxes(props)}/> :
				getBoxes(props)
			}
		</div>
	</div>
);

export default {render};
