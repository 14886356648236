/** @jsx dom */
import dom from 'magic-virtual-element';
import {Form} from '@viskan/deku-form';

const handleSearch = props => data => props.searchStores(data.searchString.trim().toLowerCase(), props.stores);

const render = ({props}) => {
	return (
		<div class={['CMS-StoreBalanceSearch', props.styleClasses.Search]} data-style-attr='Search'>
			<Form onSubmit={handleSearch(props)}>
				<input name='searchString' class={['CMS-StoreBalanceSearch-input', props.styleClasses['Search Input']]} placeholder={props.searchPlaceholder} data-style-attr='Search Input' autocomplete='off' required/>
				<button class={['CMS-StoreBalanceSearch-input', props.styleClasses['Search Button']]} type='submit' data-style-attr='Search Button'>{props.searchButtonText}</button>
			</Form>
		</div>
	);
};

export default {render};
