import {createAction} from 'redux-actions';
import store from 'store2';
import {ARTICLES_FAVORITES_ADD, ARTICLES_FAVORITES_REMOVE} from '../../../action-types';

export const ARTICLES_FAVORITES_ARTNOS_LS_KEY = 'vs-favorites-artnos';

const setFavorites = artNos => store(ARTICLES_FAVORITES_ARTNOS_LS_KEY, artNos);

const getFavorites = () => store(ARTICLES_FAVORITES_ARTNOS_LS_KEY) || [];

export const add = createAction(ARTICLES_FAVORITES_ADD, async (artNo, variant) => {
	const currentFavorites = getFavorites();
	const newFavorites = [{artNo, variant}, ...currentFavorites];
	setFavorites(newFavorites);

	return {
		artNo,
		variant
	};
});

export const remove = createAction(ARTICLES_FAVORITES_REMOVE, async (artNo, variant) => {
	const currentFavorites = getFavorites();

	const newFavorites = currentFavorites.filter(favorite => {
		if (favorite.artNo !== artNo) {
			return favorite;
		}

		if (variant === -1) {
			return favorite.artNo !== artNo;
		}

		return favorite.variant !== variant;
	});

	setFavorites(newFavorites);

	return {
		artNo,
		variant
	};
});
