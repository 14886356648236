export default node => ({
	type: 'FILTER',
	name: 'Filter',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Articles',
	props: [{
		name: 'filterSource',
		inputType: 'select',
		label: 'Source',
		values: [{
			label: 'Color',
			value: 'attribute1'
		}, {
			label: 'Size',
			value: 'attribute2'
		}, {
			label: 'Material',
			value: 'Material'
		}, {
			label: 'Category',
			value: 'category'
		}, {
			label: 'Brand',
			value: 'articleBrand'
		}, {
			label: 'Selected Filters',
			value: 'selectedFilters'
		}],
		default: 'attribute1'
	}, {
		name: 'filterStyle',
		inputType: 'select',
		label: 'Style',
		values: [{
			label: 'Accordion',
			value: 'accordion'
		}, {
			label: 'Dropdown',
			value: 'dropdownBox'
		}],
		default: 'dropdownBox'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'startOpen',
		inputType: 'checkbox',
		label: 'Start accordion open',
		default: false
	}, {
		name: 'filterType',
		inputType: 'select',
		label: 'Type',
		values: [{
			label: 'Button',
			value: 'button'
		}, {
			label: 'Checkbox',
			value: 'checkbox'
		}],
		default: 'button'
	}, {
		name: 'filterHeader',
		inputType: 'text',
		label: 'Filter header',
		default: 'Filter header'
	}, {
		name: 'groupFilters',
		inputType: 'checkbox',
		label: 'Group filter options',
		default: false
	}].filter(Boolean),
	hiddenProps: []
});
