/** @jsx dom */
import dom from 'magic-virtual-element';
import {url} from '@viskan/streamline-utilities';

const propTypes = {
	data: {
		type: 'object'
	},
	site: {
		source: 'site'
	}
};

const afterUpdate = ({props}) => (props.data.categoryId && window.nostojs) && nostojs(api => api.loadRecommendations());
const shouldUpdate = ({props}, nextProps) => props.data.linkFriendlyName !== nextProps.data.linkFriendlyName;

const render = ({props}) => {
	const {data, site} = props;
	const {categoryId, linkFriendlyName} = data;

	if (!categoryId || !site.nostoId) {
		return <noscript/>;
	}

	return (
		<div style='display:none'>=
			<div class='nosto_category' innerHTML={url.getLocaleFriendlyPath(`/${linkFriendlyName}`, props.localeMode, props.prefix)}/>
			<div class='nosto_page_type'>category</div>
		</div>
	);
};

export default {afterUpdate, propTypes, render, shouldUpdate};
