/** @jsx dom */
import bus from '@viskan/bus';
import dom from 'magic-virtual-element';
import emailRegex from 'email-regex';
import store from 'store2';
import {setMetaDescription, setTitle} from '../../actions';
import {BreakpointHandler} from '../../breakpoint';
import Footer from '../../footer';
import Header from '../../header';
import LeaveWindow from '../../leave-window';
import {NostoCart} from '../../nosto';
import Popup from '../../popup';
import PopupRedux from '../../popup-redux';
import {View} from '../../router';
import ScrollMemory from '../../scroll-memory';
import Snackbar from '../../snackbar';
import Spinner from '../../spinner';
import StreamlineCms from '../../streamline-cms';
import StructuredDataMarkup from '../../structured-data-markup';

const propTypes = {
	view: {
		source: 'view'
	}
};

const afterUpdate = ({props}, prevProps) => {
	if (props.customer.person_flg !== prevProps.customer.person_flg) {
		store('vs-person_flg', props.customer.person_flg);
		bus.emit('site:get');
	}

	if (props.mainSection.categoryId !== undefined) {
		const currentTitle = props.mainSection.title || props.mainSection.categoryName;

		if (currentTitle !== (prevProps.mainSection.title || prevProps.mainSection.categoryName)) {
			setTitle(currentTitle);
		}

		if (props.mainSection.metaDescription !== prevProps.mainSection.metaDescription) {
			setMetaDescription(props.mainSection.metaDescription);
		}
	}
};

const customerGet = data => data ? Promise.resolve(data) : v12.customer.get();
const beforeMount = ({props}) => {
	const {customer, fetchCustomer, fetchShopcart, fetchTree, customerPatch, recieveSite, site} = props;
	const locale = site.locale.toLowerCase();

	if (!props.isLandingPage && locale !== store('locale')) {
		store('locale', locale);
	}

	fetchCustomer();
	fetchShopcart();
	fetchTree(site.cmsSettings.tags.defaultTree);

	bus.on('shopcart:get', fetchShopcart);
	bus.on('site:recieve', recieveSite);
	bus.on('customer:update', data => customerPatch(data, false));
	bus.on('login', data => {
		customerGet(data)
			.then(data => {
				const {person_flg} = data;

				store('vs-person_flg', person_flg);
				bus.emit('customer:update', data);
				bus.emit('shopcart:get');
				bus.emit('site:get');
			})
			.catch(v12.util.error);
	});

	if (emailRegex().test(store('vs-email')) && customer.logged_in_level === 0) {
		v12.customer.match({
			'del_country.id': site.country_id,
			'inv_country.id': site.country_id,
			'vis_country.id': site.country_id,
			email_1: store('vs-email')
		})
			.then(data => bus.emit('login', data))
			.catch(v12.util.error);
	}
};

const render = ({props}) => {
	const classes = {
		'App': true,
		'SCMS-DevicePreview--mobile': props.breakpointOverride === 'xsm',
		'SCMS-DevicePreview--tablet': props.breakpointOverride === 'md'
	};

	return (
		<div class={classes}>
			<StructuredDataMarkup/>
			<BreakpointHandler progressive>
				<div class={props.cssNamespace.content}>
					{!props.isLandingPage && <Header/>}
					<main>
						<View/>
					</main>
					{!props.isLandingPage && <Footer/>}
					<Popup universal/>
					<PopupRedux/>
					<Spinner/>
					{props.site.country_code.toLowerCase() !== 'fi' && <LeaveWindow/>}
					<Snackbar/>
					<NostoCart/>
				</div>
				<StreamlineCms/>
			</BreakpointHandler>
			<ScrollMemory/>
		</div>
	);
};

export default {afterUpdate, beforeMount, propTypes, render};
