/** @jsx dom */
import dom from 'magic-virtual-element';

export default ({icon, iconColor, iconSize, iconClass, label, onClick}) => {
	if (!icon) {
		return label ? label : <noscript/>;
	}

	try {
		const parsedIcon = JSON.parse(icon);
		const fontSize = (iconSize && iconSize.replace(',', '.')) || '';
		const styles = {
			fontSize: `${fontSize}`,
			color: `${iconColor}`
		};

		return (
			<div style={styles}>
				<i key={`${parsedIcon.x.name}${parsedIcon.prefix}`} class={`${parsedIcon.prefix} fa-${parsedIcon.x.name} ${iconClass}`} onClick={onClick}/>
			</div>
		);
	} catch (error) {
		return <noscript/>;
	}
};
