import {connect} from '@viskan/deku-redux';
import setActiveFilters from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		activeFilters: state.articles.activeFilters,
		hasArticlesIfNoFilter: state.articles.firstTotal > 0
	}),
	dispatch => ({
		onChangeFilter: (activeFilters, data) => dispatch(setActiveFilters(activeFilters, data))
	})
)(Component);

export default {component, config};
