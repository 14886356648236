export default {
	type: 'FIND_STORE',
	name: 'Find store',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Google',
	props: [{
		name: 'apiKey',
		inputType: 'text',
		label: 'Google API key',
		default: ''
	}, {
		name: 'height',
		inputType: 'text',
		label: 'Height',
		default: '500px'
	}, {
		name: 'maxWidth',
		inputType: 'text',
		label: 'Max-width',
		default: '100%'
	}, {
		name: 'zoom',
		inputType: 'select',
		label: 'City zoom',
		default: '12',
		values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']
	}, {
		name: 'icon',
		inputType: 'media',
		label: 'Marker icon',
		default: ''
	}, {
		name: 'theme',
		inputType: 'select',
		label: 'Theme',
		default: 'Standard',
		values: ['Standard', 'Silver', 'Retro', 'Dark', 'Night', 'Aubergine']
	}, {
		name: 'note',
		inputType: 'text',
		label: 'Help message',
		default: ''
	}, {
		name: 'centerAllStores',
		inputType: 'checkbox',
		label: 'Center all stores',
		default: false
	}],
	hiddenProps: [{
		label: 'Closest store options',
		props: [{
			name: 'maxClosestStores',
			inputType: 'slider',
			label: 'Max closest stores',
			default: 5,
			min: 1,
			max: 10
		}, {
			name: 'useSlider',
			inputType: 'checkbox',
			label: 'Use slider',
			default: false
		}, {
			name: 'columnsMobile',
			inputType: 'slider',
			label: 'Columns mobile',
			default: 1,
			min: 1,
			max: 5
		}, {
			name: 'columnsTablet',
			inputType: 'slider',
			label: 'Columns tablet',
			default: 2,
			min: 1,
			max: 5
		}, {
			name: 'columnsDesktop',
			inputType: 'slider',
			label: 'Columns desktop',
			default: 3,
			min: 1,
			max: 5
		}]
	}]
};
