export default {
	type: 'B2B_B2C',
	name: 'B2B - B2C',
	allowedSections: ['footer', 'header'],
	disallowedChildren: ['*'],
	category: 'Header',
	props: [{
		name: 'b2bText',
		inputType: 'text',
		label: 'B2B Text',
		default: 'Business'
	}, {
		name: 'b2cText',
		inputType: 'text',
		label: 'B2C Text',
		default: 'Consumer'
	}, {
		name: 'delimiter',
		inputType: 'text',
		label: 'Delimiter',
		default: ' | '
	}]
};
