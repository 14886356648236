import {connect} from '@viskan/deku-redux';
import {close} from './actions';
import Popup from './components';

export default connect(
	({popup}) => ({
		...popup
	}),
	dispatch => ({
		close: () => dispatch(close())
	})
)(Popup);
