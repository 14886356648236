import bus from '@viskan/bus';

export default app => {
	bus.on('shopcart:update', data => app.set('shopcart', data));

	app.set('shopcart', {
		rows: [],
		head: {},
		outorder: {},
		offers: {}
	});
};
