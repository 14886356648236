const getType = params => {
	if ('KlarnaCheckout.Snippet' in params) {
		return 'kco';
	}

	if ('CollectorBank.PublicToken' in params) {
		return 'collector';
	}

	return false;
};

export default getType;
