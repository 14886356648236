/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {CheckboxText} from '../';
import Checkbox from './checkbox';
import Select from './select';

const handleAddAddon = props => data => props.addAddon(data);
const handleRemoveAddon = props => data => props.removeAddon(data);
const handleRemoveSelectAddon = props => () => props.removeSelectAddon();

const getAddons = props => {
	const selectAddons = [];

	const items = props.addons.map(addon => {
		switch (addon.relation_no) {
			case 1: {
				return <CheckboxText key={addon.article.art_id} data={addon} onAdd={handleAddAddon(props)} onRemove={handleRemoveAddon(props)}/>;
			}
			case 3: {
				return <Checkbox key={addon.article.art_id} data={addon} onAdd={handleAddAddon(props)} onRemove={handleRemoveAddon(props)}/>;
			}
			case 4: {
				selectAddons.push(addon);
				return <noscript key={addon.article.art_id}/>;
			}
			case 2:
			default: {
				return <noscript key={addon.article.art_id}/>;
			}
		}
	});

	if (selectAddons.length !== 0) {
		items.unshift(<Select data={selectAddons} placeholder={props.relation4Placeholder} onAdd={handleAddAddon(props)} onRemove={handleRemoveSelectAddon(props)}/>);
	}

	return items;
};

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleAddons', props.class]}>
			{getAddons(props)}
		</div>
	), props);
};

export default {render};
