import store from 'store2';
import {
	ARTICLE_ADDON_ADD,
	ARTICLE_ADDON_REMOVE,
	ARTICLE_ADDON_SELECT_REMOVE,
	ARTICLE_FETCH_FULFILLED,
	ARTICLE_FETCH_PENDING,
	ARTICLE_FETCH_REJECTED,
	ARTICLE_QUANTITY_SET,
	ARTICLE_RESET,
	ARTICLE_SKU_SET
} from '../action-types';
import {setTitle} from '../actions';
import {trackArticle} from '../analytics';

const ARTICLE_HISTORY_LS_KEY = 'vs-article-history';
const initialState = {
	active: {
		articlePrice: {},
		inStock: false
	},
	addons: [],
	article: {
		articleAttributes: [],
		articleCampaigns: [],
		articleCategories: [],
		prentypes: [],
		propertyKeys: [],
		relatedArticles: []
	},
	loadMain: false,
	history: store(ARTICLE_HISTORY_LS_KEY) || [],
	notFound: false,
	prentypeId: 0,
	quantity: 1
};

const handleSetTitle = data => {
	const metaTitle = v12.article.properties('seo.meta.title', data.propertyKeys);

	if (metaTitle.length !== 0 && metaTitle[0].propertyValues.length !== 0) {
		setTitle(metaTitle[0].propertyValues[0].propertyvalue);
		return;
	}

	setTitle(data.artname);
};

const handleHistory = (currentHistory, article) => {
	const {art_no} = article;
	const ret = [art_no, ...currentHistory.filter(x => x !== art_no)].filter((x, i) => i <= 49);
	store(ARTICLE_HISTORY_LS_KEY, ret);

	return ret;
};

const getActive = (article, sku) => {
	if (sku) {
		const active = v12.article.sku(article, sku, {getFirstInStock: true});

		if (active) {
			return active;
		}
	}

	return article.articleAttributes.find(attribute => attribute.inStock) || article.articleAttributes[0];
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ARTICLE_ADDON_ADD: {
			const newAddons = state.addons.slice();
			const index = newAddons.findIndex(addon => addon.art_id === action.payload.art_id);

			if (index !== -1) {
				newAddons.splice(index, 1);
			}

			newAddons.push(action.payload);

			return {
				...state,
				addons: newAddons
			};
		}
		case ARTICLE_ADDON_REMOVE: {
			const newAddons = state.addons.slice();
			const index = newAddons.findIndex(addon => addon.art_id === action.payload.art_id);

			if (index !== -1) {
				newAddons.splice(index, 1);
			}

			return {
				...state,
				addons: newAddons
			};
		}
		case ARTICLE_ADDON_SELECT_REMOVE: {
			return {
				...state,
				addons: state.addons.filter(addon => !addon.isAddonSelect)
			};
		}
		case ARTICLE_FETCH_PENDING: {
			return {
				...state,
				loading: true
			};
		}
		case ARTICLE_FETCH_FULFILLED: {
			const article = action.payload;
			const active = getActive(article, action.meta.sku);

			handleSetTitle(article);
			trackArticle({body: article, sku: active});

			return {
				...initialState,
				active,
				article: action.payload,
				loadMain: state.loadMain,
				notFound: false,
				history: handleHistory(state.history, article)
			};
		}
		case ARTICLE_FETCH_REJECTED: {
			return {
				...state,
				notFound: true
			};
		}
		case ARTICLE_QUANTITY_SET: {
			return {
				...state,
				quantity: action.payload
			};
		}
		case ARTICLE_RESET: {
			return {
				...initialState,
				loadMain: state.loadMain,
				history: state.history
			};
		}
		case ARTICLE_SKU_SET: {
			return {
				...state,
				active: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
