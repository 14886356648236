/** @jsx dom */
import dom from 'magic-virtual-element';
import Slide from '@viskan/deku-slide';

const render = ({props}) => {
	const activeIndex = props.options.findIndex(option => option.value === props.active[props.name]);
	const options = {
		centeredSlides: props.sliderCenter,
		onInit: instance => instance.slideTo(activeIndex),
		slidesPerView: props.sliderSlidesPerview,
		spaceBetween: props.sliderSpaceBetween
	};

	if (props.cmsLoaded) {
		options.simulateTouch = false;
	}

	return <Slide styleClasses={props.styleClasses} options={options} arrows={props.sliderArrows} pagination={props.sliderPagination} paginationType={props.paginationType} color={props.sliderNavigationColor}>{props.attributeElements}</Slide>;
};

export default {render};
