/** @jsx dom */
import dom from 'magic-virtual-element';
import {render, tree} from '@viskan/deku';
import {storePlugin} from '@viskan/deku-redux';
import {applyMiddleware} from 'redux';
import {createInjectStore, injectReducer} from 'redux-injector';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';
import store from 'store2';
import actions from './actions';
import analytics, {setOrigin} from './analytics';
import App from './app';
import cookieMiddleware from './cookie-middleware';
import {createReduxLogger, dekuErrorHandler, serverErrorLogger} from './error';
import customer from './sources/customer';
import reducer from './reducer';
import router from './router';
import shopcart from './sources/shopcart';
import sources from './sources';

setOrigin();

const defaultDebugOptions = {
	diff: false,
	diffDepth: 5,
	log: true,
	disableCountryRedirect: false,
	disableChromeCmsWarning: false
};

window._debug = options => {
	if (!options) {
		return store('vs-debug', false);
	}

	store('vs-debug', options === true ? defaultDebugOptions : {...defaultDebugOptions, ...options});
};

const debugSettings = store('vs-debug');
const isDebugging = Boolean(debugSettings);
const reduxLogger = isDebugging ? createReduxLogger(debugSettings) : undefined;
const middlewares = [cookieMiddleware, reduxThunk, reduxPromiseMiddleware(), reduxLogger].filter(Boolean);
const reduxStore = createInjectStore(reducer, applyMiddleware(...middlewares));

window._injectReducer = injectReducer;

if (isDebugging) {
	window._reduxStore = reduxStore;
} else {
	window.onerror = () => true;
	window.addEventListener('error', serverErrorLogger);
}

const app = tree(<App/>)
	.use(storePlugin(reduxStore))
	.use(actions)
	.use(customer)
	.use(sources)
	.use(shopcart)
	.use(analytics)
	.use(router);

render(app, document.querySelector('#Streamline'), {
	errorHandler: isDebugging ? undefined : dekuErrorHandler
});
