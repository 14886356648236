/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {QtySelect} from '@viskan/estore-components';

const handleInputChange = props => quantity => props.setQuantity(quantity);

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const render = ({props}) => <QtySelect qty={props.quantity} min='1' onChange={handleInputChange(props)}/>;

export default {render, shouldUpdate};
