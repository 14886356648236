export default {
	activationDateDesc: {
		translationKey: 'category_sorting.activation_date_desc',
		value: {'activation_date': 'desc'}
	},
	activationDateAsc: {
		translationKey: 'category_sorting.activation_date_asc',
		value: {'activation_date': 'asc'}
	},
	lowestPriceSalesAsc: {
		translationKey: 'category_sorting.lowest_price_sales_asc',
		value: {'lowest_price_sales': 'asc'}
	},
	lowestPriceSalesDesc: {
		translationKey: 'category_sorting.lowest_price_sales_desc',
		value: {'lowest_price_sales': 'desc'}
	},
	artnameAsc: {
		translationKey: 'category_sorting.artname_asc',
		value: {'artname': 'asc'}
	},
	artnameDesc: {
		translationKey: 'category_sorting.artname_desc',
		value: {'artname': 'desc'}
	},
	artnoAsc: {
		translationKey: 'category_sorting.art_no_asc',
		value: {'art_no': 'asc'}
	},
	artnoDesc: {
		translationKey: 'category_sorting.art_no_desc',
		value: {'art_no': 'desc'}
	},
	articleCategoryAsc: {
		translationKey: 'category_sorting.article_category_asc',
		value: {'artcatsort': 'asc'}
	},
	articleCategoryDesc: {
		translationKey: 'category_sorting.article_category_desc',
		value: {'artcatsort': 'desc'}
	}
};
