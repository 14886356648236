import {createAction} from 'redux-actions';
import {CMS_CATEGORY_IMPORT} from '../../action-types';

export const fetch = createAction(CMS_CATEGORY_IMPORT, async (query, opts) => {
	try {
		const data = await v12.category.get(query, opts);

		return {
			key: query,
			content: data.content
		};
	} catch (error) {
		throw error;
	}
});
