import bus from '@viskan/bus';
import concatObject from 'concat-object';
import {createAction} from 'redux-actions';
import {openPopup} from '../actions';
import {SHOPCART_ADD, SHOPCART_ADD_SUBSCRIPTION, SHOPCART_EDIT, SHOPCART_FETCH, SHOPCART_REMOVE, SHOPCART_UPDATE, SHOPCART_SET} from '../action-types';
import {trackAddToCart, trackRemoveFromCart} from '../analytics';

const addToCart = (data = {}) => v12.shopcart.add(data)
		.then(data => {
			bus.emit('shopcart:update', data);
			bus.emit('loading:close');

			return {
				data
			};
		})
		.catch(error => {
			openPopup(error.message);
			bus.emit('loading:close');
			throw error;
		});

export const addItem = createAction(SHOPCART_ADD, (data, sku, addons = [], qty = 1) => {
	const {art_id} = data;
	const {attr1_id, attr2_id, attr3_id} = sku;

	bus.emit('loading:open');

	const body = {
		art_id,
		attr1_id: attr1_id || 0,
		attr2_id: attr2_id || 0,
		attr3_id: attr3_id || 0,
		personalization_text: '',
		qty
	};

	trackAddToCart({
		body: data,
		qty: body.qty,
		sku
	});

	return addToCart(concatObject(body, ...addons));
});

export const addSubscription = createAction(SHOPCART_ADD_SUBSCRIPTION, subscription => {
	bus.emit('loading:open');

	trackAddToCart({
		body: {
			art_no: subscription.prentype_id,
			artname: subscription.prentype_desc
		},
		qty: 1,
		sku: {
			articlePrice: {
				price_sales: subscription.prognoses[0].price
			}
		}
	});

	return addToCart({prentype_id: subscription.prentype_id});
});

export const edit = createAction(SHOPCART_EDIT, (id, qty, dontOpen) => {
	bus.emit('loading:open');

	return v12.shopcart.edit({
		qty,
		row_id: id
	})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			openPopup(error.message);
			bus.emit('loading:close');
			throw error;
		});
});

export const fetch = createAction(SHOPCART_FETCH, dontOpen => v12.shopcart.get().then(data => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
}).catch(error => {
	openPopup(error.message);
	throw error;
}));

export const remove = createAction(SHOPCART_REMOVE, () => v12.util.http.get('/api/shopcart-remove', {json: false}));

export const removeItem = createAction(SHOPCART_REMOVE, (data, qty, dontOpen) => {
	const {art_no, artname, price_incvat_display, row_id} = data;

	bus.emit('loading:open');

	trackRemoveFromCart({
		sku: {articlePrice: {price_sales: price_incvat_display}},
		body: {
			artname,
			art_no
		},
		qty
	});

	return v12.shopcart.remove({row_id})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			v12.util.error(error);
			bus.emit('loading:close');
			throw error;
		});
});

export const set = createAction(SHOPCART_SET, (data, dontOpen) => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
});

export const update = createAction(SHOPCART_UPDATE, (data, dontOpen) => v12.shopcart.update(data)
	.then(() => fetch(dontOpen))
	.then(({payload}) => payload)
);
