export default {
	type: 'LOGIN_FORM',
	name: 'Login form',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'Account',
	props: [{
		name: 'inlineLayout',
		inputType: 'checkbox',
		label: 'Inline layout',
		default: false
	}, {
		name: 'placeholders',
		inputType: 'checkbox',
		label: 'Placeholders',
		default: false
	}, {
		name: 'showResetLink',
		inputType: 'checkbox',
		label: 'Show reset link',
		default: true
	}, {
		name: 'showRegisterLink',
		inputType: 'checkbox',
		label: 'Show register link',
		default: true
	}, {
		name: 'fluidButton',
		inputType: 'checkbox',
		label: 'Fluid button',
		default: false
	}, {
		name: 'userFieldLabel',
		inputType: 'text',
		label: 'Username/email field label',
		default: 'Username'
	}, {
		name: 'passwordFieldLabel',
		inputType: 'text',
		label: 'Password field label',
		default: 'Password'
	}, {
		name: 'loginButtonText',
		inputType: 'text',
		label: 'Text login button',
		default: 'Login'
	}],
	hiddenProps: [{
		label: 'Password reset settings',
		props: [{
			name: 'resetText',
			inputType: 'text',
			label: 'Text reset password',
			default: 'Reset password'
		}, {
			name: 'resetPath',
			inputType: 'link',
			label: 'Reset page',
			default: '/password',
			requestKey: 'linkFriendlyName'
		}]
	}, {
		label: 'Register account settings',
		props: [{
			name: 'registerText',
			inputType: 'text',
			label: 'Text register account',
			default: 'Register account'
		}, {
			name: 'registerPath',
			inputType: 'link',
			label: 'Register page',
			default: '/register',
			requestKey: 'linkFriendlyName'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
