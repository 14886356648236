import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		breakpoint: state.breakpoint.breakpointOverride || state.breakpoint.breakpoint,
		relatedArticles: state.article.article.relatedArticles
	})
)(Component);

export default {component, config};
