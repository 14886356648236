export default {
	type: 'ARTICLE_THUMBNAILS',
	name: 'Thumbnails',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'orientation',
		inputType: 'select',
		label: 'Orientation',
		default: 'horizontal',
		values: ['horizontal', 'vertical']
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
