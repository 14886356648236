import {connect} from '@viskan/deku-redux';
import config from './config';
import MainNavigation from './components';

export const component = connect(
	({cms, streamlineCms, tree}) => ({
		cmsLoaded: streamlineCms && streamlineCms.loaded,
		cmsHidden: cms && cms.base.hidden,
		tree: tree.data
	})
)(MainNavigation);

export default {component, config};
