import {connect} from '@viskan/deku-redux';
import {addAddon, removeAddon, removeSelectAddon} from '../../../article/actions';
import {setSku, toggleCheckboxInput} from './actions';
import config from './config';
import Component from './components';
import CheckboxTextComponent from './components/checkbox-text';

export const CheckboxText = connect(
	(state, props) => ({
		active: state.articleAddons.activeSkus[props.data.article.art_id] || props.data.article.articleAttributes[0],
		isOpen: state.articleAddons.openCheckboxInputs.includes(props.data.article.art_id)
	}),
	dispatch => ({
		setSku: (article, sku) => dispatch(setSku(article, sku)),
		toggleCheckboxInput: id => dispatch(toggleCheckboxInput(id))
	})
)(CheckboxTextComponent);

export const component = connect(
	state => ({
		addons: state.article.article.relatedArticles.filter(article => article.relation_type === 5)
	}),
	dispatch => ({
		addAddon: addon => dispatch(addAddon(addon)),
		removeAddon: addon => dispatch(removeAddon(addon)),
		removeSelectAddon: () => dispatch(removeSelectAddon())
	})
)(Component);

export default {component, config};
