/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import onEscape from 'on-escape';
import Popup from '../popup';
import {Image} from '../media';

const propTypes = {
	data: {
		type: 'array'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const onClick = (id, setState) => () => setState({open: id});
const onClose = setState => () => setState({open: false});

const getIconFilename = obj => {
	const vals = v12.article.properties('camp.icon', obj.propertyKeys);

	if (vals.length === 0 || vals[0].propertyValues.length === 0) {
		return 'default_campaign_icon.png';
	}

	const val = vals[0].propertyValues[0].propertyvalue;
	const prefix = '/media/';

	if (val.indexOf(prefix) === 0) {
		return val.slice(prefix.length);
	}

	return val;
};

const getOffers = ({data, getTranslation, showClubBadge, offerHeader, offerPopup, showOffers}, {open}, setState) => {
	const usedCampaignIds = {};
	const usedCampaignIcons = {};

	const items = data.map(x => {
		const iconFilename = getIconFilename(x);

		if (usedCampaignIds[x.camp_id] || usedCampaignIcons[iconFilename]) {
			return null;
		}

		usedCampaignIds[x.camp_id] = true;
		usedCampaignIcons[iconFilename] = true;

		if (!showOffers) {
			return null;
		}

		return (
			<div key={x.camp_id} class='Offers-item'>
				<span class={['Offers-span', offerPopup && 'Offers-span--clickable']} onClick={offerPopup && onClick(x.camp_id, setState)}>
					<Image class='Offers-iconImage' source={iconFilename}/>
				</span>
				<Popup class='Offers-popup' open={open === x.camp_id} close={onClose(setState)} height='500px' width='360px'>
					<h2 class='Offers-header'>{offerHeader || getTranslation('article.campaign_title')}:</h2>
					<h4 class='Offers-name' innerHTML={x.camp_name}/>
					<div class='Offers-desc' innerHTML={x.camp_desc}/>
				</Popup>
			</div>
		);
	}).filter(x => x);

	if (showClubBadge) {
		items.unshift(
			<div key='club-offer' class='Offers-item'>
				<Image class='ListArticle-club' source='icon_club_price.png' title='Club Price'/>
			</div>
		);
	}

	return items;
};

const afterMount = ({state}, el, setState) => onEscape(() => setState({open: false}));

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	return (
		<div class={['Offers Offers--icons', props.class]}>
			{getOffers(props, state, setState)}
		</div>
	);
};

export default {afterMount, propTypes, render, shouldUpdate};
