export default {
	type: 'ARTICLE_RELATED',
	name: 'Related',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'title',
		inputType: 'text',
		label: 'Title',
		default: 'Matching products'
	}, {
		name: 'relationType',
		inputType: 'select',
		label: 'Relation type',
		default: '0',
		values: [{
			label: 'Matching',
			value: '0'
		}, {
			label: 'Also bought',
			value: '4'
		}]
	}],
	hiddenProps: [{
		label: 'Layout per device',
		props: [{
			name: 'mobileLayout',
			inputType: 'select',
			label: 'Layout on mobile',
			default: 'Slider',
			values: ['Grid', 'Slider']
		}, {
			name: 'tabletLayout',
			inputType: 'select',
			label: 'Layout on tablet',
			default: 'Slider',
			values: ['Grid', 'Slider']
		}, {
			name: 'desktopLayout',
			inputType: 'select',
			label: 'Layout on desktop',
			default: 'Grid',
			values: ['Grid', 'Slider']
		}]
	}, {
		label: 'Grid settings',
		props: [{
			name: 'maxArticles',
			inputType: 'slider',
			label: 'Maximum number of articles',
			min: 1,
			max: 20,
			step: 1,
			default: 4
		}, {
			name: 'columnsSm',
			inputType: 'slider',
			label: 'Columns mobile',
			default: 2
		}, {
			name: 'columnsMd',
			inputType: 'slider',
			label: 'Columns tablet',
			default: 3
		}, {
			name: 'columnsLg',
			inputType: 'slider',
			label: 'Columns desktop',
			default: 4
		}]
	}, {
		label: 'Slider settings',
		props: [{
			name: 'slidesPerViewSm',
			inputType: 'slider',
			label: 'Articles in view in mobile',
			min: 1,
			max: 20,
			step: 1,
			default: 2
		}, {
			name: 'slidesPerViewMd',
			inputType: 'slider',
			label: 'Articles in view in tablet',
			min: 1,
			max: 20,
			step: 1,
			default: 3
		}, {
			name: 'slidesPerViewLg',
			inputType: 'slider',
			label: 'Articles in view in desktop',
			min: 1,
			max: 20,
			step: 1,
			default: 4
		}, {
			name: 'sliderPagination',
			inputType: 'checkbox',
			label: 'Pagination',
			default: false
		}, {
			name: 'sliderArrows',
			inputType: 'checkbox',
			label: 'Arrows',
			default: true
		}, {
			name: 'sliderAutoplay',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'sliderCenter',
			inputType: 'checkbox',
			label: 'Center active slide',
			default: true
		}, {
			name: 'sliderMargin',
			inputType: 'slider',
			label: 'Margin between slides',
			min: 1,
			max: 50,
			step: 1,
			default: 20
		}, {
			name: 'sliderColor',
			inputType: 'color',
			label: 'Navigation color',
			default: '#000000'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
