const displayAsSlider = {
	name: 'showAsSlider',
	inputType: 'checkbox',
	label: 'Display attributes as a slider',
	default: false
};

const checkboxConfig = [
	displayAsSlider
];

const imageConfig = [
	displayAsSlider
];

const sliderConfig = [{
	label: 'Slider configuraton',
	props: [{
		name: 'sliderSlidesPerview',
		inputType: 'select',
		label: 'Slides per view',
		default: 4,
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	}, {
		name: 'sliderSpaceBetween',
		inputType: 'select',
		label: 'Space between',
		default: 10,
		values: [0, 10, 20, 30, 40, 50]
	}, {
		name: 'sliderCenter',
		inputType: 'checkbox',
		label: 'Center slides',
		default: true
	}, {
		name: 'sliderArrows',
		inputType: 'checkbox',
		label: 'Show Arrows',
		default: true
	}, {
		name: 'sliderPagination',
		inputType: 'checkbox',
		label: 'Show Pagination',
		default: false
	}, {
		name: 'sliderPaginationType',
		inputType: 'select',
		label: 'Pagination type',
		default: 'bullets',
		values: [{
			label: 'Bullets',
			value: 'bullets'
		}, {
			label: 'Progress',
			value: 'progress'
		}, {
			label: 'Fraction',
			value: 'fraction'
		}]
	}, {
		name: 'sliderNavigationColor',
		inputType: 'color',
		label: 'Navigation color',
		default: '#000000'
	}]
}];

const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];

export default node => ({
	type: 'ARTICLE_ATTRIBUTE',
	name: 'Attribute',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'attribute',
		inputType: 'select',
		label: 'Select attribute',
		default: 'attr1',
		values: [{
			label: 'Color',
			value: 'attr1'
		}, {
			label: 'Size',
			value: 'attr2'
		}, {
			label: 'Attribute 3',
			value: 'attr3'
		}]
	}, {
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: 'Attribute'
	}, {
		name: 'showAs',
		inputType: 'select',
		label: 'Display as a',
		default: 'dropdown',
		values: [{
			label: 'Colorbox',
			value: 'checkbox'
		}, {
			label: 'Dropdown',
			value: 'dropdown'
		}, {
			label: 'Image',
			value: 'image'
		}, {
			label: 'Name',
			value: 'name'
		}]
	}, {
		name: 'outOfStockMessage',
		inputType: 'text',
		label: 'Out of stock message',
		default: ''
	},
		...conditionallyAddConfig(node, 'showAs', 'checkbox', checkboxConfig),
		...conditionallyAddConfig(node, 'showAs', 'image', imageConfig),
	{
		name: 'disableOutOfStock',
		inputType: 'checkbox',
		label: 'Disable out of stock options',
		default: false
	}, {
		name: 'checkBalance',
		inputType: 'checkbox',
		label: 'Remove out of stock options',
		default: false
	}, {
		name: 'hideIfOne',
		inputType: 'checkbox',
		label: 'Hide attribute if there is only one option',
		default: false
	}, {
		name: 'priceDifference',
		inputType: 'checkbox',
		label: 'Show difference in price in select',
		default: false
	}, {
		name: 'labelAttribute',
		inputType: 'checkbox',
		label: 'Show current SKU name in label',
		default: false
	}, {
		name: 'attributeBelow',
		inputType: 'checkbox',
		label: 'Show current SKU name below the option',
		default: true
	}],
	hiddenProps: [
		...conditionallyAddConfig(node, 'showAsSlider', true, sliderConfig),
		{
			label: 'Advanced settings',
			props: [{
				name: 'class',
				inputType: 'text',
				label: 'CSS-class',
				default: ''
			}]
		}]
});
