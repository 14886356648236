/** @jsx dom */
import Accordion from 'deku-accordion';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';
import SubscriptionElement from './subscription-element';

const initialState = () => {
	return {
		intervals: [],
		subscriptions: []
	};
};

const getItems = (intervals, subscriptions, setState) => subscriptions.filter(x => x.active_flg).map((x, i) => ({
	active: i === 0,
	content: <SubscriptionElement data={x} intervals={intervals} onChange={setState}/>,
	heading: [x.pren_desc_external, <Icon class='PanelAccordion-icon' style='arrow-down'/>]
}));

const afterMount = ({props}, el, setState) => {
	v12.customer.subscriptions()
		.then(({intervals, subscriptions}) => setState({intervals, subscriptions}))
		.catch(v12.util.error);
};

const shouldUpdate = ({state}, nextProps, nextState) => !deepEqual(state, nextState);

const render = ({state}, setState) => {
	const {intervals, subscriptions} = state;

	return (
		<div class='Subscriptions'>
			<Accordion class='PanelAccordion' items={getItems(intervals, subscriptions, setState)} multiple/>
		</div>
	);
};

export default {afterMount, initialState, render, shouldUpdate};
