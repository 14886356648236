import {SHOPCART_ADD_FULFILLED, SHOPCART_ADD_SUBSCRIPTION_FULFILLED, SHOPCART_EDIT_FULFILLED, SHOPCART_FETCH_FULFILLED, SHOPCART_REMOVE_FULFILLED, SHOPCART_UPDATE_FULFILLED, SHOPCART_SET} from '../action-types';

const initialState = {
	rows: [],
	head: {},
	outorder: {},
	offers: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOPCART_ADD_FULFILLED:
		case SHOPCART_ADD_SUBSCRIPTION_FULFILLED:
		case SHOPCART_EDIT_FULFILLED:
		case SHOPCART_FETCH_FULFILLED:
		case SHOPCART_REMOVE_FULFILLED:
		case SHOPCART_UPDATE_FULFILLED:
		case SHOPCART_SET: {
			return {
				...state,
				...action.payload.data
			};
		}
		default: {
			return state;
		}
	}
};
