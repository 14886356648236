import {TREE_FETCH_FULFILLED} from '../action-types';

const initialState = {
	data: {
		children: []
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TREE_FETCH_FULFILLED: {
			return {
				...state,
				data: action.payload || initialState.data
			};
		}
		default: {
			return state;
		}
	}
};
