/** @jsx dom */
import dom from 'magic-virtual-element';
import objectAssign from 'object-assign';
import {utils as cmsUtils} from '@viskan/cms-components';
import {findTreeByKey} from '@viskan/streamline-utilities';
import Link from '../../../link';
import Dropdown from './dropdown';

const close = (category, id) => () => {
	const el = document.querySelector(`.MainNav--${id} .MainNavDropdown--${category.categoryId}`);

	if (el) {
		el.classList.remove('is-open');
	}
};

const openMenu = (category, id, {toggle}) => event => {
	const el = document.querySelector(`.MainNav--${id} .MainNavDropdown--${category.categoryId}`);

	if (toggle) {
		event.preventDefault();

		if (el && el.classList.contains('is-open')) {
			el.classList.remove('is-open');
			return;
		}

		if (el && !el.classList.contains('is-open')) {
			el.classList.add('is-open');
			return;
		}

		return;
	}

	if (el) {
		el.classList.add('is-open');
	}
};

const filterDepth = (data, depth, level = 0) => {
	level++;

	if (depth < level) {
		return [];
	}

	const ret = data.map(child => {
		if (child.children.length !== 0) {
			return objectAssign({}, child, {
				children: filterDepth(child.children, depth, level)
			});
		}

		return child;
	});

	return ret;
};

const getItems = (id, props) => {
	const {categoryId, depth, dropdown, tree} = props;
	const {topNodeFontColor, topNodeFontSize, topNodeFontStyle, topNodeFontWeight, topNodeMarginHorizontal, topNodeMarginVertical, topNodeTextTransform} = props;
	const {dropDownFontColor, dropDownFontSize, dropDownFontStyle, dropDownFontWeight, dropDownMarginRight, dropDownMarginVertical, dropDownTextTransform} = props;
	const {cmsLoaded, cmsHidden} = props;
	const topNodeMargins = (topNodeMarginVertical ? topNodeMarginVertical : '0px') + ' ' + (topNodeMarginHorizontal ? topNodeMarginHorizontal : '0px') + ' 0px ' + (topNodeMarginHorizontal ? topNodeMarginHorizontal : '0px');
	const topNodePaddings = '0px 0px ' + (topNodeMarginVertical ? topNodeMarginVertical : '0px') + ' 0px';
	const topNodeStyles = {
		'font-size': topNodeFontSize,
		'font-style': topNodeFontStyle,
		'font-weight': topNodeFontWeight,
		'margin': topNodeMargins,
		'padding': topNodePaddings,
		'text-transform': topNodeTextTransform
	};
	const topNodeLinkStyles = {
		'color': topNodeFontColor
	};

	const dropDownMargins = (dropDownMarginVertical ? dropDownMarginVertical : '0px') + ' ' + (dropDownMarginRight ? dropDownMarginRight : '0px') + ' ' + (dropDownMarginVertical ? dropDownMarginVertical : '0px') + ' 0px';
	const dropDownStyles = {
		'color': dropDownFontColor,
		'font-size': dropDownFontSize,
		'font-style': dropDownFontStyle,
		'font-weight': dropDownFontWeight,
		'margin': dropDownMargins,
		'text-transform': dropDownTextTransform
	};

	return filterDepth(findTreeByKey('linkFriendlyName', categoryId, tree).children, depth).map(x => {
		if (x.hidden) {
			return undefined;
		}

		if (cmsLoaded && !cmsHidden) {
			return (
				<li key={x.categoryId} class='Dropdown MainNav-item' style={topNodeStyles}>
					<Link href={`/${x.linkFriendlyName}`} style={topNodeLinkStyles} onClick={dropdown && openMenu(x, id, {toggle: true})}>
						{x.categoryName}
					</Link>
					{(dropdown && x.children && Boolean(x.children.length)) && <Dropdown class={`MainNavDropdown--${x.categoryId}`} parent={x} parentProps={props} data={x.children} styles={dropDownStyles}/>}
				</li>
			);
		}

		return (
			<li key={x.categoryId} class='Dropdown MainNav-item' onClick={dropdown && close(x, id)} onMouseOver={dropdown && openMenu(x, id, {toggle: false})} onMouseOut={dropdown && close(x, id)} style={topNodeStyles}>
				<Link href={`/${x.linkFriendlyName}`} style={topNodeLinkStyles}>
					{x.categoryName}
				</Link>
				{(dropdown && x.children && Boolean(x.children.length)) && <Dropdown class={`MainNavDropdown--${x.categoryId}`} parent={x} parentProps={props} data={x.children} styles={dropDownStyles}/>}
			</li>
		);
	});
};

const render = ({id, props}) => {
	const {center} = props;
	const style = {
		textAlign: center ? 'center' : 'left'
	};

	return cmsUtils.createCmsComponent((
		<ul class={`MainNav MainNav--${id}`} style={style}>
			{getItems(id, props)}
		</ul>
	), props);
};

export default {render};
