import estoreAnalytics from '@viskan/analytics';
import Timeout from 'await-timeout';
import queryString from 'query-string';
import {setGoogleAnalyticsId} from '../site/actions';

const analytics = estoreAnalytics({
	enhanced: true,
	params: {
		id: 'GTM-TS3LZ4S',
		gaId: ''
	}
});

const noop = () => {};

const getCampaign = (data, attr1_id) => {
	let ret = '';

	data.forEach(x => {
		if (x.attr1_id === attr1_id) {
			ret = x.camp_name || x.camp_desc;
		}
	});

	return ret;
};

export const setOrigin = () => window.performance.navigation.type !== 1 && analytics.track({originalLocation: `${window.location.origin}${window.location.pathname}${window.location.search}`});

export const getClientId = async app => window.ga && Promise.race([
	analytics.getClientId(),
	new Timeout().set(2000, 'Timeout while fetching Google analytics client ID')
]).then(id => app.sources.store.dispatch(setGoogleAnalyticsId(id))).catch(error => console.warn(error.message));

export const trackAddToCart = obj => {
	analytics.enhanced.addToCart({
		name: obj.body.artname,
		id: obj.body.art_no,
		price: obj.sku.articlePrice.price_sales,
		quantity: obj.qty
	});
};

export const trackArticle = obj => {
	analytics.enhanced.detail({
		name: obj.body.artname,
		id: obj.body.art_no,
		price: obj.sku.articlePrice.price_sales
	});
};

export const trackImpression = (articles, {from} = 0) => {
	let index = from;

	analytics.enhanced.impression(articles.map(x => {
		const sku = x.articleAttributes[0];
		index++;

		return {
			name: x.artname,
			id: x.art_no,
			price: sku && sku.articlePrice.price_sales,
			list: window.location.pathname,
			campaign: sku && getCampaign(x.articleCampaigns, sku.attr1_id),
			position: index
		};
	}));
};

let sendReferrer = true;

const getOriginalReferrer = () => {
	const query = queryString.parse(location.search);
	const source = document.referrer && query.referrer !== undefined ? query : document;

	return source.referrer;
};

export const trackPageView = url => {
	const referrer = sendReferrer ? getOriginalReferrer() : null;

	analytics.enhanced.pageView(url, {
		referrer
	});
	sendReferrer = false;
};

export const trackProductClick = obj => () => {
	const sku = obj.articleAttributes[0];

	analytics.enhanced.productClick({
		callback: noop,
		name: obj.artname,
		id: obj.art_no,
		price: sku.articlePrice.price_sales
	}, {
		list: window.location.pathname
	});
};

export const trackPurchase = receipt => {
	const rows = receipt.rows.map(row => ({
		coupon: row.offerDescription,
		id: row.meta.articleNumber,
		identifier: row.meta.articleNumber + '-' + row.meta.attribute1Id,
		name: row.meta.articleName,
		price: row.value.total / row.quantity,
		quantity: row.quantity
	}));

	analytics.enhanced.purchase(rows, {
		affiliation: window.location.host,
		coupon: receipt.offerCodes.join(', '),
		id: receipt.orderNumber || receipt.identifier,
		revenue: receipt.value.total,
		shipping: receipt.deliveryMethod && receipt.deliveryMethod.methodName
	});
};

export const trackRemoveFromCart = obj => {
	analytics.enhanced.removeFromCart({
		name: obj.body.artname,
		id: obj.body.art_no,
		price: obj.sku.articlePrice.price_sales,
		quantity: obj.qty
	});
};

export const trackCheckout = obj => {
	const arr = [];

	if (obj.rows && obj.rows.length !== 0) {
		obj.rows.forEach(x => {
			arr.push({
				coupon: obj.head.camp_code || '',
				id: x.art_no,
				name: x.artname,
				price: x.price_incvat,
				variant: `${x.attr1_id ? x.attr1_code : ''}/${x.attr2_id ? x.attr2_code : ''}`,
				quantity: x.qty
			});
		});
	}

	analytics.enhanced.checkout(arr, {
		callback: noop
	});
};

export default async app => {
	analytics.start().then(() => getClientId(app));

	app.set('trackAddToCart', trackAddToCart);
	app.set('trackPageView', trackPageView);
	app.set('trackProductClick', trackProductClick);
	app.set('trackPurchase', trackPurchase);
	app.set('trackRemoveFromCart', trackRemoveFromCart);
	app.set('trackCheckout', trackCheckout);
};
