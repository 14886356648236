/** @jsx dom */
import dom from 'magic-virtual-element';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const getAddonsRows = props => props.addons.map(x => {
	const {getPrice, styleClasses} = props;
	const {row_id, artname, price_incvat, price_exvat, personalization_text} = x;

	return (
		<div key={row_id} class={['CMS-ShopcartRowAddon', styleClasses.Addon]} data-style-attr='Addon'>
			<span class={styleClasses.Addon_name} data-style-attr='Addon_name'>{artname}</span>
			{price_incvat !== 0 && <span class={styleClasses.Addon_price} data-style-attr='Addon_price'>{getPrice(price_incvat, price_exvat)}</span>}
			{Boolean(personalization_text) && <div class={styleClasses.Addon_text} data-style-attr='Addon_text'>{personalization_text}</div>}
		</div>
	);
});

const render = ({props}) => {
	const {styleClasses} = props;

	return (
		<div class={['CMS-ShopcartRowAddons', styleClasses.Addons]} data-style-attr='Addons'>
			{getAddonsRows(props)}
		</div>
	);
};

export default {propTypes, render};
