/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import {Cell, Grid} from 'viskan-deku-grid';
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import page from 'page';
import {Form} from '@viskan/deku-form';
import isUrl from 'is-url-superb';
import keyReplace from 'key-replace';
import objectAssign from 'object-assign';
import Lookup from '../lookup';
import Customer from './customer';
import Payment from './payment';
import Terms from './terms';
import Shipping from './shipping';

const propTypes = {
	customer: {
		source: 'customer'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	},
	shopcart: {
		source: 'shopcart'
	},
	site: {
		source: 'site'
	}
};

const initialState = () => {
	return {
		data: {
			errors: [],
			details: {
				deliveryMethods: [],
				paymentMethods: []
			}
		}
	};
};

const handleError = (error, openPopup) => {
	bus.emit('loading:close');

	if (error.response && error.response.statusCode === 500) {
		v12.util.error(error);
		return;
	}

	openPopup(error.message);
};

const clearUser = (data, {openPopup}) => event => {
	event.preventDefault();

	v12.customer.logout({copyCart: true})
		.then(() => bus.emit('logout'))
		.catch(error => handleError(error, openPopup));
};

const customerMatch = obj => {
	return v12.customer.match(obj)
		.then(body => {
			window._streamline.holdNewSessionReload = true;
			bus.emit('login', body);
			bus.emit('loading:close');
		});
};

const customerMatchIfNeeded = (customer, body) => customer.logged_in_level === 0 ? customerMatch(body) : Promise.resolve();

const onSubmit = (country_id, customer, openPopup) => data => {
	const parsedData = keyReplace(data, 'customer.', '');

	const customerData = {
		'del_country.id': country_id,
		'inv_country.id': country_id,
		'vis_country.id': country_id,
		'entry_id': '1',
		...parsedData
	};

	bus.emit('loading:open');

	customerMatchIfNeeded(customer, customerData)
		.then(() => v12.customer.edit(customerData))
		.then(data => bus.emit('customer:update', data))
		.then(() => v12.checkout.add(data))
		.then(body => {
			if (body && body.redirectURL) {
				bus.emit('loading:close');

				if (isUrl(body.redirectURL)) {
					location.href = body.redirectURL;
					return;
				}

				bus.emit('shopcart:get');
				page(body.redirectURL);
			}
		})
		.catch(error => handleError(error, openPopup));
};

const onLookup = (customer, openPopup) => arr => {
	const [body] = arr;

	if (customer.logged_in_level !== 0) {
		return v12.customer.logout({copyCart: true})
			.then(() => customerMatch(body, customer))
			.catch(error => handleError(error, openPopup));
	}

	customerMatch(body, customer).catch(error => handleError(error, openPopup));
};

const getForm = ({props, state}) => {
	const {shopcart, getTranslation} = props;
	const {data} = state;
	const {details} = data;
	const {deliveryMethods, paymentMethods} = details;

	return (
		<div class='Payment-Delivery-Grid'>
			<Grid gutter>
				<Cell md-size='full'>
					<Shipping title={getTranslation('checkout.shipping')} data={{deliveryMethods, shopcart}}/>
				</Cell>
				<Cell md-size='full'>
					<Payment title={getTranslation('checkout.payment')} data={{paymentMethods, shopcart}}/>
				</Cell>
			</Grid>
		</div>
	);
};

const afterMount = ({props, state}, el, setState) => {
	const {openPopup} = props;
	const {data} = state;

	v12.util.http.get(`/api/checkout?kco=false`)
		.then(({body}) => {
			if (body.errors && body.errors.length > 0) {
				if (body.errors[0].errorMessage) {
					throw new Error(body.errors[0].errorMessage);
				} else {
					throw new Error(body.errors[0]);
				}
			}

			setState(objectAssign(data, body));
		})
		.catch(error => handleError(error, openPopup));
};

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {openPopup, shopcart} = props;
	const {data} = state;

	if (!deepEqual(shopcart, prevProps.shopcart)) {
		v12.checkout.get()
			.then(body => setState(objectAssign(data, body)))
			.catch(error => handleError(error, openPopup));
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}) => {
	const {customer, getTranslation, openPopup, site} = props;
	const {data} = state;
	const {country_id, useLookup} = site;
	const needLookup = useLookup && customer.logged_in_level === 0;

	if (data.errors.length !== 0) {
		return <div innerHTML={data.errors[0]}/>;
	}

	return (
		<section class='Vco Information'>
			<div>
				<div class='Email-lookup'>
					<h2 class='Heading u-marginBottom'>
						<i class='Icon Icon--information'/>
						{getTranslation('lookup.heading')}
					</h2>
					{useLookup && <Lookup data={customer} class='Lookup-form' onSubmit={onLookup(customer, openPopup)}/>}
					{useLookup && !needLookup && (
						<div class='Customer-emailIdentified'>
							<a href='#' onClick={clearUser(data, {openPopup})}>{getTranslation('checkout.not_you')}</a>
						</div>
					)}
				</div>
				<Form class='Vco-step2 Form--horizontal' onSubmit={debounce(onSubmit(country_id, customer, openPopup), 3000, true)}>
					{needLookup && <div class='Vco-stepNotReadyIncidator'/>}
					<div class='Customer-Grid u-cf'>
						<Customer data={customer} displayEmail={!useLookup}/>
					</div>
					{getForm({props, state}, {displayEmail: !useLookup})}
					<Terms/>
					<Button class='Button--primary Complete-order' type='submit'>{getTranslation('global.buy')}</Button>
				</Form>
			</div>
		</section>
	);
};

export default {afterMount, afterUpdate, initialState, propTypes, render, shouldUpdate};
