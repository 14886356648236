import clone from 'clone';
import traverse from 'traverse';
import micromatch from 'micromatch';

export const deleteNode = (content, id) => {
	const contentClone = clone(content);
	const idInt = parseInt(id, 10);

	traverse(contentClone).forEach(function (x) {// eslint-disable-line array-callback-return
		if (x && x.id === idInt) {
			this.remove(true);
		}
	});

	return contentClone;
};

export const getById = (content, id) => {
	const idInt = parseInt(id, 10);
	let ret;

	traverse(content).forEach(function (x) {
		if (x && x.id === idInt) {
			ret = clone(x);
			this.stop();
		}
	});

	return ret;
};

export const getIndexByPath = path => path[path.length - 1];

export const getPaths = (content, parents = []) => content.reduce((paths, child, index) => {
	const myPath = parents.concat(index);
	const childPaths = child.children ? getPaths(child.children, myPath.concat('children')) : {};

	return {
		...paths,
		[child.id]: myPath,
		...childPaths
	};
}, {
	0: []
});

export const getByPath = (content, path) => path ? traverse(content).get(path) : content;
export const getDepthByPath = path => Math.floor(path.length / 2);
export const getParentByPath = (content, path) => {
	const parentPath = path && path.length > 2 ? path.slice(0, -2) : null;
	return parentPath && getByPath(content, parentPath);
};

export const getParentsByPath = (content, path) => {
	const traverser = traverse(content);

	return path.map((targetIndex, currentIndex) => {
		if (targetIndex === 'children') {
			return false;
		}

		const usedPath = currentIndex ? path.slice(0, -(currentIndex)) : path;
		return traverser.get(usedPath);
	}).filter(Boolean);
};

export const getSiblingsByPath = (content, path) => {
	const parentPath = path && path.length > 1 ? path.slice(0, -1) : null;
	return getByPath(content, parentPath);
};

export const getRelativeSiblingByPath = (content, relativeIndex, path) => {
	const currentIndex = getIndexByPath(path);
	const siblingPath = path.slice(0, -1).concat(currentIndex + relativeIndex);
	return getByPath(content, siblingPath);
};

export const id = extraSeed => parseInt(`${new Date().getTime()}${Math.floor((Math.random() * 1000) + 1)}${extraSeed}`, 10);

export const insertNode = (content, parentId, objectToInsert, opts = {}) => {
	const contentClone = clone(content);
	const {childIndex} = opts;
	const splice = typeof childIndex === 'number';

	return traverse(contentClone).map(function (x) {// eslint-disable-line array-callback-return
		let newObj;

		if (x && parentId === 0 && this.isRoot) {
			newObj = clone(x);

			if (splice) {
				newObj.splice(childIndex, 0, objectToInsert);
			} else {
				newObj.push(objectToInsert);
			}
		} else if (x && x.id === parentId) {
			newObj = clone(x);

			if (splice) {
				newObj.children.splice(childIndex, 0, objectToInsert);
			} else {
				newObj.children.push(objectToInsert);
			}
		}

		if (newObj) {
			this.update(newObj, true);
		}
	});
};

export const moveNode = (content, moveId, targetId, options) => {
	const componentToMove = getById(content, moveId);
	const afterDelete = deleteNode(content, moveId);

	return insertNode(afterDelete, targetId, componentToMove, options);
};

const getConfig = (component, node) => {
	return typeof component.config === 'function' ? component.config(node) : component.config;
};

export const isAllowedChild = (content, components, parent, child) => {
	if (parent.id === child.id) {
		return false;
	}

	const disallowedChildren = getConfig(components[parent.type], parent).disallowedChildren;

	if (micromatch(child.type, disallowedChildren).length > 0) {
		return false;
	}

	return true;
};

export const update = (content, id, key, value) => {
	const contentClone = clone(content);
	const idInt = parseInt(id, 10);

	return traverse(contentClone).map(function (x) {// eslint-disable-line array-callback-return
		if (x && x.id === idInt) {
			const nodeClode = clone(x);

			nodeClode.props[key] = value;
			this.update(nodeClode, true);
		}
	});
};

export const reassignIds = content => {
	const contentClone = clone(content);

	return traverse(contentClone).map(function (x) {// eslint-disable-line array-callback-return
		if (x && x.id) {
			const nodeClode = clone(x);

			nodeClode.id = id();
			this.update(nodeClode);
		}
	});
};
