import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';
import {articlesFetch} from './actions';

export const component = connect(
	({article, articlesSlider, breakpoint, streamlineCms}) => ({
		components: articlesSlider.components,
		articleHistory: article.history,
		breakpoint: breakpoint.breakpointOverride || breakpoint.breakpoint,
		cmsLoaded: streamlineCms.loaded
	}),
	dispatch => ({
		articlesFetch: (identifier, options) => dispatch(articlesFetch(identifier, options))
	})
)(Component);

export default {component, config};
