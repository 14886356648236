/** @jsx dom */
import dom from 'magic-virtual-element';
import {generateMediaURL} from '@viskan/streamline-utilities';
import {utils as cmsUtils} from '@viskan/cms-components';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const formatData = props => {
	const {data, getPrice, field, shopcartRowIndex} = props;

	if (field === 'price_incvat') {
		return getPrice(data[shopcartRowIndex].price_incvat, data[shopcartRowIndex].price_exvat);
	}

	if (field === 'rowsum_to_pay') {
		return getPrice(data[shopcartRowIndex].rowsum_to_pay, data[shopcartRowIndex].rowsum_to_pay_exvat);
	}

	if (field === 'discount') {
		return getPrice(data[shopcartRowIndex].discount, data[shopcartRowIndex].discount);
	}

	return data[shopcartRowIndex][field];
};

const render = ({props}) => {
	const missingField = !Object.prototype.hasOwnProperty.call(props.data[props.shopcartRowIndex], props.field);
	const data = formatData(props);
	const innerHTML = `${props.prefixText}${data}${props.suffixText}`;
	const sharedOpts = {
		class: ['CMS-ShopcartRowsField', `CMS-ShopcartRowsField-${props.field}`, props.class, props.styleClasses.Field],
		'data-style-attr': 'Field'
	};

	const element = missingField ? <noscript/> : props.field === 'media_filename' ? dom('img', {
		src: generateMediaURL(data, {scale: 'thumbnail'}, props.mediaConfig),
		...sharedOpts
	}) : dom(props.elementType, {
		innerHTML,
		...sharedOpts
	});

	return cmsUtils.createCmsComponent(element, props);
};

export default {propTypes, render};
