/** @jsx dom */
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const getSubscriptions = props => {
	const descriptionClass = 'CMS-Subscription-description';

	return props.subscriptions.map(subscription => (
		<div key={subscription.prentype_id} class={['CMS-Subscription', props.class, props.styleClasses.Subscription]} data-style-attr='Subscription'>
			<div class={['CMS-Subscription-descriptions', props.styleClasses.Descriptions]} data-style-attr='Descriptions'>
				{props.showDescription1 &&
					<div class={[descriptionClass, `${descriptionClass}--1`, props.styleClasses.Description1]} data-style-attr='Description1'>
						{subscription.prentype_desc}
					</div>
				}
				{props.showDescription2 &&
					<div class={[descriptionClass, `${descriptionClass}--2`, props.styleClasses.Description2]} data-style-attr='Description2'>
						{subscription.prentype_desc2}
					</div>
				}
				{props.showDescription3 &&
					<div class={[descriptionClass, `${descriptionClass}--3`, props.styleClasses.Description3]} data-style-attr='Description3'>
						{subscription.prentype_desc3}
					</div>
				}
			</div>
			<div class={['CMS-Subscription-price']} data-style-attr='Price'>
				{props.getPrice(subscription.prognoses[0].price)}
			</div>
			<button class={['Button CMS-Subscription-buyButton', props.styleClasses.Button]} onClick={debounce(() => props.addToCart(subscription), 1000, true)} data-style-attr='Button'>
				{props.buttonText}
			</button>
		</div>
	));
};

const render = ({props}) => cmsUtils.createCmsComponent((
	<div class={['CMS-Subscriptions', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
		{getSubscriptions(props)}
	</div>
), props);

export default {propTypes, render};
