/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {OfferIcons} from '../offer';
import Price from '../price';
import QuickBuy from '../quick-buy';
import Link from '../link';
import {component as FavoriteButton} from '../cms-components/article/favorite';
import {Image} from '../media';

const propTypes = {
	article: {
		type: 'object'
	},
	back: {
		type: 'string'
	},
	class: {
		type: 'string'
	},
	front: {
		type: 'string'
	},
	media: {
		type: 'object'
	},
	meta: {
		type: 'object'
	},
	site: {
		source: 'site'
	},
	trackProductClick: {
		source: 'trackProductClick'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const defaultProps = {
	back: '-1',
	front: '1010',
	media: {},
	meta: {
		split_flg: false
	},
	styleClasses: {},
	styling: {}
};

const initialState = () => {
	return {
		image: ''
	};
};

const onMouseOver = (medias, setState) => () => medias.back && setState({image: medias.back});
const onMouseOut = (medias, setState) => () => setState({image: medias.front});
const getLink = ({article, meta, site}) => `/${site.articleUrlPrefix}/${article.link_friendly_name}${meta.split_flg ? `?attr1_id=${article.articleAttributes[0].attr1_id}` : ''}`;

const afterMount = ({props}, el, setState) => {
	const {article, back, front} = props;
	const medias = v12.article.media(article, {
		include: [parseInt(front, 10), parseInt(back, 10)],
		size: '',
		useFilename: true
	});

	setState({image: medias.front});
};

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {article, back, front} = props;
	const {image} = state;
	const medias = v12.article.media(article, {
		include: [parseInt(front, 10), parseInt(back, 10)],
		size: '',
		useFilename: true
	});

	if (front !== prevProps.front) {
		setState({image: medias.front});
	}

	if (medias.front !== image && medias.back !== image) {
		setState({image: medias.front});
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const sanitizeMediaFilename = filename => {
	// V12 returns media prefixed with /
	if (filename.startsWith('/')) {
		return filename.slice(1);
	}

	return filename;
};

const render = ({props, state}, setState) => {
	const {article, back, cmsActive, front, quickbuy, popupForSingleSku, styleClasses, styling, trackProductClick, favorite, isArticle} = props;

	const {image} = state;
	const {articleCampaigns, artname} = article;
	const medias = v12.article.media(article, {
		include: [parseInt(front, 10), parseInt(back, 10)],
		size: '',
		useFilename: true
	});
	const url = getLink(props);
	const {articleMargins, articleNameFontColor, articleNameFontSize, articleNameFontStyle, articleNameFontWeight, priceFontSize, priceFontStyle, priceFontWeight, articleTextPaddings} = styling;

	const articleStyles = {
		margin: articleMargins
	};

	const articleNameStyles = {
		'color': articleNameFontColor,
		'font-size': articleNameFontSize,
		'font-style': articleNameFontStyle,
		'font-weight': articleNameFontWeight
	};

	const priceStyles = {
		'font-size': priceFontSize,
		'font-style': priceFontStyle,
		'font-weight': priceFontWeight
	};

	const articleTextStyles = {
		'padding': articleTextPaddings
	};

	return (
		<article class={['ListArticle', props.class, styleClasses.Article]} data-style-attr='Article' style={!styleClasses.Article && articleStyles}>
			<OfferIcons data={articleCampaigns}/>
			<figure class={['ListArticle-img', styleClasses.Image]} data-style-attr='Image' onMouseOver={onMouseOver(medias, setState)} onMouseOut={onMouseOut(medias, setState)}>
				{!cmsActive ?
					<Link href={url} onClick={trackProductClick(article)}>
						<Image source={sanitizeMediaFilename(image)} scale='normal' alt={artname} title={artname} style='cursor: pointer;'/>
					</Link> : <Image source={sanitizeMediaFilename(image)} scale='normal' alt={artname} title={artname} style='cursor: pointer;'/>
				}
			</figure>
			<div class={['ListArticle-body', styleClasses.Article_Body]} style={!styleClasses.Article_Body && articleTextStyles} data-style-attr='Article_Body'>
				<span style={!styleClasses.Price && priceStyles}>
					<Price class={['ListArticle-prices', styleClasses.Price]} data-style-attr='Price' styleClasses={styleClasses} data={article}/>
				</span>
				<h2 class={['ListArticle-title', styleClasses.Title]} style={!styleClasses.Title && articleNameStyles} data-style-attr='Title'>
					{artname}
				</h2>
				{favorite && <FavoriteButton artNo={article.art_no} variant={article._attr1_id}/>}
				{isArticle && quickbuy ? <Link href={url} class={['Button Button Button--primary ListArticle-button-buy']} onClick={trackProductClick(article)}>{props.getTranslation('articles.buy')}</Link> : quickbuy ? <QuickBuy data={article} styleClasses={styleClasses} popupForSingleSku={popupForSingleSku}/> : <noscript/> }
			</div>
		</article>
	);
};

export default {afterMount, afterUpdate, defaultProps, initialState, propTypes, render, shouldUpdate};
