/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {trackCheckout} from '../../analytics';
import Vco from '../../vco';
import Shopcart from '../../shopcart';
import {Section} from '../../streamline-cms';
import Embedded from './embedded';
import Shipping from './shipping';
import getType from './embedded-type';

const name = 'Checkout';

const initialState = () => ({
	trackCheckout: true
});

const isPending = ({pendingActions}) => pendingActions && Object.values(pendingActions).some(Boolean);

const isValidDeliveryPoint = (shipping, chosenDeliveryMethod) => {
	return chosenDeliveryMethod && (chosenDeliveryMethod.deliveryPointRule !== 'MANDATORY' || (shipping.chosenCity && shipping.chosenDeliveryPoint));
};

const renderCheckoutMethod = (props, chosenDeliveryMethod) => {
	const {checkout, fetch, shopcart, specialParameters} = props;
	const type = getType(specialParameters);
	const isSuspended = isPending(props) || !isValidDeliveryPoint(checkout.shipping, chosenDeliveryMethod);

	return type ? <Embedded type={type} isSuspended={isSuspended} shopcart={shopcart} fetch={fetch} params={specialParameters}/> : <Vco/>;
};

const getKCOCallbackError = props => {
	const errorMapping = {
		'missing-balance': 'checkout.error.missing-balance',
		'missing-deliverypoint-id': 'checkout.error.missing-deliverypoint-id',
		'no-shopcart': 'checkout.error.no-shopcart'
	};

	const errors = Object.entries(props.queryParameters)
		.filter(parameter => errorMapping[parameter[0]])
		.map(parameter => <div key={parameter[0]} class={`${name}-kcoCallbackError`} innerHTML={props.getTranslation(errorMapping[parameter[0]])}/>);

	return <div class={`${name}-kcoCallbackErrors`}>{errors}</div>;
};

const afterMount = ({props}) => {
	const {categoryFetch, contentManagementActive, fetch, query} = props;

	fetch({pathPrefix: props.pathPrefix, googleAnalyticsId: props.analyticsClientId});

	if (!contentManagementActive) {
		categoryFetch(query, {
			languageId: props.languageId
		});
	}
};

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	if (!deepEqual(props.shopcart, prevProps.shopcart) || (props.analyticsClientId !== prevProps.analyticsClientId)) {
		props.fetch({pathPrefix: props.pathPrefix, googleAnalyticsId: props.analyticsClientId});
	}

	if (state.trackCheckout && props.shopcart.rows.length > 0) {
		trackCheckout(props.shopcart);
		setState({trackCheckout: false});
	}
};

const render = ({props}) => {
	const {getTranslation, shopcart, shopcartAvailable} = props;
	const validShopcart = shopcartAvailable && shopcart && shopcart.rows && shopcart.rows.length > 0;
	const chosenDeliveryMethod = props.checkout.deliveryMethods.find(deliveryMethod => deliveryMethod.method.deliveryMethodId === shopcart.outorder.wdel_id);
	const classes = [
		name,
		shopcartAvailable === false && `${name}--empty`
	];

	return (
		<div class={classes}>
			<Section sectionId='main'/>
			<div class='Container'>
				<div class={`${name}-content`}>
					{!validShopcart && getTranslation('shopcart.empty')}
					{(validShopcart && typeof props.queryParameters['validation-failure'] !== 'undefined') && getKCOCallbackError(props)}
					{validShopcart && <Shopcart/>}
					{validShopcart && <Shipping {...props} method={chosenDeliveryMethod.method}/>}
					{validShopcart && renderCheckoutMethod(props, chosenDeliveryMethod)}
				</div>
			</div>
		</div>
	);
};

export default {afterMount, afterUpdate, initialState, name, render};
