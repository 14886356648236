import bus from '@viskan/bus';
import scriptRegex from 'script-regex';
import {saveEmail} from '../../actions';

const condenseKeys = obj => obj && Object.values(obj).filter(item => item !== undefined && item !== null && item !== '');

const kcoOnChange = () => v12.customer.klarnaCustomer()
	.then(condenseKeys)
	.then(data => {
		if (!data.dataAvailable) {
			return;
		}

		saveEmail(data.email_1);

		const estoreData = {
			'del_country.id': data.country_id,
			'inv_country.id': data.country_id,
			'vis_country.id': data.country_id,
			'email_1': data.email_1
		};

		if (data.authenticatedInEstore) {
			return v12.customer.edit(estoreData).then(data => bus.emit('customer:update', data));
		}

		return v12.customer.match(estoreData).then(data => bus.emit('login', data));
	})
	.catch(v12.util.error);

export const collector = params => ({
	appendScript: (id, el, containerId) => {
		const script = document.createElement('script');
		script.src = `${params['CollectorBank.Endpoint']}/collector-checkout-loader.js`;
		script.dataset.containerId = containerId;
		script.dataset.lang = params['CollectorBank.LanguageCode'];
		script.dataset.padding = 'none';
		script.dataset.token = params['CollectorBank.PublicToken'];
		script.dataset.variant = params['CollectorBank.Variant'];

		el.appendChild(script);
	},
	isAvailable: () => Boolean(window.collector),
	onCheckoutReady: () => {},
	resume: () => window.collector.checkout.api.resume(params['CollectorBank.PublicToken']),
	suspend: () => window.collector.checkout.api.suspend(params['CollectorBank.PublicToken'])
});

export const kco = params => ({
	appendScript: (id, el, containerId) => {
		const snippetContent = scriptRegex().exec(params['KlarnaCheckout.Snippet'])[2];
		const script = document.createElement('script');
		const innerContainer = document.createElement('div');

		script.textContent = snippetContent;
		innerContainer.id = 'klarna-checkout-container';

		el.querySelector(`#${containerId}`).appendChild(innerContainer);
		el.appendChild(script);
	},
	isAvailable: () => Boolean(window._klarnaCheckout),
	onCheckoutReady: () => window._klarnaCheckout(api => api.on({change: kcoOnChange})),
	resume: () => window._klarnaCheckout(api => api.resume()),
	suspend: () => window._klarnaCheckout(api => api.suspend())
});
