/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import Link from '../../../link';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	isPerson: {
		source: 'isPerson'
	},
	onClick: {
		type: 'function'
	}
};

const handleClick = onClick => e => {
	if (onClick) {
		onClick(e);
	}
};

const getOffers = (data, getTranslation) => data.map(x => {
	return (
		<div key={x.offer_desc} class='QuickCartSummary-item'>
			<Arrange>
				<ArrangeFill>
					<strong>{getTranslation('article.offer')}</strong>
				</ArrangeFill>
				<ArrangeFit>
					<div innerHTML={x.offer_desc}/>
				</ArrangeFit>
			</Arrange>
		</div>
	);
});

const getRow = (value, label) => {
	return (
		<div class='QuickCartSummary-item'>
			<Arrange>
				<ArrangeFill>
					<strong>{label}</strong>
				</ArrangeFill>
				<ArrangeFit>
					{value}
				</ArrangeFit>
			</Arrange>
		</div>
	);
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, isPerson, getPrice, getTranslation, onClick} = props;
	const {head, offers, rows} = data;
	const {charge, total_discount, total_discount_exvat, tot_to_pay, tot_to_pay_exvat, tot_vat, wdel_carriage, wdel_carriage_exvat, wpay_carriage, wpay_carriage_exvat} = head;
	const person_flg = isPerson();

	if (rows.length === 0) {
		return (
			<div class='QuickCartSummary QuickCartDropdownMenu-item u-textCenter'>
				{getTranslation('shopcart.empty')}
			</div>
		);
	}

	return (
		<div class='QuickCartSummary QuickCartDropdownMenu-item DropdownMenu-item'>
			<div>{Boolean(offers.head.length) && getOffers(offers.head, getTranslation)}</div>
			{Boolean(total_discount) && getRow(getPrice(total_discount, total_discount_exvat), getTranslation('global.discount'))}
			{Boolean(wdel_carriage) && getRow(getPrice(wdel_carriage, wdel_carriage_exvat), getTranslation('shopcart.shipping_carriage'))}
			{Boolean(wpay_carriage) && getRow(getPrice(wpay_carriage, wpay_carriage_exvat), getTranslation('shopcart.payment_carriage'))}
			{Boolean(charge) && getRow(getPrice(charge), getTranslation('shopcart.charge'))}
			{!person_flg && getRow(getPrice(tot_vat), getTranslation('shopcart.vat'))}
			{person_flg && getRow(getPrice(tot_to_pay, tot_to_pay_exvat), getTranslation('global.total'))}
			{!person_flg && getRow(getPrice(tot_to_pay), getTranslation('shopcart.total_incvat'))}
			<div class='QuickCartSummary-cta'>
				<Link class='Button Button--primary u-sizeFull' href='/checkout' onClick={handleClick(onClick)}>{getTranslation('shopcart.to_checkout')}</Link>
			</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
