/** @jsx dom */
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import queryString from 'query-string';

const propTypes = {
	data: {
		type: 'object'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	},
	title: {
		type: 'string'
	}
};

const beforeMount = ({props}) => {
	const {getTranslation, openPopup} = props;

	if (window.location.search) {
		const parsedQuery = queryString.parse(window.location.search);

		if (parsedQuery.error === 'true') {
			openPopup(getTranslation('payment.paypal_error'));
		}
	}
};

const onClick = (pay_id, shopcart_id, setState) => () => {
	setState({active: pay_id});
	v12.shopcart.update({pay_id, shopcart_id}).then(() => bus.emit('shopcart:get'));
};

const getImage = id => `/public/images/psp/${id}.png`;

const getPayments = ({paymentMethods, shopcart}, active, setState) => {
	const {head, outorder} = shopcart;
	const {shopcart_id} = head;
	const {pay_id} = outorder;

	return paymentMethods.filter(x => x.methodCode !== 'klarnacheckout')
		.filter(x => x.applicable)
		.map(({method}) => {
			const classes = {
				'Payment-option': true,
				'Payment-option--active': (active || pay_id) === method.paymentMethodId
			};

			return (
				<div key={method.paymentMethodId} class={['FormField RadioField', classes]}>
					<div class='FormField-controls u-cf'>
						<label for={`payment.${method.paymentMethodId}`} class='Payment-option-label u-floatLeft'>
							<input type='radio' name='payment.pay_id' id={`payment.${method.paymentMethodId}`} value={method.paymentMethodId} checked={method.paymentMethodId === pay_id} onClick={onClick(method.paymentMethodId, shopcart_id, setState)}/>
							{method.methodDescription || method.methodCode}
						</label>
						<img class='Payment-option-img u-floatRight' src={getImage(method.paymentMethodId)}/>
					</div>
				</div>
			);
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {data, title} = props;
	const {active} = state;

	return (
		<fieldset class='Payment'>
			<h2 class='Heading u-marginBottom u-marginTop'><i class='Icon Icon--card'/>{title}</h2>
			<div class='Payment-options'>
				{getPayments(data, active, setState)}
			</div>
		</fieldset>
	);
};

export default {beforeMount, propTypes, render, shouldUpdate};
