/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {FilterAccordion, FilterBox} from '../../../filter';

const handleFilterChange = (activeFilters, onChangeFilter) => data => onChangeFilter(activeFilters, data);

const renderFilters = (filterData, currentFilterData, {activeFilters, filterHeader, filterStyle, filterSource, filterType, onChangeFilter, selectedFilters, startOpen}) => {
	switch (filterStyle) {
		case 'accordion': {
			return <FilterAccordion activeFilters={activeFilters.curr[filterSource] || []} currentFilters={currentFilterData} filters={filterData} header={filterHeader} onChange={handleFilterChange(activeFilters, onChangeFilter)} selectedFilters={selectedFilters} source={filterSource} startOpen={startOpen} type={filterType}/>;
		}
		case 'dropdownBox':
		default: {
			return <FilterBox activeFilters={activeFilters.curr[filterSource] || []} currentFilters={currentFilterData} filters={filterData} header={filterHeader} onChange={handleFilterChange(activeFilters, onChangeFilter)} selectedFilters={selectedFilters} source={filterSource} type={filterType}/>;
		}
	}
};

const getFilters = (filters, filterSource, groupFilters) => {
	const filter = (filters[filterSource] && filters[filterSource].buckets.length > 0) ? filters[filterSource] : null;

	if (groupFilters && filter) {
		return {
			...filter,
			buckets: filter.buckets.filter(f => f.fields.attr1_group_flg)
		};
	}

	return filter;
};

const render = ({props}) => {
	const selected = props.filterSource === 'selectedFilters';
	const filterData = !selected && getFilters(props.filters, props.filterSource, props.groupFilters);
	const currentFilterData = !selected && getFilters(props.currentFilters, props.filterSource, props.groupFilters);

	if (!props.hasArticlesIfNoFilter || (!selected && !filterData && !currentFilterData)) {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class='CMS-Filter'>
			{renderFilters(filterData, currentFilterData, props)}
		</div>
	), props);
};

export default {render};
