import {LOGIN_SET_ERROR, POPUP_CLOSE} from '../../action-types';

const initialState = {
	error: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SET_ERROR: {
			return {
				...state,
				error: action.payload
			};
		}
		case POPUP_CLOSE: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
