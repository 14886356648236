/** @jsx dom */
import dom from 'magic-virtual-element';
import {Section} from '../../streamline-cms';

const afterMount = ({props}) => !props.contentManagementActive && props.categoryFetch(props.tag, {
	languageId: props.languageId
});
const render = () => <Section sectionId='header'/>;

export default {afterMount, render};
