/** @jsx dom */
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {QtySelect} from '@viskan/estore-components';
import Link from '../../../link';
import {Image} from '../../../media';
import Addon from './addon';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	}
};

const name = 'CMS-QuickCart';
const getAddons = data => data.map(x => <Addon key={`${x.row_id}-${x.art_id}`} data={x}/>);
const onQtyChange = (row_id, editCart) => qty => debounce(editCart(row_id, qty), 500, true);

const getAttrs = data => [1, 2, 3].filter(x => data[`attr${x}_id`]).map((x, i, arr) => {
	return <span key={`attr${x}_id`}>{data[`attr${x}_code`]}{i !== arr.length - 1 && ', '}</span>;
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {articleUrlPrefix, data, editCart, getPrice, removeFromCart} = props;
	const {addons, artname, attr1_id, link_friendly_name, media_filename, offer_desc, price_exvat, price_incvat, qty, row_id, rowsum_to_pay, rowsum_to_pay_exvat} = data;
	const attrs = getAttrs(data);
	const onRemove = () => removeFromCart(data, qty);

	return (
		<div class='QuickCartDropdownMenu-item DropdownMenu-item'>
			<article class='Arrange Arrange--middle'>
				<div class='Arrange-sizeFit'>
					<Link href={link_friendly_name && `/${articleUrlPrefix}/${link_friendly_name}${attr1_id ? `?attr1_id=${attr1_id}` : ''}`}>
						<figure class='QuickCartDropdownMenu-item-img'>
							<Image source={media_filename} height='64' width='64' scale='thumbnail' alt={artname}/>
						</figure>
					</Link>
				</div>
				<div class='Arrange-sizeFill'>
					<div class='u-cf'>
						<span class='QuickCartDropdownMenu-item-title'>
							{artname}
						</span>
						{Boolean(attrs.length) && <span class='QuickCartDropdownMenu-item-attr'>({attrs})</span>}
						<i class='QuickCartDropdownMenu-item-remove Icon Icon--remove u-floatRight u-textRight' onClick={onRemove}/>
					</div>
					{offer_desc && <div innerHTML={offer_desc}/>}
					<div class='QuickCartDropdownMenu-item-summary'>
						<div class='Arrange Arrange--middle Arrange--equal'>
							<div class='Arrange-sizeFill'>
								{getPrice(price_incvat, price_exvat)}
							</div>
							<div class='Arrange-sizeFill'>
								<QtySelect qty={qty} onChange={onQtyChange(row_id, editCart)}/>
							</div>
							<div class='Arrange-sizeFill u-textRight'>
								<strong>{getPrice(rowsum_to_pay, rowsum_to_pay_exvat)}</strong>
							</div>
						</div>
					</div>
					{Boolean(addons.length) && <div class='QuickCartDropdownMenu-item-addons'>{getAddons(addons)}</div>}
				</div>
			</article>
		</div>
	);
};

export default {name, propTypes, render, shouldUpdate};
