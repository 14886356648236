/** @jsx dom */
import {createAction} from 'redux-actions';
import {
	FINDSTORE_STORES_FETCH,
	FINDSTORE_SET_LOCATION,
	FINDSTORE_SET_STORES
} from '../../action-types';

export const storesFetch = createAction(FINDSTORE_STORES_FETCH, query => {
	return v12.util.http('/api/store', {query})
		.then(({body}) => {
			return {
				activeCountry: query.countryCode,
				activeStore: body[0] || {},
				stores: body
			};
		})
		.catch(console.error);
});

export const setLocation = createAction(FINDSTORE_SET_LOCATION);
export const setStores = createAction(FINDSTORE_SET_STORES);

const sortStores = key => (a, b) => a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
const degreeToRadian = deg => deg * Math.PI / 180;
const capitalize = (str = '') => `${str.charAt(0).toUpperCase()}${str.toLowerCase().slice(1)}`;

const pythagorasEquirectangular = (lat1, lon1, lat2, lon2) => {
	const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
	const y = (lat2 - lat1);

	return Math.sqrt((x * x) + (y * y)) * 6371;
};

const getClosestStores = (arr, position) => arr.map(x => ({
	distance: pythagorasEquirectangular(degreeToRadian(position.latitude), degreeToRadian(position.longitude), degreeToRadian(x.address.latitude), degreeToRadian(x.address.longitude)),
	...x
})).sort(sortStores('distance'));

export {capitalize, degreeToRadian, getClosestStores, pythagorasEquirectangular, sortStores};
