import {ARTICLE_MEDIA_CURRENT_SET, ARTICLE_FETCH_FULFILLED} from '../../../action-types';

export const initialState = {
	currentMedia: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ARTICLE_FETCH_FULFILLED: {
			return initialState;
		}
		case ARTICLE_MEDIA_CURRENT_SET: {
			return {
				...state,
				currentMedia: action.payload || initialState.currentMedia
			};
		}
		default: {
			return state;
		}
	}
};
