/** @jsx dom */
import {Cell, Grid} from 'viskan-deku-grid';
import Slide from '@viskan/deku-slide';
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';
import Breakpoint from '../../../breakpoint';
import ListArticle from '../../../list-article';
import {getAllMarkers, getMarker, getVideo, getMediaByQuality, parseTags} from '../actions';

const propTypes = {
	class: {
		type: 'string'
	},
	posts: {
		type: 'array'
	},
	index: {
		type: 'number'
	}
};

const afterMount = ({props}) => {
	const {popupOpen, posts, index} = props;
	const data = posts[index];

	popupOpen({data, index, loaded: true});
};

const getMarkers = (markers, visibleMarkers, visibleText) => markers.map(x => getMarker(x, visibleMarkers, visibleText));
const onMouseLeave = setMarkers => e => e.fromElement === e.delegateTarget && setMarkers({visibleMarkers: [], visibleText: []});

const onMouseEnter = (markers, setMarkers, opts) => e => {
	if (e.toElement !== e.delegateTarget) {
		return;
	}

	const m = markers.map(x => x.markerId);

	if (opts && opts.showText) {
		setMarkers({
			visibleMarkers: m,
			visibleText: m
		});

		return;
	}

	setMarkers({visibleMarkers: m});
};

const handleClick = (f, posts, index) => () => {
	f({posts, index});
};

const getArticles = (articles, close, setMarkers, opts = {}) => articles.map((x, i) => {
	const styling = {};
	const meta = {
		split_flg: false
	};

	if (opts.slider) {
		return (
			<div class='ListArticleContainer' onClick={close} onMouseEnter={onMouseEnter(x.markers, setMarkers, {showText: true})} onMouseLeave={onMouseLeave(setMarkers)}>
				<div class='InstagramArticleNumber'><span innerHTML={i + 1}/></div>
				<ListArticle article={x.metainfo} styling={styling} meta={meta}/>
			</div>
		);
	}

	return (
		<Cell key={x.articleId} size='full' sm-size='1of2' md-size='1of2' lg-size='1of3'>
			<div class='ListArticleContainer' onClick={close} onMouseEnter={onMouseEnter(x.markers, setMarkers, {showText: true})} onMouseLeave={onMouseLeave(setMarkers)}>
				<div class='InstagramArticleNumber'><span innerHTML={i + 1}/></div>
				<ListArticle article={x.metainfo} styling={styling} meta={meta}/>
			</div>
		</Cell>
	);
});

const getPostInfo = data => {
	const {username, shareText, shareUrl} = data;
	return (
		<div class='InstagramPostInfo'>
			<div class='InstagramPostInfo-head'>
				<span class='InstagramPostInfo-username'><a href={shareUrl} target='_blank'>{username}</a></span>
			</div>
			<div class='InstagramPostInfo-shareText' innerHTML={parseTags(shareText)}/>
		</div>
	);
};

const getMobile = ({props, showArrow, mediaOriginal, video, markers}) => {
	const {close, data, index, posts, setMarkers, visibleMarkers, visibleText, setNextIndex, setPrevIndex} = props;
	const {articles} = data;

	return (
		<div class='InstagramPostMobile'>
			<div class='InstagramPostMobile-top'>
				<Grid>
					<Cell size='1of12'>
						{showArrow && <div class='InstagramPostArrowMobile InstagramPostArrow--prev' onClick={handleClick(setPrevIndex, posts, index)}/>}
					</Cell>
					<Cell size='1of12'>
						{showArrow && <div class='InstagramPostArrowMobile InstagramPostArrow--next' onClick={handleClick(setNextIndex, posts, index)}/>}
					</Cell>
					<Cell size='9of12'/>
					<Cell size='1of12'>
						<div class='InstagramPostMobile-closeButton' onClick={close}>
							<Icon style='close'/>
						</div>
					</Cell>
				</Grid>
			</div>
			<div class='InstagramPostMobile-placeholder'/>
			<Grid gutter>
				<Cell size='full'>
					<div class='InstagramPost-image' onMouseEnter={onMouseEnter(markers, setMarkers)} onMouseLeave={onMouseLeave(setMarkers)}>
						<div class='InstagramPost-markers'>
							{getMarkers(markers, visibleMarkers, visibleText)}
						</div>
						{video &&
							<video src={video.url} controls autoplay>
								<img src={mediaOriginal.url}/>
							</video>
						}
						{!video && <img src={mediaOriginal.url}/>}
					</div>
				</Cell>
				<Cell size='full'>
					<Slide class={['InstagramArticleSlider', {'InstagramArticleSlider--single': articles.length < 2}]} loop options={{centeredSlides: true, slidesPerView: 2, initialSlide: 2}}>
						{getArticles(articles, close, setMarkers, {slider: true})}
					</Slide>
				</Cell>
			</Grid>
			<div>
				{getPostInfo(data)}
			</div>
		</div>
	);
};

const getDesktop = ({props, showArrow, mediaOriginal, video, markers}) => {
	const {close, data, index, posts, setMarkers, visibleMarkers, visibleText, setNextIndex, setPrevIndex} = props;
	const {articles} = data;

	return (
		<div>
			{showArrow && <div class='InstagramPostArrow InstagramPostArrow--prev' onClick={handleClick(setPrevIndex, posts, index)}/>}
			<Grid gutter>
				<Cell size='full' sm-size='full' md-size='7of12'>
					<div class='InstagramPost-image' onMouseEnter={onMouseEnter(markers, setMarkers)} onMouseLeave={onMouseLeave(setMarkers)}>
						<div class='InstagramPost-markers'>
							{getMarkers(markers, visibleMarkers, visibleText)}
						</div>
						{video &&
							<video src={video.url} controls autoplay>
								<img src={mediaOriginal.url}/>
							</video>
						}
						{!video && <img src={mediaOriginal.url}/>}
					</div>
				</Cell>
				<Cell size='full' sm-size='full' md-size='5of12'>
					<div class='InstagramPostArticles'>
						<Grid>
							{getArticles(articles, close, setMarkers)}
						</Grid>
					</div>
					<div>
						{getPostInfo(data)}
					</div>
				</Cell>
			</Grid>
			{showArrow && <div class='InstagramPostArrow InstagramPostArrow--next' onClick={handleClick(setNextIndex, posts, index)}/>}
		</div>
	);
};

const render = ({props}) => {
	const {data, loaded, posts} = props;
	const {articles} = data;

	if (!loaded) {
		return <noscript/>;
	}

	const showArrow = posts.length > 1;
	const {media} = data;
	const mediaOriginal = getMediaByQuality(media, 2);
	const video = getVideo(media);
	const markers = getAllMarkers(articles);

	return (
		<div class='InstagramPost'>
			<Breakpoint max='md'>
				{getMobile({props, showArrow, mediaOriginal, video, markers})}
			</Breakpoint>
			<Breakpoint min='lg'>
				{getDesktop({props, showArrow, mediaOriginal, video, markers})}
			</Breakpoint>
		</div>
	);
};

export default {propTypes, afterMount, render};
