/** @jsx dom */
import dom from 'magic-virtual-element';
import Select from '@viskan/deku-select';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const handleChange = (name, props) => data => {
	const addon = data[name][0];

	props.onRemove();

	if (addon) {
		props.onAdd(addon);
	}
};

const getItems = props => {
	const items = props.data.map(addon => {
		return {
			label: `${addon.article.artname} +${props.getPrice(addon.article.articleAttributes[0].articlePrice.price_sales)}`,
			value: {
				art_id: addon.article.art_id,
				attr1_id: addon.article.articleAttributes[0].attr1_id,
				attr2_id: addon.article.articleAttributes[0].attr2_id,
				attr3_id: addon.article.articleAttributes[0].attr3_id,
				isAddonSelect: true,
				personalization_text: '',
				qty: 1
			}
		};
	});

	return items;
};

const render = ({props}) => {
	const name = 'CMS-AddonSelect-select';

	return (
		<div class='CMS-AddonSelect'>
			<Select name={name} placeholder={props.placeholder} options={getItems(props)} onChange={handleChange(name, props)} toggle forceClose/>
		</div>
	);
};

export default {propTypes, render};
