import {connect} from '@viskan/deku-redux';
import {open as openPopup} from '../../../popup-redux/actions';
import {setCurrentMedia, setDefaultCurrentMedia} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		active: state.article.active,
		article: state.article.article,
		cmsLoaded: state.streamlineCms.loaded,
		currentMedia: state.articleMedia.currentMedia,
		mediaConfig: state.site.mediaConfig
	}),
	dispatch => ({
		openPopup: (content, options) => dispatch(openPopup(content, options)),
		setCurrentMedia: (newImage, props) => dispatch(setCurrentMedia(newImage, props)),
		setDefaultCurrentMedia: props => dispatch(setDefaultCurrentMedia(props))
	})
)(Component);

export default {component, config};
