/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import easydate from 'easydate';
import Link from '../link';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const initialState = () => {
	return {
		data: []
	};
};

const getItems = (data, props) => data.map(x => {
	return (
		<tr key={x.outorderno} class='StripedRows-row'>
			<td>{easydate('Y-M-d', {setDate: new Date(x.orderdate).toISOString()})}</td>
			<td><Link href={`/receipt/${x.purchase_no}`}>{x.outorderno}</Link></td>
			<td>{props.getPrice(x.tot_value)}</td>
			<td>{x.cstatus.const_desc}</td>
			<td>{x.pay_code}</td>
			<td>{x.logisticsType.const_desc}</td>
		</tr>
	);
});

const afterMount = ({props}, el, setState) => {
	v12.customer.orders()
		.then(data => setState({data}))
		.catch(v12.util.error);
};

const shouldUpdate = ({state}, nextProps, nextState) => !deepEqual(state, nextState);

const render = ({props, state}) => {
	const {getTranslation} = props;
	const {data} = state;

	return (
		<div class='OrderHistory'>
			<table class='Table'>
				<thead>
					<tr>
						<th>{getTranslation('global.date')}</th>
						<th>{getTranslation('global.order_no')}</th>
						<th>{getTranslation('global.value')}</th>
						<th>{getTranslation('global.status')}</th>
						<th>{getTranslation('checkout.payment')}</th>
						<th>{getTranslation('checkout.shipping')}</th>
					</tr>
				</thead>
				<tbody class='StripedRows'>
					{getItems(data, props)}
				</tbody>
			</table>
		</div>
	);
};

export default {afterMount, initialState, propTypes, render, shouldUpdate};
