import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../sources/translator';
import config from './config';
import NewsletterForm from './components';

export const component = connect(
	state => ({
		getTranslation: getTranslator(state),
		site: state.site
	})
)(NewsletterForm);

export default {component, config};
