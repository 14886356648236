export default {
	type: 'ARTICLE_SUBSCRIPTIONS',
	name: 'Subscriptions',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [],
	hiddenProps: [{
		label: 'Description settings',
		props: [{
			name: 'showDescription1',
			inputType: 'checkbox',
			label: 'Show description 1',
			default: true
		}, {
			name: 'showDescription2',
			inputType: 'checkbox',
			label: 'Show description 2',
			default: true
		}, {
			name: 'showDescription3',
			inputType: 'checkbox',
			label: 'Show description 3',
			default: true
		}]
	}, {
		label: 'Button settings',
		props: [{
			name: 'buttonText',
			inputType: 'text',
			label: 'Button text',
			default: 'Add to cart'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
