/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import Breakpoint from '../../../../breakpoint';
import {OfferIcons} from '../../../../offer';
import {getSlider, getZoom, getSliderZoom, getTwoStepZoom, getTwoStepSliderZoom} from './layouts';

const getLayout = (layout, props) => {
	switch (layout.toLowerCase()) {
		case 'zoom': {
			return getZoom(props);
		}
		case 'twostepzoom': {
			return getTwoStepZoom(props);
		}
		case 'slider': {
			return getSlider(props);
		}
		case 'sliderzoom': {
			return getSliderZoom(props);
		}
		case 'twostepsliderzoom': {
			return getTwoStepSliderZoom(props);
		}
		default: {
			return getSlider(layout, props);
		}
	}
};

const afterMount = ({props}) => props.article.art_id && props.setDefaultCurrentMedia(props);
const afterUpdate = ({props}, prevProps) => props.article.art_id && (props.active.attr1_id !== prevProps.active.attr1_id || props.article.art_id !== prevProps.article.art_id) && props.setDefaultCurrentMedia(props);
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleMedia', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{props.article.articleCampaigns && <OfferIcons data={props.article.articleCampaigns} offerHeader={props.offerHeader} offerPopup={props.offerPopup} showClubBadge={props.active.articlePrice.club_id !== 0} showOffers={props.showOffers}/>}
			<Breakpoint max='sm'>
				{() => getLayout(props.mobileLayout, props)}
			</Breakpoint>
			<Breakpoint min='md' max='md'>
				{() => getLayout(props.tabletLayout, props)}
			</Breakpoint>
			<Breakpoint min='lg'>
				{() => getLayout(props.desktopLayout, props)}
			</Breakpoint>
		</div>
	), props);
};

export default {afterMount, afterUpdate, render, shouldUpdate};
