/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import {InputField} from '@viskan/deku-form';
import {getPrivateFields} from './actions';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	isPerson: {
		source: 'isPerson'
	},
	validationError: {
		source: 'validationError'
	},
	openPopup: {
		source: 'openPopup'
	},
	site: {
		source: 'site'
	}
};

const getFields = (keys, {getTranslation, validationError}) => Object.keys(keys).map(x => {
	const {label, size, value, type, required, rules} = keys[x];
	return (
		<Cell sm-size='full' md-size={size} class={{'u-hidden': type === 'hidden'}}>
			<InputField key={label} id={x} rules={rules} type={type || 'text'} name={x} placeholder={label} value={value} validationMessage={validationError(label, getTranslation)} required={required} validate={['onBlur']}/>
		</Cell>
	);
});

const afterMount = ({props}, el, setState) => {
	const {data, isPerson} = props;
	const person_flg = data && data.person_flg ? data.person_flg : isPerson();

	setState({person_flg});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props}) => {
	const {getTranslation, validationError} = props;
	const keys = getPrivateFields(props);

	return (
		<Grid gutter>
			<Cell size='full'>
				<h2 class='Heading u-marginBottom'>
					<i class='Icon Icon--information'/>
					{getTranslation('account.profile')}
				</h2>
			</Cell>
			{getFields(keys, {getTranslation, validationError})}
		</Grid>
	);
};

export default {afterMount, propTypes, render, shouldUpdate};
