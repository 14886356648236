import {CUSTOMER_FETCH_FULFILLED, CUSTOMER_PATCH, CUSTOMER_PATCH_FULFILLED} from '../action-types';
import {isPerson} from '../actions';

const initialState = {
	...window._streamline.customerOnLoadStatus,
	person_flg: isPerson()
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMER_FETCH_FULFILLED:
		case CUSTOMER_PATCH:
		case CUSTOMER_PATCH_FULFILLED: {
			return {
				...state,
				...action.payload
			};
		}
		default: {
			return state;
		}
	}
};
