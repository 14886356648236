/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import hex2rgb from 'hex-to-rgb';
import luminance from 'color-luminance';

const propTypes = {
	activeFilters: {
		type: 'object'
	},
	filters: {
		type: 'object'
	},
	onChange: {
		type: 'function'
	},
	type: {
		type: 'string'
	}
};

const onClick = (item, onChange) => () => onChange(item);

const getBoxes = (items, activeFilters, onChange, type, currentItems, disable) => items.map(item => {
	const background = (item.rgb_code && item.rgb_code.length) ? `background-color: #${item.rgb_code};` : '';
	const color = luminance(hex2rgb(item.rgb_code || '#fff')) >= 128;
	const isActive = Boolean(activeFilters.find(activeFilter => activeFilter.value === item.value));
	const isSelectable = Boolean(currentItems.find(currentItem => currentItem.value === item.value));
	const isDisabled = !disable && !isActive && !isSelectable;

	switch (type) {
		case 'checkbox': {
			return (
				<div class={['FilterCheck-option', 'FilterCheck-option--dropdown', {'FilterCheck-option--active': isActive}, {'FilterCheck-option--disabled': isDisabled}]} onClick={onClick(item, onChange)}>
					<span>{item.label}</span>
					<span class={['FilterCheck-option-checkmark', {'FilterCheck-option-checkmark--light': color}]} style={background && background}/>
				</div>
			);
		}
		case 'button':
		default: {
			return (
				<div class={['FilterBox-option', 'u-cf', {'FilterBox-option--active': isActive}, {'FilterBox-option--disabled': isDisabled}]} onClick={onClick(item, onChange)}>
					{background && <div class='FilterBox-option-color u-floatLeft' style={background}/>}
					<div class='FilterBox-option-label u-floatRight'>{item.label}</div>
				</div>
			);
		}
	}
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {activeFilters, currentFilters, filters, header, onChange, selectedFilters, source, type} = props;
	const selected = source === 'selectedFilters';
	const items = v12.filter.getItems(filters, source);
	const currentItems = v12.filter.getItems(currentFilters, source);
	const disable = selectedFilters.length > 0 && selectedFilters[0].type === source;

	if (items.length === 0 && !selected) {
		return <noscript/>;
	}

	return (
		<div class='FilterBox'>
			<div class='FilterBox-label'>{header}</div>
			<div class='FilterBox-options'>
				{selected ? getBoxes(selectedFilters, selectedFilters, onChange, type, currentItems, true) : getBoxes(items, activeFilters, onChange, type, currentItems, disable)}
			</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
