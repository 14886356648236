import {BREAKPOINT_SET, BREAKPOINT_OVERRIDE_SET} from '../action-types';

const initialState = {
	breakpoint: 'xlg',
	breakpointOverride: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case BREAKPOINT_SET: {
			return {
				...state,
				breakpoint: action.payload
			};
		}
		case BREAKPOINT_OVERRIDE_SET: {
			return {
				...state,
				breakpointOverride: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
