/** @jsx dom */
import dom from 'magic-virtual-element';
import Slide from '@viskan/deku-slide';
import {Post} from '../';

const getPosts = posts => posts.map((x, index) => <Post key={`${x.creationDate}-${x.username}`} posts={posts} index={index}/>);

const onInit = ({activeIndex, onReachEnd, setActiveIndex}) => sliderInstance => {
	sliderInstance.on('reachEnd', () => {
		setActiveIndex(sliderInstance.activeIndex + 1);

		if (sliderInstance.activeIndex === 0 || sliderInstance.activeIndex === activeIndex) {
			return;
		}

		onReachEnd();
	});
};

const shouldUpdate = ({props}, nextProps) => props.posts.length !== nextProps.posts.length || props.columns !== nextProps.columns || props.rows !== nextProps.rows || props.activeIndex !== nextProps.activeIndex;

const render = ({props}) => {
	const {activeIndex, columns, posts, rows} = props;

	const options = {
		initialSlide: activeIndex,
		slidesPerColumn: rows,
		slidesPerView: columns
	};

	return (
		<div class='InstagramFeed'>
			<Slide options={options} onInit={onInit(props)} loop={false} arrows>
				{getPosts(posts)}
			</Slide>
		</div>
	);
};

export default {render, shouldUpdate};
