/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import Addons from './addon';

const appendRowIndex = (children, shopcartRowIndex) => {
	return children.map(x => {
		return {
			...x,
			attributes: {
				...x.attributes,
				shopcartRowIndex
			},
			children: appendRowIndex(x.children, shopcartRowIndex)
		};
	});
};

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-ShopcartRows', props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{props.shopcart.rows.map((y, shopcartRowIndex) => {
				return (
					<div key={y.row_id} class='CMS-ShopcartRow'>
						{appendRowIndex(props.children, shopcartRowIndex)}
						<Addons addons={y.addons} styleClasses={props.styleClasses}/>
					</div>
				);
			})}
		</div>
	), props);
};

export default {render};
