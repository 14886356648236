/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import Button from '@viskan/deku-button';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {activeStore, getTranslation} = props;
	const {address, description, openingHoursDeviation, openingHoursSaturday, openingHoursSunday, openingHoursWeekdays, phone, storeName} = activeStore;
	const {addressLine1, city, countryCode, zipcode} = address;

	return (
		<div class='FindStore-current'>
			<div class='FindStore-top'>
				<p>{getTranslation('store.current_store')}</p>
				<h2 class='FindStore-currentTitle'>{storeName}</h2>
			</div>
			<div class='u-marginBottom'>{phone}</div>
			<div>{addressLine1}</div>
			<div>{`${zipcode} ${city}`}</div>
			<a href={`https://www.google.com/maps?f=d&daddr=${storeName},${addressLine1},${zipcode},${city},${countryCode}&dirflg=d`} target='_blank' rel='noopener noreferrer'>
				<Button class='Button Button--primary FindStore-button u-marginTop'>
					{getTranslation('store.directions')}
				</Button>
			</a>
			<div class='FindStore-times u-marginTopLarge u-marginBottomLarge'>
				<div class='FindStore-time'>
					<Arrange>
						<ArrangeFill>
							{getTranslation('store.opening_weekdays')}
						</ArrangeFill>
						<ArrangeFit>
							<div class='u-textNoWrap'>{openingHoursWeekdays}</div>
						</ArrangeFit>
					</Arrange>
				</div>
				<div class='FindStore-time'>
					<Arrange>
						<ArrangeFill>
							{getTranslation('store.opening_saturday')}
						</ArrangeFill>
						<ArrangeFit>
							<div class='u-textNoWrap'>{openingHoursSaturday}</div>
						</ArrangeFit>
					</Arrange>
				</div>
				<div class='FindStore-time'>
					<Arrange>
						<ArrangeFill>
							{getTranslation('store.opening_sunday')}
						</ArrangeFill>
						<ArrangeFit>
							<div class='u-textNoWrap'>{openingHoursSunday}</div>
						</ArrangeFit>
					</Arrange>
				</div>
			</div>
			{openingHoursDeviation && <div class='FindStore-timeDeviation u-marginBottomLarge' innerHTML={openingHoursDeviation}/>}
			<div class='FindStore-description' innerHTML={description}/>
		</div>
	);
};

export default {render, shouldUpdate};
