/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {SelectField} from '@viskan/deku-form';
import AttributeBox from './box';
import AttributeName from './name';
import AttributeImage from './image';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const handleChange = props => e => {
	const sku = v12.article.resetAttributes(props.article, Object.assign({}, props.active, {
		[`${props.attribute}_id`]: parseInt(e.target.value, 10)
	}), {getFirstInStock: props.checkBalance});

	props.setSku(props.article, sku, {getFirstInStock: props.attribute === 'attr1'});
	history.replaceState(null, null, `${window.location.pathname}?attr1_id=${sku.attr1_id}`);
};

const getPriceDifference = (attribute, props) => {
	if (!props.priceDifference) {
		return '';
	}

	const diff = attribute.articlePrice.price_sales - v12.article.price(props.article).price_sales;

	if (diff === 0) {
		return '';
	}

	return ` +${props.getPrice(diff)}`;
};

const getOutOfStockMessage = (attribute, {outOfStockMessage}) => {
	if (attribute.inStock) {
		return '';
	}

	return outOfStockMessage ? ` - ${outOfStockMessage}` : '';
};

const isAttribute1InStock = (article, attr1Id) => article.articleAttributes && article.articleAttributes.some(attribute => attribute.attr1_id === attr1Id && attribute.inStock);

const getItems = (id, props) => {
	const {disableOutOfStock} = props;
	const attributeArray = v12.article.attributes(props.article, props.active, {checkBalance: props.checkBalance})[props.attribute];

	return attributeArray.map(attribute => {
		const isInStock = id === '1' ? isAttribute1InStock(props.article, attribute.attr1_id) : attribute.inStock;

		return ({
			inStock: isInStock,
			label: `${attribute[`attribute${id}`][`attr${id}_code`]}${getPriceDifference(attribute, props)}${getOutOfStockMessage(attribute, props)}`,
			value: attribute[`attr${id}_id`],
			disabled: disableOutOfStock && !isInStock,
			rgb_code: id === '1' && attribute[`attribute${id}`][`rgb_code`]
		});
	});
};

const getAttribute = props => {
	const id = props.attribute.match(/attr(\d)/)[1];
	const items = getItems(id, props);
	const name = `${props.attribute}_id`;
	const label = (
		<div class={['CMS-ArticleAttribute-label', props.styleClasses.Label]} data-style-attr='Label'>
			{props.label}
			{props.labelAttribute && <span class={['CMS-ArticleAttribute-labelCurrent', props.styleClasses['Label Current']]} data-style-attr='Label Current'>{props.active[`attribute${id}`][`${props.attribute}_code`]}</span>}
		</div>
	);

	if (items.length === 0 || (props.hideIfOne && items.length === 1)) {
		return <noscript key={`${name}-${props.article.art_id}`}/>;
	}

	switch (props.showAs) {
		case 'image': {
			return <AttributeImage key={`${name}-${props.article.art_id}`} name={name} options={items} {...props} label={label}/>;
		}
		case 'checkbox': {
			return <AttributeBox key={`${name}-${props.article.art_id}`} name={name} options={items} {...props} label={label}/>;
		}
		case 'name': {
			return <AttributeName key={`${name}-${props.article.art_id}`} name={name} options={items} {...props} label={label}/>;
		}
		case 'dropdown':
		default: {
			return <SelectField key={`${props.attribute}-${props.article.art_id}`} class={['CMS-ArticleAttribute-select', props.styleClasses.Select]} name={name} value={props.active[name]} label={label} options={items} onChange={handleChange(props)} data-style-attr='Select'/>;
		}
	}
};

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleAttributes', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{getAttribute(props)}
		</div>
	), props);
};

export default {propTypes, render};
