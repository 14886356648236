import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../sources/translator';
import * as shopcart from '../../shopcart/actions';
import config from './config';
import {close, toggle} from './actions';
import QuickCart from './components';
import RemarketComponent from './components/remarket';
import SummaryComponent from './components/summary';
import RowComponent from './components/row';

export const component = connect(
	({shopcart, site, quickCart}) => ({
		...quickCart,
		shopcart,
		site
	}),
	dispatch => ({
		close: autoClosed => dispatch(close(autoClosed)),
		toggle: () => dispatch(toggle())
	})
)(QuickCart);

export const Remarket = connect(
	state => ({
		getTranslation: getTranslator(state),
		site: state.site
	})
)(RemarketComponent);

export const Row = connect(
	({site}) => ({
		articleUrlPrefix: site.articleUrlPrefix
	}),
	dispatch => ({
		editCart: (id, qty, dontOpen) => dispatch(shopcart.edit(id, qty, dontOpen)),
		removeFromCart: (data, qty, dontOpen) => dispatch(shopcart.removeItem(data, qty, dontOpen))
	})
)(RowComponent);

export const Summary = connect(
	state => ({
		getTranslation: getTranslator(state)
	})
)(SummaryComponent);

export default {component, config};
