/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import store from 'store2';

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {shopcart} = props;
	const {opened} = state;
	const {rows} = shopcart;

	if (store('vs-email') || opened) {
		return;
	}

	document.documentElement.onmouseleave = e => {
		document.documentElement.onmouseleave = () => {};

		if (store('vs-email') || opened || rows.length === 0 || e.clientY >= 0) {
			return;
		}

		props.leaveWindowAction();
		setState({opened: true});
	};
};

const render = () => <noscript/>;

export default {afterUpdate, render, shouldUpdate};
