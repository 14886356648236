/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Form, InputField} from '@viskan/deku-form';
import AccountNav from '../account-nav';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	validationError: {
		source: 'validationError'
	}
};

const onSubmit = (setState, getTranslation) => data => {
	bus.emit('loading:open');

	v12.customer.resetPassword({
		identifier: 'EMAIL_1_OR_CUST_NO',
		email_1: data.email
	})
		.then(() => {
			bus.emit('loading:close');
			setState({message: getTranslation('password.reset_success')});
		})
		.catch(error => {
			bus.emit('loading:close');
			setState({message: error.response.body.errorMessage});
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {getTranslation, validationError} = props;
	const {message} = state;

	return (
		<div class='Password'>
			<div class='Container'>
				<Grid gutter>
					<Cell size='1of6'>
						<AccountNav active={4} loggedIn={false}/>
					</Cell>
					<Cell size='5of6'>
						<Grid>
							<Cell size='full' md-size='1of2' lg-size='1of3'>
								<div class='u-marginTop u-marginBottom'>
									<Form onSubmit={onSubmit(setState, getTranslation)}>
										<InputField label={getTranslation('customer.email')} name='email' type='email' validationMessage={validationError(getTranslation('customer.email'), getTranslation)} required/>
										{message && <p class='Password-feedback' innerHTML={message}/>}
										<Button class='Button--primary' type='submit'>
											{getTranslation('global.send')}
										</Button>
									</Form>
								</div>
							</Cell>
						</Grid>
					</Cell>
				</Grid>
			</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
