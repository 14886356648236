/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import NotFound from '../../not-found';
import {NostoCategory} from '../../nosto';
import {Section} from '../../streamline-cms';

const propTypes = {
	query: {
		type: 'string'
	}
};

const afterMount = ({props}) => !props.contentManagementActive && props.categoryFetch(props.query, {languageId: props.languageId});
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const afterUpdate = ({props}, prevProps) => (props.query !== prevProps.query && !props.contentManagementActive) && props.categoryFetch(props.query, {languageId: props.languageId});

const render = ({props}) => {
	const {contentManagementActive, notFound} = props;

	if (notFound && !contentManagementActive) {
		return <NotFound/>;
	}

	if (notFound && contentManagementActive) {
		return (
			<div class='u-textCenter'>
				404 Page not found
			</div>
		);
	}

	return (
		<div>
			<Section sectionId='main'/>
			<NostoCategory/>
		</div>
	);
};

export default {afterMount, afterUpdate, propTypes, render, shouldUpdate};
