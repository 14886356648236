/** @jsx dom */
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';
import onEscape from 'on-escape';
import Popup from '@viskan/deku-popup';

const propTypes = {
	auto: {
		type: 'boolean'
	},
	height: {
		type: 'number'
	},
	margin: {
		type: 'number'
	},
	close: {
		type: 'function'
	},
	open: {
		type: 'boolean'
	},
	universal: {
		type: 'boolean'
	},
	width: {
		type: 'number'
	}
};

const defaultProps = {
	height: '1000px',
	margin: 20,
	width: '1000px'
};

const initialState = () => {
	return {
		input: null,
		open: false,
		output: null
	};
};

const onClose = setState => () => setState(initialState());
const onClick = (universal, setState, close) => universal ? onClose(setState) : close;

const afterMount = ({props}, el, setState) => {
	const {universal} = props;

	if (!universal) {
		return;
	}

	bus.on('popup:open', (el, opts = {}) => setState({class: opts && opts.class, open: true, output: typeof el === 'string' ? <div innerHTML={el}/> : el, ...opts}));
	bus.on('popup:close', () => setState(initialState()));
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {auto, children, close, height, margin, open, universal, width} = props;
	const {output} = state;
	const attrs = {
		auto: universal ? (!state.width || !state.height) : auto,
		close: universal ? state.close : close,
		height: universal ? state.height : height,
		margin: universal ? state.margin : margin,
		open: universal ? state.open : open,
		width: universal ? state.width : width
	};

	if (!open && !state.open) {
		return <noscript/>;
	}

	if (universal) {
		onEscape(onClose(setState));
	}

	return (
		<Popup class={props.class || state.class} {...attrs} onClickOutside={onClick(universal, setState, close)}>
			<div class='Popup-close' onClick={onClick(universal, setState, close)}>
				<Icon style='close'/>
			</div>
			<div class='Popup-content'>
				{universal ? output : children}
			</div>
		</Popup>
	);
};

export default {afterMount, defaultProps, initialState, propTypes, render, shouldUpdate};
