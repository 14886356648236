import {createAction} from 'redux-actions';
import {RECEIPT_FETCH} from '../action-types';

const getEndpoint = type => {
	switch (type) {
		case 'v1': {
			return '/api/receipt';
		}
		case 'v2':
		default: {
			return '/api/receipt-v2';
		}
	}
};

export default createAction(RECEIPT_FETCH, (type, query) => v12.util.http.get(getEndpoint(type), {query}).then(({body}) => {
	if (body.errors && body.errors.length > 0) {
		if (body.errors[0].errorMessage) {
			throw new Error(body.errors[0].errorMessage);
		} else {
			throw new Error(body.errors[0]);
		}
	}
	return body;
}), options => options);
