/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const renderChildren = (children, props) => {
	const {components} = props;

	return children.filter(Boolean).map(child => {
		let data = components[child.type];
		const Comp = data.component;
		const classes = [
			child.props.class
		];

		if (!data) {
			data = components.PARAGRAPH_01;
			child.props.text = `Component "${child.props.componentName || child.type}" could not be rendered`;
		}

		return (
			<Comp id={child.id} {...child.props} components={components} class={classes} styleClasses={child.props.styleClasses || {}}>
				{child.children && renderChildren(child.children, props)}
			</Comp>
		);
	});
};

const afterUpdate = ({props}, prevProps) => props.linkUrl !== prevProps.linkUrl && props.fetch(props.linkUrl);
const afterMount = ({props}) => !props.data[props.linkUrl] && props.fetch(props.linkUrl);

const render = ({props}) => {
	const content = props.data[props.linkUrl];

	return cmsUtils.createCmsComponent((
		<div class={['CMS-Category-section', props.class, props.styleClasses.Container]} data-style-attr='Container'>
			{props.title && <h1 class={['CMS-Category-title', props.class, props.styleClasses.Title]} data-style-attr='Title'>{props.title}</h1>}
			{content && Boolean(content.length) && <div>{renderChildren(content, props)}</div>}
		</div>
	), props);
};

export default {afterMount, afterUpdate, render};
