import {connect} from '@viskan/deku-redux';
import * as shopcartApi from '../../../shopcart/actions';
import config from './config';
import Component from './components';

export const component = connect(
	({shopcart}) => ({
		data: shopcart.rows
	}),
	dispatch => ({
		editCart: (id, qty) => dispatch(shopcartApi.edit(id, qty)),
		removeFromCart: (data, qty) => dispatch(shopcartApi.removeItem(data, qty))
	})
)(Component);

export default {component, config};
