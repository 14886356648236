import {connect} from '@viskan/deku-redux';
import {setQuantity} from '../../../article/actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		quantity: state.article.quantity
	}),
	dispatch => ({
		setQuantity: quantity => dispatch(setQuantity(quantity))
	})
)(Component);

export default {component, config};
