/** @jsx dom */
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import {Form, InputField} from '@viskan/deku-form';
import store from 'store2';
import {utils as cmsUtils} from '@viskan/cms-components';
import {saveEmail, validationError} from '../../../../actions';

const handleSubmit = props => data => {
	saveEmail(data.email_1);
	props.requestBackInStock(data, props);
	props.closePopup();
};

const getForm = props => (
	<Form class='CMS-ArticleBackInStock-registerForm' onSubmit={handleSubmit(props)}>
		<div class='u-cf'>
			<div class='u-floatRight'>
				<button class='Button--primary' type='submit'>{props.buttonText}</button>
			</div>
			<div class='u-nbfc'>
				<InputField name='email_1' id='email_1' value={store('vs-email')} validationMessage={validationError(props.getTranslation('customer.email'), props.getTranslation)} placeholder={props.getTranslation('customer.email')} type='email' required/>
			</div>
		</div>
	</Form>
);

const getRegister = props => (
	<div class='CMS-ArticleBackInStock-register'>
		<p>{props.popupText}</p>
		{getForm(props)}
	</div>
);

const handleClick = props => () => props.openPopup(getRegister(props));

const getText = props => {
	if (props.requested) {
		return (
			<div class='CMS-ArticleBackInStock-feedback'>{props.feedbackText}</div>
		);
	}

	switch (props.displayAs) {
		case 'input': {
			return (
				<div class='CMS-ArticleBackInStock-text'>
					<p>{props.text}</p>
					{getForm(props)}
				</div>
			);
		}
		case 'popup':
		default: {
			return (
				<div class='CMS-ArticleBackInStock-text'>
					<p>{props.text}</p>
					<span class='CMS-ArticleBackInStock-cta' onClick={debounce(handleClick(props), 1000, true)}>{props.openPopupText}</span>
				</div>
			);
		}
	}
};

const render = ({props}) => {
	if (props.active.inStock) {
		return cmsUtils.createCmsComponent(<noscript/>, props);
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleBackInStock', props.class]}>
			{getText(props)}
		</div>
	), props);
};

export default {render};
