/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const render = ({props}) => {
	const sale = props.price.price_sales < props.price.price_org;
	const salesPrice = props.getPrice(props.price.price_sales, props.price.price_sales_exvat);
	const defaultClass = 'CMS-ArticlePrice-price';

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticlePrice', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{!sale &&
				<span class={[defaultClass, props.styleClasses.Price]} data-style-attr='Price'>
					{salesPrice}
				</span>
			}
			{sale &&
				<span class={[defaultClass, `${defaultClass}--sale`, props.styleClasses.Sale]} data-style-attr='Sale'>
					{salesPrice}
				</span>
			}
			{sale &&
				<span class={['CMS-ArticlePrice-price CMS-ArticlePrice-price--org', props.styleClasses.Original]} data-style-attr='Original'>
					{props.getPrice(props.price.price_org, props.price.price_org_exvat)}
				</span>
			}
		</div>
	), props);
};

export default {propTypes, render};
