/** @jsx dom */
import dom from 'magic-virtual-element';
import {generateMediaURL} from '@viskan/streamline-utilities';

const getImage = (id, images) => {
	const foundImage = images.find(imageObj => imageObj.attr1_id === id && imageObj.row_id === 1010);

	if (foundImage && foundImage.length !== 0) {
		return foundImage.media.filename;
	}

	return '';
};

const renderCategories = arr => arr.map(el => <span key={el.category.link_friendly_name} class='category' innerHTML={el.category.link_friendly_name}/>);

const renderSkus = props => props.article.articleAttributes.map(sku => {
	const mediaConfig = props.site.mediaConfig;
	const mediaFilename = getImage(sku.attribute1.attr1_id, props.article.articleAttributeMedias);
	const mediaURL = generateMediaURL(mediaFilename, {forceFullURL: true, location: window.location.origin}, mediaConfig);
	return (
		<span key={`${props.article.art_id}-${sku.pluno}`} class='nosto_sku'>
			<span class='id'>{sku.pluno}</span>
			<span class='name'>{sku.attribute1.attr1_code}-{sku.attribute2.attr2_code}</span>
			<span class='price'>{sku.articlePrice.price_sales}</span>
			<span class='list_price'>{sku.articlePrice.price_org}</span>
			<span class='url'>{location.origin}{location.pathname}?attr1_id={sku.attribute1.attr1_id}&attr2_id={sku.attribute2.attr2_id}</span>
			<span class='image_url'>{mediaURL}</span>
			<span class='availability'>{sku.inStock ? 'InStock' : 'OutOfStock'}</span>
			<span class='custom_fields'>
				<span class='size'>{sku.attribute2.attr2_code}</span>
				<span class='color'>{sku.attribute1.attr1_code}</span>
			</span>
		</span>
	);
});

const afterRender = () => window.nostojs && nostojs(api => api.loadRecommendations());

const shouldUpdate = ({props}, nextProps) => props.article.art_no !== nextProps.article.art_no;

const render = ({props}) => {
	const {article, site} = props;
	const {mediaConfig} = site;

	if (!site.nostoId || article.articleAttributes.length === 0) {
		return <noscript/>;
	}

	const mediaFilename = article.articleAttributeMedias[0].media.filename;
	const mediaURL = generateMediaURL(mediaFilename, {forceFullURL: true, location: window.location.origin}, mediaConfig);
	return (
		<div class='NostoArticle'>
			<div key={article.art_no} class='nosto_product' style='display:none'>
				<div class='nosto_page_type'>product</div>
				<span class='url'>{location.origin}{location.pathname}</span>
				<span class='product_id' innerHTML={article.art_no}/>
				<span class='name' innerHTML={article.artname}/>
				<span class='image_url'>{Boolean(mediaURL) && mediaURL}</span>
				<span class='price'>{article.articleAttributes[0].articlePrice.price_sales}</span>
				<span class='price_currency_code'>{site.currency_code}</span>
				<span class='list_price'>{article.articleAttributes[0].articlePrice.price_org}</span>
				<span class='availability'>{article.inStock ? 'InStock' : 'OutOfStock'}</span>
				{renderCategories(article.articleCategories)}
				<span class='description' innerHTML={article.art_desc2}/>
				{renderSkus(props)}
			</div>
		</div>
	);
};

export default {afterRender, shouldUpdate, render};
