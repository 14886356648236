export default {
	type: 'CATEGORY_IMPORT',
	name: 'Category Import',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'Layout',
	icon: 'fal fa-inbox-in',
	props: [{
		name: 'title',
		inputType: 'text',
		label: 'Heading',
		default: ''
	}, {
		name: 'linkUrl',
		inputType: 'link',
		label: 'Category',
		default: '',
		requestKey: 'linkFriendlyName'
	}]
};
