import {createAction} from 'redux-actions';
import {CHECKOUT_FETCH, CHECKOUT_SET_CHOSEN_CITY, CHECKOUT_SET_CHOSEN_DELIVERY_POINT, CHECKOUT_GET_DELIVERY_POINTS} from '../action-types';

export const fetch = createAction(CHECKOUT_FETCH, (options = {}) => v12.util.http.get(`/api/checkout`, {query: {pathPrefix: options.pathPrefix, googleAnalyticsId: options.googleAnalyticsId}}).then(({body}) => {
	if (body.errors && body.errors.length > 0) {
		if (body.errors[0].errorMessage) {
			throw new Error(body.errors[0].errorMessage);
		} else {
			throw new Error(body.errors[0]);
		}
	}

	return body;
}));

export const setChosenCity = createAction(CHECKOUT_SET_CHOSEN_CITY);
export const setChosenDeliveryPoint = createAction(CHECKOUT_SET_CHOSEN_DELIVERY_POINT);

export const getDeliveryPoints = createAction(CHECKOUT_GET_DELIVERY_POINTS, data => v12.util.http.get(`/api/delivery-points?countryCode=${data.countryCode}&deliveryMethodId=${data.deliveryMethodId}`)
	.then(({body}) => body)
	.then(payload => {
		const cities = payload.map(deliveryPoint => deliveryPoint.address.city);

		return {cities, deliveryPoints: payload};
	})
);
