/** @jsx dom */
import Icon from '@viskan/deku-icon-wrapper';
import dom from 'magic-virtual-element';
import * as contentApi from '../../app/content';
import StickAroundParent from '../../stick-around';

const name = 'StreamlineCmsOverlay';

const onSelectNode = (props, node) => () => props.setActive(node);
const nodeKey = (props, node) => `${node.id}-${JSON.stringify(props.paths[node.id])}`;

const renderDefaultNode = props => node => {
	const title = node.props.componentName || node.type;
	const isActiveParent = Boolean(node.children.find(child => child.id === props.activeNode.id));
	const isAllowedParent = contentApi.isAllowedChild(props.content, props.components, node, props.activeNode);
	const onMoveNodeLayer = () => props.moveNode({
		node: props.activeNode,
		sectionId: props.sectionId,
		toId: node.id
	});

	return (
		<li class={`${name}-node`}>
			<button class={`${name}-nodeTitle`} title='Select component' onClick={onSelectNode(props, node)}>{title}</button>
			{!isActiveParent && isAllowedParent && <button class={`${name}-moveNode`} title='Move selected component into this component' onClick={onMoveNodeLayer}><Icon key={nodeKey(props, node, 'sign-in-alt')} class='SCMS-Icon--white' icon='sign-in-alt'/></button>}
		</li>
	);
};

const renderNodesAroundActive = props => node => {
	const title = node.props.componentName || node.type;
	const currentPath = props.paths[node.id];
	const currentIndex = contentApi.getIndexByPath(currentPath);
	const previousNode = contentApi.getRelativeSiblingByPath(props.content, -1, currentPath);
	const nextNode = contentApi.getRelativeSiblingByPath(props.content, 1, currentPath);
	const onMoveNodeIndex = move => () => {
		const newIndex = Math.max(0, currentIndex + move);

		props.moveNode({
			node: props.activeNode,
			sectionId: props.sectionId,
			toIndex: newIndex
		});
	};

	const current = (
		<li class={`${name}-node isActive`}>
			<div class={`${name}-nodeTitle`} title='Selected component'>{title}</div>
			{previousNode && <button class={`${name}-moveNode`} onClick={onMoveNodeIndex(-1)} title='Move to previous'><Icon key={nodeKey(props, node, 'arrow-up')} class='SCMS-Icon--white' icon='arrow-up'/></button>}
			{nextNode && <button class={`${name}-moveNode`} onClick={onMoveNodeIndex(1)} title='Move to next'><Icon key={nodeKey(props, node, 'arrow-down')} class='SCMS-Icon--white' icon='arrow-down'/></button>}
		</li>
	);

	const previous = previousNode && (
		<li class={`${name}-node isSibling`}>
			<div class={`${name}-nodeTitle`} title='Previous sibling' onClick={onSelectNode(props, previousNode)}>{previousNode.props.componentName || previousNode.type}</div>
		</li>
	);

	const next = nextNode && (
		<li class={`${name}-node isSibling`}>
			<div class={`${name}-nodeTitle`} title='Next sibling' onClick={onSelectNode(props, nextNode)}>{nextNode.props.componentName || nextNode.type}</div>
		</li>
	);

	return [previous, current, next].filter(Boolean);
};

const renderNode = props => node => {
	if (props.activeNode.id === node.id) {
		return renderNodesAroundActive(props)(node);
	}

	return renderDefaultNode(props)(node);
};

const renderSectionNode = props => {
	const title = props.cmsSection.title;
	const onMoveNode = () => props.moveNode({
		node: props.activeNode,
		sectionId: props.sectionId,
		toId: 0
	});

	const classes = [
		`${name}-node`,
		`${name}-node--section`,
		!props.activeNode && 'isActive'
	];

	return (
		<div class={classes}>
			<button onClick={onSelectNode(props)} class={`${name}-nodeTitle`}>{title}</button>
			{contentApi.getDepthByPath(props.paths[props.activeNode.id]) > 0 && (
				<button class={`${name}-moveNode`} title='Move to top' onClick={onMoveNode}>
					<Icon key='section-sign-in-alt' class='SCMS-Icon--white' icon='sign-in-alt'/>
				</button>
			)}
		</div>
	);
};

const renderArrayAsNested = items => (
	<ul class={`${name}-items`}>
		{items[0]}
		{items.length > 1 && renderArrayAsNested(items.slice(1))}
	</ul>
);

const render = ({props}) => {
	const currentPath = props.activeNode && props.activeNode.id && props.paths && props.paths[props.activeNode.id];

	if (!currentPath) {
		return <noscript/>;
	}

	const activeComponents = currentPath ? contentApi.getParentsByPath(props.content, currentPath).reverse() : [];
	const renderedParents = activeComponents.map(renderNode(props));
	const selections = [renderSectionNode(props)].concat(renderedParents);

	return (
		<StickAroundParent class={name}>
			{renderArrayAsNested(selections)}
		</StickAroundParent>
	);
};

export default {name, render};
