/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const handleClick = props => props.resetFilters;

const render = ({props}) => {
	if (!props.alwaysShow && (!props.hasSelectedFilters || !props.hasArticles)) {
		return cmsUtils.createCmsComponent(<noscript/>, props);
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ResetFilters', props.class, props.styleClasses.Reset_Filter_Container]} data-style-attr='Reset Filter Container' onClick={handleClick(props)}>
			{props.children.length !== 0 ? props.children : '[Filter Reset] Drop component (e.g. an icon) here'}
		</div>
	), props);
};

export default {render};
