import store from 'store2';
import omit from '../../utils';
import {ARTICLES_FAVORITES_ARTNOS_LS_KEY} from '../article/favorite/actions';
import {saveScrollMemoryData, restoreScrollMemoryData} from '../../scroll-memory/actions';
import {
	ARTICLES_FAVORITES_ADD_FULFILLED,
	ARTICLES_FAVORITES_ADD_PENDING,
	ARTICLES_FAVORITES_ADD_REJECTED,
	ARTICLES_FAVORITES_FETCH_FULFILLED,
	ARTICLES_FAVORITES_FETCH_PENDING,
	ARTICLES_FAVORITES_FETCH_REJECTED,
	ARTICLES_FAVORITES_REMOVE_FULFILLED,
	ARTICLES_FAVORITES_REMOVE_PENDING,
	ARTICLES_FAVORITES_REMOVE_REJECTED,
	ARTICLES_FETCH_FULFILLED,
	ARTICLES_FETCH_PENDING,
	ARTICLES_FETCH_REJECTED,
	ARTICLES_FILTERS_RESET,
	ARTICLES_PAGINATION_NEXT,
	ARTICLES_SET_ACTIVE_FILTERS,
	ARTICLES_SET_STATE,
	ARTICLES_SET_VIEW,
	ARTICLES_SORT,
	PATH_CHANGE
} from '../../action-types';

const ARTICLES_MEMORY_DATA_KEY = 'articlesData';
export const initialState = {
	activeFilters: {
		apiInput: {},
		curr: {}
	},
	articles: [],
	back: 'back',
	category: {
		categoryBlurbs: []
	},
	currentFilters: {},
	favorites: {
		articles: [],
		artNos: store(ARTICLES_FAVORITES_ARTNOS_LS_KEY) || []
	},
	filters: {},
	firstTotal: 0,
	from: 0,
	front: 'front',
	initialLoad: false,
	initialSize: 0,
	isLoading: false,
	key: 'cat_id',
	navItems: [],
	selectedFilters: [],
	sort: [],
	viewActive: false
};

const sortFavorites = (articles, artNos) => {
	const arrSortBy = artNos && artNos.map(f => articles.hits[0]._attr1_id === -1 || f.variant === -1 ? f.artNo : f.artNo + f.variant);

	return [...articles.hits].sort((a, b) => {
		if (a._attr1_id === -1 && b._attr1_id === -1) {
			return arrSortBy.indexOf(a.art_no) - arrSortBy.indexOf(b.art_no);
		}

		return arrSortBy.indexOf(a.art_no + a._attr1_id) - arrSortBy.indexOf(b.art_no + b._attr1_id);
	});
};

export default (state = initialState, action) => {
	const {type, meta = {}, payload, sort} = action;

	switch (type) {
		case ARTICLES_SET_STATE: {
			return {
				...state,
				...action.payload
			};
		}
		case ARTICLES_FAVORITES_ADD_PENDING:
		case ARTICLES_FAVORITES_FETCH_PENDING:
		case ARTICLES_FAVORITES_REMOVE_PENDING:
		case ARTICLES_FETCH_PENDING: {
			return {
				...state,
				isLoading: true
			};
		}
		case ARTICLES_FETCH_FULFILLED: {
			const initialLoadData = !state.initialLoad ? {
				filters: state.from === 0 ? payload.aggregations : state.filters,
				firstTotal: payload.total,
				initialLoad: true
			} : {};

			const newState = {
				...state,
				...initialLoadData,
				articles: state.from === 0 ? payload.articles : state.articles.concat(payload.articles),
				currentFilters: state.from === 0 ? payload.aggregations : state.currentFilters,
				isLoading: false,
				meta: payload.meta,
				total: payload.total
			};

			saveScrollMemoryData(ARTICLES_MEMORY_DATA_KEY, {
				...newState,
				articles: [],
				from: state.from,
				initialSize: newState.articles.length,
				sort: payload.sort
			});

			return newState;
		}
		case ARTICLES_FAVORITES_ADD_REJECTED:
		case ARTICLES_FAVORITES_FETCH_REJECTED:
		case ARTICLES_FAVORITES_REMOVE_REJECTED:
		case ARTICLES_FETCH_REJECTED: {
			return {
				...state,
				isLoading: false
			};
		}
		case ARTICLES_FAVORITES_REMOVE_FULFILLED:
		case ARTICLES_FAVORITES_ADD_FULFILLED: {
			return {
				...state,
				isLoading: false,
				favorites: {
					articles: state.favorites.articles,
					artNos: store(ARTICLES_FAVORITES_ARTNOS_LS_KEY)
				}
			};
		}
		case ARTICLES_FAVORITES_FETCH_FULFILLED: {
			const savedMemoryState = restoreScrollMemoryData(ARTICLES_MEMORY_DATA_KEY);
			const articles = action.payload.articles.hits && action.payload.articles.hits.length > 1 ? sortFavorites(action.payload.articles, state.favorites.artNos) : action.payload.articles;

			const newState = {
				isLoading: false,
				favorites: {
					articles,
					artNos: store(ARTICLES_FAVORITES_ARTNOS_LS_KEY)
				}
			};

			if (savedMemoryState) {
				saveScrollMemoryData(ARTICLES_MEMORY_DATA_KEY, {
					...savedMemoryState,
					...newState
				});
			}

			return {
				...state,
				...newState
			};
		}
		case ARTICLES_FILTERS_RESET: {
			return {
				...state,
				activeFilters: initialState.activeFilters,
				from: initialState.from,
				selectedFilters: initialState.selectedFilters
			};
		}
		case ARTICLES_SET_ACTIVE_FILTERS: {
			const isPriceSlider = meta.key === 'lowest_price_sales';
			const isBoxFilter = !isPriceSlider;

			const boxFilters = {
				apiInput: omit(payload.apiInput, ['lowest_price_sales']),
				curr: omit(payload.curr, ['lowest_price_sales'])
			};

			const getActiveFilters = () => {
				if (isPriceSlider) {
					return {
						apiInput: {
							lowest_price_sales: payload.apiInput.lowest_price_sales
						},
						curr: {
							lowest_price_sales: payload.curr.lowest_price_sales
						}
					};
				}

				if (isBoxFilter) {
					return {
						apiInput: {
							...boxFilters.apiInput
						},
						curr: {
							...boxFilters.curr
						}
					};
				}
			};

			const getSelectedFilters = () => {
				if (isPriceSlider) {
					return state.selectedFilters;
				}

				if (isBoxFilter) {
					const boxFilterKeys = Object.keys(boxFilters.curr);

					return boxFilterKeys.reduce((acc, key) => {
						const filter = boxFilters.curr[key];
						acc = acc.concat(filter);
						return acc;
					}, []);
				}
			};

			return {
				...state,
				activeFilters: getActiveFilters(),
				from: 0,
				selectedFilters: getSelectedFilters()
			};
		}
		case ARTICLES_PAGINATION_NEXT: {
			if (state.isLoading || state.articles.length === state.total) {
				return state;
			}

			return {
				...state,
				from: state.from + payload.size
			};
		}
		case ARTICLES_SET_VIEW: {
			if (!payload.viewActive) {
				return {
					...state,
					front: initialState.front,
					back: initialState.back,
					viewActive: payload.viewActive
				};
			}

			return {
				...state,
				front: payload.front,
				back: payload.back,
				viewActive: payload.viewActive
			};
		}
		case ARTICLES_SORT: {
			return {
				...state,
				from: initialState.from,
				sort
			};
		}
		case PATH_CHANGE: {
			return {
				...initialState,
				isLoading: state.isLoading
			};
		}
		default: {
			return state;
		}
	}
};
