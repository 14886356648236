/** @jsx dom */
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import {Image} from '../../media';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	mediaFilename: {
		type: 'string'
	}
};

const getPrices = (price, discount, getPrice) => {
	if (discount) {
		return (
			<Cell sm-size='1of6' lg-size='4of8' class='u-textRight'>
				<span class='Row-discount'>
					<strike>{getPrice(price)}</strike>
				</span>
				<strong>{getPrice(price - discount)}</strong>
			</Cell>
		);
	}

	return (
		<Cell sm-size='1of6' lg-size='4of8' class='u-textRight'>
			<strong>{getPrice(price)}</strong>
		</Cell>
	);
};

function render({props}) {
	const {mediaFilename, getPrice, data} = props;
	const {camp_id, camp_name, discount, pren_desc, tot_value} = data;

	return (
		<div class='Receipt-row'>
			<Grid middle>
				<Cell sm-size='1of6' md-size='1of8'>
					<figure class='Receipt-image'>
						<Image source={mediaFilename} scale='thumbnail' alt={pren_desc}/>
					</figure>
				</Cell>
				<Cell sm-size='4of6' md-size='3of8'>
					<div class='Receipt-name'>
						{pren_desc}
					</div>
					{(camp_id !== 0 && camp_name) && <div class='Receipt-offer' innerHTML={camp_name}/>}
				</Cell>
				{getPrices(tot_value, discount, getPrice)}
			</Grid>
		</div>
	);
}

export default {propTypes, render};
