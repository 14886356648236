import {connect} from '@viskan/deku-redux';
import {setSku} from '../../../article/actions';
import {getTranslator} from '../../../sources/translator';
import config from './config';
import Component from './components';
import SliderComponent from './components/slider';

export const component = connect(
	state => ({
		active: state.article.active,
		article: state.article.article,
		getTranslation: getTranslator(state)
	}),
	dispatch => ({
		setSku: (article, sku, options) => dispatch(setSku(article, sku, options))
	})
)(Component);

export const Slider = connect(
	state => ({
		cmsLoaded: state.streamlineCms.loaded
	})
)(SliderComponent);

export default {component, config};
