/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const name = 'ArticleFavorite';

const render = ({props}) => {
	const {favoritesArtNos, artNo, remove, add, styleClasses, variant} = props;
	const isFavorite = favoritesArtNos && favoritesArtNos.some(favorite => {
		if (variant === -1) {
			return favorite.artNo === artNo;
		}

		return favorite.artNo === artNo && favorite.variant === variant;
	});

	const iconClass = isFavorite ? 'fas fa-heart' : 'fal fa-heart';
	const onClick = () => isFavorite ? remove(artNo, variant) : add(artNo, variant);

	return cmsUtils.createCmsComponent((
		<a class={['CMS-ArticleFavorite-icon', styleClasses && styleClasses['Favorite Icon']]} data-style-attr='Favorite Icon' onClick={onClick}>
			<i class={iconClass}/>
		</a>
	), props);
};

export default {name, render};
