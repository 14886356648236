import {connect} from '@viskan/deku-redux';
import * as shopcart from '../../../shopcart/actions';
import config from './config';
import Component from './components';

export const component = connect(
	({shopcart}) => ({
		shopcart
	}),
	dispatch => ({
		editCart: (id, qty, dontOpen) => dispatch(shopcart.edit(id, qty, dontOpen)),
		removeFromCart: (data, qty, dontOpen) => dispatch(shopcart.removeItem(data, qty, dontOpen))
	})
)(Component);

export default {component, config};
