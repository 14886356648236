/** @jsx dom */
import dom from 'magic-virtual-element';
import {Slider} from '../';

const handleClick = (option, props) => e => {
	e.preventDefault();

	const sku = v12.article.resetAttributes(props.article, Object.assign({}, props.active, {
		[props.name]: parseInt(option.value, 10)
	}), {getFirstInStock: props.checkBalance});

	props.setSku(props.article, sku, {getFirstInStock: props.attribute === 'attr1'});
	history.replaceState(null, null, `${window.location.pathname}?attr1_id=${sku.attr1_id}`);
};

const getBoxes = props => {
	return props.options.map(option => {
		const isActive = option.value === props.active[props.name];
		const id = `${props.name}_${option.value}_${props.article.art_id}_${props.showAsSlider}`;
		const classes = {
			'CMS-AttributeName': true,
			'CMS-AttributeName--active': isActive,
			'CMS-AttributeName--disabled': option.disabled
		};

		return (
			<div class={['CMS-AttributeName-wrapper', !option.inStock && 'CMS-ArticleAttribute--outOfStock', props.styleClasses['Name Wrapper']]} onClick={!option.disabled && handleClick(option, props)} data-style-attr='Name Wrapper'>
				<label for={id} class={[classes, props.styleClasses['Name Label']]} data-style-attr='Name Label'>{option.label}</label>
				<input type='radio' name={props.name} value={option.value} id={id} checked={isActive} style='display: none;'/>
			</div>
		);
	});
};

const render = ({props}) => (
	<div class={['CMS-AttributeName-container', props.class, props.styleClasses['Name Container']]} data-style-attr='Name Container'>
		{props.label}
		<div class={['CMS-AttributeName-attributes', props.styleClasses['Attribute Container']]} data-style-attr='Attribute Container'>
			{props.showAsSlider ?
				<Slider {...props} attributeElements={getBoxes(props)}/> :
				getBoxes(props)
			}
		</div>
	</div>
);

export default {render};
