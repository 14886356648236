/** @jsx dom */
import {connect} from '@viskan/deku-redux';
import {url} from '@viskan/streamline-utilities';
import bus from '@viskan/bus';
import page from 'page';
import queryString from 'query-string';
import {trackPageView} from '../analytics';
import {pathChange} from './actions';
import ViewComponent from './components/view';

export const View = connect(
	({router, site}) => ({
		router,
		site
	})
)(ViewComponent);

window.addEventListener('error', error => (error.error instanceof ReferenceError || error.error instanceof TypeError) && page.stop());

const isLocaleChange = (state, context) => {
	const localePath = url.resolveLocalePath(context.path, state.site.localeMode);

	return state.router.changes > 0 && state.router.localePathPrefixInUse !== localePath.prefix;
};

export default app => {
	page('*', context => {
		const state = app.sources.store.getState();

		context.query = queryString.parse(context.querystring);

		trackPageView(context.path);
		bus.emit('slideouts:close');

		if (state.router.canonicalPath !== context.canonicalPath) {
			if (isLocaleChange(state, context)) {
				return window.location.replace(context.canonicalPath);
			}

			app.sources.store.dispatch(pathChange({
				context,
				site: state.site
			}));
		}
	});
	page();
};
