import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';

export const component = connect(
	({shopcart}) => ({
		data: shopcart.head
	})
)(Component);

export default {component, config};
