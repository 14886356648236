/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import Calendar from 'deku-calendar';
import {Cell, Grid} from 'viskan-deku-grid';
import dom from 'magic-virtual-element';
import easydate from 'easydate';
import {Form, Select} from '@viskan/deku-form';

const propTypes = {
	data: {
		type: 'object'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	intervals: {
		type: 'array'
	},
	message: {
		type: 'string'
	},
	openPopup: {
		source: 'openPopup'
	}
};

const initialState = () => {
	return {
		data: {},
		intervals: []
	};
};

const getInterval = (data, intervals) => {
	let ret = intervals[0];

	for (const obj of intervals) {
		if (Math.abs(obj.value - data) < Math.abs(ret.value - data)) {
			ret = obj;
		}
	}

	return ret;
};

const onSubmit = (id, {getTranslation, onChange, openPopup, setState}) => data => {
	data.prendate_next = easydate('Y-M-d', {setDate: new Date(data.prendate_next).toISOString()});

	bus.emit('loading:open');

	v12.customer.editSubscription(id, data)
		.then(() => v12.customer.subscriptions())
		.then(({intervals, subscriptions}) => {
			bus.emit('loading:close');

			onChange({intervals, subscriptions});
			setState({message: getTranslation('customer.save_success')});
			setTimeout(() => setState({message: ''}), 5000);
		})
		.catch(error => {
			v12.util.error(error);
			bus.emit('loading:close');
			openPopup(error.message);
		});
};

const getForm = (data, intervals, message, {getTranslation, onChange, openPopup, setState}) => {
	const last = new Date(data.last_change_date);
	last.setDate(last.getDate() + 1);

	if (last <= new Date()) {
		return (
			<div class='Subscription-section'>
				{getTranslation('subscription.last_change')}
			</div>
		);
	}

	return (
		<Form onSubmit={onSubmit(data.cust_pren_id, {getTranslation, onChange, openPopup, setState})}>
			<div class='Subscription-section'>
				<Grid>
					<Cell size='2of3'>
						{getTranslation('subscription.choose_next')}
					</Cell>
					<Cell size='1of3'>
						<Calendar name='prendate_next' defaultDate={new Date(data.prendate_next)} setDefaultDate firstDate={1} minDate={new Date()}/>
					</Cell>
					<Cell size='2of3'>
						{getTranslation('subscription.choose_every')}
					</Cell>
					<Cell size='1of3'>
						<Select name='interval' options={intervals} value={getInterval(data.interval, intervals).value}/>
					</Cell>
				</Grid>
			</div>
			<div cass='Subscription-section'>
				<Button class='Button--primary' type='submit'>{getTranslation('global.edit')}</Button>
				&nbsp;{message}
			</div>
		</Form>
	);
};

const render = ({props, state}, setState) => {
	const {data, getTranslation, intervals, onChange, openPopup} = props;
	const {message} = state;
	const {cust_pren_id, interval, prendate_next, prendate_prev} = data;

	return (
		<div key={cust_pren_id} class='Subscription'>
			<div class='Subscription-section'>
				{prendate_prev > 0 && <div>{getTranslation('subscription.prev', {date: easydate('Y-M-d', {setDate: new Date(prendate_prev).toISOString()})})}</div>}
				{<div>{getTranslation('subscription.next', {date: easydate('Y-M-d', {setDate: new Date(prendate_next).toISOString()})})}</div>}
				{<div>{getTranslation('subscription.every', {date: getInterval(interval, intervals).label})}</div>}
			</div>
			<div class='Subscription-section'>
				<h3>{getTranslation('subscription.change')}</h3>
			</div>
			{getForm(data, intervals, message, {getTranslation, onChange, openPopup, setState})}
		</div>
	);
};

export default {initialState, propTypes, render};
