import {createAction} from 'redux-actions';
import {trackImpression} from '../../analytics';
import {ARTICLES_FETCH, ARTICLES_FAVORITES_FETCH, ARTICLES_PAGINATION_NEXT, ARTICLES_SET_STATE} from '../../action-types';

const fetch = fetchFunction => (identifier, options) => {
	const from = parseInt(options.from, 10);
	const size = parseInt(options.size, 10);

	return fetchFunction(identifier, {
		filter: options.activeFilters.apiInput,
		from,
		size,
		sort: options.sort,
		key: options.key,
		url: options.url,
		query: {
			ignoreAggregations: from > 0,
			splitByAttribute1: options.splitByAttribute1
		}
	}).then(data => {
		trackImpression(data.articles.hits, {from});

		return {
			aggregations: v12.filter.unNestProperties(data.articles.aggregations),
			articles: data.articles.hits,
			meta: data.meta,
			sort: options.sort,
			total: data.articles.total
		};
	});
};

export const fetchFavorites = (currentFavorites, splitByAttribute1) => {
	const currentFavoritesArtNos = currentFavorites.map(favorite => (favorite.artNo));

	return v12.search(currentFavoritesArtNos.join(','), {
		key: 'article',
		query: {
			artNos: true,
			splitByAttribute1
		},
		size: 60
	});
};

export const articlesFetchFavorites = createAction(ARTICLES_FAVORITES_FETCH, (currentFavorites, splitByAttribute1) => fetchFavorites(currentFavorites, splitByAttribute1));

export const nextPagination = createAction(ARTICLES_PAGINATION_NEXT, options => ({
	size: parseInt(options.size, 10)
}));

export const articlesFetch = createAction(ARTICLES_FETCH, fetch(v12.articles.get));

export const articlesSetState = createAction(ARTICLES_SET_STATE);
