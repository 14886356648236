import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		article: state.article.article
	})
)(Component);

export default {component, config};
