/** @jsx dom */
import {utils as cmsUtils} from '@viskan/cms-components';
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import Button from '@viskan/deku-button';
import uniqueBy from 'unique-by';
import {capitalize, getClosestStores, sortStores} from '../actions';
import ClosestStores from './closest-stores';
import CurrentStore from './current-store';
import GoogleMaps from './map';

const getCountryOptions = (active, items) => items.map(x => {
	return <option key={x.value.iso} disabled={x.value.iso === active} selected={x.value.iso === active} value={x.value.iso}>{x.label}</option>;
});

const getCityOptions = (activeStore, items, placeholder, zoomCountry) => items.map(x => {
	if (zoomCountry === 0 && activeStore.address && x.label === activeStore.address.city) {
		return <option disabled selected value={x.value.storeId}>{x.label}</option>;
	}

	return <option value={x.value.storeId}>{x.label}</option>;
});

const getGeoLocation = ({latitude, longitude}) => {
	return new Promise(resolve => {
		if (!navigator.geolocation) {
			resolve(null);
		}

		navigator.geolocation.getCurrentPosition(data => {
			resolve(data);
		}, error => {
			console.error(error);

			resolve({
				coords: {
					latitude,
					longitude
				}
			});
		});
	});
};

const onCountryChange = (items, storesFetch) => e => {
	const countryCode = items.find(x => x.value.iso === e.target.value).value.iso;

	storesFetch({countryCode});
};

const onCityChange = (items, setLocation) => e => {
	const address = items.find(x => x.value.storeId === parseInt(e.target.value, 10)).value.address;

	setLocation({location: address});
};

const onUsePositonClick = ({location, setLocation, stores}) => () => {
	if (stores && stores.length > 0) {
		getGeoLocation(location)
			.then(res => {
				setLocation({location: getClosestStores(stores, res.coords)[0].address});
			})
			.catch(v12.util.error);
	}
};

const getCities = (activeStore, placeholder, setLocation, stores, zoomCountry) => {
	if (stores && stores.length > 0) {
		const filteredStores = stores.filter((store, index, self) => self.findIndex(t => t.address.city === store.address.city) === index);

		const items = filteredStores.map(x => ({
			label: capitalize(x.address.city.trim()),
			value: x
		}))
		.sort(sortStores('label'));

		return (
			<select name='storeCity' class='Select-input FindStore-select' onChange={onCityChange(uniqueBy(items, 'label'), setLocation)}>
				{zoomCountry === 4 ? <option value='' disabled selected>{placeholder}</option> : <option value='' disabled>{placeholder}</option>}
				{getCityOptions(activeStore, uniqueBy(items, 'label'), placeholder, zoomCountry)}
			</select>
		);
	}

	return (
		<select name='storeCity' class='Select-input FindStore-select'>
			<option value='' disabled selected>{placeholder}</option>
		</select>
	);
};

const getCountries = (active, countries, storesFetch) => {
	const items = countries.map(x => ({
		label: capitalize(x.name.trim()),
		value: x
	}))
	.sort(sortStores('label'));

	return (
		<select name='storeCountry' class='Select-input FindStore-select' onChange={onCountryChange(uniqueBy(items, 'label'), storesFetch)}>
			{getCountryOptions(active, uniqueBy(items, 'label'))}
		</select>
	);
};

const afterMount = ({props}) => {
	const {site, storesFetch} = props;
	const {country_code} = site;

	storesFetch({countryCode: country_code});
};

const render = ({props}) => {
	const {activeCountry, activeStore, getTranslation, setLocation, site, stores, storesFetch, zoomCountry} = props;
	const {countries} = site;

	return cmsUtils.createCmsComponent((
		<div class={['FindStore', props.class]}>
			<div class='FindStore-content'>
				<Grid gutter>
					<Cell size='full' lg-size='4of5'>
						<div class='FindStore-actionBar'>
							<Grid gutter>
								<Cell size='full' md-size='4of12' lg-size='4of12'>
									<Button class='Button Button--primary FindStore-usePosition' onClick={onUsePositonClick(props)}>
										{getTranslation('store.use_position')}
									</Button>
								</Cell>
								<Cell size='full' md-size='4of12' lg-size='4of12'>
									<div class='FindStore-countries'>
										{getCountries(activeCountry, countries, storesFetch)}
									</div>
								</Cell>
								<Cell size='full' md-size='4of12' lg-size='4of12'>
									<div class='FindStore-cities'>
										{getCities(activeStore, getTranslation('store.choose_city'), setLocation, stores, zoomCountry)}
									</div>
								</Cell>
							</Grid>
						</div>
					</Cell>
					<Cell size='full' lg-size='1of5'/>
					<Cell size='full' lg-size='4of5'>
						<GoogleMaps {...props}/>
					</Cell>
					<Cell size='full' lg-size='1of5'>
						{activeStore && activeStore.storeId && <CurrentStore {...props}/>}
					</Cell>
				</Grid>
			</div>
			<div class='FindStore-closest-wrapper'>
				<ClosestStores {...props}/>
			</div>
		</div>
	), props);
};

export default {afterMount, render};
