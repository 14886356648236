import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../../sources/translator';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		price: v12.article.price(state.article.article, state.article.active),
		getTranslation: getTranslator(state)
	})
)(Component);

export default {component, config};
