/** @jsx dom */
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';
import {getMediaByQuality} from '../actions';
import {Modal} from '../';

const propTypes = {
	class: {
		type: 'string'
	},
	posts: {
		type: 'array'
	},
	index: {
		type: 'number'
	}
};

const hidePost = index => () => {
	document.getElementById(`igp-${index}`).classList.add('InstagramFeed-post--unavailable');
};

const onClick = ({breakpoint, closePopup, index, openPopup, posts, setCurrentIndex}) => () => {
	setCurrentIndex({index});
	if (breakpoint === 'sm' || breakpoint === 'md') {
		openPopup(<Modal posts={posts} close={closePopup}/>, {class: 'InstagramPopupMobile', auto: true});
	} else {
		openPopup(<Modal posts={posts} close={closePopup}/>, {class: 'InstagramPopup', auto: true});
	}
};

const render = ({props}) => {
	const {posts, index} = props;
	const data = posts[index];
	const {media, username, shareText} = data;
	const mediaOriginal = getMediaByQuality(media, 2);

	return (
		<div class='InstagramFeed-post' id={`igp-${index}`} onClick={onClick(props)}>
			<div>
				<div class='InstagramFeed-image'>
					<img onError={hidePost(index)} src={mediaOriginal.url}/>
					<Icon class='InstagramFeed-imageNotFound' style='broken-link'/>
				</div>
				<div class='InstagramFeed-hoverInfo'>
					<div>
						<div class='InstagramFeed-hoverInfo-username'>{username}</div>
						<div class='InstagramFeed-hoverInfo-text'>{`${shareText.substr(0, 100)} ${shareText.length > 100 ? '...' : ''}`}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default {propTypes, render};
