import {CLOSE_QUICK_CART, SHOPCART_ADD_FULFILLED, SHOPCART_ADD_SUBSCRIPTION_FULFILLED, SHOPCART_FETCH_FULFILLED, SHOPCART_SET, TOGGLE_QUICK_CART} from '../../action-types';

const initialState = {
	autoCloseTimer: 0,
	isOpen: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLOSE_QUICK_CART: {
			if (!state.autoClosed || (state.autoCloseTimer)) {
				return {
					...state,
					autoCloseTimer: 0,
					isOpen: false
				};
			}

			return state;
		}

		case SHOPCART_ADD_FULFILLED:
		case SHOPCART_ADD_SUBSCRIPTION_FULFILLED:
		case SHOPCART_FETCH_FULFILLED:
		case SHOPCART_SET: {
			return {
				...state,
				autoCloseTimer: !action.payload.dontOpen ? 3000 : 0,
				isOpen: !action.payload.dontOpen
			};
		}

		case TOGGLE_QUICK_CART: {
			return {
				...state,
				autoCloseTimer: 0,
				isOpen: !state.isOpen
			};
		}
		default: {
			return state;
		}
	}
};
