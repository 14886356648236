/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Form} from '@viskan/deku-form';
import objectAssign from 'object-assign';
import AccountNav from '../account-nav';
import Link from '../link';
import Fields from './fields';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	},
	site: {
		source: 'site'
	},
	viewAccount: {
		source: 'viewAccount'
	}
};

const initialState = () => {
	return {
		data: {
			errors: [],
			fields: {}
		}
	};
};

const onSubmit = (country_id, {getTranslation, openPopup, viewAccount}, pathPrefix) => data => {
	const res = objectAssign(data, {
		'del_country.id': country_id,
		'inv_country.id': country_id,
		'vis_country.id': country_id
	});

	bus.emit('loading:open');

	v12.customer.lookup(res.email_1)
		.then(body => {
			if (body.addresses.length === 0) {
				return;
			}

			throw new Error(getTranslation('customer.already_exists'));
		})
		.then(() => v12.customer.add(res))
		.then(data => {
			bus.emit('login', data);
			bus.emit('loading:close');
			viewAccount(pathPrefix);
		})
		.catch(error => {
			openPopup(error.message);
			bus.emit('loading:close');
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(props, nextProps)) {
		return true;
	}

	return !deepEqual(state, nextState);
};

const render = ({props, state}) => {
	const {getTranslation, openPopup, site, viewAccount, pathPrefix} = props;
	const {data} = state;
	const {country_id} = site;
	const {fields} = data;

	return (
		<div class='Register'>
			<div class='Container'>
				<Grid gutter>
					<Cell size='1of6'>
						<AccountNav active={3} loggedIn={false}/>
					</Cell>
					<Cell size='5of6'>
						<Grid>
							<Cell size='full' md-size='2of3' lg-size='1of2'>
								<div class='u-marginTop u-marginBottom'>
									<Form onSubmit={onSubmit(country_id, {getTranslation, openPopup, viewAccount}, pathPrefix)}>
										<Fields title={getTranslation('account.profile')} data={fields}/>
										<Button class='Button--primary' type='submit'>{getTranslation('login.register')}</Button>
									</Form>
									<Link href='/login'>{getTranslation('login.login')}</Link>
								</div>
							</Cell>
						</Grid>
					</Cell>
				</Grid>
			</div>
		</div>
	);
};

export default {initialState, propTypes, render, shouldUpdate};
