/** @jsx dom */
import arrayInsert from 'array-insert';
import {Cell} from 'viskan-deku-grid';
import dom from 'magic-virtual-element';

export const getPosition = data => {
	const {blurb, column, insertPosition, maxColumns} = data;

	if ((blurb.pos_x === column && ((blurb.pos_x - 1) + blurb.width) <= maxColumns)) {
		return {
			position: insertPosition,
			add: true
		};
	}

	return {
		position: -1,
		add: false
	};
};

export const createBlurbEl = (obj, filler, c) => {
	const {BlurbComponent} = obj;
	const w = obj.width !== 0 ? obj.width : 1;
	const size = {
		xsm: w === parseInt(c.xsm, 10) ? 'full' : `${w}of${c.xsm}`,
		sm: w === parseInt(c.sm, 10) ? 'full' : `${w}of${c.sm}`,
		md: w === parseInt(c.md, 10) ? 'full' : `${w}of${c.md}`,
		lg: w === parseInt(c.lg, 10) ? 'full' : `${w}of${c.lg}`,
		xlg: w === parseInt(c.xlg, 10) ? 'full' : `${w}of${c.xlg}`
	};

	const classes = {
		ListArticle: true,
		'ListArticle--blurb': true,
		'ListArticle--filler': Boolean(filler)
	};

	return (
		<Cell key={`${obj.id}-${filler ? `filler-${Math.floor((Math.random() * 10000) + 1)}` : `blurb-${w}`}`} size={size.xsm} sm-size={size.sm} md-size={size.md} lg-size={size.lg} xlg-size={size.xlg}>
			<div class={['ListArticleContainer', `ListArticleContainer--${w}`]}>
				{filler ? <div class={classes}/> : <div class={classes}>{BlurbComponent}</div>}
			</div>
		</Cell>
	);
};

export const getXPos = (blurb, maxColumns) => {
	for (let i = blurb.pos_x; i > 0; i--) {
		if ((i - 1) + blurb.width <= maxColumns) {
			return i;
		}
	}
};

export const createRows = (blurbs, maxColumns, columns) => {
	const rows = {};
	const added = {};
	let count = 0;

	blurbs.forEach(blurb => {
		count += (blurb.width * blurb.height);
		const xPos = getXPos(blurb, maxColumns);

		if (added[blurb.pos_y] === xPos + blurb.width) {
			return;
		}

		added[blurb.pos_y] = xPos + blurb.width;

		[...Array(blurb.height).keys()].forEach(x => {
			const pos = blurb.pos_y + x;
			rows[pos] = rows[pos] || [];
			rows[pos].push({
				pos_x: xPos,
				el: x === 0 ? createBlurbEl(blurb, false, columns) : createBlurbEl(blurb, true, columns),
				width: blurb.width,
				height: x === 0 ? blurb.height : 1
			});
		});
	});

	return {rows, count};
};

export const insertBlurbs = (articles, categoryBlurbs, maxColumns, columns) => {
	const blurbs = categoryBlurbs.map(x => {
		const {attributes} = x;
		const BlurbComponent = x;
		const {position} = attributes;
		const {posX, posY} = position;

		return {
			id: parseInt(attributes.id, 10),
			width: parseInt(attributes.width, 10),
			height: parseInt(attributes.height, 10),
			pos_y: parseInt(posY, 10),
			pos_x: parseInt(posX, 10),
			BlurbComponent
		};
	});

	if (blurbs.length === 0) {
		return articles;
	}

	const blurbData = createRows(blurbs, maxColumns, columns);
	const maxRows = Math.ceil((articles.length + blurbData.count) / maxColumns);

	let insertPosition = 0;

	[...Array(maxRows).keys()].forEach(x => {
		x++;

		for (let column = 1; column <= maxColumns; column++) {
			if (blurbData.rows[x]) {
				blurbData.rows[x].forEach(blurb => {
					const positionData = getPosition({insertPosition, blurb, maxColumns, column});

					if (positionData.add) {
						if (x > (maxRows - blurb.height)) {
							return;
						}

						articles = arrayInsert(articles, positionData.position, blurb.el);
						column += (blurb.width - 1);
					}
				});
			}

			insertPosition++;
		}
	});

	return articles;
};
