export default {
	type: 'ARTICLE_BACK_IN_STOCK',
	name: 'Back in stock',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Remarketing',
	props: [{
		name: 'text',
		inputType: 'text',
		label: 'Text',
		default: 'Lorem ipsum..'
	}, {
		name: 'feedbackText',
		inputType: 'text',
		label: 'Feedback text',
		default: 'You\'ll be notified'
	}, {
		name: 'displayAs',
		inputType: 'select',
		label: 'Display as',
		default: 'popup',
		values: [{
			label: 'Popup button',
			value: 'popup'
		}, {
			label: 'Input field',
			value: 'input'
		}]
	}],
	hiddenProps: [{
		label: 'Popup settings',
		props: [{
			name: 'openPopupText',
			inputType: 'text',
			label: 'Button text to open popup',
			default: 'Yes please'
		}, {
			name: 'popupText',
			inputType: 'text',
			label: 'Popup text',
			default: 'Lorem ipsum..'
		}, {
			name: 'buttonText',
			inputType: 'text',
			label: 'Button text',
			default: 'OK'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
