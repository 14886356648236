export default {
	type: 'ARTICLE_BUY_BUTTON',
	name: 'Buy button',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'buttonText',
		inputType: 'text',
		label: 'Text',
		default: 'Add to cart'
	}],
	hiddenProps: [{
		label: 'Out of stock settings',
		props: [{
			name: 'outOfStockButtonText',
			inputType: 'text',
			label: 'Text',
			default: 'Out of stock'
		}, {
			name: 'outOfStockBackgroundColor',
			inputType: 'color',
			label: 'Button background color',
			default: '#777'
		}, {
			name: 'outOfStockColor',
			inputType: 'color',
			label: 'Text color',
			default: '#fff'
		}, {
			name: 'hideOutOfStockButton',
			inputType: 'checkbox',
			label: 'Hide button',
			default: false
		}, {
			name: 'outOfStockMessage',
			inputType: 'text',
			label: 'Out of stock message',
			default: ''
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
