/** @jsx dom */
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';

const render = ({props}) => {
	if (!props.selectedStore) {
		return <noscript/>;
	}

	return (
		<div class={['CMS-StoreBalanceStore', props.styleClasses.Store]} data-style-attr='Store'>
			<div class={['CMS-StoreBalanceStore-name', props.styleClasses['Store Name']]} data-style-attr='Store Name'>{props.selectedStore.storeName}</div>
			<a href={`http://www.google.com/maps/place/${props.selectedStore.address.latitude},${props.selectedStore.address.longitude}`} class={['CMS-StoreBalanceStore-map', props.styleClasses['Store Map']]} target='_blank' data-style-attr='Store Map'><Icon style='pin'/></a>
			<span class={['CMS-StoreBalanceStore-address', props.styleClasses['Store Address']]} data-style-attr='Store Address'>{props.selectedStore.address.addressLine1}</span>
			<span class={['CMS-StoreBalanceStore-zipcode', props.styleClasses['Store Zipcode']]} data-style-attr='Store Zipcode'>{props.selectedStore.address.zipcode}</span>
			<div class={['CMS-StoreBalanceStore-phone', props.styleClasses['Store Phone']]} data-style-attr='Store Phone'>{props.selectedStore.phone}</div>
		</div>
	);
};

export default {render};
