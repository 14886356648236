import {RECIEVE_SITE, SET_CLIENT_ID} from '../action-types';

const initialState = window._streamline.siteContext;

export default (state = initialState, action) => {
	switch (action.type) {
		case RECIEVE_SITE: {
			return {
				...state,
				...action.data
			};
		}
		case SET_CLIENT_ID: {
			return {
				...state,
				analyticsClientId: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
