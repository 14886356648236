/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const onClick = (image, imageOnHover, onChangeView, viewActive) => () => onChangeView({front: image, back: imageOnHover, viewActive: !viewActive});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const afterUpdate = ({props}, prevProps) => {
	const {viewActive, image, imageOnHover, onChangeView} = props;

	if (props.image !== prevProps.image || props.imageOnHover !== prevProps.imageOnHover) {
		onChangeView({front: image, back: imageOnHover, viewActive});
	}
};

const render = ({props}) => {
	const {defaultViewLabel, extendedViewLabel, hasArticlesIfNoFilter, image, imageOnHover, onChangeView, viewActive, styleClasses} = props;

	if (!hasArticlesIfNoFilter) {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticlesView', styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<span class={styleClasses.Default_View_Label} data-style-attr='Default_View_Label'>{defaultViewLabel}</span>
			<div class={['CMS-ArticlesView-switch', {'CMS-ArticlesView-switch--active': viewActive}, styleClasses.Switch]} data-style-attr='Switch' onClick={onClick(image, imageOnHover, onChangeView, viewActive)}>
				<span class={['CMS-ArticlesView-slider round', styleClasses.Slider]} data-style-attr='Slider'/>
			</div>
			<span class={styleClasses.Extended_View_Label} data-style-attr='Extended_View_Label'>{extendedViewLabel}</span>
		</div>
	), props);
};

export default {afterUpdate, render, shouldUpdate};
