export default node => ({
	type: 'ARTICLES_SLIDER',
	name: 'Articles slider',
	allowedSections: ['main', 'footer', 'header'],
	disallowedChildren: ['*'],
	category: 'Articles',
	props: [{
		label: 'Source',
		name: 'source',
		inputType: 'select',
		values: [{
			label: 'Recent views',
			value: 'recent'
		}, {
			label: 'Selection',
			value: 'selection'
		}, {
			label: 'Category',
			value: 'category'
		}],
		default: 'recent'
	}, node && node.props.source !== 'category' && {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'size',
		inputType: 'slider',
		label: 'Number of articles to load',
		default: 10,
		min: 1,
		max: 20
	}, node && (node.props.source === 'category' || node.props.source === 'legacy') && {
		name: 'categoryId',
		inputType: 'link',
		label: 'Source category',
		default: '',
		requestKey: 'categoryId'
	}, node && node.props.source === 'selection' && {
		name: 'articleNumbers',
		inputType: 'text',
		label: 'Article numbers (eg. 1234, 5678, 91011)',
		default: ''
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Slider settings',
		props: [{
			name: 'play',
			inputType: 'checkbox',
			label: 'Autoplay',
			default: false
		}, {
			name: 'loop',
			inputType: 'checkbox',
			label: 'Loop',
			default: false
		}, {
			name: 'speed',
			inputType: 'text',
			label: 'Animation speed',
			default: '300'
		}, {
			name: 'duration',
			inputType: 'text',
			label: 'Animation duration',
			default: '3000'
		}, {
			name: 'effect',
			inputType: 'select',
			label: 'Effect',
			default: 'slide',
			values: ['slide', 'fade', 'cube', 'coverflow', 'flip']
		}]
	}, {
		label: 'Navigation',
		props: [{
			name: 'arrows',
			inputType: 'checkbox',
			label: 'Show Arrows',
			default: true
		}, {
			name: 'pagination',
			inputType: 'checkbox',
			label: 'Show Pagination',
			default: true
		}, {
			name: 'paginationType',
			inputType: 'select',
			label: 'Pagination type',
			default: 'bullets',
			values: ['bullets', 'progress', 'fraction']
		}, {
			name: 'navigationColor',
			inputType: 'color',
			label: 'Navigation color',
			default: '#000000'
		}]
	}, {
		label: 'Slide options mobile',
		props: [{
			name: 'slidesPerViewMobile',
			inputType: 'select',
			label: 'Slides per view',
			default: '1',
			values: ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			name: 'slidesPerColumnMobile',
			inputType: 'select',
			label: 'Slides per column',
			default: '1',
			values: ['1', '2', '3', '4']
		}, {
			name: 'spaceBetweenMobile',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}, {
			name: 'centerSlidesMobile',
			inputType: 'checkbox',
			label: 'Center slides',
			default: false
		}]
	}, {
		'label': 'Slide options tablet',
		'props': [{
			'name': 'slidesPerViewTablet',
			'inputType': 'select',
			'label': 'Slides per view',
			'default': '3',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			'name': 'slidesPerColumnTablet',
			'inputType': 'select',
			'label': 'Slides per column',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, {
			'name': 'spaceBetweenTablet',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, {
			'name': 'centerSlidesTablet',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}]
	}, {
		'label': 'Slide options desktop',
		'props': [{
			'name': 'slidesPerViewDesktop',
			'inputType': 'select',
			'label': 'Slides per view',
			'default': '5',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, {
			'name': 'slidesPerColumnDesktop',
			'inputType': 'select',
			'label': 'Slides per column',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, {
			'name': 'spaceBetweenDesktop',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, {
			'name': 'centerSlidesDesktop',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}]
	}]
});
