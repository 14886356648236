/** @jsx dom */
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Form, InputField} from '@viskan/deku-form';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	validationError: {
		source: 'validationError'
	}
};

const onSubmit = (token, {getTranslation, setState}) => data => {
	bus.emit('loading:open');

	v12.customer.newPassword(token, data)
		.then(() => {
			setState({message: getTranslation('password.change_success')});
			bus.emit('loading:close');
		})
		.catch(error => {
			bus.emit('loading:close');

			if (error.statusCode === 404) {
				setState({message: getTranslation('password.invalid_token')});
				return;
			}

			setState({message: error.response.body.errorMessage});
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {getTranslation, token, validationError} = props;
	const {message} = state;

	return (
		<div class='NewPassword'>
			<div class='Container'>
				<Grid>
					<Cell size='full' md-size='1of2' lg-size='1of3'>
						<div class='u-marginTop u-marginBottom'>
							<Form onSubmit={onSubmit(token, {getTranslation, setState})}>
								<InputField type='password' label={getTranslation('password.password')} name='password_1' validationMessage={validationError(getTranslation('password.password'), getTranslation)} required/>
								<InputField type='password' label={getTranslation('password.confirm')} name='password_2' validationMessage={validationError(getTranslation('password.confirm'), getTranslation)} required/>
								{message && <p class='NewPassword-feedback' innerHTML={message}/>}
								<Button class='Button--primary' type='submit'>
									{getTranslation('password.change')}
								</Button>
							</Form>
						</div>
					</Cell>
				</Grid>
			</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
