/** @jsx dom */
import dom from 'magic-virtual-element';
import deepEqual from 'deep-equal';
import {utils as cmsUtils} from '@viskan/cms-components';
import {getSize} from '../actions';
import {Feed, Slider} from '../';

const propTypes = {
	artId: {
		type: 'number'
	},
	title: {
		type: 'string'
	},
	class: {
		type: 'string'
	},
	slider: {
		type: 'boolean'
	}
};

const getFrom = (props, prevProps) => {
	const {sliderDesktop, sliderTablet, sliderMobile} = props;
	const {columnsDesktop, columnsTablet, columnsMobile} = props;
	const {rowsDesktop, rowsTablet, rowsMobile} = props;
	const {xlg, lg, md, sm, xsm} = props;
	const {artId, from, useWithArticle} = props;

	if (!deepEqual({artId, sliderDesktop, sliderTablet, sliderMobile, columnsDesktop, columnsTablet, columnsMobile, rowsDesktop, rowsTablet, rowsMobile, xlg, lg, md, sm, xsm, useWithArticle}, {
		artId: prevProps.artId,
		sliderDesktop: prevProps.sliderDesktop,
		sliderTablet: prevProps.sliderTablet,
		sliderMobile: prevProps.sliderMobile,
		columnsDesktop: prevProps.columnsDesktop,
		columnsTablet: prevProps.columnsTablet,
		columnsMobile: prevProps.columnsMobile,
		rowsDesktop: prevProps.rowsDesktop,
		rowsTablet: prevProps.rowsTablet,
		rowsMobile: prevProps.rowsMobile,
		xlg: prevProps.xlg,
		lg: prevProps.lg,
		md: prevProps.md,
		sm: prevProps.sm,
		xsm: prevProps.xsm,
		useWithArticle: prevProps.useWithArticle
	})) {
		return {
			propsChanged: true,
			value: 0
		};
	}

	return {
		propsChanged: false,
		value: from
	};
};

const getDisplayType = (columns, rows, props, slider) => {
	const {from, grams, gutter, xsm, sm, md, lg, xlg} = props;
	const feedColumns = {xsm, sm, md, lg, xlg};

	if (slider) {
		return <Slider columns={columns} rows={rows} posts={grams} from={from} onScroll={props}/>;
	}

	return <Feed gutter={gutter} posts={grams} columns={feedColumns}/>;
};

const afterMount = ({props}) => {
	const {feedFetch, from, useWithArticle} = props;
	const artId = (useWithArticle && props.artId) ? props.artId : undefined;
	const size = getSize(props);

	if (useWithArticle && !artId) {
		return;
	}

	feedFetch({artId, from, size: size < 3 ? 4 : size});
};

const afterUpdate = ({props}, prevProps) => {
	const {feedFetch, useWithArticle} = props;
	const artId = (useWithArticle && props.artId) ? props.artId : undefined;
	const size = getSize(props);
	const from = getFrom(props, prevProps);

	if (!from.propsChanged && from.value === prevProps.from) {
		return;
	}

	if (useWithArticle && !artId) {
		return;
	}

	feedFetch({artId, from: from.value, size: size < 3 ? 4 : size});
};

const render = ({props}) => {
	const {breakpoint} = props;
	const currentBreakpoint = breakpoint.breakpointOverride || breakpoint.breakpoint;

	return cmsUtils.createCmsComponent((
		<div class={['CMS-Instagram', props.class]}>
			{(currentBreakpoint === 'xlg' || currentBreakpoint === 'lg') && getDisplayType(props.columnsDesktop, props.rowsDesktop, props, props.sliderDesktop)}
			{currentBreakpoint === 'md' && getDisplayType(props.columnsTablet, props.rowsTablet, props, props.sliderTablet)}
			{(currentBreakpoint === 'xsm' || currentBreakpoint === 'sm') && getDisplayType(props.columnsMobile, props.rowsMobile, props, props.sliderMobile)}
		</div>
	), props);
};

export default {afterMount, afterUpdate, propTypes, render};
