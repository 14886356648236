import {connect} from '@viskan/deku-redux';
import {setView} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		back: state.articles.back,
		front: state.articles.front,
		hasArticlesIfNoFilter: state.articles.firstTotal > 0,
		viewActive: state.articles.viewActive
	}),
	dispatch => ({
		onChangeView: data => dispatch(setView(data))
	})
)(Component);

export default {component, config};
