import {connect} from '@viskan/deku-redux';
import {setCurrentMedia, setDefaultCurrentMedia} from '../media/actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		active: state.article.active,
		article: state.article.article,
		currentMedia: state.articleMedia.currentMedia
	}),
	dispatch => ({
		setCurrentMedia: (newImage, props) => dispatch(setCurrentMedia(newImage, props)),
		setDefaultCurrentMedia: props => dispatch(setDefaultCurrentMedia(props))
	})
)(Component);

export default {component, config};
