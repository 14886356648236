import {connectWrap as connect} from '@viskan/deku-redux';
import BaseComponent from './components';
import config from './config';

const component = connect(
	({site}) => ({
		site
	}),
	() => ({})
)(BaseComponent);

export default {component, config};
