/** @jsx dom */
import dom from 'magic-virtual-element';
import breakpoints from '../../breakpoints';

const getStylePropertyValue = (el, prop) => window.getComputedStyle(el).getPropertyValue(prop);

const afterMount = ({props}, el) => {
	const {setBreakpoint} = props;
	const breakpointValueEl = el.querySelector('.Breakpoint-value');
	let ticking = false;

	setBreakpoint(getStylePropertyValue(breakpointValueEl, 'font-family'));

	window.addEventListener('resize', () => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				setBreakpoint(getStylePropertyValue(breakpointValueEl, 'font-family'));
				ticking = false;
			});
		}

		ticking = true;
	});
};

const render = ({props}) => {
	const {breakpoint, breakpointOverride, getIntValue, progressive} = props;
	const breakpointValue = getIntValue(breakpointOverride ? breakpointOverride : breakpoint, breakpoints);
	const breakpointClasses = progressive ? breakpoints.map(x => {
		if (x.width <= breakpointValue) {
			return `Breakpoint--${x.id}`;
		}

		return '';
	}) : [`Breakpoint--${breakpoint}`];

	return (
		<div class={['Breakpoint', breakpointClasses, props.class]}>
			<div class='Breakpoint-children'>{props.children}</div>
			<div class='Breakpoint-value'/>
		</div>
	);
};

export default {afterMount, render};
