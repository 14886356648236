/** @jsx dom */
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import queryString from 'query-string';
import {scroller} from 'windowsill';
import {utils as cmsUtils} from '@viskan/cms-components';
import ListArticle from '../../../list-article';
import {restoreScrollMemoryData} from '../../../scroll-memory/actions';
import {initialState as reducerInitialState} from '../reducer';
import {insertBlurbs} from './blurbs';

const defaultProps = {
	size: 20,
	splitByAttribute1: true
};

const propTypes = {
	data: {
		type: 'object'
	},
	meta: {
		type: 'string'
	}
};

const sourceFavorites = source => source === 'favorites';

const articleIsFavorite = (favoritesArtNos, article, splitByAttribute1) => {
	return favoritesArtNos && favoritesArtNos.some(favorite => {
		if (!splitByAttribute1 || article._attr1_id === -1) {
			return favorite.artNo === article.art_no;
		}

		return favorite.artNo === article.art_no && favorite.variant === article._attr1_id;
	});
};

const listenForScrollPastBottom = (props, el) => {
	let tick;

	if (!props.nextPagination) {
		return;
	}

	scroller.unbind();
	scroller.addListener(() => {
		const bottom = el.offsetTop + el.offsetHeight;
		const pos = window.scrollY + Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		if (!tick) {
			requestAnimationFrame(() => {
				tick = null;

				if (pos < bottom) {
					return;
				}

				props.nextPagination({size: props.size});
			});
		}

		tick = true;
	});

	scroller.bind();
};

const getSize = size => size === 1 ? 'full' : `1of${size}`;

const renderArticles = (articles, props, styling) => {
	const {columns} = styling;
	const {back, front, image, imageOnHover, meta, noBlurbs, popupForSingleSku, styleClasses, viewActive, quickbuy, favorite, isArticle, favoritesArtNos, source, splitByAttribute1} = props;

	return articles.map(article => {
		try {
			const {art_id, articleAttributes} = article;
			const isFavorite = articleIsFavorite(favoritesArtNos, article, splitByAttribute1);

			if (sourceFavorites(source) && !isFavorite) {
				return <noscript key={`${art_id}-${articleAttributes[0].attr1_id}-${articleAttributes[0].attr2_id}`}/>;
			}

			const listArticleElement = <ListArticle styleClasses={styleClasses} article={article} isArticle={isArticle} favorite={favorite} favoritesArtNos={favoritesArtNos} source={source} splitByAttribute1={splitByAttribute1} back={viewActive ? back : imageOnHover} front={viewActive ? front : image} meta={meta} quickbuy={quickbuy} popupForSingleSku={popupForSingleSku} styling={styling}/>;

			return (
				<Cell key={`${art_id}-${articleAttributes[0].attr1_id}-${articleAttributes[0].attr2_id}`} size={getSize(columns.xsm)} sm-size={getSize(columns.sm)} md-size={getSize(columns.md)} lg-size={getSize(columns.lg)} xlg-size={getSize(columns.xlg)}>
					{!noBlurbs ? <div class='ListArticleContainer'>{listArticleElement}</div> : listArticleElement}
				</Cell>
			);
		} catch (error) {
			v12.util.error(error);

			return console.error(error);
		}
	}).filter(Boolean);
};

const fetchArticles = (props, fetchOptions) => {
	switch (props.source) {
		case 'searchResult': {
			return props.articlesFetch(props.searchQuery, {...fetchOptions, url: '/api/search'});
		}
		case 'favorites': {
			return props.favoritesArtNos.length > 0 && props.articlesFetchFavorites(props.favoritesArtNos, props.splitByAttribute1);
		}
		case 'currentCategory':
		default: {
			return props.articlesFetch(String(props.categoryId), fetchOptions);
		}
	}
};

const getInitialFrom = props => {
	const {from, isCrawler} = props;
	const page = parseInt(queryString.parse(window.location.search).page, 10);
	const size = parseInt(props.size, 10);

	return !isCrawler || !page ? from : size * (page - 1);
};

const afterMount = ({props}, el) => {
	const scrollMemoryData = restoreScrollMemoryData('articlesData');
	const {activeFilters, initialSize, pagination, size, sort, splitByAttribute1, source, favoritesArtNos} = {
		...props,
		...scrollMemoryData
	};

	if (scrollMemoryData) {
		props.articlesSetState(scrollMemoryData);
	}

	if (sourceFavorites(source) && (!favoritesArtNos || favoritesArtNos.length === 0)) {
		return;
	}

	fetchArticles(props, {activeFilters, from: getInitialFrom(props), size: initialSize || size, sort, splitByAttribute1});

	if (pagination) {
		listenForScrollPastBottom(props, el);
	}
};

const afterUpdate = ({props}, prevProps) => {
	const {activeFilters, articles, categoryId, firstTotal, from, isLoading, initialLoad, searchQuery, size, sort, source, splitByAttribute1} = props;

	if (initialLoad && articles.length >= firstTotal) {
		scroller.unbind();
	}

	if (categoryId !== prevProps.categoryId || source !== prevProps.source || searchQuery !== prevProps.searchQuery || splitByAttribute1 !== prevProps.splitByAttribute1) {
		fetchArticles(props, {...reducerInitialState, size, sort, splitByAttribute1});
		return;
	}

	if (!sourceFavorites(source)) {
		if (!initialLoad || isLoading || (deepEqual(activeFilters, prevProps.activeFilters) && deepEqual(sort, prevProps.sort) && from === prevProps.from && size === prevProps.size)) {
			return;
		}
	}

	if (sourceFavorites(source) && (!props.favoritesArtNos || props.favoritesArtNos.length === 0 || deepEqual(prevProps.favoritesArtNos, props.favoritesArtNos))) {
		return;
	}

	fetchArticles(props, {activeFilters, from, size, sort, splitByAttribute1});
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const beforeUnmount = () => scroller.unbind();

const render = ({props}) => {
	const {articleNameFontColor, articleNameFontSize, articleNameFontStyle, articleNameFontWeight, breakpoint, children, priceFontSize, priceFontStyle, priceFontWeight, styleClasses, xsm, sm, md, lg, xlg, noFavoritesSavedText, source} = props;
	const columns = {xsm, sm, md, lg, xlg};
	const maxColumns = columns[breakpoint];
	const styling = {
		columns,
		articleNameFontColor,
		articleNameFontSize,
		articleNameFontStyle,
		articleNameFontWeight,
		priceFontSize,
		priceFontStyle,
		priceFontWeight
	};
	const articles = sourceFavorites(source) ? props.favorites : props.articles;

	return cmsUtils.createCmsComponent((
		<div class={['CMS-Articles Articles', styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{articles.length >= 1 ? <Grid>{insertBlurbs(renderArticles(articles, props, styling), children, maxColumns, columns)}</Grid> : sourceFavorites(source) ? <p class={['CMS-favorites-no-saved-articles', styleClasses['No Saved Favorites Text']]} data-style-attr='No Saved Favorites Text'>{noFavoritesSavedText}</p> : <noscript/>}
		</div>
	), props);
};

export default {afterMount, afterUpdate, beforeUnmount, defaultProps, propTypes, render, shouldUpdate};
