/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const handleInputChange = props => e => props.setQuantity(e.target.value);

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	return (
		<select class={['CMS-ArticleQuantity-select', props.styleClasses.Select]} data-style-attr='Select' onChange={handleInputChange(props)}>
			{Array.from({length: 10}, (x, i) => <option class={['CMS-ArticleQuantity-option', props.styleClasses.Option]} value={i + 1} data-style-attr='Option'>{i + 1}</option>)}
		</select>
	);
};

export default {render, shouldUpdate};
