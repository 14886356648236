import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import Component from './components';

export default connect(
	({router, site}) => ({
		contentManagementActive: site.cmsTokenSet,
		languageId: site.lang_id,
		query: router.pathname,
		pathPrefix: router.pathPrefix
	}),
	dispatch => ({
		categoryFetch: (query, options) => dispatch(categoryFetch(query, 'main', options))
	})
)(Component);
