/** @jsx dom */
import dom from 'magic-virtual-element';

const name = 'DomObserver';

const observe = (el, props) => {
	const onChange = (...args) => props.onChange(el, ...args);
	const mutationObserver = new window.MutationObserver(onChange);
	const repositionObserver = new window.ResizeObserver(onChange);

	mutationObserver.observe(el, {
		attributes: true,
		childList: true,
		subtree: true,
		attributeOldValue: true,
		attributeFilter: ['class']
	});
	repositionObserver.observe(el);

	return [mutationObserver, repositionObserver];
};

const unobserve = observers => observers.map(observer => observer.disconnect());

const afterMount = ({props}, el, setState) => setState({
	observers: observe(el, props)
});

const beforeUnmount = ({state}) => unobserve(state.observers);

const render = ({props}) => <div class={name}>{props.children}</div>;

export default {afterMount, beforeUnmount, render};
