import {RECEIPT_FETCH_FULFILLED} from '../action-types';

const initialState = {
	data: undefined
};

const mapVersion1To2 = data => ({
	offerCodes: [data.camp_code],
	orderNumber: data.outorder.outorderno,
	identifier: data.purchase_no,
	value: {
		total: data.tot_value,
		totalExcludingTax: data.tot_value_exvat,
		discountTotal: data.discount,
		discountTotalExcludingTax: data.discount_exvat,
		shippingFee: data.wdel_carriage,
		shippingFeeExcludingTax: data.wdel_carriage_exvat,
		paymentFee: data.wpay_carriage,
		paymentFeeExcludingTax: data.wpay_carriage_exvat
	},
	deliveryMethod: {
		methodName: data.wayOfDelivery.wdel_code
	},
	rows: data.outorder.rows.map(x => ({
		meta: {
			articleNumber: x.art_no,
			articleName: x.artname,
			attribute1Id: x.attr1_id,
			attribute1Name: x.attr1_code,
			attribute2Id: x.attr2_id,
			attribute2Name: x.attr2_code,
			attribute3Id: x.attr3_id,
			attribute3Name: x.attr3_code,
			linkFriendlyName: x.link_friendly_name,
			imageFileName: x.media_filename
		},
		offerDescription: x.offer_desc,
		quantity: x.qty,
		value: {
			discount: x.discount,
			discountExcludingTax: x.discount_exvat,
			unitPrice: x.price_incvat,
			unitPriceExcludingTax: x.price_exvat,
			total: x.rowSumToPay
		}
	})),
	subscriptions: [],
	offers: data.offers.map(x => ({
		offerId: x.camp_id,
		camp_name: x.camp_name
	})),
	customer: {
		companyName: data.customer.person_flg ? '' : data.customer.cust_desc1,
		socialSecurityNumber: data.customer.org_no,
		organizationNumber: data.customer.org_no,
		customerNumber: data.customer.cust_no,
		contactPerson: data.customer.contactperson_1,
		firstName: data.customer.cust_desc2,
		lastName: data.customer.cust_desc1,
		cellphone: data.customer.phone_2,
		email: data.customer.email_1,
		address: {
			city: data.customer.invoiceAddress.city,
			zipcode: data.customer.invoiceAddress.zipcode,
			addressLine1: data.customer.invoiceAddress.address,
			countryCode: data.customer.invoiceAddress.country.countrycode_iso2,
			administrativeDivision: data.customer.invoiceAddress.province,
			careOf: data.customer.invoiceAddress.address_desc
		},
		deliveryAddress: {
			city: data.customer.deliveryAddress.city,
			zipcode: data.customer.deliveryAddress.zipcode,
			addressLine1: data.customer.deliveryAddress.address,
			countryCode: data.customer.deliveryAddress.country.countrycode_iso2,
			administrativeDivision: data.customer.deliveryAddress.province,
			careOf: data.customer.deliveryAddress.address_desc
		}
	}
});

export default (state = initialState, action) => {
	switch (action.type) {
		case RECEIPT_FETCH_FULFILLED: {
			const data = action.meta === 'v1' ? mapVersion1To2(action.payload) : action.payload;

			return {
				...state,
				data
			};
		}
		default: {
			return state;
		}
	}
};
