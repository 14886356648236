import {connect} from '@viskan/deku-redux';
import {resetFilters} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		hasArticles: state.articles.firstTotal !== 0,
		hasSelectedFilters: state.articles.selectedFilters.length !== 0
	}),
	dispatch => ({
		resetFilters: () => dispatch(resetFilters())
	})
)(Component);

export default {component, config};
