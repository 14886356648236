/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const render = ({props}) => {
	const innerHTML = `${props.prefixText}${props.article[props.field]}${props.suffixText}`;
	const element = !props.article[props.field] ? <noscript/> : dom(props.elementType, {
		innerHTML,
		class: props.styleClasses.Field,
		'data-style-attr': 'Field'
	});

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticleField', `CMS-ArticleField-${props.field}`, props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{element}
		</div>
	), props);
};

export default {render};
