/** @jsx dom */
import Button from '@viskan/deku-button';
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import ArticleData from '../../article';

const name = 'QuickBuy';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	}
};

const addOrPromptSku = ({props}) => {
	const {addToCart, data, openPopup, popupForSingleSku} = props;

	if (!popupForSingleSku && data.articleAttributes.length === 1) {
		debounce(() => addToCart(data, data.articleAttributes[0]), 1000, true)();
		return;
	}

	openPopup(<ArticleData popup query={data.link_friendly_name}/>);
};

const render = ({props}) => {
	const {data, getTranslation, styleClasses = {}} = props;
	const onClick = () => addOrPromptSku({props});

	return <Button class={[name, styleClasses.QuickBuy]} disabled={!data.inStock} onClick={onClick} name={name}>{getTranslation('articles.buy')}</Button>;
};

export default {name, propTypes, render};
