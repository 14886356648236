/** @jsx dom */
import Button from '@viskan/deku-button';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {CheckboxField, Form, InputField} from '@viskan/deku-form';
import {Spinner} from '@viskan/estore-components';
import objectAssign from 'object-assign';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	validationError: {
		source: 'validationError'
	}
};

const initialState = () => {
	return {
		loading: false
	};
};

const getFeedback = (description, header) => {
	return (
		<div class='CMS-Newsletter-feedbackWrapper'>
			<h1 class='CMS-Newsletter-feedbackHeader'>{header}</h1>
			<p class='CMS-Newsletter-feedbackDescription'>{description}</p>
		</div>
	);
};

const onSubmit = (props, setState) => (data, el) => {
	const {site} = props;
	setState({loading: true});

	switch (props.source) {
		case 'apsis': {
			const obj = {
				countryCode: site.country_id,
				email: data.email_1
			};

			v12.util.http.post('/api/apsis', {
				body: obj,
				query: {
					apiKey: props.apiKey,
					listid: props.mailingListId
				}
			})
			.then(() => el.reset())
			.then(() => {
				setState({loading: false});
				props.openPopup(getFeedback(props.feedbackDescription, props.feedbackHeader), {class: 'CMS-Newsletter-popup', close: true});
			})
			.catch(error => {
				setState({loading: false});
				props.openPopup(getFeedback(error.response.body.Message, error.statusMessage), {class: 'CMS-Newsletter-popup', close: true});
			});
			break;
		}
		case 'mailChimp': {
			const dataCenter = props.apiKey.substring(props.apiKey.indexOf('-') + 1, props.apiKey.length);
			const obj = {
				email: data.email_1
			};

			v12.util.http.post('/api/mailchimp', {
				body: obj,
				query: {
					apiKey: props.apiKey,
					dc: dataCenter,
					listid: props.mailingListId
				}
			})
			.then(() => el.reset())
			.then(() => {
				setState({loading: false});
				props.openPopup(getFeedback(props.feedbackDescription, props.feedbackHeader), {class: 'CMS-Newsletter-popup', close: true});
			})
			.catch(error => {
				setState({loading: false});
				if (error.response.body.title === 'Member Exists') {
					props.openPopup(getFeedback(props.getTranslation(`newsletter.${error.response.body.title}`), props.getTranslation('newsletter.header-exists')), {class: 'CMS-Newsletter-popup', close: true});
				} else {
					props.openPopup(getFeedback(error.response.body.detail, error.statusMessage), {class: 'CMS-Newsletter-popup', close: true});
				}
			});
			break;
		}
		case 'viskan':
		default: {
			const obj = objectAssign(data, {
				'inv_country.id': site.country_id,
				'del_country.id': site.country_id,
				'vis_country.id': site.country_id
			});

			props.saveEmail(obj.email_1);

			v12.customer.newsletter(obj)
			.then(() => el.reset())
			.then(() => {
				setState({loading: false});
				props.openPopup(getFeedback(props.feedbackDescription, props.feedbackHeader), {class: 'CMS-Newsletter-popup', close: true});
			})
			.catch(error => {
				setState({loading: false});
				props.openPopup(getFeedback(error.response.body.detail, error.statusMessage), {class: 'CMS-Newsletter-popup', close: true});
			});
		}
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {loading} = state;

	return cmsUtils.createCmsComponent((
		<div class={['CMS-Newsletter-wrapper', props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<Form onSubmit={onSubmit(props, setState)}>
				<div class={['CMS-Newsletter-header', props.styleClasses.Header]} data-style-attr='Header'>{props.header}</div>
				<InputField class={['CMS-Newsletter-input', props.styleClasses.Input]} data-style-attr='Input' type='email' name='email_1' placeholder={props.placeholder} validationMessage={props.validationError(props.getTranslation('newsletter.email'), props.getTranslation)} required/>
				<Button class={['CMS-Newsletter-button', props.styleClasses.Button]} data-style-attr='Button' type='submit'>
					{props.buttonText}
				</Button>
				{props.showCompliance && <CheckboxField class='CMS-Newsletter-compliance' name='compliance' label={props.complianceText} validationMessage={props.validationError(props.getTranslation('newsletter.concent'), props.getTranslation)} required/>}
				{props.showConcent && <CheckboxField class='CMS-Newsletter-concent' name='concent' label={props.concentText} validationMessage={props.validationError(props.getTranslation('newsletter.concent'), props.getTranslation)} required/>}
				{loading && <Spinner/>}
			</Form>
		</div>
	), props);
};

export default {propTypes, initialState, render, shouldUpdate};
