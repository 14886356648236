import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import Component from './components';

export default connect(
	({app, router, site}) => ({
		contentManagementActive: site.cmsTokenSet,
		languageId: router.path === site.localePickerPath ? site.defaultLanguageId : site.lang_id,
		notFound: app.category.main.notFound
	}),
	dispatch => ({
		categoryFetch: (query, opts) => dispatch(categoryFetch(query, 'main', opts))
	})
)(Component);
