export default () => ({
	type: 'FILTER_RESET',
	name: 'Reset filter',
	allowedSections: ['main'],
	disallowedChildren: [],
	category: 'Articles',
	icon: 'fal fa-trash-alt',
	props: [{
		name: 'alwaysShow',
		inputType: 'checkbox',
		label: 'Show even if no filters are selected',
		default: true
	}],
	hiddenProps: []
});
