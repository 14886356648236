/** @jsx dom */
import {Cell, Grid} from 'viskan-deku-grid';
import dom from 'magic-virtual-element';
import Slide from '@viskan/deku-slide';
import {utils as cmsUtils} from '@viskan/cms-components';
import Breakpoint from '../../../../breakpoint';
import ListArticle from '../../../../list-article';

const cutArray = (array, length) => {
	const ret = array.slice();

	if (ret.length > length) {
		ret.length = length;
	}

	return ret;
};

const getSliderOptions = props => {
	const defaultOptions = {
		centeredSlides: props.sliderCenter,
		spaceBetween: props.sliderMargin
	};

	switch (props.breakpoint) {
		case 'xsm':
		case 'sm': {
			return {
				...defaultOptions,
				slidesPerView: parseInt(props.slidesPerViewSm, 10)
			};
		}
		case 'md': {
			return {
				...defaultOptions,
				slidesPerView: parseInt(props.slidesPerViewMd, 10)
			};
		}
		case 'lg':
		case 'xlg':
		default: {
			return {
				...defaultOptions,
				slidesPerView: parseInt(props.slidesPerViewLg, 10)
			};
		}
	}
};

const useIfEnoughtChildrens = (slidesPerView, numChildren) => value => numChildren > slidesPerView ? value : false;

const getSlider = (articles, props) => {
	const items = articles.map(article => <ListArticle key={article.article.art_id} article={article.article} styleClasses={props.styleClasses}/>);
	const options = getSliderOptions(props);
	const useIfSuited = useIfEnoughtChildrens(options.slidesPerView, items.length);

	return (
		<Slide options={options} arrows={useIfSuited(props.sliderArrows)} color={props.sliderColor} pagination={useIfSuited(props.sliderPagination)} play={useIfSuited(props.sliderAutoplay)}>
			{items}
		</Slide>
	);
};

const getGrid = (articles, props) => {
	const items = cutArray(articles, parseInt(props.maxArticles, 10)).map(article => (
		<Cell key={article.article.art_id} size={`1of${props.columnsSm}`} md-size={`1of${props.columnsMd}`} lg-size={`1of${props.columnsLg}`}>
			<ListArticle article={article.article} styleClasses={props.styleClasses}/>
		</Cell>
	));

	return (
		<Grid gutter>
			{items}
		</Grid>
	);
};

const getLayout = (layout, articles, props) => {
	switch (layout.toLowerCase()) {
		case 'slider': {
			return getSlider(articles, props);
		}
		case 'grid':
		default: {
			return getGrid(articles, props);
		}
	}
};

const render = ({props}) => {
	const articles = props.relatedArticles.filter(article => article.relation_type === parseInt(props.relationType, 10));

	if (articles.length === 0) {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-RelatedArticles', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<h2 class={['CMS-RelatedArticles-title', props.styleClasses.Title]} data-style-attr='Title'>{props.title}</h2>
			<Breakpoint max='sm'>
				{() => getLayout(props.mobileLayout, articles, props)}
			</Breakpoint>
			<Breakpoint min='md' max='md'>
				{() => getLayout(props.tabletLayout, articles, props)}
			</Breakpoint>
			<Breakpoint min='lg'>
				{() => getLayout(props.desktopLayout, articles, props)}
			</Breakpoint>
		</div>
	), props);
};

export default {render};
