/** @jsx dom */
import dom from 'magic-virtual-element';
import {generateMediaURL} from '@viskan/streamline-utilities';
import TwoStepZoom from '../two-step-zoom';
import {getSliderCommon} from '../common';

const twoStepSliderZoomContent = (imageArray, props) => imageArray.map(image => {
	const key = props.cmsLoaded ? `${new Date().getTime()}-zoom` : `${props.active.attr1_id}-${props.article.art_id}-superzoom`;
	const src = generateMediaURL(image.filename, {scale: 'normal'}, props.mediaConfig);
	const zoom = generateMediaURL(image.filename, {scale: 'large'}, props.mediaConfig);

	return (
		<figure key={key} class={['CMS-ArticleMedia-Zoom', props.styleClasses['Zoom Wrapper']]} data-style-attr='Zoom Wrapper'>
			{image.path &&
				<div class={['CMS-ArticleMedia-mainImage', props.styleClasses['Main Image']]} data-style-attr='Main Image'>
					<TwoStepZoom key={`${props.currentMedia.path}-${props.zoomDelay}`} src={src} zoom={zoom} alt={image.media_alt_desc || props.article.artname} delay={parseInt(props.zoomDelay, 10)}/>
				</div>
			}
		</figure>
	);
});

const getTwoStepSliderZoom = props => getSliderCommon(props, twoStepSliderZoomContent);

export default getTwoStepSliderZoom;
