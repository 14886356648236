/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const handleInputChange = props => e => props.setQuantity(e.target.value);

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const name = 'CMS-ArticleQuantity';

	return <input type='number' id={name} class={['CMS-ArticleQuantity-input', props.styleClasses.Input]} name={name} min='1' value={props.quantity} onChange={handleInputChange(props)} data-style-attr='Input'/>;
};

export default {render, shouldUpdate};
