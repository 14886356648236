/** @jsx dom */
import dom from 'magic-virtual-element';

const defaultProps = {
	insideSelector: '.App'
};

const name = 'StickAround';

const updateFitClasses = (fixedClasses, el, insideElement) => {
	const contentRect = el.getBoundingClientRect();
	const childrenRect = el.parentNode.getBoundingClientRect();
	const windowHeight = window.innerHeight || document.documentElement.clientHeight;
	const windowWidth = window.innerWidth || document.documentElement.clientWidth;

	const fit = {
		top: childrenRect.top - contentRect.height - insideElement.offsetTop >= 0,
		right: childrenRect.right + contentRect.width + insideElement.offsetLeft <= windowWidth,
		bottom: childrenRect.bottom + contentRect.height <= windowHeight,
		left: childrenRect.left - contentRect.width - insideElement.offsetLeft >= 0
	};

	el.className = [
		fixedClasses,
		fit.top && 'StickAround--top',
		fit.left && 'StickAround--left',
		fit.bottom && 'StickAround--bottom',
		fit.right && 'StickAround--right'
	].filter(Boolean).join(' ');
};

const afterMount = ({id, props}, el) => {
	const {insideSelector} = props;
	const insideElement = document.querySelector(insideSelector);
	const fixedClasses = [name, props.class].join(' ');

	updateFitClasses(fixedClasses, el, insideElement);
	const listner = window.addEventListener('scroll', () => requestAnimationFrame(() => {
		if (!el || !el.parentNode) {
			return window.removeEventListener('scroll', listner);
		}

		updateFitClasses(fixedClasses, el, insideElement);
	}));
};

const afterRender = ({id, props}, el) => {
	const {insideSelector} = props;
	const fixedClasses = [name, props.class].join(' ');
	const insideElement = document.querySelector(insideSelector);

	requestAnimationFrame(() => updateFitClasses(fixedClasses, el, insideElement));
};

const render = ({props}) => {
	return (
		<div class={[name, props.class]}>
			{props.children}
		</div>
	);
};

export default {afterMount, afterRender, defaultProps, name, render};
