/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import Link from '../../../link';
import Price from '../../../price';
import {Image} from '../../../media';

const propTypes = {
	data: {
		type: 'object'
	},
	meta: {
		type: 'object'
	}
};

const getImage = (data, colorId) => {
	const specificColor = colorId ? {
		sku: {
			attr1_id: colorId
		}
	} : {};

	const medias = v12.article.medias(data, {
		...specificColor,
		size: ''
	});

	if (medias.length === 0) {
		return {};
	}

	return medias[0];
};

const getLink = ({prefix, data, meta}) => {
	const {articleAttributes, link_friendly_name} = data;
	const {split_flg} = meta;

	return `/${prefix}/${link_friendly_name}${split_flg ? `?attr1_id=${articleAttributes[0].attr1_id}` : ''}`;
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data} = props;
	const {artname, articleAttributeMedias} = data;

	const image = articleAttributeMedias.find(image => image.attr1_id !== 0);
	const colorId = image && Object.keys(image).length ? image.attr1_id : null;

	const {media_alt_desc, filename} = getImage(data, colorId);

	return (
		<div class='SearchFormDropdownMenu-item'>
			<article class='Arrange Arrange--middle'>
				<div class='Arrange-sizeFit'>
					<figure class='SearchFormRow-img'>
						<Link href={getLink(props)}>
							<Image source={filename} scale='thumbnail' alt={media_alt_desc || artname}/>
						</Link>
					</figure>
				</div>
				<div class='Arrange-sizeFill'>
					<Link href={getLink(props)}>
						<h3 class='SearchFormRow-title'>{artname}</h3>
						<Price class='SearchFormRow-prices' data={data}/>
					</Link>
				</div>
			</article>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
