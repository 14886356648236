/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import page from 'page';
import {Cell, Grid} from 'viskan-deku-grid';
import Tabs from 'deku-tabs';
import AccountNav from '../account-nav';
import Profile from './profile';
import Orders from './orders';
import Subscriptions from './subscriptions';

const propTypes = {
	customer: {
		source: 'customer'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const afterUpdate = ({props}) => {
	const {customer, pathPrefix} = props;
	const {logged_in} = customer;

	if (!logged_in) {
		page(`${pathPrefix}login`);
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props}) => {
	const {customer, getTranslation, pathPrefix} = props;
	const {logged_in} = customer;
	const items = [{
		content: <Profile/>,
		heading: getTranslation('account.profile')
	}, {
		content: <Subscriptions/>,
		heading: getTranslation('account.subscriptions')
	}, {
		content: <Orders pathPrefix={pathPrefix}/>,
		heading: getTranslation('account.order_history')
	}];

	if (!logged_in) {
		return <noscript/>;
	}

	return (
		<div class='Container'>
			<div class='Account'>
				<Grid gutter>
					<Cell size='1of6'>
						<AccountNav active={2} loggedIn/>
					</Cell>
					<Cell size='5of6'>
						<Tabs items={items}/>
					</Cell>
				</Grid>
			</div>
		</div>
	);
};

export default {propTypes, afterUpdate, render, shouldUpdate};
