/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	class: {
		type: 'string'
	},
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	sku: {
		type: 'object'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getPrice, getTranslation, sku, styleClasses} = props;
	const price = v12.article.price(data, sku);
	const classes = {
		Price: true,
		'Price--sale': price.price_sales < price.price_org
	};

	return (
		<div class={['Prices', props.class, styleClasses && styleClasses.Price]} data-style-attr='Price'>
			{price.more && <span class='Price-fromLabel'>{getTranslation('article.price_from_label')}</span>}
			<span class={classes}>
				{getPrice(price.price_sales, price.price_sales_exvat)}
			</span>
			{price.price_sales < price.price_org && <span class='Price Price--org'>{getPrice(price.price_org, price.price_org_exvat)}</span>}
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
