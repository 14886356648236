/** @jsx dom */
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import Attributes from './attributes';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const remove = props => props.onRemove({art_id: props.data.article.art_id});

const handleCheckboxChange = props => () => {
	props.toggleCheckboxInput(props.data.article.art_id);

	if (props.isOpen) {
		remove(props);
	}
};

const handleTextChange = props => e => {
	if (e.target.value.length === 0) {
		remove(props);
		return;
	}

	props.onAdd({
		art_id: props.data.article.art_id,
		attr1_id: props.active.attr1_id || 0,
		attr2_id: props.active.attr2_id || 0,
		attr3_id: props.active.attr3_id || 0,
		personalization_text: e.target.value,
		qty: 1
	});
};

const getAttribute = props => {
	if (!props.isOpen || !props.data.article.articleAttributes || props.data.article.articleAttributes.length < 2) {
		return <noscript/>;
	}

	return (
		<div class='CMS-AddonCheckboxText-attributes'>
			<Attributes attribute='attr1' article={props.data.article} active={props.active} setSku={props.setSku}/>
			<Attributes attribute='attr2' article={props.data.article} active={props.active} setSku={props.setSku}/>
			<Attributes attribute='attr3' article={props.data.article} active={props.active} setSku={props.setSku}/>
		</div>
	);
};

const render = ({props}) => {
	const name = `CMS-AddonCheckboxText-${props.data.article.art_id}`;

	return (
		<div class='CMS-AddonCheckboxText'>
			<div class='CMS-AddonCheckbox'>
				<input class='CMS-AddonCheckboxText-checkbox' type='checkbox' id={name} name={name} onChange={handleCheckboxChange(props)}/>
				<label class='CMS-AddonCheckboxText-label' for={name}>
					<span class='CMS-AddonCheckboxText-name'>{props.data.article.artname}</span>
				</label>
				<span class='CMS-AddonCheckboxText-price'>+{props.getPrice(props.active.articlePrice.price_sales)}</span>
			</div>
			{props.isOpen &&
				<div class='CMS-AddonCheckboxText-toggled'>
					<input class='CMS-AddonCheckboxText-input' type='text' placeholder='Text...' onInput={debounce(handleTextChange(props), 500)}/>
					{getAttribute(props)}
				</div>
			}
		</div>
	);
};

export default {propTypes, render};
