import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import {getTranslator} from '../sources/translator';
import {update} from '../shopcart/actions';
import {fetch, getDeliveryPoints, setChosenCity, setChosenDeliveryPoint} from './actions';
import Checkout from './components';

export default connect(
	state => ({
		checkout: state.checkout,
		contentManagementActive: state.site.cmsTokenSet,
		countryCode: state.site.country_code,
		getTranslation: getTranslator(state),
		analyticsClientId: state.site.analyticsClientId,
		isPerson: state.customer.person_flg,
		languageId: state.site.lang_id,
		pathPrefix: state.router.pathPrefix,
		paymentMethods: state.checkout.paymentMethods,
		pendingActions: state.checkout.pendingActions,
		query: state.router.pathname,
		queryParameters: state.router.query,
		shopcart: state.shopcart,
		shopcartAvailable: state.checkout.shopcartAvailable,
		specialParameters: state.checkout.specialParameters
	}),
	dispatch => ({
		categoryFetch: (query, options) => dispatch(categoryFetch(query, 'main', options)),
		fetch: options => dispatch(fetch(options)),
		getDeliveryPoints: payload => dispatch(getDeliveryPoints(payload)),
		setChosenCity: payload => dispatch(setChosenCity(payload)),
		setChosenDeliveryPoint: payload => dispatch(setChosenDeliveryPoint(payload)),
		updateShopcart: (data, dontOpen) => dispatch(update(data, dontOpen))
	})
)(Checkout);
