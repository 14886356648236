/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const name = 'CMS-ArticleCampaign';

const render = ({props}) => {
	if (props.campaigns.length === 0) {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class={[name, props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			{props.heading && <div class={[name.concat('-heading'), props.styleClasses.Heading]} data-style-attr='Heading'>{props.heading}</div>}
			<div class={[name.concat('-list'), props.styleClasses.List]} data-style-attr='List'>
				{props.campaigns.map(campaign => (
					<div class={[name.concat('-item'), props.styleClasses.Item]} data-style-attr='Item'>{campaign.camp_desc}</div>
				))}
			</div>
		</div>
	), props);
};

export default {name, render};
