export default node => ({
	type: 'ARTICLES',
	name: 'Articles',
	allowedSections: ['main', 'header'],
	disallowedChildren: ['*', '!BLURB_*', '!MAIN_NAVIGATION_*'],
	category: 'Articles',
	props: [{
		name: 'source',
		inputType: 'select',
		label: 'Source to load articles from',
		default: 'currentCategory',
		values: [{
			label: 'Current category',
			value: 'currentCategory'
		}, {
			label: 'Search result',
			value: 'searchResult'
		}, {
			label: 'Favorites',
			value: 'favorites'
		}]
	}, node && node.props.source === 'favorites' && {
		name: 'noFavoritesSavedText',
		inputType: 'text',
		label: 'No favorites saved text',
		default: ''
	}, node && node.props.source !== 'currentCategory' && {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'quickbuy',
		inputType: 'checkbox',
		label: 'Quick buy',
		default: false
	}, {
		name: 'favorite',
		inputType: 'checkbox',
		label: 'Toggle favorite',
		default: false
	}, node && node.props.quickbuy && {
		name: 'popupForSingleSku',
		inputType: 'checkbox',
		label: 'Show popup for single SKU articles',
		default: false
	}, {
		name: 'noBlurbs',
		inputType: 'checkbox',
		label: 'No blurbs grid',
		default: false
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: 'none',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}].filter(Boolean),
	hiddenProps: [{
		label: 'List settings',
		props: [{
			name: 'size',
			inputType: 'slider',
			label: 'Number of articles to load',
			default: 20,
			min: 1,
			max: 50
		}, {
			name: 'pagination',
			inputType: 'checkbox',
			label: 'Load articles on scroll',
			default: true
		}]
	}, {
		label: 'Columns settings',
		props: [{
			name: 'xsm',
			inputType: 'slider',
			label: 'Columns mobile (small)',
			default: '2'
		}, {
			name: 'sm',
			inputType: 'slider',
			label: 'Columns mobile',
			default: '2'
		}, {
			name: 'md',
			inputType: 'slider',
			label: 'Columns tablet',
			default: '3'
		}, {
			name: 'lg',
			inputType: 'slider',
			label: 'Columns desktop',
			default: '4'
		}, {
			name: 'xlg',
			inputType: 'slider',
			label: 'Columns desktop (large)',
			default: '5'
		}]
	}]
});
