/** @jsx dom */
import {createAction} from 'redux-actions';
import dom from 'magic-virtual-element';
import findHashTags from 'find-hashtags';
import {
	INSTAGRAM_FEED_FETCH,
	INSTAGRAM_SET_ACTIVE_INDEX,
	INSTAGRAM_SET_CURRENT_INDEX,
	INSTAGRAM_SET_NEXT_INDEX,
	INSTAGRAM_SET_PREV_INDEX,
	INSTAGRAM_SET_MARKERS,
	INSTAGRAM_SHOW_MARKER_TEXT,
	INSTAGRAM_PAGINATION_NEXT,
	INSTAGRAM_POPUP_OPEN
} from '../../action-types';
import {PointMarker} from './';

export const feedFetch = createAction(INSTAGRAM_FEED_FETCH, ({artId, from, size}) => v12.util.http.post('/api/social/shares', {
	query: {from, size, art_id: artId}
}).then(({body}) => ({
	grams: body.hits,
	total: body.total,
	from
})));

export const setActiveIndex = createAction(INSTAGRAM_SET_ACTIVE_INDEX);
export const setCurrentIndex = createAction(INSTAGRAM_SET_CURRENT_INDEX);
export const setNextIndex = createAction(INSTAGRAM_SET_NEXT_INDEX);
export const setPrevIndex = createAction(INSTAGRAM_SET_PREV_INDEX);
export const setMarkers = createAction(INSTAGRAM_SET_MARKERS);
export const showMarkerText = createAction(INSTAGRAM_SHOW_MARKER_TEXT);
export const nextPagination = createAction(INSTAGRAM_PAGINATION_NEXT);
export const popupOpen = createAction(INSTAGRAM_POPUP_OPEN);

const getMediaByQuality = (media, quality) => media.find(x => x.quality === quality && x.type === 0);
const getVideo = media => media.find(x => x.type === 1 && x.quality === 3);
const replaceTags = (text, tag) => text.replace(tag, `<a href='https://www.instagram.com/explore/tags/${tag.replace('#', '')}/' target='_blank'>${tag.replace('#', '&#35;')}</a>`);

const getAllMarkers = articles => articles.filter(x => x.markers[0]).map((article, i) => ({
	...article.markers[0],
	number: i + 1,
	text: article.metainfo.artname
}));

const getMarker = (marker, visibleMarkers, visibleText) => {
	const {type} = marker;

	if (type.toLowerCase() === 'point') {
		return <PointMarker data={marker} visibleMarkers={visibleMarkers} visibleText={visibleText}/>;
	}
};

const getSize = props => {
	const currentBreakpoint = props.breakpoint.breakpointOverride || props.breakpoint.breakpoint;

	const sizesValues = {
		large: (parseInt(props.columnsDesktop, 10) * parseInt(props.rowsDesktop, 10)) * 2,
		medium: (parseInt(props.columnsTablet, 10) * parseInt(props.columnsTablet, 10)) * 2,
		small: (parseInt(props.columnsMobile, 10) * parseInt(props.columnsMobile, 10)) * 2
	};

	const sizes = {
		xlg: props.sliderDesktop ? sizesValues.large : parseInt(props.xlg, 10) * 2,
		lg: props.sliderDesktop ? sizesValues.large : parseInt(props.xlg, 10) * 2,
		md: props.sliderTablet ? sizesValues.medium : parseInt(props.md, 10) * 2,
		sm: props.sliderTablet ? sizesValues.small : parseInt(props.sm, 10) * 2,
		xsm: props.sliderTablet ? sizesValues.small : parseInt(props.xsm, 10) * 2
	};

	return sizes[currentBreakpoint] || 16;
};

const parseTags = text => {
	const hashTags = findHashTags(text);

	return hashTags.reduce(replaceTags, text);
};

export {getAllMarkers, getMarker, getMediaByQuality, getSize, getVideo, parseTags};
