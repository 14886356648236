export default {
	type: 'ARTICLE_PRICE',
	name: 'Price',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
