/** @jsx dom */
import dom from 'magic-virtual-element';
import Zoom from '@viskan/deku-inline-zoom';
import {generateMediaURL} from '@viskan/streamline-utilities';
import {handleImageClick, getSliderCommon} from '../common';

const sliderZoomContent = (imageArray, props) => imageArray.map(image => {
	const key = props.cmsLoaded ? `${new Date().getTime()}-zoom` : `${props.active.attr1_id}-${props.article.art_id}-zoom`;
	const src = generateMediaURL(image.filename, {scale: 'normal'}, props.mediaConfig);
	const zoom = generateMediaURL(image.filename, {scale: 'large'}, props.mediaConfig);

	return (
		<figure key={key} class={['CMS-ArticleMedia-Zoom', props.styleClasses['Zoom Wrapper']]} data-style-attr='Zoom Wrapper'>
			{image.path &&
				<div class={['CMS-ArticleMedia-mainImage', props.styleClasses['Main Image']]} onClick={props.zoomPopup && handleImageClick(props)} data-style-attr='Main Image'>
					<Zoom key={`${props.currentMedia.path}-${props.zoomDelay}`} src={src} zoom={zoom} alt={image.media_alt_desc || props.article.artname} delay={parseInt(props.zoomDelay, 10)}/>
				</div>
			}
		</figure>
	);
});

const getSliderZoom = props => getSliderCommon(props, sliderZoomContent);

export default getSliderZoom;
