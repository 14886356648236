/** @jsx dom */
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {QtySelect} from '@viskan/estore-components';
import Link from '../../link';
import {Image} from '../../media';
import Addon from './addon';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	site: {
		source: 'site'
	}
};

const getAddons = data => data.map(x => <Addon data={x}/>);
const onQtyChange = (row_id, editCart, dontOpen, onEmpty) => qty => qty < 1 ? onEmpty() : debounce(editCart(row_id, qty, dontOpen), 500, true);

const getAttrs = (data, getTranslation) => {
	const rows = [1, 2, 3].filter(x => data[`attr${x}_id`]).map(x => (
		<tr key={`attr${x}_id`}>
			<th>{getTranslation(`article.attr${x}`)}</th>
			<td>{data[`attr${x}_code`]}</td>
		</tr>
	));

	if (rows.length === 0) {
		return <noscript/>;
	}

	return <table class='ShopcartRow-attr'>{rows}</table>;
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, editCart, getPrice, getTranslation, removeFromCart, site} = props;
	const {addons, artname, attr1_id, link_friendly_name, media_filename, offer_desc, qty, row_id, rowsum_to_pay, rowsum_to_pay_exvat} = data;
	const {articleUrlPrefix} = site;
	const onRemove = () => removeFromCart(data, qty, true);

	return (
		<article class='ShopcartRow Shopcart-card'>
			<div class='ShopcartRow-columns'>
				<div class='ShopcartRow-article'>
					<div class='ShopcartRow-image'>
						<Link href={link_friendly_name && `/${articleUrlPrefix}/${link_friendly_name}${attr1_id ? `?attr1_id=${attr1_id}` : ''}`}>
							<figure>
								<Image source={media_filename} height='64' width='64' scale='thumbnail' alt={artname}/>
							</figure>
						</Link>
					</div>
					<div class='ShopcartRow-articleDetails'>
						<h3 class='heading'>
							{artname}
						</h3>
						{getAttrs(data, getTranslation)}
						{Boolean(addons.length) && <div class='ShopcartRow-addons'>{getAddons(addons)}</div>}
					</div>
				</div>
				<div class='ShopcartRow-quantity'>
					<QtySelect qty={qty} min='0' onChange={onQtyChange(row_id, editCart, true, onRemove)}/>
				</div>
				<div class='ShopcartRow-impact'>
					<div class='ShopcartRow-price'>{getPrice(rowsum_to_pay, rowsum_to_pay_exvat)}</div>
					{offer_desc && <div class='ShopcartRow-offer' innerHTML={offer_desc}/>}
				</div>
				<div class='ShopcartRow-impact'>
					<i class='Shopcart-item-remove Icon Icon--remove u-floatRight u-textRight' onClick={onRemove}/>
				</div>
			</div>
		</article>
	);
};

export default {propTypes, render, shouldUpdate};
