export default {
	type: 'SHOPCART_ROWS_FIELD',
	name: 'Rows field',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'artname',
		values: [{
			label: 'Article name',
			value: 'artname'
		}, {
			label: 'Article number',
			value: 'art_no'
		}, {
			label: 'Attribute 1',
			value: 'attr1_code'
		}, {
			label: 'Attribute 2',
			value: 'attr2_code'
		}, {
			label: 'Attribute 3',
			value: 'attr3_code'
		}, {
			label: 'Article Price',
			value: 'price_incvat'
		}, {
			label: 'Offer description',
			value: 'offer_desc'
		}, {
			label: 'Discount',
			value: 'discount'
		}, {
			label: 'Row summary price',
			value: 'rowsum_to_pay'
		}, {
			label: 'Article image',
			value: 'media_filename'
		}, {
			label: 'Quantity',
			value: 'qty'
		}]
	}, {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}]
	}, {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
