/** @jsx dom */
import dom from 'magic-virtual-element';
import * as embeddedApi from './api';
import EmbeddedSnippet from './embedded-snippet';

const name = 'CheckoutEmbedded';

const defaultProps = {
	interval: 500,
	isSuspended: false
};

const intervalUntil = (repeat, until, interval = 500) => {
	const intervalId = setInterval(() => {
		repeat();

		if (until()) {
			clearInterval(intervalId);
		}
	}, interval);

	return intervalId;
};

const createSetSuspension = api => ({isSuspended}) => {
	if (!api.isAvailable()) {
		console.warn('api is not available');
		return;
	}

	if (isSuspended) {
		return api.suspend();
	}

	api.resume();
};

const createSuspensionTimer = props => {
	const {params, type} = props;
	const api = embeddedApi[type](params);
	const setSuspension = createSetSuspension(api);

	setSuspension(props);

	if (!props.isSuspended) {
		return intervalUntil(() => setSuspension(props), api.isAvailable, props.interval);
	}

	return setInterval(() => setSuspension(props), props.interval);
};

const afterMount = ({id, props}, el, setState) => {
	const {interval, params, type} = props;
	const api = embeddedApi[type](params);

	if (api.onCheckoutReady) {
		intervalUntil(() => api.isAvailable() && api.onCheckoutReady(), api.isAvailable, interval);
	}

	setState({
		timer: createSuspensionTimer(props)
	});
};

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	if (props.isSuspended !== prevProps.isSuspended) {
		clearInterval(state.timer);
		setState({
			timer: createSuspensionTimer(props)
		});
	}
};

const beforeUnmount = ({state}) => clearInterval(state.timer);

const render = ({props}) => <EmbeddedSnippet class={name} type={props.type} params={props.params}/>;

export default {afterMount, afterUpdate, beforeUnmount, defaultProps, name, render};
