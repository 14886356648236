export default {
	type: 'ARTICLES_VIEW',
	name: 'Change View',
	allowedSections: ['main'],
	disallowedChildren: ['*', '!BLURB_*', '!MAIN_NAVIGATION_*'],
	category: 'Articles',
	props: [{
		name: 'defaultViewLabel',
		inputType: 'text',
		label: 'Default view label',
		default: 'Product view'
	}, {
		name: 'extendedViewLabel',
		inputType: 'text',
		label: 'Extended view label',
		default: 'Outfit view'
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: '-1',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}],
	hiddenProps: []
};
