export default {
	type: 'SHOPCART_SUMMARY_FIELD',
	name: 'Summary field',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'artname',
		values: [{
			label: 'Campaign code',
			value: 'camp_code'
		}, {
			label: 'Free carriage left',
			value: 'free_carriage_left'
		}, {
			label: 'Total quantity',
			value: 'tot_qty_wo_addons'
		}, {
			label: 'Price to pay',
			value: 'tot_to_pay'
		}, {
			label: 'VAT',
			value: 'tot_vat'
		}, {
			label: 'Total discount',
			value: 'total_discount'
		}, {
			label: 'Delivery carriage',
			value: 'wdel_carriage'
		}, {
			label: 'Payment carriage',
			value: 'wpay_carriage'
		}]
	}, {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}]
	}, {
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: ''
	}, {
		name: 'hideEmpty',
		inputType: 'checkbox',
		label: 'Hide zero values',
		default: true
	}, {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
