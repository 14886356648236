/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {Image} from '../../../../media';

const getThumbnails = (props, thumbnails) => {
	if (!props) {
		return <noscript key={`${props.active.attr1_id}-${props.article.art_id}-nothumbnails`}/>;
	}

	const items = thumbnails.map((thumb, index) => {
		const classes = {
			'CMS-ArticleThumbnails-thumbnail': true,
			'CMS-ArticleThumbnails-thumbnail--active': thumb.media_id === props.currentMedia.media_id,
			[props.styleClasses.Thumbnail]: true
		};

		const onClick = () => props.setCurrentMedia({
			...thumb,
			filename: thumb.filename,
			path: thumb.path,
			index,
			prevIndex: props.currentMedia.index
		});

		return (
			<figure key={thumb.path} class={classes} onClick={onClick} data-style-attr='Thumbnail'>
				<Image class={props.styleClasses['Thumbnail Image']} source={thumb.filename} scale='thumbnail' alt={thumb.media_alt_desc || props.article.artname} data-style-attr='Thumbnail Image'/>
			</figure>
		);
	});

	return (
		<div class={['CMS-ArticleThumbnails-thumbnails', `CMS-ArticleThumbnails-thumbnails--${props.orientation}`, props.styleClasses.Thumbnails]} data-style-attr='Thumbnails'>
			{items}
		</div>
	);
};

const afterMount = ({props}) => props.article.art_id && props.setDefaultCurrentMedia(props);

const afterUpdate = ({props}, prevProps) => props.article.art_id && (props.active.attr1_id !== prevProps.active.attr1_id || props.article.art_id !== prevProps.article.art_id) && props.setDefaultCurrentMedia(props);

const render = ({props}) => {
	if (props.article.art_id) {
		const thumbnails = v12.article.medias(props.article, {
			sku: {
				attr1_id: props.active.attr1_id
			},
			size: ''
		});

		if (thumbnails && thumbnails.length < 2) {
			return cmsUtils.createCmsComponent(<noscript/>, props);
		}

		return cmsUtils.createCmsComponent((
			<div class={['CMS-ArticleThumbnails', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
				{getThumbnails(props, thumbnails)}
			</div>
		), props);
	}

	return cmsUtils.createCmsComponent(<noscript/>, props);
};

export default {afterMount, afterUpdate, render};
