/** @jsx dom */
import bus from '@viskan/bus';
import dom from 'magic-virtual-element';
import page from 'page';

const afterMount = ({props}) => {
	const {pathPrefix, redirect} = props;

	bus.emit('loading:open');

	v12.customer.logout({copyCart: true})
		.then(() => bus.emit('shopcart:get'))
		.then(() => bus.emit('logout'))
		.then(() => bus.emit('loading:close'))
		.then(() => page(redirect || pathPrefix))
		.catch(v12.util.error);
};

const render = () => {
	return <noscript/>;
};

export default {afterMount, render};
