/** @jsx dom */
import clickOutside from 'click-outside';
import dom from 'magic-virtual-element';
import {Dropdown, DropdownMenu} from '@viskan/estore-components';
import {utils as cmsUtils} from '@viskan/cms-components';
import getIcon from '../../utils/actions';
import {Remarket, Row, Summary} from '../';

const getRows = data => data.map(x => <Row key={x.row_id} data={x}/>);

const afterRender = ({props}, el) => {
	const {autoCloseTimer, close, isOpen} = props;

	requestAnimationFrame(() => clickOutside(document.querySelector('.QuickCart'), () => isOpen && close()));

	if (autoCloseTimer) {
		setTimeout(() => {
			if (el === document.querySelector('.QuickCart:hover')) {
				return;
			}

			close(true);
		}, autoCloseTimer);
	}
};

const render = ({props}) => {
	const {isOpen, toggle, shopcart, site} = props;
	const {head, rows} = shopcart;
	const {tot_qty_wo_addons} = head;
	const country_code = site.country_code.toLowerCase();
	const classes = {
		QuickCartDropdownMenu: true,
		'is-open': isOpen
	};

	return cmsUtils.createCmsComponent((
		<div class='CMS-QuickCart'>
			<Dropdown class='QuickCart'>
				<span class='QuickCart-icon' onClick={toggle}>
					{props.image ? <img src={props.image} alt=''/> : getIcon(props)}
					{Boolean(tot_qty_wo_addons) && <span class='QuickCart-qty'>{tot_qty_wo_addons}</span>}
				</span>
				<DropdownMenu class={classes} alignRight>
					<div>{Boolean(rows.length) && getRows(rows)}</div>
					<Summary data={shopcart} onClick={toggle}/>
					{country_code !== 'fi' && <Remarket/>}
				</DropdownMenu>
			</Dropdown>
		</div>
	), props);
};

export default {afterRender, render};
