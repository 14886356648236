/** @jsx dom */
import dom from 'magic-virtual-element';

const handleSetStore = (store, props) => () => props.setStore(store);

const getResultItems = props => props.searchResult.map(store => (
	<div key={store.storeId} class={['CMS-StoreBalanceSearchResult-store', props.styleClasses.Store]} onClick={handleSetStore(store, props)} data-style-attr='Store'>
		<div class={props.styleClasses['Search Store Name']} data-style-attr='Search Store Name'>{store.storeName}</div>
		<div class={props.styleClasses['Search Store Address']} data-style-attr='Search Store Address'>{store.address.addressLine1}, {store.address.zipcode} {store.address.city}</div>
	</div>
));

const render = ({props}) => {
	if (props.searchResult.length === 0) {
		return <noscript/>;
	}

	return (
		<div class={['CMS-StoreBalanceSearchResult', props.styleClasses['Search Results']]} data-style-attr='Search Results'>
			{getResultItems(props)}
		</div>
	);
};

export default {render};
