/** @jsx dom */
import dom from 'magic-virtual-element';
import {loadCSS} from 'fg-loadcss';
import cmsComponents from '@viskan/cms-components';
import streamlineCmsComponents from '../../cms-components';

const components = {
	...cmsComponents,
	...streamlineCmsComponents
};

const afterMount = ({props}) => {
	const {cmsTokenSet, cmsUrl} = props;

	if (!cmsTokenSet) {
		return;
	}

	loadCSS(`${cmsUrl}/index.css`);
	props.requestCms(cmsUrl);
};

const render = ({props}) => {
	const {loaded} = props;

	if (loaded) {
		const Comp = window._streamlineCms;
		return <Comp components={components}/>;
	}

	return <noscript/>;
};

export default {render, afterMount};
