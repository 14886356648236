/** @jsx dom */
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	}
};

const render = ({props}) => {
	const {data} = props;

	if (!data || data.length === 0) {
		return <noscript/>;
	}

	const rows = data.map(x => <p class='ShopcartSummary-offer'>{x.offer_desc}</p>);

	return (
		<div class='ShopcartOffers'>
			{rows}
		</div>
	);
};

export default {propTypes, render};
