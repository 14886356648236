import {connect} from '@viskan/deku-redux';
import {remove, add} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	(state, props) => ({
		artNo: props.artNo || state.article.article.art_no,
		favorites: state.articles.favorites.articles,
		favoritesArtNos: state.articles.favorites.artNos,
		variant: props.variant !== undefined ? props.variant : state.article.active.attr1_id
	}),
	dispatch => ({
		remove: (artNo, variant) => dispatch(remove(artNo, variant)),
		add: (artNo, variant) => dispatch(add(artNo, variant))
	})
)(Component);

export default {component, config};
