import bus from '@viskan/bus';
import store from 'store2';

const get = (app, logout = false) => {
	v12.customer.get()
		.then(data => app.set('customer', data))
		.then(() => logout && store.remove('vs-person_flg'))
		.then(() => logout && store.remove('vs-email'))
		.catch(error => bus.emit('popup:open', error.message));
};

export default app => {
	get(app);

	bus.on('customer:get', () => get(app));
	bus.on('customer:update', data => app.set('customer', data));
	bus.on('logout', () => get(app, true));
	app.set('customer', window._streamline.customerOnLoadStatus);
};
