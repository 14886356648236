/** @jsx dom */
import {Cell, Grid} from 'viskan-deku-grid';
import dom from 'magic-virtual-element';
import {scroller} from 'windowsill';
import {Post} from '../';

const propTypes = {
	class: {
		type: 'string'
	},
	posts: {
		type: 'array'
	}
};

const getPosts = (columns, gutter, posts) => posts.map((x, index) => {
	return (
		<Cell key={x.creationDate} class={gutter && 'InstagramFeed-cell--spacing'} xsm-size={columns.xsm === '1' ? 'full' : `1of${columns.xsm}`} sm-size={columns.sm === '1' ? 'full' : `1of${columns.sm}`} md-size={columns.md === '1' ? 'full' : `1of${columns.md}`} lg-size={columns.lg === '1' ? 'full' : `1of${columns.lg}`} xlg-size={columns.xlg === '1' ? 'full' : `1of${columns.xlg}`}>
			<Post posts={posts} index={index}/>
		</Cell>
	);
});

const listenForScrollPastBottom = (onScrollPastBottom, total, el) => {
	let tick;

	if (!onScrollPastBottom) {
		return;
	}

	scroller.unbind();
	scroller.addListener(() => {
		const bottom = el.offsetTop + el.offsetHeight;
		const pos = window.scrollY + Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		if (!tick) {
			requestAnimationFrame(() => {
				tick = null;

				if (pos < bottom) {
					return;
				}

				if (el.getElementsByClassName('Grid-cell').length === total) {
					return;
				}

				onScrollPastBottom();
			});
		}

		tick = true;
	});

	scroller.bind();
};

const beforeUnmount = () => {
	scroller.unbind();
};

const afterMount = ({props}, el) => listenForScrollPastBottom(props.onScrollPastBottom, props.total, el);

const render = ({props}) => {
	const {columns, gutter, posts} = props;

	return (
		<div class='InstagramFeed'>
			<Grid class={gutter && 'InstagramFeed-grid--spacing'}>
				{getPosts(columns, gutter, posts)}
			</Grid>
		</div>
	);
};

export default {beforeUnmount, propTypes, render, afterMount};
