import imagesloaded from 'imagesloaded';

let clicks = 0;
let scroll = 0;

const reset = parentNode => {
	scroll = 0;
	clicks = 0;
	const img = parentNode.querySelector('.InlineZoom-image');
	const zoomedImg = parentNode.querySelector('.InlineZoom-zoomed');

	parentNode.style.height = 'auto';
	parentNode.style.width = 'auto';
	parentNode.classList.remove('InlineZoom--active');

	img.style.visibility = 'visible';
	zoomedImg.style.visibility = 'hidden';
};

const triggerZoom = (e, parentNode, img, zoomedImg, type, sizeFactor) => {
	const scrollOrZoom = type === 'scroll' ? scroll : clicks;

	img.style.visibility = 'hidden';
	zoomedImg.style.visibility = 'visible';
	parentNode.classList.add('InlineZoom--active');

	zoomedImg.style.height = `${img.offsetHeight + (scrollOrZoom * sizeFactor)}px`;
	zoomedImg.style.width = `${img.offsetHeight + (scrollOrZoom * sizeFactor)}px`;

	parentNode.style.height = `${img.offsetHeight}px`;
	parentNode.style.width = `${img.offsetWidth}px`;

	const x = e.clientX - parentNode.getBoundingClientRect().left;
	const y = e.clientY - parentNode.getBoundingClientRect().top;
	const multiWidth = (zoomedImg.offsetWidth - parentNode.offsetWidth) / parentNode.offsetWidth;
	const multiHeight = (zoomedImg.offsetHeight - parentNode.offsetHeight) / parentNode.offsetHeight;

	zoomedImg.style.position = 'absolute';
	zoomedImg.style.maxWidth = 'none';
	zoomedImg.style.left = `-${x * multiWidth}px`;
	zoomedImg.style.top = `-${y * multiHeight}px`;
};

const onClick = ({parentNode}) => e => {
	if (scroll > 0) {
		reset(parentNode);
		return false;
	}
	clicks++;
	if (clicks === 3) {
		reset(parentNode);
		clicks = 0;
		return false;
	}
	const img = parentNode.querySelector('.InlineZoom-image');
	const zoomedImg = parentNode.querySelector('.InlineZoom-zoomed');

	zoomedImg.src = zoomedImg.dataset.src;
	imagesloaded(zoomedImg, () => {
		triggerZoom(e, parentNode, img, zoomedImg, 'clicks', 350);
	});
};

const onScroll = (parentNode, e) => {
	const img = parentNode.querySelector('.InlineZoom-image');
	const zoomedImg = parentNode.querySelector('.InlineZoom-zoomed');
	zoomedImg.src = zoomedImg.dataset.src;
	imagesloaded(zoomedImg, () => {
		triggerZoom(e, parentNode, img, zoomedImg, 'scroll', 50);
	});
};

const onWheel = ({parentNode}) => e => {
	e.preventDefault();
	if (e.wheelDelta <= 0) {
		// Scroll down
		if (scroll <= 0) {
			return false;
		}
		scroll--;
		onScroll(parentNode, e);
	} else {
		// Scroll up
		if (scroll >= 25) {
			return false;
		}
		scroll++;
		onScroll(parentNode, e);
	}
};

const onMouseMove = ({parentNode}) => e => {
	const img = parentNode.querySelector('.InlineZoom-zoomed');
	const multiWidth = (img.offsetWidth - parentNode.offsetWidth) / parentNode.offsetWidth;
	const multiHeight = (img.offsetHeight - parentNode.offsetHeight) / parentNode.offsetHeight;
	const x = e.clientX - parentNode.getBoundingClientRect().left;
	const y = e.clientY - parentNode.getBoundingClientRect().top;

	img.style.left = `-${x * multiWidth}px`;
	img.style.top = `-${y * multiHeight}px`;
};

export default el => {
	el.parentNode.addEventListener('click', onClick(el));
	el.parentNode.addEventListener('wheel', onWheel(el));
	el.parentNode.addEventListener('mousemove', onMouseMove(el));
};
