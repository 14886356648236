/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import LoginForm from '../../../account/login-form';

const render = ({props}) => cmsUtils.createCmsComponent((
	<div class='CMS-Login'>
		<LoginForm {...props}/>
	</div>
), props);

export default {render};
