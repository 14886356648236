import {connect} from '@viskan/deku-redux';
import Component from './components';
import Handler from './components/handler';
import {getIntValue, setBreakpoint} from './actions';

export const BreakpointHandler = connect(
	({breakpoint}) => ({
		breakpoint: breakpoint.breakpoint,
		breakpointOverride: breakpoint.breakpointOverride
	}),
	dispatch => ({
		setBreakpoint: value => dispatch(setBreakpoint(value)),
		getIntValue: (value, breakpoints) => getIntValue(value, breakpoints)
	})
)(Handler);

export default connect(
	({breakpoint}) => ({
		breakpoint: breakpoint.breakpoint,
		breakpointOverride: breakpoint.breakpointOverride
	}),
	() => ({
		getIntValue: (value, breakpoints) => getIntValue(value, breakpoints)
	})
)(Component);
