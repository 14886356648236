import ARTICLE_ADDONS from './article/addons';
import ARTICLE_ATTRIBUTE from './article/attribute';
import ARTICLE_BUY_BUTTON from './article/buy-button';
import ARTICLE_CAMPAIGNS from './article/campaigns';
import ARTICLE_FAVORITE from './article/favorite';
import ARTICLE_FIELD from './article/field';
import ARTICLE_MEDIA from './article/media';
import ARTICLE_PRICE from './article/price';
import ARTICLE_BACK_IN_STOCK from './article/back-in-stock';
import ARTICLE_PROPERTIES from './article/properties';
import ARTICLE_QUANTITY from './article/quantity';
import ARTICLE_RELATED from './article/related';
import ARTICLE_STORE_BALANCE from './article/store-balance';
import ARTICLE_SUBSCRIPTIONS from './article/subscriptions';
import ARTICLE_THUMBNAILS from './article/thumbnails';
import ARTICLES from './articles';
import ARTICLES_SORT from './articles-sort';
import ARTICLES_SLIDER from './articles-slider';
import ARTICLES_VIEW from './articles-view';
import B2B_B2C from './b2b-b2c';
import CATEGORY_IMPORT from './category-import';
import FILTER from './filter';
import FILTER_RESET from './filter-reset';
import FILTER_SLIDER from './filter-slider';
import FIND_STORE from './find-store';
import INSTAGRAM from './instagram';
import LOCALE from './locale';
import LOGIN_FORM from './login-form';
import MAIN_NAVIGATION_01 from './main-navigation';
import NEWSLETTER_FORM from './newsletter-form';
import SEARCH_FORM from './search-form';
import SHOPCART_ROWS from './shopcart/rows';
import SHOPCART_ROWS_ACTION from './shopcart/rows-action';
import SHOPCART_ROWS_FIELD from './shopcart/rows-field';
import SHOPCART_SUMMARY_FIELD from './shopcart/summary-field';
import SHOPCART_SAVE_FORM from './shopcart-save-form';
import QUICK_CART from './quick-cart';

export default {
	ARTICLE_ADDONS,
	ARTICLE_ATTRIBUTE,
	ARTICLE_BUY_BUTTON,
	ARTICLE_CAMPAIGNS,
	ARTICLE_FAVORITE,
	ARTICLE_FIELD,
	ARTICLE_MEDIA,
	ARTICLE_PRICE,
	ARTICLE_BACK_IN_STOCK,
	ARTICLE_PROPERTIES,
	ARTICLE_QUANTITY,
	ARTICLE_RELATED,
	ARTICLE_STORE_BALANCE,
	ARTICLE_SUBSCRIPTIONS,
	ARTICLE_THUMBNAILS,
	ARTICLES,
	ARTICLES_SORT,
	ARTICLES_SLIDER,
	ARTICLES_VIEW,
	B2B_B2C,
	CATEGORY_IMPORT,
	FILTER,
	FILTER_RESET,
	FILTER_SLIDER,
	FIND_STORE,
	INSTAGRAM,
	LOCALE,
	LOGIN_FORM,
	MAIN_NAVIGATION_01,
	NEWSLETTER_FORM,
	SEARCH_FORM,
	SHOPCART_ROWS,
	SHOPCART_ROWS_ACTION,
	SHOPCART_ROWS_FIELD,
	SHOPCART_SUMMARY_FIELD,
	SHOPCART_SAVE_FORM,
	QUICK_CART
};
