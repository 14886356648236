/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Discount, Row, Summary} from '../';
import ShopcartOffers from './offers';

const getRows = data => data.map(x => <Row key={x.row_id} data={x}/>);

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {getTranslation, shopcart} = props;
	const {rows, offers} = shopcart;

	return (
		<div class='Shopcart'>
			<h2 class='Heading'>
				<i class='Icon Icon--bag'/>
				{getTranslation('shopcart.label')}
			</h2>
			<div class='Shopcart-rowColumns'>
				<div class='Shopcart-rowColumn-article'>{getTranslation('global.product')}</div>
				<div class='Shopcart-rowColumn-quantity'>{getTranslation('global.quantity')}</div>
				<div class='Shopcart-rowColumn-impact'>{getTranslation('global.price')}</div>
				<div class='Shopcart-rowColumn-impact'/>
			</div>
			<div class='Shopcart-rows'>
				{Boolean(rows.length) && getRows(rows)}
			</div>
			<div class='Shopcart-global'>
				<div class='Shopcart-deductions Shopcart-footerSpace'>
					<Discount/>
					<ShopcartOffers data={offers.head}/>
				</div>
				<Summary data={shopcart}/>
			</div>
		</div>
	);
};

export default {render, shouldUpdate};
