export default {
	type: 'LOCALE',
	name: 'Locale',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'Locale',
	props: [{
		name: 'flags',
		inputType: 'checkbox',
		label: 'Show flags',
		default: true
	}]
};
