import bus from '@viskan/bus';
import cookie from 'component-cookie';
import intlCurrency from 'intl-currency';
import isEmpty from 'is-empty';
import queryString from 'query-string';
import {isPerson} from '../actions';
import translator from './translator';

const getCurrency = (locale, code) => val => {
	if (val !== 0 && isEmpty(val)) {
		return '';
	}

	return `${intlCurrency(val, {
		currency: code,
		locales: locale,
		minimumFractionDigits: val % 1 !== 0 ? 2 : 0,
		maximumFractionDigits: 2,
		style: 'currency'
	})}`;
};

export const getPrice = (person, getCurrency) => (inc, ex) => person || typeof ex !== 'number' ? getCurrency(inc) : getCurrency(ex);

const setMaintenance = (properties, opts) => {
	const {force} = opts || {};

	if (cookie('bypass') === 'true' || queryString.parse(location.search).bypass) {
		cookie('bypass', 'true', {maxage: 3600000, path: '/'});
		return;
	}

	const arr = properties.filter(x => x.propertykey_name_internal === 'storeprop.maintenance');

	if (force || (arr.length !== 0 && arr[0].propertyValues[0].propertyvalue_internal === 'true')) {
		location.href = '/maintenance';
	}
};

const handleSite = (app, site) => {
	return new Promise(resolve => {
		if (typeof site === 'undefined') {
			throw new TypeError('site is undefined');
		}

		const {currency_code, locale, properties} = site;

		if (properties && properties.length > 0) {
			setMaintenance(properties);
		}

		if (site.nostoId) {
			nostojs.init(site.nostoId);
		}

		app.set('getCurrency', getCurrency(locale, currency_code));
		app.set('getPrice', getPrice(isPerson(), getCurrency(locale, currency_code)));
		app.set('getTranslation', translator(site));
		app.set('site', site);

		v12.options.defaults.country_id = site.country_id;
		v12.options.defaults.currency_id = site.currency_id;
		v12.options.defaults.lang_id = site.lang_id;
		resolve();
	});
};

const getSite = app => {
	v12.util.http('/api/site')
		.then(({body}) => {
			handleSite(app, body);
			bus.emit('site:recieve', body);
		})
		.catch(v12.util.error);
};

export default app => {
	handleSite(app, window._streamline.siteContext)
		.catch(v12.util.error);

	bus.on('site:get', () => getSite(app));
};
