/** @jsx dom */
import bus from '@viskan/bus';
import dom from 'magic-virtual-element';

const initialState = () => {
	return {
		data: {
			text: null,
			action: null,
			actionText: null
		},
		open: false
	};
};

const data = {
	stack: [],
	timer: null
};

const process = setState => {
	if (data.stack.length === 0) {
		clearInterval(data.timer);
		data.timer = null;
		setState(initialState());
		return;
	}

	const item = data.stack.shift();

	setState({data: item, open: true});
	clearInterval(data.timer);
	data.timer = setInterval(() => process(setState), (item.duration || 3000) + 750);
};

const queue = (item, setState) => {
	data.stack.push(item);
	if (data.timer === null) {
		data.timer = setInterval(() => process(setState), 0);
	}
};

const getAction = (action, actionText) => {
	if (!action || !actionText) {
		return;
	}

	return <span class='Snackbar-action' onClick={action} innerHTML={actionText}/>;
};

const afterMount = ({props}, el, setState) => {
	bus.on('snackbar:open', data => queue(data, setState));
};

const afterRender = ({state}, el) => {
	const {data, open} = state;
	const {duration} = data;

	if (!open) {
		return;
	}

	el.classList.add('Snackbar--up');

	setTimeout(() => {
		el.classList.add('Snackbar--down');

		setTimeout(() => {
			el.classList.remove('Snackbar--down');
			el.classList.remove('Snackbar--up');
		}, 750);
	}, (duration || 3000) - 100);
};

const render = ({state}) => {
	const {data, open} = state;

	if (!open) {
		return <noscript/>;
	}

	const {action, actionText, text} = data;

	return (
		<div class={['Snackbar', data.class]}>
			<div class='Snackbar-body'>
				<span class='Snackbar-text' innerHTML={text}/>
				{getAction(action, actionText)}
			</div>
		</div>
	);
};

export default {initialState, afterMount, render, afterRender};
