import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../../sources/translator';
import config from './config';
import Component from './components';

const isActiveCampaign = active => campaign => {
	return active.attr1_id === campaign.attr1_id && active.attr2_id === campaign.attr2_id && active.attr3_id === campaign.attr3_id;
};

export const component = connect(
	state => ({
		campaigns: state.article.article.articleCampaigns.filter(isActiveCampaign(state.article.active)),
		getTranslation: getTranslator(state)
	})
)(Component);

export default {component, config};
