/** @jsx dom */
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	},
	site: {
		source: 'site'
	}
};

const getRows = ({currencyCode, rows}) => rows.map(row => (
	<div class='line_item'>
		<span class='product_id'>{row.meta.articleNumber}</span>
		<span class='quantity'>{row.quantity}</span>
		<span class='name'>{row.meta.articleName}</span>
		<span class='unit_price'>{row.value.unitPrice}</span>
		<span class='price_currency_code'>{currencyCode}</span>
	</div>
));

const shouldUpdate = ({props}, nextProps) => props.data.purchase_no !== nextProps.data.purchase_no;
const afterRender = ({props}) => (props.data.purchase_no && props.data.view_count === 0 && window.nostojs) && nostojs(api => api.sendTagging());

const render = ({props}) => {
	const {data, site} = props;
	const {customer, identifier, orderNumber} = data;

	if (!site.nostoId) {
		return <noscript/>;
	}

	return (
		<div class='nosto_purchase_order' style='display:none'>
			<span class='order_number'>{orderNumber || identifier}</span>
			<div class='buyer'>
				<span class='email'>{customer.email}</span>
				<span class='first_name'>{customer.firstName}</span>
				<span class='last_name'>{customer.lastName}</span>
			</div>
			<div class='purchased_items'>
				{getRows(data)}
			</div>
		</div>
	);
};

export default {afterRender, propTypes, render, shouldUpdate};
