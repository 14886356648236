/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const name = 'CMS-B2BB2C';

const render = ({props}) => {
	const {b2bText, b2cText, delimiter} = props;
	const handleClick = isPerson => () => isPerson !== props.isPerson && props.customerPatch({
		person_flg: isPerson
	}, false);

	return cmsUtils.createCmsComponent((
		<div class={[name, props.class]}>
			<span class={['CMS-B2BB2C-option', !props.isPerson && 'CMS-B2BB2C-option--current']} onClick={handleClick(false)}>{b2bText}</span>
			<span class='CMS-B2BB2C-delimiter'>{delimiter}</span>
			<span class={['CMS-B2BB2C-option', props.isPerson && 'CMS-B2BB2C-option--current']} onClick={handleClick(true)}>{b2cText}</span>
		</div>
	), props);
};

export default {name, render};
