import {
	CHECKOUT_FETCH_FULFILLED,
	CHECKOUT_FETCH_PENDING,
	CHECKOUT_FETCH_REJECTED,
	CHECKOUT_GET_DELIVERY_POINTS_FULFILLED,
	CHECKOUT_GET_DELIVERY_POINTS_PENDING,
	CHECKOUT_GET_DELIVERY_POINTS_REJECTED,
	CHECKOUT_SET_CHOSEN_CITY,
	CHECKOUT_SET_CHOSEN_DELIVERY_POINT,
	SHOPCART_ADD_FULFILLED,
	SHOPCART_ADD_PENDING,
	SHOPCART_ADD_REJECTED,
	SHOPCART_EDIT_FULFILLED,
	SHOPCART_EDIT_PENDING,
	SHOPCART_EDIT_REJECTED,
	SHOPCART_FETCH_FULFILLED,
	SHOPCART_FETCH_PENDING,
	SHOPCART_FETCH_REJECTED,
	SHOPCART_REMOVE_FULFILLED,
	SHOPCART_REMOVE_PENDING,
	SHOPCART_REMOVE_REJECTED
} from '../action-types';

const initialState = {
	deliveryMethods: [],
	paymentMethods: [],
	shopcartAvailable: undefined,
	specialParameters: {},
	shipping: {}
};

const pendingStatusReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOPCART_ADD_PENDING:
		case SHOPCART_EDIT_PENDING:
		case SHOPCART_FETCH_PENDING:
		case SHOPCART_REMOVE_PENDING:
		case CHECKOUT_FETCH_PENDING:
		case CHECKOUT_GET_DELIVERY_POINTS_PENDING: {
			return {
				...state,
				pendingActions: {
					...state.pendingActions,
					[action.type.replace(/_PENDING/, '')]: true
				}
			};
		}
		case SHOPCART_ADD_FULFILLED:
		case SHOPCART_EDIT_FULFILLED:
		case SHOPCART_FETCH_FULFILLED:
		case SHOPCART_REMOVE_FULFILLED:
		case CHECKOUT_FETCH_FULFILLED:
		case CHECKOUT_GET_DELIVERY_POINTS_FULFILLED:
		case SHOPCART_ADD_REJECTED:
		case SHOPCART_EDIT_REJECTED:
		case SHOPCART_FETCH_REJECTED:
		case SHOPCART_REMOVE_REJECTED:
		case CHECKOUT_FETCH_REJECTED:
		case CHECKOUT_GET_DELIVERY_POINTS_REJECTED: {
			return {
				...state,
				pendingActions: {
					...state.pendingActions,
					[action.type.replace(/_FULFILLED|_REJECTED/, '')]: false
				}
			};
		}
		default: {
			return state;
		}
	}
};

export default (state = initialState, action) => {
	const reducedLoadingState = pendingStatusReducer(state, action);

	switch (action.type) {
		case CHECKOUT_FETCH_FULFILLED: {
			return {
				...reducedLoadingState,
				deliveryMethods: action.payload.deliveryMethods,
				paymentMethods: action.payload.paymentMethods,
				shopcartAvailable: action.payload.shopcartAvailable,
				specialParameters: action.payload.params
			};
		}
		case CHECKOUT_SET_CHOSEN_CITY: {
			return {
				...reducedLoadingState,
				shipping: {
					...reducedLoadingState.shipping,
					chosenCity: action.payload
				}
			};
		}
		case CHECKOUT_SET_CHOSEN_DELIVERY_POINT: {
			return {
				...reducedLoadingState,
				shipping: {
					...reducedLoadingState.shipping,
					chosenDeliveryPoint: action.payload
				}
			};
		}
		case CHECKOUT_GET_DELIVERY_POINTS_FULFILLED: {
			return {
				...reducedLoadingState,
				shipping: {
					...reducedLoadingState.shipping,
					...action.payload
				}
			};
		}
		default: {
			return reducedLoadingState;
		}
	}
};
