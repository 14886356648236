/** @jsx dom */
import dom from 'magic-virtual-element';
import DomObserver from './';

const name = 'DomObserverOverlay';

const adapt = ({id, props}) => observerElement => requestAnimationFrame(() => {
	const overlayElement = document.getElementById(`${name}-${id}-overlay`);
	const targetElement = props.selector ? observerElement.querySelector(props.selector) : observerElement;

	if (!targetElement || !overlayElement) {
		if (overlayElement) {
			overlayElement.style.display = 'none';
		}

		return;
	}

	const componentRect = targetElement.getBoundingClientRect();
	const observerRect = observerElement.getBoundingClientRect();

	const metaRect = {
		top: componentRect.top - observerRect.top,
		left: componentRect.left - observerRect.left,
		right: observerRect.right - componentRect.right,
		bottom: observerRect.bottom - componentRect.bottom
	};

	overlayElement.style.display = 'block';
	overlayElement.style.position = 'absolute';
	overlayElement.style.top = `${metaRect.top}px`;
	overlayElement.style.left = `${metaRect.left}px`;
	overlayElement.style.bottom = `${metaRect.bottom}px`;
	overlayElement.style.right = `${metaRect.right}px`;
	overlayElement.style.height = `${metaRect.height}px`;
	overlayElement.style.width = `${metaRect.width}px`;
});

const render = component => (
	<div id={`${name}-${component.id}`} class={[name, component.props.class]}>
		<div id={`${name}-${component.id}-overlay`} class={`${name}-overlay`}>
			{component.props.overlayContent}
		</div>
		<DomObserver onChange={adapt(component)}>
			{component.props.children}
		</DomObserver>
	</div>
);

export default {name, render};
