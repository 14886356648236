import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';

const component = connect(
	({router, site}) => ({
		articleUrlPrefix: site.articleUrlPrefix,
		localeMode: site.localeMode,
		pathPrefix: `${router.pathPrefix}`,
		searchPath: site.searchPath
	})
)(Component);

export default {component, config};
