/** @jsx dom */
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import emailRegex from 'email-regex';
import {InputField, RadioField} from '@viskan/deku-form';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	isPerson: {
		source: 'isPerson'
	},
	saveEmail: {
		source: 'saveEmail'
	},
	title: {
		type: 'string'
	},
	validationError: {
		source: 'validationError'
	}
};

const getFields = (keys, {getTranslation, validationError}) => Object.keys(keys).map(el => {
	const {attrs, label, onChange, pattern, value, type} = keys[el];

	return (
		<Cell {...attrs}>
			<InputField key={label} id={el} pattern={pattern} onChange={onChange} type={type || 'text'} name={el} label={label} value={value} validationMessage={validationError(label, getTranslation)} required/>
		</Cell>
	);
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getTranslation, isPerson, saveEmail, title, validationError} = props;
	const keys = {
		cust_desc2: {
			label: getTranslation('customer.cust_desc2'),
			value: data.cust_desc2,
			attrs: {size: '1of2'}
		},
		cust_desc1: {
			label: getTranslation('customer.cust_desc1'),
			value: data.cust_desc1,
			attrs: {size: '1of2'}
		},
		inv_address: {
			label: getTranslation('customer.address'),
			value: data.inv_address
		},
		inv_zipcode: {
			label: getTranslation('customer.zipcode'),
			value: data.inv_zipcode,
			attrs: {size: '1of3'}
		},
		inv_city: {
			label: getTranslation('customer.city'),
			value: data.inv_city,
			attrs: {size: '2of3'}
		},
		email_1: {
			label: getTranslation('customer.email'),
			onChange: saveEmail,
			pattern: emailRegex().source,
			attrs: {size: '1of2'}
		},
		phone_2: {
			label: getTranslation('customer.phone'),
			attrs: {size: '1of2'}
		},
		password: {
			type: 'password',
			label: getTranslation('password.password'),
			value: data.password
		}
	};

	return (
		<section>
			<h2 class='Heading u-marginBottom'>{title}</h2>
			<RadioField name='person_flg' label={getTranslation('b2b.person')} value='1' checked={isPerson()}/>
			<RadioField name='person_flg' label={getTranslation('global.company')} value='0' checked={!isPerson()}/>
			<Grid gutter>
				{getFields(keys, {getTranslation, validationError})}
			</Grid>
		</section>
	);
};

export default {propTypes, render, shouldUpdate};
