/** @jsx dom */
import dom from 'magic-virtual-element';
import {NostoArticle} from '../../nosto';
import NotFound from '../../not-found';
import {Section} from '../../streamline-cms';

const afterMount = ({props}) => {
	const {club_id, fetchArticle, query, sku} = props;

	fetchArticle(query, sku, {club_id});

	if (!props.contentManagementActive) {
		props.getContent(props.tag, {
			languageId: props.languageId
		});
	}
};

const afterUpdate = ({props}, prevProps) => props.query !== prevProps.query && props.fetchArticle(props.query, props.sku, {club_id: props.club_id});
const beforeUnmount = ({props}) => props.resetArticle(false);

const render = ({props}) => {
	const {articleIsLoaded, contentManagementActive, loadMain, notFound, popup} = props;

	if (notFound) {
		return contentManagementActive ? <div class='u-textCenter'>404 Page not found</div> : <NotFound/>;
	}

	if (!articleIsLoaded) {
		return <noscript/>;
	}

	return (
		<article class='Article'>
			<Section sectionId='article'/>
			{(!popup && loadMain) && <Section sectionId='main'/>}
			<NostoArticle/>
		</article>
	);
};

export default {afterMount, afterUpdate, beforeUnmount, render};
