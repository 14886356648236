import {connect} from '@viskan/deku-redux';
import * as shopcart from '../../../shopcart/actions';
import {close as closePopup} from '../../../popup-redux/actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		active: state.article.active,
		addons: state.article.addons,
		article: state.article.article,
		quantity: state.article.quantity,
		popupIsOpen: state.popup.open
	}),
	dispatch => ({
		addToCart: (data, sku, addons, quantity) => dispatch(shopcart.addItem(data, sku, addons, quantity)),
		closePopup: () => dispatch(closePopup())
	})
)(Component);

export default {component, config};
