import {CLOSE_QUICK_CART, TOGGLE_QUICK_CART} from '../../action-types';

export const close = autoClosed => dispatch => dispatch({
	autoClosed,
	type: CLOSE_QUICK_CART
});

export const toggle = () => dispatch => dispatch({
	type: TOGGLE_QUICK_CART
});
