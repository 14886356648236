export default {
	type: 'SHOPCART_ROWS_ACTION',
	name: 'Row Action',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	props: [{
		name: 'action',
		inputType: 'select',
		label: 'Action',
		default: 'remove',
		values: [{
			label: 'Remove item',
			value: 'remove'
		}, {
			label: 'Increase quantity',
			value: 'increase'
		}, {
			label: 'Decrease quantity',
			value: 'decrease'
		}]
	}, {
		name: 'icon',
		inputType: 'icon',
		label: 'Icon',
		default: false
	}, {
		name: 'iconSize',
		inputType: 'text',
		label: 'Icon size',
		default: '2em'
	}, {
		name: 'iconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#000'
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
