/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {DropdownMenu} from '@viskan/estore-components';
import {Grid, Cell} from 'viskan-deku-grid';
import Link from '../../../link';

const propTypes = {
	data: {
		type: 'array'
	}
};

const getItems = (arr, depth = 0, styles) => {
	return arr.map(x => {
		if (x.hidden) {
			return null;
		}

		return (
			<li key={x.categoryId} class={depth === 0 && 'MainNavDropdown-item'}>
				<Link href={`/${x.linkFriendlyName}`} style={styles}>
					{x.categoryName}
				</Link>
				{(x.children && Boolean(x.children.length)) && <ul>{getItems(x.children, depth + 1)}</ul>}
			</li>
		);
	});
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data} = props;
	const children = props.parentProps.children.filter(x => parseInt(x.attributes.categoryLinkId, 10) === props.parent.categoryId);
	const hasChildren = children.length !== 0;

	return (
		<DropdownMenu class={['MainNavDropdown', props.class]}>
			<div class='Container'>
				<Grid>
					<Cell size={hasChildren ? '1of2' : 'full'}>
						<ul class='u-floatLeft u-textLeft'>
							{getItems(data)}
						</ul>
					</Cell>
					{hasChildren && (
						<Cell size='1of2'>
							<div class='CMS-Component-dynamicSection'>
								{children}
							</div>
						</Cell>
					)}
				</Grid>
			</div>
		</DropdownMenu>
	);
};

export default {propTypes, render, shouldUpdate};
