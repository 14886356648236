import {connect} from '@viskan/deku-redux';
import {fetch} from './actions';
import Component from './components';
import config from './config';

const component = connect(
	state => ({
		data: state.categoryImport.data
	}),
	dispatch => ({
		fetch: query => dispatch(fetch(query))
	})
)(Component);

export default {component, config};
