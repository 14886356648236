import {CMS_FETCH_FULFILLED} from '../action-types';

const initialState = {
	loaded: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CMS_FETCH_FULFILLED: {
			return {
				...state,
				loaded: action.payload.loaded
			};
		}
		default: {
			return state;
		}
	}
};
