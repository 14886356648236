import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import {fetchArticle, resetArticle} from './actions';
import Component from './components';

export default connect(
	({app, article, site}) => ({
		articleIsLoaded: Boolean(article.article.art_id),
		club_id: site.club_id,
		contentManagementActive: site.cmsTokenSet,
		languageId: site.lang_id,
		loadMain: article.loadMain,
		notFound: app.category.article.notFound || article.notFound,
		tag: site.cmsSettings.tags.article
	}),
	dispatch => ({
		fetchArticle: (query, opts) => dispatch(fetchArticle(query, opts)),
		getContent: (tag, options) => dispatch(categoryFetch(tag, 'article', {...options, key: 'tag'})),
		resetArticle: () => dispatch(resetArticle())
	})
)(Component);
