/** @jsx dom */
import dom from 'magic-virtual-element';
import * as embeddedApi from './api';

const name = 'CheckoutEmbeddedSnippet';

const afterMount = ({id, props}, el) => {
	const {params, type} = props;
	const api = embeddedApi[type](params);
	api.appendScript(id, el, `${name}-iframe-${id}`);
};

const render = ({id, props}) => (
	<div class={[name, `${name}--${props.type}`]}>
		<div id={`${name}-iframe-${id}`}/>
	</div>
);

export default {afterMount, name, render};
