/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {SubMenu} from '@viskan/estore-components';
import {url} from '@viskan/streamline-utilities';

const propTypes = {
	active: {
		type: 'number'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	loggedIn: {
		type: 'boolean'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const getMenu = ({getTranslation, localeMode, loggedIn, prefix}) => {
	const getPath = path => url.getLocaleFriendlyPath(path, localeMode, prefix).slice(1);

	if (loggedIn) {
		return [{
			cat_id: 1,
			level: 3,
			catname: getTranslation('account.my_pages'),
			link_friendly_name: getPath('/account'),
			children: [
				{
					cat_id: 2,
					level: 4,
					catname: getTranslation('account.profile'),
					link_friendly_name: getPath('/account'),
					children: []
				},
				{
					cat_id: 3,
					level: 4,
					catname: getTranslation('login.logout'),
					link_friendly_name: getPath('/logout'),
					children: []
				}
			]
		}];
	}

	if (!loggedIn) {
		return [{
			cat_id: 1,
			level: 3,
			catname: getTranslation('account.my_pages'),
			link_friendly_name: getPath('/account'),
			children: [
				{
					cat_id: 2,
					level: 4,
					catname: getTranslation('login.login'),
					link_friendly_name: getPath('/login'),
					children: []
				},
				{
					cat_id: 3,
					level: 4,
					catname: getTranslation('login.register'),
					link_friendly_name: getPath('/register'),
					children: []
				},
				{
					cat_id: 4,
					level: 4,
					catname: getTranslation('password.forgot'),
					link_friendly_name: getPath('/password'),
					children: []
				}
			]
		}];
	}
};

const render = ({props}) => {
	const {active} = props;
	const menu = getMenu(props);

	return <SubMenu class='CategoryNav' data={menu} active={active}/>;
};

export default {propTypes, render, shouldUpdate};
