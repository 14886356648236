/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import imagesloaded from 'imagesloaded';
import zoom from './zoom';

const propTypes = {
	alt: {
		type: 'string'
	},
	class: {
		type: 'string'
	},
	delay: {
		type: 'number'
	},
	src: {
		type: 'string'
	},
	zoom: {
		type: 'string'
	}
};

const defaultProps = {
	alt: '',
	delay: 0,
	src: '',
	zoom: ''
};

const afterRender = ({props}, el) => imagesloaded(el, () => zoom(el.querySelector('.InlineZoom-image'), props.delay));
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const afterUpdate = ({id}) => {
	const zoom = document.querySelector(`.InlineZoom--${id} .InlineZoom-zoomed`);

	if (!zoom) {
		return;
	}

	zoom.src = '';
};

const render = ({id, props}) => {
	const {alt, src, zoom} = props;
	const style = {
		margin: 'auto',
		overflow: 'hidden',
		position: 'relative'
	};

	return (
		<div class={['InlineZoom', `InlineZoom--${id}`, props.class]} style={style}>
			<img class='InlineZoom-image' src={src} alt={alt}/>
			<img class='InlineZoom-zoomed' data-src={zoom}/>
		</div>
	);
};

export default {afterUpdate, afterRender, defaultProps, propTypes, render, shouldUpdate};
