import {connect} from '@viskan/deku-redux';
import {getTranslator} from '../../sources/translator';
import {getSort} from '../articles';
import sort from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	state => ({
		getTranslation: getTranslator(state),
		hasArticlesIfNoFilter: state.articles.firstTotal > 0,
		sort: getSort(state)
	}),
	dispatch => ({
		onSortChange: sortValues => dispatch(sort(sortValues))
	})
)(Component);

export default {component, config};
