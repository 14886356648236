import {connectWrap as connect} from '@viskan/deku-redux';
import BaseComponent from './components';
import {leaveWindowAction} from './actions';

export default connect(
	({shopcart}) => ({
		shopcart
	}),
	() => ({
		leaveWindowAction
	})
)(BaseComponent);

