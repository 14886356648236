import {connect, connectWrap} from '@viskan/deku-redux';
import {fetchBalance, fetchStores, searchStores, setStore} from './actions';
import config from './config';
import BalanceListComponent from './components/balance-list';
import Component from './components';
import SearchComponent from './components/search';
import SearchResultComponent from './components/search-result';
import StoreComponent from './components/store';

export const component = connectWrap(
	state => ({
		site: state.site,
		stores: state.articleStoreBalance.stores
	}),
	() => ({
		fetchStores
	})
)(Component);

export const BalanceList = connect(
	state => ({
		article: state.article.article,
		selectedStore: state.articleStoreBalance.selectedStore,
		sku: state.article.active,
		storeBalance: state.articleStoreBalance.storeBalance
	}),
	dispatch => ({
		fetchBalance: (article, sku, store) => dispatch(fetchBalance(article, sku, store))
	})
)(BalanceListComponent);

export const Search = connect(
	state => ({
		stores: state.articleStoreBalance.stores
	}),
	dispatch => ({
		searchStores: (searchString, stores) => dispatch(searchStores(searchString, stores))
	})
)(SearchComponent);

export const SearchResult = connect(
	state => ({
		searchResult: state.articleStoreBalance.searchResult
	}),
	dispatch => ({
		setStore: store => dispatch(setStore(store))
	})
)(SearchResultComponent);

export const Store = connect(
	state => ({
		selectedStore: state.articleStoreBalance.selectedStore
	})
)(StoreComponent);

export default {component, config};
