import {connect} from '@viskan/deku-redux';
import * as shopcart from '../shopcart/actions';
import {open as openPopup} from '../popup-redux/actions';
import QuickBuy from './components';

export default connect(
	undefined,
	dispatch => ({
		openPopup: (content, options) => dispatch(openPopup(content, options)),
		addToCart: (data, sku) => dispatch(shopcart.addItem(data, sku, [], 1))
	})
)(QuickBuy);
