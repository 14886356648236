/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import {BalanceList, Search, SearchResult, Store} from '..';

const beforeMount = ({props}) => props.stores.length === 0 && props.fetchStores(props.site);

const render = ({props}) => {
	return cmsUtils.createCmsComponent((
		<div class={['CMS-StoreBalance', props.class, props.styleClasses.Store_Balance]} data-style-attr='Store Balance'>
			<Store {...props}/>
			<BalanceList {...props}/>
			<Search {...props}/>
			<SearchResult {...props}/>
		</div>
	), props);
};

export default {beforeMount, render};
