import {connect} from '@viskan/deku-redux';
import {dragStart, fetch as requestCms, setActive, moveNode} from './actions';
import StreamlineCMS from './components';
import OverlayComponent from './components/overlay';
import SectionComponent from './components/section';

export default connect(
	({site, streamlineCms}) => ({
		cmsTokenSet: site.cmsTokenSet,
		cmsUrl: site.cmsUrl,
		loaded: streamlineCms && streamlineCms.loaded
	}),
	dispatch => ({
		requestCms: cmsTokenSet => dispatch(requestCms(cmsTokenSet))
	})
)(StreamlineCMS);

export const Section = connect(
	({app, cms, streamlineCms}, {sectionId}) => ({
		activeNode: cms && cms.componentEditor.activeNode,
		isEditing: streamlineCms && streamlineCms.loaded && cms && cms.section.active === sectionId && !cms.base.hidden,
		content: app.category[sectionId].content
	}),
	dispatch => ({
		dragStart: data => dispatch(dragStart(data))
	})
)(SectionComponent);

export const Overlay = connect(
	({app, cms, streamlineCms}, {sectionId}) => ({
		activeNode: cms && cms.componentEditor.activeNode,
		content: app.category[sectionId].content,
		paths: app.category[sectionId].componentPaths,
		cmsSection: cms && cms.section.list[sectionId]
	}),
	dispatch => ({
		moveNode: (fromId, toId) => dispatch(moveNode(fromId, toId)),
		setActive: node => dispatch(setActive(node))
	})
)(OverlayComponent);
