const getDocumentHeight = () => Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

export const restoreScroll = () => {
	if (!window.history || !window.history.state) {
		window.scrollTo(0, 0);
		return;
	}

	const restorePosition = window.history.state.scrollPosition;

	if (restorePosition) {
		const intervalStart = new Date();

		const interval = setInterval(() => {
			if (getDocumentHeight() - window.innerHeight >= restorePosition) {
				clearInterval(interval);
				window.scrollTo(0, restorePosition);

				return;
			}

			if (new Date() - intervalStart > 500) {
				window.scrollTo(0, 0);
			}

			if (new Date() - intervalStart > 2000) {
				clearInterval(interval);
			}
		}, 50);
	} else {
		window.scrollTo(0, 0);
	}
};

export const restoreScrollMemoryData = key => {
	if (!window.history || !window.history.state) {
		return;
	}

	return window.history.state[key];
};

export const saveScrollMemoryData = (key, data) => {
	if (!window.history || !window.history.state) {
		return;
	}

	window.history.replaceState({
		...window.history.state,
		[key]: data
	}, null);
};
