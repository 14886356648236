export const lite = [
	'cat_desc',
	'cat_desc2',
	'cat_id',
	'catname',
	'h1',
	'h2',
	'h3',
	'link_friendly_name',
	'meta_description',
	'meta_keywords',
	'meta_title'
];

export const blurbs = [
	'categoryBlurbs.category.cat_desc2',
	'categoryBlurbs.category.cat_id',
	'categoryBlurbs.category.catname',
	'categoryBlurbs.height',
	'categoryBlurbs.pos_x',
	'categoryBlurbs.pos_y',
	'categoryBlurbs.width',
	'categoryBlurbs.width'
];
