/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';

const getProperties = props => props.properties.map(property => {
	return (
		<div key={property.propertykey_name_internal} class={['CMS-ArticleProperty', props.styleClasses.Property]} data-style-attr='Property'>
			<div class={['CMS-ArticleProperty-key', props.styleClasses.Key]} data-style-attr='Key'>
				{property.propertykey_name}
			</div>
			<div class={['CMS-ArticleProperty-value', props.styleClasses.Value]} data-style-attr='Value'>
				{property.propertyValues.map(value => <div key={value.propertyvalue} innerHTML={value.propertyvalue}/>)}
			</div>
		</div>
	);
});

const render = ({props}) => cmsUtils.createCmsComponent((
	<div class={['CMS-ArticleProperties', props.class, props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
		{getProperties(props)}
	</div>
), props);

export default {render};
