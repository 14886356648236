import {getLocaleFriendlyPathPrefix, resolveLocalePath} from '@viskan/streamline-utilities/lib/url';
import {PATH_CHANGE} from '../action-types';

const initialState = {
	canonicalPath: '',
	canonicalPathname: '',
	hash: '',
	changes: 0,
	isSearch: false,
	pathPrefix: '',
	localePathPrefixInUse: '',
	params: {},
	path: '',
	pathname: '',
	query: {},
	querystring: '',
	state: {},
	title: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PATH_CHANGE: {
			const {context, site} = action.payload;
			const localePath = resolveLocalePath(context.path, site.localeMode);
			const localePathname = resolveLocalePath(context.pathname, site.localeMode);
			const pathPrefix = getLocaleFriendlyPathPrefix(site.lang_code_web, site.country_code, site.localeMode);

			if (action.payload.context.pathname === '/' && !localePath.isValid) {
				return {
					...action.payload.context,
					canonicalPathname: action.payload.context.pathname,
					changes: state.changes + 1,
					isSearch: false,
					pathPrefix,
					localePathPrefixInUse: false,
					path: action.payload.site.localePickerPath,
					pathname: action.payload.site.localePickerPath,
					searchQuery: ''
				};
			}

			const isSearch = localePathname.path && localePathname.path.startsWith(site.searchPath);
			const usedPathname = isSearch ? site.searchPath : localePathname.path;
			const searchQuery = isSearch ? localePathname.path.replace(`${site.searchPath}/`, '') : '';

			return {
				...action.payload.context,
				canonicalPathname: action.payload.context.pathname,
				changes: state.changes + 1,
				isSearch,
				pathPrefix,
				localePathPrefixInUse: localePath.prefix,
				path: localePath.path,
				pathname: usedPathname,
				searchQuery
			};
		}
		default: {
			return state;
		}
	}
};
