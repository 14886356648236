/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import dom from 'magic-virtual-element';

const addressKeys = [
	'companyName',
	'addressLine1',
	'administrativeDivision',
	'careOf',
	'city',
	'countryCode',
	'zipcode'
];

const personalKeys = [
	'companyName',
	'socialSecurityNumber',
	'organizationNumber',
	'customerNumber',
	'firstName',
	'lastName',
	'cellphone',
	'email'
];

const createFieldTransformer = (getTranslation, translationKey, object) => field => object[field] && ({
	label: getTranslation(`${translationKey}.${field}`),
	value: object[field]
});

const getFields = (getTranslation, translationKey, object, keys) => {
	const fieldTransformer = createFieldTransformer(getTranslation, translationKey, object);
	return keys.map(fieldTransformer).filter(Boolean);
};

const renderRow = field => (
	<Arrange gutter>
		<ArrangeFill>
			<strong>{field.label}</strong>
		</ArrangeFill>
		<ArrangeFit>
			<div class='u-textNoWrap'>
				{field.value}
			</div>
		</ArrangeFit>
	</Arrange>
);

const render = ({props}) => {
	const {customer, getTranslation} = props;
	const personalFields = getFields(getTranslation, 'customer', customer, personalKeys);
	const addressFields = getFields(getTranslation, 'address', customer.address, addressKeys);
	const deliveryAddressFields = getFields(getTranslation, 'address', customer.deliveryAddress, addressKeys);

	return (
		<div class='Receipt-customer'>
			<h1>{getTranslation('receipt.customer_heading')}</h1>
			{personalFields.map(renderRow)}
			<h1>{getTranslation('receipt.address_heading')}</h1>
			{addressFields.map(renderRow)}
			<h1>{getTranslation('receipt.delivery_heading')}</h1>
			{deliveryAddressFields.map(renderRow)}
		</div>
	);
};

export default {render};
