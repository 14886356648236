/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	},
	visibleMarkers: {
		type: 'array'
	},
	visibleText: {
		type: 'array'
	},
	text: {
		type: 'string'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const onMouseEnter = (showMarkerText, markerId) => e => e.toElement === e.delegateTarget && showMarkerText({showText: markerId});
const onMouseLeave = setText => e => e.fromElement === e.delegateTarget && setText({showText: false});

const render = ({props}) => {
	const {data, visibleMarkers, visibleText, showMarkerText, showText} = props;
	const {text, x, y, markerId, number} = data;
	const left = parseInt(x * 100, 10);
	const top = parseInt(y * 100, 10);
	const style = `top: ${top}%; left: ${left}%;`;
	const visibleM = visibleMarkers.indexOf(markerId) !== -1;
	const visibleT = showText === markerId || (visibleText && visibleText.indexOf(markerId) !== -1);
	const directionClass = left > 50 ? 'InstagramMarkerText--left' : 'InstagramMarkerText--right';

	return (
		<div class={['InstagramMarker', 'InstagramMarker--point', {'InstagramMarker--visible': visibleM}]} style={style} onMouseEnter={onMouseEnter(showMarkerText, markerId)} onMouseLeave={onMouseLeave(showMarkerText)}>
			<span class='InstagramMarkerNumber' innerHTML={number}/>
			<div class={['InstagramMarkerText', `${directionClass}`, {'InstagramMarkerText--visible': visibleT}]}>{text}</div>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
