/** @jsx dom */
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import getIcon from '../../../utils/actions';

const getAction = props => {
	const {action, data, shopcartRowIndex, removeFromCart, editCart} = props;
	const row = data[shopcartRowIndex];
	const {row_id, qty} = row;

	if (action === 'remove') {
		return () => removeFromCart(row, qty);
	}

	if (action === 'increase') {
		return () => editCart(row_id, qty + 1);
	}

	if (action === 'decrease') {
		return () => qty > 1 ? editCart(row_id, qty - 1) : removeFromCart(row, qty);
	}
};

const render = ({props}) => {
	const {styleClasses} = props;
	const onAction = getAction(props);

	return cmsUtils.createCmsComponent((
		<span class={['CMS-ShopcartRowRemove', `CMS-ShopcartRowRemove--${props.action}`, props.class, styleClasses.Icon]} data-style-attr='Icon' onClick={onAction}>
			{props.icon && getIcon(props)}
		</span>
	), props);
};

export default {render};
