/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getPrice} = props;
	const {artname, personalization_text, price_exvat, price_incvat} = data;

	return (
		<div class='QuickCart-item-addon u-cf'>
			<span class='QuickCart-item-addon-name'>{artname}</span>
			{price_incvat !== 0 && <span class='QuickCart-item-addon-price u-floatRight'>{getPrice(price_incvat, price_exvat)}</span>}
			{Boolean(personalization_text) && <div class='QuickCart-item-addon-text'>{personalization_text}</div>}
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
