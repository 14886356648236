export default node => ({
	type: 'NEWSLETTER_FORM',
	name: 'Newsletter form',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'Account',
	props: [{
		name: 'source',
		inputType: 'select',
		label: 'Select newsletter source',
		default: 'viskan',
		values: [{
			label: 'Viskan',
			value: 'viskan'
		}, {
			label: 'Apsis',
			value: 'apsis'
		}, {
			label: 'MailChimp',
			value: 'mailChimp'
		}]
	}, node && (node.props.source === 'apsis' || node.props.source === 'mailChimp') && {
		name: 'apiKey',
		inputType: 'text',
		label: 'API Key',
		default: ''
	}, node && (node.props.source === 'apsis' || node.props.source === 'mailChimp') && {
		name: 'mailingListId',
		inputType: 'text',
		label: 'Mailinglist Id',
		default: ''
	}, {
		name: 'header',
		inputType: 'text',
		label: 'Header',
		default: 'Subscribe to our newsletter'
	}, {
		name: 'placeholder',
		inputType: 'text',
		label: 'Placeholder',
		default: 'Enter your email'
	}, {
		name: 'buttonText',
		inputType: 'text',
		label: 'Button text',
		default: 'Subscribe'
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Feedback settings',
		props: [{
			name: 'feedbackHeader',
			inputType: 'text',
			label: 'Feedback header',
			default: 'Thank you for your subscription!'
		}, {
			name: 'feedbackDescription',
			inputType: 'text',
			label: 'Feedback description',
			default: 'You have been added to our list of newsletter recipients.'
		}]
	}, {
		label: 'Concent settings',
		props: [{
			name: 'showCompliance',
			inputType: 'checkbox',
			label: 'Show GDPR compliance',
			default: true
		}, {
			name: 'complianceText',
			inputType: 'text',
			label: 'Compliance text',
			default: 'I agree to my personal data being stored and used to receive the newsletter'
		}, {
			name: 'showConcent',
			inputType: 'checkbox',
			label: 'Show information concent',
			default: true
		}, {
			name: 'concentText',
			inputType: 'text',
			label: 'Concent text',
			default: 'I agree to receive information and commercial offers about our products'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
