/** @jsx dom */
import dom from 'magic-virtual-element';
import Slide from '@viskan/deku-slide';
import {Image} from '~/media';

export const handleImageClick = props => () => {
	props.openPopup((
		<div class='CMS-ArticleMedia-popupImage'>
			<Image key={props.currentMedia.filename} source={props.currentMedia.filename} scale='large' alt={props.currentMedia.media_alt_desc || props.article.artname}/>
		</div>
	), {
		width: 'auto',
		height: '96vh',
		class: 'CMS-ArticleMedia-popup'
	});
};

export const updateSlider = (currentMedia, loop) => instance => {
	if (loop) {
		return;
	}

	instance.slideTo(currentMedia.index);
};

export const getSliderCommon = (props, getContent) => {
	const imageArray = v12.article.medias(props.article, {
		sku: {
			attr1_id: props.active.attr1_id
		},
		size: ''
	});

	const key = imageArray.map(image => image.filename).join(',');
	const hasMultipleImages = imageArray.length > 1;

	const options = {
		arrows: hasMultipleImages && props.sliderArrows,
		color: props.sliderColor,
		loop: hasMultipleImages && props.sliderLoop,
		options: {
			key: `${props.active.attr1_id}-${props.article.art_id}-slider`,
			initialSlide: props.currentMedia.prevIndex || 0
		},
		pagination: hasMultipleImages && props.sliderPagination,
		play: hasMultipleImages && props.sliderAutoplay
	};

	if (props.cmsLoaded) {
		options.key = `${new Date().getTime()}-slider`;
		options.options.simulateTouch = false;
	}

	const onChange = (props, imageArray) => data => {
		const index = data.activeIndex;
		const img = imageArray.find((image, i) => i === index);

		props.setCurrentMedia({
			...img,
			filename: img.filename,
			index,
			path: img.path,
			prevIndex: props.currentMedia.index
		});
	};

	return (<Slide key={key} class='CMS-ArticleMedia-Slider' onInit={updateSlider(props.currentMedia, props.sliderLoop)} onChange={onChange(props, imageArray)} {...options}>{getContent(imageArray, props)}</Slide>);
};
