import {CMS_CATEGORY_IMPORT_FULFILLED} from '../../action-types';

const initialState = {
	data: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CMS_CATEGORY_IMPORT_FULFILLED: {
			const parseData = data => {
				try {
					const res = JSON.parse(data);
					return res;
				} catch (error) {
					return {};
				}
			};

			return {
				...state,
				data: {
					...state.data,
					[action.payload.key]: parseData(action.payload.content)
				}
			};
		}
		default: {
			return state;
		}
	}
};
