export const companyFields = ({props}) => {
	const {data, site, getTranslation} = props;

	return {
		'customer.org_no': {
			label: getTranslation('customer.org_no'),
			value: data.org_no,
			type: 'text',
			size: 'full',
			required: false,
			rules: val => v12.validation(val, {cin: site.country_code})
		},
		'customer.contactperson_1': {
			label: getTranslation('customer.contactperson_1'),
			value: data.contactperson_1,
			size: '1of2',
			type: 'text',
			required: true
		},
		'customer.cust_desc1': {
			label: getTranslation('customer.cust_desc1_alt'),
			value: data.cust_desc1,
			size: '1of2',
			required: true
		},
		'customer.inv_address': {
			label: getTranslation('customer.address'),
			value: data.inv_address,
			size: 'full',
			required: true
		},
		'customer.inv_zipcode': {
			label: getTranslation('customer.zipcode'),
			value: data.inv_zipcode,
			size: '1of2',
			required: true,
			rules: val => v12.validation(val, {zipCode: site.country_code})
		},
		'customer.inv_city': {
			label: getTranslation('customer.city'),
			value: data.inv_city,
			size: '1of2',
			required: true
		},
		'customer.email_1': {
			label: getTranslation('customer.email'),
			size: 'full',
			type: !site.useLookup ? 'text' : 'hidden',
			value: data.email_1,
			required: true,
			rules: val => v12.validation(val, {email: true})
		},
		'customer.phone_2': {
			label: getTranslation('customer.phone'),
			value: data.phone_2,
			required: true,
			rules: val => v12.validation(val, {phone: site.country_code})
		}
	};
};

export const privateFields = ({props}) => {
	const {data, site, getTranslation} = props;

	return {
		'customer.cust_desc2': {
			label: getTranslation('customer.cust_desc2'),
			value: data.cust_desc2,
			size: '1of2',
			type: 'text',
			required: true
		},
		'customer.cust_desc1': {
			label: getTranslation('customer.cust_desc1'),
			value: data.cust_desc1,
			size: '1of2',
			required: true
		},
		'customer.inv_address': {
			label: getTranslation('customer.address'),
			value: data.inv_address,
			size: 'full',
			required: true
		},
		'customer.inv_zipcode': {
			label: getTranslation('customer.zipcode'),
			value: data.inv_zipcode,
			size: '1of2',
			required: true,
			rules: val => v12.validation(val, {zipCode: site.country_code})
		},
		'customer.inv_city': {
			label: getTranslation('customer.city'),
			value: data.inv_city,
			size: '1of2',
			required: true
		},
		'customer.email_1': {
			label: getTranslation('customer.email'),
			size: 'full',
			type: !site.useLookup ? 'text' : 'hidden',
			value: data.email_1,
			required: true,
			rules: val => v12.validation(val, {email: true})
		},
		'customer.phone_2': {
			label: getTranslation('customer.phone'),
			value: data.phone_2,
			required: true,
			rules: val => v12.validation(val, {phone: site.country_code})
		}
	};
};
