/** @jsx dom */
import arrayUniq from 'array-uniq';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import Range from '@viskan/deku-range';

let current = [];
const propTypes = {
	activeFilters: {
		type: 'object'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	onChange: {
		type: 'function'
	}
};

const handleChange = (values, onChange) => obj => {
	if (!onChange || (obj[0] === current[0] && obj[1] === current[1])) {
		return;
	}

	const arr = [];
	current = obj;

	values.forEach((x, i) => {
		if (x.min === obj[0]) {
			arr[0] = i;
		}

		if (x.max === obj[1]) {
			arr[1] = i;
		}
	});

	if (typeof arr[0] === 'undefined') {
		arr[0] = 0;
	}

	if (typeof arr[1] === 'undefined') {
		arr[1] = values.length - 1;
	}

	const ranges = values.filter((x, i) => i >= arr[0] && i <= arr[1]).map(x => ({from: x.min, to: x.max}));

	if (current[1] === values[values.length - 1].min) {
		ranges.push({
			from: current[1],
			to: ''
		});
	}

	onChange({
		type: 'lowest_price_sales',
		value: {
			ranges
		}
	});
};

const formatTooltip = val => val;
const getItems = ranges => ranges.map(x => ({label: x, value: x}));

const getValues = items => {
	const reg = new RegExp(/^(\d*)-(\d*)$/);

	return items.map(x => {
		const arr = reg.exec(x.value);
		return {
			min: arr[1] !== '' ? parseInt(arr[1], 10) : '',
			max: arr[2] !== '' ? parseInt(arr[2], 10) : ''
		};
	});
};

const getStart = active => {
	if (!active.lowest_price_sales || active.lowest_price_sales.ranges.length === 0) {
		return;
	}

	const ranges = active.lowest_price_sales.ranges;
	const arr = [ranges[0].from || 0];

	if (ranges[ranges.length - 1].to !== '') {
		arr.push(ranges[ranges.length - 1].to);
	}

	return arr;
};

const getRanges = input => {
	const ret = {};
	let arr = [];

	input.forEach(x => {
		if (x.min === '') {
			arr.push(0);
			return;
		}

		arr.push(x.min);

		if (x.max === '') {
			return;
		}

		arr.push(x.max);
	});

	arr = arrayUniq(arr);
	const len = arr.length;

	ret.min = [arr[0], arr[1] - arr[0]];
	ret.max = [arr[arr.length - 1]];
	arr.shift();
	arr.pop();

	arr.forEach((x, i) => {
		ret[`${Math.round(((i + 1) / (len - 1)) * 100)}%`] = [x, i === arr.length - 1 ? ret.max - x : arr[i + 1] - x];
	});

	return ret;
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {activeFilters, onChange, ranges} = props;
	const items = getItems(ranges);

	if (items.length === 0) {
		return <noscript/>;
	}

	const values = getValues(items);
	const range = getRanges(values);
	const start = getStart(activeFilters.apiInput);

	return <Range class='Filter-slider' range={range} onSet={handleChange(values, onChange)} start={start} connect tooltips={[{to: formatTooltip}, {to: formatTooltip}]}/>;
};

export default {propTypes, render, shouldUpdate};
