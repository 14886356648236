import {createAction} from 'redux-actions';
import {
	STORE_BALANCE_FETCH,
	STORE_BALANCE_SEARCH,
	STORE_BALANCE_STORE_SET,
	STORES_FETCH
} from '../../../action-types';

export const fetchStores = createAction(STORES_FETCH, site => v12.util.http('/api/store', {query: {
	countryCode: site.country_code
}}).then(({body}) => body));
export const setStore = createAction(STORE_BALANCE_STORE_SET);

const searchifyStoreData = data => data.toLowerCase().replace(/\s/, '');

export const searchStores = createAction(STORE_BALANCE_SEARCH, (searchString, stores) => stores.filter(store =>
	searchifyStoreData(store.address.city).includes(searchString) ||
	searchifyStoreData(store.address.addressLine1).includes(searchString) ||
	searchifyStoreData(store.address.administrativeDivision).includes(searchString) ||
	searchifyStoreData(store.address.zipcode).includes(searchString) ||
	searchifyStoreData(store.storeName).includes(searchString)
));

export const fetchBalance = createAction(STORE_BALANCE_FETCH, (article, sku, store) => {
	return v12.util.http('/api/store/stock-balance', {
		query: {
			art_id: article.art_id,
			attr1_id: sku.attr1_id,
			city: store.address.city
		}
	})
		.then(({body}) => body.find(stockBalance => stockBalance.store.storeId === store.storeId));
});
