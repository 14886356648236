import {createLogger} from 'redux-logger';
import traverse from 'traverse';

const nonComplexConstructors = ['Object', 'Array'];
const isComplexObject = x => typeof x === 'object' && x && x.constructor && !nonComplexConstructors.includes(x.constructor.name);

export const createReduxLogger = options => {
	const diffMethod = options.diff === true || new RegExp(options.diff, 'i');
	const logMethod = options.log === true || new RegExp(options.log, 'i');

	return createLogger({
		collapsed: (getState, action, logEntry) => !logEntry.error,
		diff: Boolean(diffMethod),
		diffPredicate: (getState, action) => diffMethod === true || diffMethod.test(action.type),
		duration: true,
		predicate: (getState, action) => logMethod && (logMethod === true || logMethod.test(action.type)),
		stateTransformer: state => {
			return traverse(state).map(function () {
				if (this.level > options.diffDepth) {
					return this.update(`[LEVEL ABOVE ${options.diffDepth}]`, true);
				}

				if (isComplexObject(this.node)) {
					return this.update('[COMPLEX OBJECT]', true);
				}

				if (this.circular) {
					return this.update('[CIRCULAR]', true);
				}

				return this.node;
			});
		}
	});
};

export const serverErrorLogger = error => {
	try {
		v12.util.error(error.error);
	} catch (error) {
		console.error('Error while posting error to server:', error);
	}
};

export const dekuErrorHandler = (error, errorType, component) => {
	console.error(`Deku ${errorType} error in`, component, `\n\n`, error);
	serverErrorLogger(error);
};
