/** @jsx dom */
import dom from 'magic-virtual-element';
import Select from '@viskan/deku-select';
import {Cell, Grid} from 'viskan-deku-grid';
import isEmpty from 'is-empty';
import {Image} from '../../media';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	}
};

const chooseDeliveryMethod = (props, method) => () => {
	const {checkout, countryCode, getDeliveryPoints, setChosenCity, setChosenDeliveryPoint, shopcart, updateShopcart} = props;

	if (method.deliveryPointRule === 'MANDATORY' && !checkout.shipping.deliveryPoints) {
		getDeliveryPoints({countryCode, deliveryMethodId: method.deliveryMethodId});
	} else {
		setChosenCity(false);
		setChosenDeliveryPoint(false);
	}

	updateShopcart({
		deliverypoint_id: -1,
		shopcart_id_str: shopcart.head.shopcart_id_str,
		wdel_id: method.deliveryMethodId
	});
};

const chooseCity = (setChosenCity, setChosenDeliveryPoint) => e => {
	setChosenDeliveryPoint(false);
	setChosenCity(e.selectCity[0] === 0 ? false : e.selectCity[0]);
};

const chooseDeliveryPoint = (setChosenDeliveryPoint, props) => e => {
	const {checkout, shopcart, updateShopcart} = props;
	const {deliveryMethodId} = props.method;

	const matchingDeliveryPoint = checkout.shipping.deliveryPoints
		.map(deliveryPoint => deliveryPoint.deliveryPointId)
		.filter(id => id === e.selectDeliveryPoint[0]);

	if (isEmpty(matchingDeliveryPoint) || !e.selectDeliveryPoint[0]) {
		setChosenDeliveryPoint(false);
		return false;
	}

	const chosenDeliveryPoint = e.selectDeliveryPoint[0];

	setChosenDeliveryPoint(chosenDeliveryPoint);

	updateShopcart({
		deliverypoint_id: chosenDeliveryPoint,
		shopcart_id_str: shopcart.head.shopcart_id_str,
		wdel_id: deliveryMethodId
	});
};

const renderDeliveryMethods = props => {
	const {checkout, getTranslation} = props;
	const chosenDeliveryMethodId = props.method && props.method.deliveryMethodId;

	return checkout.deliveryMethods.map(delivery => {
		const {deliveryMethodId, methodCode, methodDescription, shippingFee} = delivery.method;

		return (
			<Cell key={deliveryMethodId} size='full' md-size='1of2' lg-size='1of4'>
				<div class={['Shipping-deliveryMethod', chosenDeliveryMethodId === deliveryMethodId && 'Shipping-deliveryMethod--active']}>
					<div class='FormField RadioField'>
						<div class='FormField-controls u-cf'>
							<input class='Shipping-deliveryMethod-radioButton' id={`wdel_${deliveryMethodId}`} type='radio' name='wdel_id' value={deliveryMethodId} onClick={chooseDeliveryMethod(props, delivery.method)}/>
							<label for={`wdel_${deliveryMethodId}`} class='Shipping-optionLabel'>
								<div class='Shipping-optionTitle' innerHTML={methodCode}/>
								<Image class='Shipping-optionImage' source={`wdel_${deliveryMethodId}.png`}/>
								<div class='Shipping-optionDesc' innerHTML={methodDescription}/>
								<div class='Shipping-optionCarriage'>
									{`${getTranslation('checkout.shipping_cost')}: `}<strong>{props.getPrice(shippingFee)}</strong>
								</div>
							</label>
						</div>
					</div>
				</div>
			</Cell>
		);
	});
};

const renderCitiesSelect = props => {
	const {checkout, getTranslation, setChosenCity, setChosenDeliveryPoint} = props;

	if (!checkout.shipping.cities) {
		return <noscript/>;
	}

	const mappedCities = checkout.shipping.cities.map(city => ({
		label: city,
		value: city
	}));

	const cities = [{
		active: true,
		label: getTranslation('checkout.choose_city'),
		value: 0
	},
		...mappedCities
	];

	return <Select name='selectCity' placeholder={getTranslation('checkout.choose_city')} options={cities} onChange={chooseCity(setChosenCity, setChosenDeliveryPoint)}/>;
};

const renderDeliveryPointsSelect = props => {
	const {checkout, getTranslation, setChosenDeliveryPoint} = props;

	if (!checkout.shipping.deliveryPoints) {
		return <noscript/>;
	}

	const mappedDeliveryPoints = checkout.shipping.deliveryPoints
		.filter(deliveryPoint => deliveryPoint.address.city === checkout.shipping.chosenCity)
		.map(deliveryPoint => ({
			label: deliveryPoint.deliveryPointName,
			value: deliveryPoint.deliveryPointId
		}));

	const deliveryPoints = [{
		active: true,
		label: getTranslation('checkout.choose_delivery_point'),
		value: 0
	},
		...mappedDeliveryPoints
	];

	return <Select name='selectDeliveryPoint' placeholder={getTranslation('checkout.choose_delivery_point')} options={deliveryPoints} onChange={chooseDeliveryPoint(setChosenDeliveryPoint, props)}/>;
};

const afterMount = ({props}) => {
	const {checkout, countryCode, getDeliveryPoints} = props;
	const isDeliveryPointNeeded = props.method && props.method.deliveryPointRule === 'MANDATORY';

	if (isDeliveryPointNeeded && !checkout.shipping.cities) {
		getDeliveryPoints({countryCode, deliveryMethodId: props.method.deliveryMethodId});
	}
};

const render = ({props}) => {
	const {checkout} = props;
	const isDeliveryPointNeeded = props.method && props.method.deliveryPointRule === 'MANDATORY';

	return (
		<div class='CheckoutShipping'>
			<Grid gutter>{renderDeliveryMethods(props)}</Grid>
			<div class='Shipping-chooseDeliveryPoint'>
				{isDeliveryPointNeeded && renderCitiesSelect(props)}
				{checkout.shipping.chosenCity && renderDeliveryPointsSelect(props)}
			</div>
		</div>
	);
};

export default {afterMount, propTypes, render};
