/** @jsx dom */
import dom from 'magic-virtual-element';
import {generateMediaURL} from '@viskan/streamline-utilities';
import TwoStepZoom from '../two-step-zoom';

const getTwoStepZoom = props => {
	const {active, cmsLoaded, article, currentMedia, styleClasses, zoomDelay, mediaConfig} = props;
	const key = cmsLoaded ? `${new Date().getTime()}-zoom` : `${active.attr1_id}-${active.attr2_id}-${article.art_id}-superzoom`;
	const src = generateMediaURL(currentMedia.filename, {scale: 'normal'}, mediaConfig);
	const zoom = generateMediaURL(currentMedia.filename, {scale: 'large'}, mediaConfig);

	return (
		<div key={key} class={['CMS-ArticleMedia-Zoom', styleClasses['Zoom Wrapper']]} data-style-attr='Zoom Wrapper'>
			{currentMedia.path &&
				<div class={['CMS-ArticleMedia-mainImage', styleClasses['Main Image']]} data-style-attr='Main Image'>
					<TwoStepZoom key={`${currentMedia.path}-${zoomDelay}`} src={src} zoom={zoom} alt={currentMedia.media_alt_desc || article.artname}/>
				</div>
			}
		</div>
	);
};

export default getTwoStepZoom;
