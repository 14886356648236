import {connect} from '@viskan/deku-redux';
import {close as closePopup, open as openPopup} from '../../popup-redux/actions';
import {getTranslator} from '../../sources/translator';
import setErrorMessage from './actions';
import config from './config';
import LoginForm from './components';

export const component = connect(
	state => ({
		error: state.loginForm.error,
		getTranslation: getTranslator(state),
		pathPrefix: state.router.pathPrefix
	}),
	dispatch => ({
		setErrorMessage: message => dispatch(setErrorMessage(message)),
		closePopup: () => dispatch(closePopup()),
		openPopup: (content, options) => dispatch(openPopup(content, options))
	})
)(LoginForm);

export default {component, config};
