/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import Select from '@viskan/deku-select';
import {utils as cmsUtils} from '@viskan/cms-components';
import options from '../sort-options';

const handleSortChange = onSortChange => selection => onSortChange(selection[Object.keys(selection)[0]]);

const getSortOptions = (sort, getTranslation, options, props) => Object.keys(options).filter(x => props[x]).map(x => {
	return {
		label: getTranslation(options[x].translationKey),
		active: sort.some(y => deepEqual(options[x].value, y)),
		value: options[x].value
	};
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {getTranslation, hasArticlesIfNoFilter, onSortChange, sort, styleClasses} = props;
	const sortOptions = getSortOptions(sort, getTranslation, options, props);
	const sortPlaceholder = getTranslation('category_sorting.placeholder');

	if (!hasArticlesIfNoFilter) {
		return <noscript/>;
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticlesSort', styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<Select styleClasses={styleClasses} class='Article-sort' name='Article-sort' options={sortOptions} onChange={handleSortChange(onSortChange)} placeholder={sortPlaceholder}/>
		</div>
	), props);
};

export default {render, shouldUpdate};
