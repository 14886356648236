import {connect} from '@viskan/deku-redux';
import config from './config';
import Component from './components';

export const component = connect(
	({shopcart, site}) => ({
		data: shopcart.rows,
		mediaConfig: site.mediaConfig
	})
)(Component);

export default {component, config};
