/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'array'
	},
	sku: {
		type: 'object'
	}
};

const getOffers = ({data, sku}) => data.map(x => {
	if (x.attr1_id !== sku.attr1_id || x.attr2_id !== sku.attr2_id || x.attr3_id !== sku.attr3_id) {
		return null;
	}

	return <div key={x.camp_id} class='Offers-item' innerHTML={x.camp_desc}/>;
});

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, sku} = props;

	return (
		<div class={['Offers Offers--list', props.class]}>
			{getOffers({data, sku})}
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
