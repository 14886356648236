import {connect} from '@viskan/deku-redux';
import Article from './components/article';
import Category from './components/category';
import Purchase from './components/purchase';
import Cart from './components/shopcart';

export const NostoArticle = connect(
	state => ({
		article: state.article.article,
		site: state.site
	})
)(Article);

export const NostoCategory = connect(
	state => ({
		data: state.app.category.main,
		localeMode: state.site.localeMode,
		prefix: state.router.pathPrefix,
		site: state.site
	})
)(Category);

export const NostoPurchase = connect(
	({site}) => ({
		site
	})
)(Purchase);

export const NostoCart = connect(
	({shopcart, site}) => ({
		data: shopcart.rows,
		site
	})
)(Cart);
