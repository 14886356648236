/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Cell, Grid} from 'viskan-deku-grid';
import Link from '../../link';
import {Image} from '../../media';

const propTypes = {
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	mediaFilename: {
		type: 'string'
	},
	price: {
		type: 'string'
	}
};

const getPrices = ({unitPrice, unitPriceExcludingTax, discount, discountExcludingTax, total}, quantity, getPrice) => {
	if (discount) {
		return (
			<Cell size='3of8' class='u-textRight'>
				<span class='Row-discount'>
					<strike>{getPrice(unitPrice, unitPriceExcludingTax)}</strike>
				</span>
				<strong>{getPrice(total / quantity, (unitPriceExcludingTax - discountExcludingTax) * quantity)}</strong>
			</Cell>
		);
	}

	return (
		<Cell size='3of8' class='u-textRight'>
			<strong>{getPrice(unitPrice * quantity, unitPriceExcludingTax * quantity)}</strong>
		</Cell>
	);
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {articleUrlPrefix, data, mediaFilename, getPrice} = props;
	const {meta, offerDescription, quantity, value} = data;
	const {articleName, attribute1Id, attribute1Name, attribute2Id, attribute2Name, attribute3Id, attribute3Name, linkFriendlyName} = meta;

	return (
		<div class='Receipt-row'>
			<Grid middle>
				<Cell size='1of8'>
					<figure class='Receipt-image'>
						<Image source={mediaFilename} scale='thumbnail' alt={articleName}/>
					</figure>
				</Cell>
				<Cell size='3of8'>
					<div class='Receipt-name'>
						<Link href={`/${articleUrlPrefix}/${linkFriendlyName}${attribute1Id ? `?attr1_id=${attribute1Id}` : ''}`}>{articleName}</Link><br/>
						<div class='Shopcart-item-attr'>
							<span key={attribute1Id} class='Shopcart-item-attr1'>{attribute1Name}</span><br/>
							<span key={attribute2Id} class='Shopcart-item-attr2'>{attribute2Name}</span>
							<span key={attribute3Id} class='Shopcart-item-attr3'>{attribute3Name}</span>
						</div>
					</div>
					{Boolean(offerDescription.length) && <div class='Receipt-offer' innerHTML={offerDescription}/>}
				</Cell>
				<Cell size='1of8'>
					<div class='Receipt-qty u-textCenter'>{quantity}</div>
				</Cell>
				{getPrices(value, quantity, getPrice)}
			</Grid>
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
