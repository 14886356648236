import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import Component from './components';

export default connect(
	({site}) => ({
		contentManagementActive: site.cmsTokenSet,
		languageId: site.lang_id,
		tag: site.cmsSettings.tags.footer
	}),
	dispatch => ({
		categoryFetch: (tag, options) => dispatch(categoryFetch(tag, 'footer', {...options, key: 'tag'}))
	})
)(Component);
