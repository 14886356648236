/** @jsx dom */
import bus from '@viskan/bus';
import Button from '@viskan/deku-button';
import {Cell, Grid} from 'viskan-deku-grid';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Form} from '@viskan/deku-form';
import {Spinner} from '@viskan/estore-components';
import PasswordFields from './password-fields';
import ProfileFields from './profile-fields';

const propTypes = {
	customer: {
		source: 'customer'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	}
};

const initialState = () => {
	return {
		data: []
	};
};

const onSubmit = (getTranslation, openPopup, setState) => data => {
	setState({
		loading: true,
		status: false
	});

	v12.customer.edit(data)
		.then(data => {
			bus.emit('login', data);

			setState({
				loading: false,
				status: getTranslation('customer.save_success')
			});
		})
		.catch(error => {
			setState({
				loading: false,
				status: false
			});

			openPopup(error.message);
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {customer, getTranslation, openPopup} = props;
	const {loading, status} = state;

	return (
		<div>
			<Form onSubmit={onSubmit(getTranslation, openPopup, setState)} class='account'>
				<Grid gutter>
					<Cell md-size='1of2'>
						<ProfileFields title={getTranslation('account.profile')} data={customer}/>
						<Button class='Button--primary' type='submit'>{getTranslation('global.save')}</Button>
					</Cell>
					<Cell md-size='1of2'>
						<PasswordFields title={getTranslation('password.change')} data={customer}/>
					</Cell>
				</Grid>
				{loading && <Spinner/>}
				{Boolean(status) && <span class='Account-status_message'>{status}</span>}
			</Form>
		</div>
	);
};

export default {initialState, propTypes, render, shouldUpdate};
