/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {utils as cmsUtils} from '@viskan/cms-components';
import Slide from '@viskan/deku-slide';
import ListArticle from '../../../list-article';

const defaultProps = {
	splitByAttribute1: true
};

const stringToArray = str => str ? str.replace(/\s/g, '').split(',') : [];
const parseNumber = (num, def) => parseInt(num, 10) || def;
const sortArticles = (articles, {articleHistory, articleNumbers, source}) => {
	if (articles.length === 0 || (source !== 'Selection' && source !== 'Recent views')) {
		return articles;
	}

	const sortArr = source === 'Selection' ? stringToArray(articleNumbers) : articleHistory;
	return sortArr.reduce((acc, curr) => [...acc, articles.find(x => x.art_no === curr)], []).filter(x => x);
};

const fetchArticles = props => {
	const {articlesFetch, articleHistory, articleNumbers, categoryId, id, source, size, splitByAttribute1} = props;

	if (source === 'selection') {
		articlesFetch(stringToArray(articleNumbers), {size, splitByAttribute1, key: 'search', id, artNos: true});
		return;
	}

	if (source === 'recent' && articleHistory && articleHistory.length > 0) {
		articlesFetch(articleHistory, {size, splitByAttribute1, key: 'search', id, artNos: true});
		return;
	}

	if (categoryId) {
		articlesFetch(categoryId, {size, splitByAttribute1, key: 'cat_id', id});
	}
};

const afterMount = ({props}) => fetchArticles(props);

const afterUpdate = ({props}, prevProps) => {
	if (props.source !== prevProps.source ||
		props.size !== prevProps.size ||
		props.splitByAttribute1 !== prevProps.splitByAttribute1 ||
		(props.source === 'recent' && !deepEqual(props.articleHistory, prevProps.articleHistory)) ||
		(props.source === 'selection' && props.articleNumbers !== prevProps.articleNumbers) ||
		(props.source === 'category' && props.categoryId !== prevProps.categoryId)) {
		fetchArticles(props);
	}
};

const getArticles = (articles, props) => articles.map(x => {
	return (
		<div key={`${x.art_id}-${x.articleAttributes[0].attr1_id}-${x.articleAttributes[0].attr2_id}`}>
			<ListArticle article={x} styleClasses={props.styleClasses}/>
		</div>
	);
});

const render = ({props}) => {
	const articles = props.components[props.id] ? props.components[props.id].articles : [];
	const items = getArticles(sortArticles(articles, props), props);
	const {arrows, breakpoint, effect, cmsLoaded, loop, navigationColor, pagination, paginationType, play, styleClasses} = props;
	const date = new Date().getTime();

	const speed = parseNumber(props.speed, 300);
	const duration = parseNumber(props.duration, 3000);

	const slidesPerViewMobile = parseNumber(props.slidesPerViewMobile, 1);
	const slidesPerColumnMobile = parseNumber(props.slidesPerColumnMobile, 1);
	const spaceBetweenMobile = parseNumber(props.spaceBetweenMobile, 0);
	const slidesPerViewTablet = parseNumber(props.slidesPerViewTablet, 1);
	const slidesPerColumnTablet = parseNumber(props.slidesPerColumnTablet, 1);
	const spaceBetweenTablet = parseNumber(props.spaceBetweenTablet, 0);
	const slidesPerViewDesktop = parseNumber(props.slidesPerViewDesktop, 1);
	const slidesPerColumnDesktop = parseNumber(props.slidesPerColumnDesktop, 1);
	const spaceBetweenDesktop = parseNumber(props.spaceBetweenDesktop, 0);

	const options = {
		breakpoints: {
			640: {
				slidesPerView: slidesPerViewMobile,
				slidesPerColumn: slidesPerColumnMobile,
				spaceBetween: spaceBetweenMobile,
				centeredSlides: props.centeredSlidesMobile
			},
			960: {
				slidesPerView: slidesPerViewTablet,
				slidesPerColumn: slidesPerColumnTablet,
				spaceBetween: spaceBetweenTablet,
				centeredSlides: props.centeredSlidesTablet
			}
		},
		slidesPerView: slidesPerViewDesktop,
		slidesPerColumn: slidesPerColumnDesktop,
		spaceBetween: spaceBetweenDesktop,
		centeredSlides: props.centeredSlidesDesktop
	};

	if (cmsLoaded) {
		options.simulateTouch = false;
	}

	if (cmsLoaded && breakpoint === 'md') {
		options.slidesPerView = slidesPerViewTablet;
		options.slidesPerColumn = slidesPerColumnTablet;
		options.spaceBetween = spaceBetweenTablet;
		options.centeredSlides = props.centeredSlidesTablet;
	}

	if (cmsLoaded && breakpoint === 'xsm') {
		options.slidesPerView = slidesPerViewMobile;
		options.slidesPerColumn = slidesPerColumnMobile;
		options.spaceBetween = spaceBetweenMobile;
		options.centeredSlides = props.centeredSlidesMobile;
	}

	return cmsUtils.createCmsComponent((
		<div class={['CMS-ArticlesSlider', props.styleClasses.Wrapper]} data-style-attr='Wrapper'>
			<Slide key={date} styleClasses={styleClasses} options={options} arrows={arrows} duration={duration} effect={effect} loop={loop} color={navigationColor} pagination={pagination} paginationType={paginationType} play={play} speed={speed}>{items}</Slide>
		</div>
	), props);
};

export default {afterMount, afterUpdate, defaultProps, render};
