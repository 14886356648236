import {connectWrap as connect} from '@viskan/deku-redux';
import {patch as customerPatch} from '../../customer/actions';
import Component from './components';
import config from './config';

const component = connect(
	({customer}) => ({
		isPerson: customer.person_flg
	}),
	() => ({
		customerPatch
	})
)(Component);

export default {component, config};
