export default {
	type: 'INSTAGRAM',
	name: 'Instagram feed',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Social',
	props: [{
		name: 'useWithArticle',
		label: 'Use on article page',
		inputType: 'checkbox',
		default: false
	}],
	hiddenProps: [{
		label: 'Feed options',
		props: [{
			name: 'gutter',
			inputType: 'checkbox',
			label: 'Gutter',
			default: false
		}, {
			name: 'xsm',
			inputType: 'select',
			label: 'Columns mobile (small)',
			default: '2',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'sm',
			inputType: 'select',
			label: 'Columns mobile',
			default: '2',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'md',
			inputType: 'select',
			label: 'Columns tablet',
			default: '3',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'lg',
			inputType: 'select',
			label: 'Columns desktop',
			default: '4',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'xlg',
			inputType: 'select',
			label: 'Columns desktop (large)',
			default: '5',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}]
	}, {
		label: 'Slider options desktop',
		props: [{
			name: 'sliderDesktop',
			inputType: 'checkbox',
			label: 'Use slider on desktop',
			default: false
		}, {
			name: 'rowsDesktop',
			inputType: 'slider',
			label: 'Rows desktop',
			default: 2,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsDesktop',
			inputType: 'slider',
			label: 'Columns desktop',
			default: 5,
			min: 1,
			max: 8,
			step: 1
		}]
	}, {
		label: 'Slider options tablet',
		props: [{
			name: 'sliderTablet',
			inputType: 'checkbox',
			label: 'Use slider on tablet',
			default: false
		}, {
			name: 'rowsTablet',
			inputType: 'slider',
			label: 'Rows tablet',
			default: 1,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsTablet',
			inputType: 'slider',
			label: 'Columns tablet',
			default: 3,
			min: 1,
			max: 8,
			step: 1
		}]
	}, {
		label: 'Slider options mobile',
		props: [{
			name: 'sliderMobile',
			inputType: 'checkbox',
			label: 'Use slider on mobile',
			default: false
		}, {
			name: 'rowsMobile',
			inputType: 'slider',
			label: 'Rows mobile',
			default: 1,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsMobile',
			inputType: 'slider',
			label: 'Columns mobile',
			default: 1,
			min: 1,
			max: 8,
			step: 1
		}]
	}]
};
