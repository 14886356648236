/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	},
	site: {
		source: 'site'
	}
};

const afterUpdate = () => window.nostojs && nostojs(api => api.loadRecommendations());
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const getRows = (arr, {currency_code}) => {
	return arr.map((el, i) => {
		return (
			<div key={i} class='line_item'>
				<span class='product_id'>{el.art_no}</span>
				<span class='quantity'>{el.qty}</span>
				<span class='name'>{el.artname}</span>
				<span class='unit_price'>{el.price_incvat}</span>
				<span class='price_currency_code'>{currency_code}</span>
			</div>
		);
	});
};

const render = ({props}) => {
	const {data, site} = props;

	if (!data || data.length === 0 || !site.nostoId) {
		return <noscript/>;
	}

	return <div class='nosto_cart' style='display:none'>{getRows(data, site)}</div>;
};

export default {afterUpdate, propTypes, render, shouldUpdate};
