export default () => ({
	type: 'SHOPCART_SAVE_FORM',
	name: 'Shopcart save form',
	allowedSections: ['footer', 'header', 'main'],
	disallowedChildren: ['*'],
	category: 'Remarketing',
	props: [{
		name: 'header',
		inputType: 'text',
		label: 'Header',
		default: 'Save your shopcart'
	}, {
		name: 'placeholder',
		inputType: 'text',
		label: 'Placeholder',
		default: 'Enter your email'
	}, {
		name: 'buttonText',
		inputType: 'text',
		label: 'Button text',
		default: 'Save'
	}],
	hiddenProps: [{
		label: 'Feedback settings',
		props: [{
			name: 'feedbackHeader',
			inputType: 'text',
			label: 'Feedback header',
			default: 'Shopcart saved!'
		}, {
			name: 'feedbackDescription',
			inputType: 'text',
			label: 'Feedback description',
			default: 'Your shopcart has now been save and will be sent to your E-mail.'
		}]
	}]
});
