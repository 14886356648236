export default {
	type: 'SEARCH_FORM',
	name: 'Search',
	allowedSections: ['header'],
	disallowedChildren: ['*'],
	category: 'Header',
	props: [{
		name: 'placeholder',
		inputType: 'text',
		label: 'Search placeholder',
		default: 'Search...'
	}, {
		name: 'icon',
		inputType: 'icon',
		label: 'Choose an icon',
		required: true,
		default: false
	}, {
		name: 'iconSize',
		inputType: 'text',
		label: 'Size on icon',
		default: '1em'
	}, {
		name: 'iconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}, {
		name: 'image',
		inputType: 'media',
		label: 'Choose an image',
		default: ''
	}, {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}]
};
