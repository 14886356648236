import {connect} from '@viskan/deku-redux';
import * as shopcart from '../shopcart/actions';
import {getTranslator} from '../sources/translator';
import fetch from './actions';
import Component from './components';

export default connect(
	state => ({
		articleUrlPrefix: state.site.articleUrlPrefix,
		countryCode: state.site.country_code,
		getTranslation: getTranslator(state),
		receipt: state.receipt.data,
		shopcart: state.shopcart
	}),
	dispatch => ({
		fetchShopcart: dontOpen => dispatch(shopcart.fetch(dontOpen)),
		removeShopcart: () => dispatch(shopcart.remove()),
		fetch: (type, params) => dispatch(fetch(type, params))
	})
)(Component);
