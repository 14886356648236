/** @jsx dom */
import dom from 'magic-virtual-element';
import Zoom from '@viskan/deku-inline-zoom';
import {generateMediaURL} from '@viskan/streamline-utilities';
import {handleImageClick} from '../common';

const getZoom = props => {
	const {active, cmsLoaded, article, currentMedia, styleClasses, zoomPopup, zoomDelay, mediaConfig} = props;
	const key = cmsLoaded ? `${new Date().getTime()}-zoom` : `${active.attr1_id}-${article.art_id}-zoom`;
	const src = generateMediaURL(currentMedia.filename, {scale: 'normal'}, mediaConfig);
	const zoom = generateMediaURL(currentMedia.filename, {scale: 'large'}, mediaConfig);

	return (
		<div key={key} class={['CMS-ArticleMedia-Zoom', styleClasses['Zoom Wrapper']]} data-style-attr='Zoom Wrapper'>
			{currentMedia.path &&
				<div class={['CMS-ArticleMedia-mainImage', styleClasses['Main Image']]} onClick={zoomPopup && handleImageClick(props)} data-style-attr='Main Image'>
					<Zoom key={`${currentMedia.path}-${zoomDelay}`} src={src} zoom={zoom} alt={props.currentMedia.media_alt_desc || props.article.artname} delay={parseInt(props.zoomDelay, 10)}/>
				</div>
			}
		</div>
	);
};

export default getZoom;
