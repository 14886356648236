/** @jsx dom */
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Icon} from '@viskan/estore-components';

const getAttribute2 = (id, props) => props.article.articleAttributes.find(attribute => attribute.attr2_id === id);
const getAttribute3 = (id, props) => props.article.articleAttributes.find(attribute => attribute.attr3_id === id);

const getBalanceItems = props => props.storeBalance.stockKeepingUnits.map(stockKeepingUnit => {
	const inStock = Boolean(stockKeepingUnit.quantity);
	const attribute2 = stockKeepingUnit.attribute2Id !== 0 && getAttribute2(stockKeepingUnit.attribute2Id, props);
	const attribute3 = stockKeepingUnit.attribute3Id !== 0 && getAttribute3(stockKeepingUnit.attribute3Id, props);

	const iconClasses = {
		'CMS-StoreBalanceList-icon': true,
		'CMS-StoreBalanceList-icon--inStock': inStock,
		'CMS-StoreBalanceList-icon--outOfStock': !inStock,
		[props.styleClasses['Icon In Stock']]: inStock,
		[props.styleClasses['Icon Out Of Stock']]: !inStock
	};

	return (
		<div key={stockKeepingUnit.stockKeepingUnitId} class={['CMS-StoreBalanceList-item', props.styleClasses['List Item']]} data-style-attr='List Item'>
			<span>{props.sku.attribute1.attr1_code}</span>
			<span>{attribute2 && `, ${attribute2.attribute2.attr2_code}`}</span>
			<span>{attribute3 && `, ${attribute3.attribute3.attr3_code}`}</span>
			<Icon class={iconClasses} data-style-attr={inStock ? 'Icon In Stock' : 'Icon Out Of Stock'} style={inStock ? 'in-stock' : 'out-of-stock'}/>
		</div>
	);
});

const afterMount = ({props}) => props.selectedStore && props.fetchBalance(props.article, props.sku, props.selectedStore);
const afterRender = ({props}) => props.selectedStore && props.fetchBalance(props.article, props.sku, props.selectedStore);
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	if (props.storeBalance.length === 0) {
		return <noscript/>;
	}

	return (
		<div class={['CMS-StoreBalanceList', props.styleClasses['Balance List']]} data-style-attr='Balance List'>
			{getBalanceItems(props)}
		</div>
	);
};

export default {afterMount, afterRender, render, shouldUpdate};
