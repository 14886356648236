import {connect} from '@viskan/deku-redux';
import {close as closePopup, open as openPopup} from '../../popup-redux/actions';
import {feedFetch, nextPagination, popupOpen, setActiveIndex, setCurrentIndex, setMarkers, showMarkerText, setNextIndex, setPrevIndex} from './actions';
import config from './config';
import Instagram from './components';
import SliderComponent from './components/slider';
import FeedComponent from './components/feed';
import PostComponent from './components/post';
import ModalComponent from './components/modal';
import PointMarkerComponent from './components/point-marker';

export const component = connect(
	state => ({
		artId: state.article.article.art_id,
		breakpoint: state.breakpoint,
		data: state.instagram.data,
		from: state.instagram.from,
		grams: state.instagram.grams,
		size: state.instagram.size,
		total: state.instagram.total
	}),
	dispatch => ({
		feedFetch: options => dispatch(feedFetch(options))
	})
)(Instagram);

export const Slider = connect(
	state => ({
		activeIndex: state.instagram.activeIndex
	}),
	dispatch => ({
		onReachEnd: () => dispatch(nextPagination()),
		setActiveIndex: index => dispatch(setActiveIndex(index))
	})
)(SliderComponent);

export const Feed = connect(
	state => ({
		total: state.instagram.total
	}),
	dispatch => ({
		onScrollPastBottom: () => dispatch(nextPagination())
	})
)(FeedComponent);

export const Post = connect(
	state => ({
		breakpoint: typeof state.breakpoint.breakpointOverride === 'number' ? state.breakpoint.breakpointOverride : state.breakpoint.breakpoint
	}),
	dispatch => ({
		closePopup: () => dispatch(closePopup()),
		openPopup: (content, options) => dispatch(openPopup(content, options)),
		setCurrentIndex: index => dispatch(setCurrentIndex(index))
	})
)(PostComponent);

export const Modal = connect(
	state => ({
		data: state.instagram.data,
		loaded: state.instagram.loaded,
		index: state.instagram.index,
		visibleMarkers: state.instagram.visibleMarkers,
		visibleText: state.instagram.visibleText
	}),
	dispatch => ({
		popupOpen: data => dispatch(popupOpen(data)),
		setMarkers: (visibleMarkers, visibleText) => dispatch(setMarkers(visibleMarkers, visibleText)),
		setNextIndex: (post, index) => dispatch(setNextIndex(post, index)),
		setPrevIndex: (post, index) => dispatch(setPrevIndex(post, index))
	})
)(ModalComponent);

export const PointMarker = connect(
	state => ({
		showText: state.instagram.showText
	}),
	dispatch => ({
		showMarkerText: markerId => dispatch(showMarkerText(markerId))
	})
)(PointMarkerComponent);

export default {component, config};
