/** @jsx dom */
import deepEqual from 'deep-equal';
import debounce from 'debounce';
import dom from 'magic-virtual-element';
import loadScript from 'load-script';
import pify from 'pify';
import scrollTo from 'scroll-to';
import {getClosestStores} from '../actions';
import * as themes from '../themes';

const loadGoogleScript = apiKey => {
	if (typeof window.google !== 'undefined' && window.google.maps) {
		return Promise.resolve();
	}

	return pify(loadScript)(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry`);
};

const closeAllInfoWindows = (map, markers) => {
	markers.forEach(marker => {
		marker.infowindow.close(map, marker);
	});
};

const getInfoWindow = store => {
	return new window.google.maps.InfoWindow({
		content: (`<div class="FindStore-marker"><div class="FindStore-marker-row">${store.storeName}</div></div>`)
	});
};

const onMarkerClick = (map, marker, markers) => () => {
	closeAllInfoWindows(map, markers);

	marker.infowindow.open(map, marker);

	map.setCenter(new window.google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng()));
};

const placeMarkers = (map, {centerAllStores, icon, stores}) => {
	if (stores || stores.length > 0) {
		const markers = stores.map(store => {
			const marker = new window.google.maps.Marker({
				map,
				icon,
				title: location.title,
				infowindow: getInfoWindow(store)
			});

			marker.setOptions({
				animation: window.google.maps.Animation.DROP,
				position: new window.google.maps.LatLng(store.address.latitude, store.address.longitude)
			});

			return marker;
		});

		markers.forEach(marker => {
			window.google.maps.event.addListener(marker, 'click', onMarkerClick(map, marker, markers));
		});

		const bounds = new window.google.maps.LatLngBounds();

		if (centerAllStores) {
			markers.map(x => bounds.extend(x.getPosition()));
			map.fitBounds(bounds);
		}
	}
};

const onMapIdle = (map, maxClosestStores, setStores, stores) => debounce(() => {
	const max = parseInt(maxClosestStores, 10);
	const closestArr = getClosestStores(stores, {latitude: map.data.map.center.lat(), longitude: map.data.map.center.lng()});

	setStores({activeStore: closestArr[0], closestStores: closestArr.slice(1, (max + 1))});
}, 500, true);

const setClosestStore = (map, {maxClosestStores, setStores, stores}) => {
	if (stores && stores.length > 0) {
		window.google.maps.event.clearListeners(map, 'idle');
		window.google.maps.event.addListener(map, 'idle', onMapIdle(map, maxClosestStores, setStores, stores));
	}
};

const unBindEvents = map => () => {
	window.google.maps.event.clearListeners(map, 'idle');
};

const afterUpdate = ({id, props}, prevProps) => {
	const {location, theme} = props;
	const zoom = props.zoomCountry > 0 ? props.zoomCountry : parseInt(props.zoom, 10);
	const element = document.querySelector(`#FindStore-map-${id}`);

	if (!element) {
		return Promise.resolve();
	}

	loadGoogleScript(props.apiKey)
	.then(() => {
		const map = new window.google.maps.Map(element, {
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			styles: themes[theme]
		});

		scrollTo(0, 0, {
			duration: 250,
			ease: 'out-circ'
		});

		map.setCenter(new window.google.maps.LatLng(location.latitude, location.longitude));
		placeMarkers(map, props);
		if (location !== prevProps.location) {
			setClosestStore(map, props);
			unBindEvents(map);
		}
		map.setZoom(zoom);

		window.google.maps.event.addDomListener(window, 'resize', () => {
			map.setCenter(new window.google.maps.LatLng(location.latitude, location.longitude));
		});
	});
};

const shouldUpdate = ({props}, nextProps) => deepEqual(props.closestStores, nextProps.closestStores) && deepEqual(props.activeStore, nextProps.activeStore);

const render = ({id, props}) => {
	const {height, maxWidth, note} = props;

	const style = {
		'max-width': maxWidth,
		height
	};

	return (
		<div class={['FindStore-canvas', props.class]}>
			<div id={`FindStore-map-${id}`} style={style}/>
			{note && <i class='FindStore-note'>{note}</i>}
		</div>
	);
};

export default {afterUpdate, render, shouldUpdate};
