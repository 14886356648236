export default {
	type: 'ARTICLE_STORE_BALANCE',
	name: 'Store Balance',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-warehouse-alt',
	props: [],
	hiddenProps: [{
		label: 'Search settings',
		props: [{
			name: 'searchPlaceholder',
			inputType: 'text',
			label: 'Placeholder',
			default: 'Search for a store'
		}, {
			name: 'searchButtonText',
			inputType: 'text',
			label: 'Button text',
			default: 'Search'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
