import {
	CATEGORY_FETCH_FULFILLED,
	CATEGORY_FETCH_PENDING,
	CATEGORY_FETCH_REJECTED,
	CMS_DRAFT_CATEGORY_FETCH_FULFILLED,
	CMS_DRAFT_CATEGORY_POST_FULFILLED,
	CMS_NODE_DELETE,
	CMS_NODE_INSERT,
	CMS_NODE_MOVE,
	CONTENT_RESET,
	CONTENT_SET,
	CONTENT_SET_FULFILLED,
	PATH_CHANGE,
	SET_COMPONENT_PROPERTY
} from '../action-types';
import * as contentApi from './content';

const defaultCategoryState = {content: [], notFound: false};

const initialState = {
	category: {
		article: defaultCategoryState,
		header: defaultCategoryState,
		footer: defaultCategoryState,
		main: defaultCategoryState
	},
	cssNamespace: {
		content: 'App-content'
	},
	initialCategory: {
		article: defaultCategoryState,
		header: defaultCategoryState,
		footer: defaultCategoryState,
		main: defaultCategoryState
	},
	categoryIsPending: true,
	isCrawler: /bot|crawler|crawling|googlebot|robot|spider/i.test(navigator.userAgent.toLowerCase()),
	latestCategoryId: -1
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONTENT_SET: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: action.payload.content,
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CONTENT_RESET: {
			return {
				...state,
				category: {
					...state.initialCategory
				}
			};
		}
		case CONTENT_SET_FULFILLED: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: action.payload.content,
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CMS_NODE_DELETE: {
			const {node, sectionId} = action.payload;
			const {content} = state.category[sectionId];
			const newContent = contentApi.deleteNode(content, node.id);

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case CMS_NODE_INSERT: {
			const {node, sectionId, toIndex = 0, toId} = action.payload;
			const {content} = state.category[sectionId];
			const newContent = contentApi.insertNode(content, toId, node, {childIndex: toIndex});

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case CMS_NODE_MOVE: {
			const {node, sectionId, toIndex = 0, toId} = action.payload;
			const {content, componentPaths: paths} = state.category[sectionId];
			const parent = contentApi.getParentByPath(content, paths[node.id]);
			const parentId = parent ? parent.id : 0;
			const targetId = typeof toId === 'undefined' ? parentId : toId;
			const newContent = contentApi.moveNode(content, node.id, targetId, {childIndex: toIndex});

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case SET_COMPONENT_PROPERTY: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: contentApi.update(action.payload.content, action.payload.activeNode.id, action.payload.key, action.payload.value),
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CATEGORY_FETCH_PENDING: {
			const obj = {};

			obj[action.meta.section] = {
				content: [],
				componentPaths: [],
				notFound: state.category[action.meta.section].notFound
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				categoryIsPending: true
			};
		}

		case CATEGORY_FETCH_FULFILLED: {
			const obj = {};
			const content = JSON.parse(action.payload.data.content || '[]');

			obj[action.payload.section] = {
				...action.payload.data,
				content,
				componentPaths: contentApi.getPaths(content),
				languageId: action.meta.languageId,
				notFound: false
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				initialCategory: {
					...state.initialCategory,
					...obj
				},
				latestCategoryId: action.payload.section === 'main' ? action.payload.data.categoryId : state.latestCategoryId,
				categoryIsPending: false
			};
		}
		case CMS_DRAFT_CATEGORY_FETCH_FULFILLED: {
			return {
				...state,
				initialCategory: {
					...state.category
				}
			};
		}
		case CMS_DRAFT_CATEGORY_POST_FULFILLED: {
			if (!action.meta.section) {
				return state;
			}

			const content = JSON.parse(action.payload.content || '[]');
			const nextCategory = {
				...state.category,
				[action.meta.section]: {
					...state.category[action.meta.section],
					...action.payload,
					content,
					componentPaths: contentApi.getPaths(content)
				}
			};

			return {
				...state,
				category: nextCategory,
				initialCategory: nextCategory
			};
		}
		case CATEGORY_FETCH_REJECTED: {
			const obj = {};

			obj[action.meta.section] = {
				content: [],
				componentPaths: [],
				notFound: true
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				initialCategory: {
					...state.initialCategory,
					...obj
				},
				categoryIsPending: false
			};
		}
		case PATH_CHANGE: {
			return {
				...state,
				category: {
					...state.category,
					main: defaultCategoryState
				},
				initialCategory: {
					...state.initialCategory,
					main: defaultCategoryState
				}
			};
		}
		default: {
			return state;
		}
	}
};
