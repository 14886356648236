import {createAction} from 'redux-actions';
import {ARTICLE_MEDIA_CURRENT_SET} from '../../../action-types';

export const setCurrentMedia = createAction(ARTICLE_MEDIA_CURRENT_SET);

export const setDefaultCurrentMedia = createAction(ARTICLE_MEDIA_CURRENT_SET, props => {
	const imageArray = v12.article.medias(props.article, {
		sku: {
			attr1_id: props.active.attr1_id
		},
		size: ''
	});

	return imageArray[0] || {};
});
