/** @jsx dom */
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';

const propTypes = {
	data: {
		type: 'object'
	},
	title: {
		type: 'string'
	}
};

const onClick = (wdel_id, shopcart_id, setState) => () => {
	setState({active: wdel_id});
	v12.shopcart.update({shopcart_id, wdel_id}).then(() => bus.emit('shopcart:get'));
};

const getImage = id => `/public/images/wdel/${id}.png`;

const getShipping = ({deliveryMethods, shopcart}, active, setState) => {
	const {head, outorder} = shopcart;
	const {shopcart_id} = head;
	const {wdel_id} = outorder;

	return deliveryMethods
		.filter(x => x.applicable)
		.map(({method}) => {
			const classes = {
				'Shipping-option': true,
				'Shipping-option--active': (active || wdel_id) === method.deliveryMethodId
			};

			return (
				<div key={method.deliveryMethodId} class={['FormField RadioField', classes]}>
					<div class='FormField-controls u-cf'>
						<label for={`delivery.${method.deliveryMethodId}`} class='Shipping-option-label u-floatLeft'>
							<input type='radio' name='wdel_id' id={`delivery.${method.deliveryMethodId}`} value={method.deliveryMethodId} checked={method.deliveryMethodId === wdel_id} onClick={onClick(method.deliveryMethodId, shopcart_id, setState)}/>
							{method.methodDescription || method.methodCode}
						</label>
						<img class='Shipping-option-img u-floatRight' src={getImage(method.deliveryMethodId)}/>
					</div>
				</div>
			);
		});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const render = ({props, state}, setState) => {
	const {data, title} = props;
	const {active} = state;

	return (
		<fieldset class='Shipping'>
			<h2 class='Heading u-marginBottom'><i class='Icon Icon--bus'/>{title}</h2>
			<div class='Shipping-options'>
				{getShipping(data, active, setState)}
			</div>
		</fieldset>
	);
};

export default {propTypes, render, shouldUpdate};
