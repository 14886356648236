export default {
	type: 'ARTICLE_FIELD',
	name: 'Field',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		default: 'artname',
		values: [{
			label: 'Article name',
			value: 'artname'
		}, {
			label: 'Article number',
			value: 'art_no'
		}, {
			label: 'Short description',
			value: 'art_desc'
		}, {
			label: 'Long description',
			value: 'art_desc2'
		}, {
			label: 'Brand',
			value: 'brandName'
		}]
	}, {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}, {
			label: 'H1',
			value: 'h1'
		}, {
			label: 'H2',
			value: 'h2'
		}, {
			label: 'H3',
			value: 'h3'
		}, {
			label: 'H4',
			value: 'h4'
		}, {
			label: 'H5',
			value: 'h5'
		}, {
			label: 'H6',
			value: 'h6'
		}]
	}, {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
