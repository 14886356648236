/** @jsx dom */
import {Arrange, ArrangeFill, ArrangeFit} from 'deku-arrange';
import Button from '@viskan/deku-button';
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {Form, InputField} from '@viskan/deku-form';

const propTypes = {
	openPopup: {
		source: 'openPopup'
	}
};

const onSubmit = (openPopup, getTranslation, setState) => data => {
	bus.emit('shopcart:data');
	bus.emit('loading:open');

	v12.shopcart.edit({camp_code: data.camp_code || -1})
		.then(data => {
			if (data.head.camp_code_error) {
				openPopup(getTranslation('shopcart.discount_error'));
				setState({code: ''});
				return;
			}

			setState({code: data.head.camp_code});
		})
		.then(() => {
			bus.emit('shopcart:get');
			bus.emit('loading:close');
		})
		.catch(error => {
			v12.util.error(error);
			bus.emit('loading:close');
		});
};

const shouldUpdate = ({state}, nextProps, nextState) => !deepEqual(state, nextState);

const render = ({props, state}, setState) => {
	const {getTranslation, openPopup} = props;
	const {code} = state;

	return (
		<Form class='ShopcartDiscount' onSubmit={onSubmit(openPopup, getTranslation, setState)}>
			<Arrange>
				<ArrangeFill>
					<div class='ShopcartDiscount-input'>
						<InputField name='camp_code' id='camp_code' placeholder={getTranslation('shopcart.discount_code')}/>
					</div>
				</ArrangeFill>
				<ArrangeFit>
					<div class='ShopcartDiscount-submit'>
						<Button class='Button--primary'>{getTranslation('global.send')}</Button>
					</div>
				</ArrangeFit>
			</Arrange>
			{code && <div>{getTranslation('shopcart.discount_success', {code})}</div>}
		</Form>
	);
};

export default {propTypes, render, shouldUpdate};
