/** @jsx dom */
import dom from 'magic-virtual-element';
import {Section} from '../../streamline-cms';

const afterMount = ({props}) => props.categoryFetch('404/', 'main', {languageId: props.languageId}, {dontRedirect: true});

const render = () => (
	<div class='Container NotFound'>
		<section>
			<Section sectionId='main'/>
		</section>
	</div>
);

export default {afterMount, render};
