import {reducer as cmsComponents} from '@viskan/cms-components';
import app from './app/reducer';
import article from './article/reducer';
import articleAddons from './cms-components/article/addons/reducer';
import articleBackInStock from './cms-components/article/back-in-stock/reducer';
import articleMedia from './cms-components/article/media/reducer';
import articleStoreBalance from './cms-components/article/store-balance/reducer';
import checkout from './checkout/reducer';
import articles from './cms-components/articles/reducer';
import articlesSlider from './cms-components/articles-slider/reducer';
import breakpoint from './breakpoint/reducer';
import categoryImport from './cms-components/category-import/reducer';
import customer from './customer/reducer';
import findStore from './cms-components/find-store/reducer';
import instagram from './cms-components/instagram/reducer';
import loginForm from './cms-components/login-form/reducer';
import popup from './popup-redux/reducer';
import quickCart from './cms-components/quick-cart/reducer';
import receipt from './receipt/reducer';
import router from './router/reducer';
import shopcart from './shopcart/reducer';
import site from './site/reducer';
import streamlineCms from './streamline-cms/reducer';
import tree from './tree/reducer';

export default {
	app,
	article,
	articleAddons,
	articleBackInStock,
	articleMedia,
	articleStoreBalance,
	articles,
	articlesSlider,
	breakpoint,
	categoryImport,
	checkout,
	cmsComponents,
	customer,
	findStore,
	instagram,
	loginForm,
	popup,
	quickCart,
	shopcart,
	site,
	receipt,
	router,
	tree,
	streamlineCms
};
