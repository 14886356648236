import {createAction} from 'redux-actions';
import {trackImpression} from '../../analytics';
import {ARTICLES_SLIDER_FETCH} from '../../action-types';

const removeDuplicates = articles => articles.reduce((acc, curr) => {
	if (!acc.find(x => x.art_id === curr.art_id)) {
		return [...acc, curr];
	}

	return acc;
}, []);

const articlesSearch = (identifier, {size, id, artNos}) => {
	return v12.search(identifier, {size, query: {artNos}})
		.then(({articles, meta}) => {
			if (!articles) {
				return {
					articles: [],
					meta,
					id
				};
			}
			const {hits} = articles;
			const filteredArticles = hits.filter(x => identifier.includes(x.art_no));
			const retArticles = meta.split_flg ? removeDuplicates(filteredArticles) : filteredArticles;

			trackImpression(retArticles);

			return {
				articles: retArticles,
				meta,
				id
			};
		});
};

export const articlesFetch = createAction(ARTICLES_SLIDER_FETCH, (identifier, options) => {
	const {artNos, id, key, size} = options;

	if (key === 'search') {
		return articlesSearch(identifier, {size, id, artNos});
	}

	return v12.articles.get(identifier, {
		size: parseInt(size, 10),
		key,
		filter: {},
		query: {
			splitByAttribute1: options.splitByAttribute1
		}
	})
		.then(data => {
			trackImpression(data.articles.hits);

			return {
				articles: data.articles.hits,
				meta: data.meta,
				id
			};
		});
});
