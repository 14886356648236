/** @jsx dom */
import {CheckboxField} from '@viskan/deku-form';
import bus from '@viskan/bus';
import deepEqual from 'deep-equal';
import dom from 'magic-virtual-element';
import {lite} from '../utils/fields';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	site: {
		source: 'site'
	},
	validationError: {
		source: 'validationError'
	}
};

const onClick = languageId => event => {
	event.preventDefault();

	v12.category.get('terms-vco', {
		fields: lite,
		key: 'tag',
		languageId
	})
		.then(data => bus.emit('popup:open', <div innerHTML={data.category.cat_desc2}/>, {class: 'TermsPopup'}))
		.catch(v12.util.error);
};

const getLabel = props => {
	return (
		<span>
			{`${props.getTranslation('checkout.accept_terms')} `}
			<a href='#' onClick={onClick(props.languageId)}>
				{props.getTranslation('checkout.terms').toLowerCase()}
			</a>
		</span>
	);
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {getTranslation, validationError} = props;

	return (
		<section>
			<CheckboxField name='terms' label={getLabel(props)} validationMessage={validationError(getTranslation('checkout.terms'), getTranslation)} required/>
		</section>
	);
};

export default {propTypes, render, shouldUpdate};
